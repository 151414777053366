import { useLayoutToast } from "../store/store";

export const showErrorToast = ({ error }) => {
  const { layoutToast } = useLayoutToast.getState();

  if (error.code === "ERR_CANCELED") return;

  layoutToast.current.show({
    severity: "error",
    summary: "Error",
    detail:
      error?.response?.data?.message ||
      "Something went wrong. Please try again later.",
    life: 4000,
  });
};

export const showSuccessToast = ({ message }) => {
  const { layoutToast } = useLayoutToast.getState();

  layoutToast.current.show({
    severity: "success",
    summary: "Success",
    detail: message,
    life: 4000,
  });
};
