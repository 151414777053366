import { useRef } from "react";

import { Tooltip } from "primereact/tooltip";

import {
  NUMBER_TYPE_ENUM,
  QUESTION_TYPES_ENUM,
} from "../../../utils/constants";

const ReferenceTemplate = ({ option }) => {
  const isNumber = option.type === QUESTION_TYPES_ENUM.NUMBER.value;
  const isStandard =
    option.constraints.type === NUMBER_TYPE_ENUM.STANDARD.value;

  const isSafe = isNumber && isStandard;

  const tooltip = useRef();

  return (
    <div className="flex items-center gap-2">
      <Tooltip position="top" target={tooltip} className="max-w-xs">
        <TooltipMessage option={option} />
      </Tooltip>
      {!isSafe && (
        <i ref={tooltip} className="pi pi-info-circle text-yellow-600" />
      )}
      <span>{option.label}</span>
    </div>
  );
};

const TooltipMessage = ({ option }) => {
  const optionType = Object.values(NUMBER_TYPE_ENUM).find(
    (item) => item.value === option.constraints.type,
  );

  return (
    <div>
      <strong className="capitalize">
        {optionType.label} - {option.type}
      </strong>
      <p>The value will be rounded to the nearest whole number</p>
    </div>
  );
};

export default ReferenceTemplate;
