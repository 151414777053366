import { WEIGHT_OPTIONS_ENUM } from "./constants";

export const convertStandardToAmerican = (value) => {
  const feet = Math.floor(value);
  const inches = Math.round((value - feet) * 12);
  return { feet, inches };
};

export const convertAmericanToStandard = (feet, inches) => {
  return feet + inches / 12;
};

const poundToKilogram = (value) => value / 2.20462262;

export const convertStandardToWeight = (value, storeAs) => {
  if (storeAs === WEIGHT_OPTIONS_ENUM.KGS) {
    return poundToKilogram(value)?.toFixed(2);
  } else {
    return value?.toFixed(2);
  }
};
