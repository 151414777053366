import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React, { useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import DescriptionWithTooltipTemplate from "../../rules/DescriptionWithTooltipTemplate";
import { ModTemplate } from "../../../templates/ModTemplate";
import { PriceBillableTemplate } from "../../../templates/PriceBillableTemplate";
import { useServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import {
  getAllBillableItemsInRules,
  getBillableItemsIds,
} from "../../rules/actions/helpers";
import useFetchBillablesByIds from "../../rules/useFetchBillablesByIds";
import { BillableItemsAttributesTemplate } from "../../../templates/BillableItemsAttributesTemplate";

const ChooseBillableItems = ({ inputNamePath }) => {
  const { control, getValues } = useFormContext();

  const [tableVisible, setTableVisible] = useState(false);

  const { defaultBillableItems } = useServiceDetailsContext();

  const billableItemsInRulesExceptCurrent = getAllBillableItemsInRules(
    getValues("id"),
  );

  const allBillableItems = [
    ...billableItemsInRulesExceptCurrent,
    ...defaultBillableItems,
  ];

  const allBillableItemsIds = getBillableItemsIds(allBillableItems);

  const { isLoading, billableItems: billableItemsWithData } =
    useFetchBillablesByIds(allBillableItemsIds);

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="Select A Billable Item"
        visible={tableVisible}
        onHide={() => setTableVisible(false)}
      >
        <div className="flex flex-col p-4">
          <div className="flex flex-col gap-2">
            <Controller
              name={inputNamePath}
              control={control}
              render={({ field }) => (
                <DataTable
                  loading={isLoading}
                  value={billableItemsWithData}
                  emptyMessage={
                    <p className="text-center">
                      Currently, there are no billable items in your service.
                    </p>
                  }
                  selectionMode="single"
                  selection={billableItemsWithData?.filter(
                    (b) => field.value === b.id,
                  )}
                  onSelectionChange={(e) => {
                    field.onChange(e.value.id);
                    setTableVisible(false);
                  }}
                >
                  {COLUMNS.map((column) => (
                    <Column
                      key={column.header}
                      {...column}
                      align="center"
                      alignHeader="center"
                    />
                  ))}
                </DataTable>
              )}
            />
          </div>
        </div>
      </Dialog>
      <Button
        className="mt-4 bg-light-purple"
        id="button-add-billable-item"
        label={"Select a billable item"}
        type="button"
        onClick={() => {
          setTableVisible(true);
        }}
        icon="pi pi-plus"
      />
    </>
  );
};

export default ChooseBillableItems;

const COLUMNS = [
  { header: "HCPCS", field: "hcpcs" },
  {
    header: "Description",
    body: DescriptionWithTooltipTemplate,
  },
  {
    header: "Mods",
    body: ModTemplate,
  },
  {
    header: "Attributes",
    body: BillableItemsAttributesTemplate,
  },
  {
    header: "Price per unit",
    body: PriceBillableTemplate,
  },
];
