import { convertStandardToWeight } from "../../utils/numericConversions";
import { NUMBER_TYPE_ENUM, QUESTION_TYPES_ENUM } from "../../utils/constants";
import ForcedNumericValueNotification from "./ForcedNumericValueNotification";

const DisplayConstraints = ({ question }) => {
  const isText = question?.type === QUESTION_TYPES_ENUM.TEXT.value;

  const isAmerican =
    question?.constraints?.type === NUMBER_TYPE_ENUM.AMERICAN.value;
  const isWeight =
    question?.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;

  const iSMinValueForced = question?.constraints?.min?.force;
  const iSMaxValueForced = question?.constraints?.max?.force;

  const iSMinLengthValueForced = question?.constraints?.minLength?.force;
  const iSMaxLengthValueForced = question?.constraints?.maxLength?.force;

  return isText ? (
    <div className="flex items-center gap-4 text-sm text-gray-500">
      {iSMinLengthValueForced &&
      iSMaxLengthValueForced &&
      question.constraints.minLength.value ===
        question.constraints.maxLength.value ? (
        <span className="flex items-center gap-2">
          <i className="pi pi-info-circle" />
          Required Characters:&nbsp;{question.constraints.minLength.value}
        </span>
      ) : (
        <>
          {iSMinLengthValueForced && (
            <span className="flex items-center gap-2">
              <i className="pi pi-info-circle" />
              Min. Characters:&nbsp;{question.constraints.minLength.value}
            </span>
          )}
          {iSMaxLengthValueForced && (
            <span className="flex items-center gap-2">
              <i className="pi pi-info-circle" />
              Max. Characters:&nbsp;{question.constraints.maxLength.value}
            </span>
          )}
        </>
      )}
    </div>
  ) : (
    <div className="flex items-center gap-4 text-sm text-gray-500">
      {iSMinValueForced && (
        <span className="flex items-center gap-2">
          <i className="pi pi-info-circle" />
          Min:&nbsp;
          <ForcedNumericValueNotification
            isAmerican={isAmerican}
            value={
              isWeight
                ? convertStandardToWeight(
                    question.constraints.min.value,
                    question?.constraints?.storeAs,
                  )
                : question.constraints.min.value
            }
          />
        </span>
      )}
      {iSMaxValueForced && (
        <span className="flex items-center gap-2">
          <i className="pi pi-info-circle" />
          Max:&nbsp;
          <ForcedNumericValueNotification
            isAmerican={isAmerican}
            value={
              isWeight
                ? convertStandardToWeight(
                    question.constraints.max.value,
                    question?.constraints?.storeAs,
                  )
                : question.constraints.max.value
            }
          />
        </span>
      )}
    </div>
  );
};

export default DisplayConstraints;
