import CheckIcon from "../iconComponents/CheckIcon";

export const VIEW_TYPE = {
  RENDER: {
    label: "render",
    value: 1,
  },
  ORDER: {
    label: "order",
    value: 2,
  },
};

export const USER_ROLES_ENUM = {
  ADMIN: "Admin",
  STAFF: "Staff",
  SYSTEM_ADMIN: "SystemAdministrator",
  ORDERING_ONLY_STAFF: "OrderingOnlyStaff",
};
export const USER_ROLES_ENUM_Number = {
  None: 1,
  SystemAdministrator: 2,
  Admin: 3,
  Staff: 4,
  OrderingOnlyStaff: 5,
};
export const STAFF_ROLES = [
  {
    key: USER_ROLES_ENUM.STAFF,
    label: "Staff",
    value: USER_ROLES_ENUM_Number.Staff,
    icon: "pi pi-info-circle",
    tooltip:
      "Can access the ordering view to create orders and manage actions related to submitted orders.Can access the rendering view to view and manage the practice's received orders.View the list of practice locations.",
  },
  {
    key: USER_ROLES_ENUM.ADMIN,
    label: "Admin",
    value: USER_ROLES_ENUM_Number.Admin,
    icon: "pi pi-info-circle",
    tooltip:
      "Has full access, including managing services, billable items, staff, transactions, practice locations, and practice settings.",
  },
  {
    key: USER_ROLES_ENUM.ORDERING_ONLY_STAFF,
    label: "Ordering Only Staff",
    value: USER_ROLES_ENUM_Number.OrderingOnlyStaff,
    icon: "pi pi-info-circle",
    tooltip:
      "Can access the ordering view to create orders and manage actions related to submitted orders.",
  },
];

export const SERVICE_LOCATION_STATUS = {
  LOADING: "loading",
  NO_DATA: "no-data",
  SUCCESS: "success",
  IDLE: "idle",
};

export const SERVICE_TAB = {
  ALL: "all",
  SAVED: "saved",
  OUR: "our",
};

export const REQUESTED_DATA_SIZE = 10;

export const SERVICE_TYPE = {
  SCHEDULED: {
    label: "Scheduled Appointment Service",
    value: 1,
  },
  WALK_IN: {
    label: "Walk-In Service",
    value: 2,
  },
};

export const GENDERS_ENUM = {
  MALE: { label: "Male", value: 1 },
  FEMALE: { label: "Female", value: 2 },
};
export const GENDERS = [GENDERS_ENUM.MALE, GENDERS_ENUM.FEMALE];

export const EXTERNAL_AUTH_ATTR_ENUM = {
  SIGN_UP: "SIGN_UP",
  SIGN_IN: "SIGN_IN",
};
export const GENERAL_REQUEST_HEADERS = {
  "X-Robots-Tag": "noindex, nofollow, noarchive, nosnippet",
};

export const RULE_ACTION_VALUES = {
  ADD_CPT: {
    label: "Add Billable Items",
    value: "addCPT",
  },
  ADD_UNITS_TO_EXISTING: {
    label: "Add Units To Existing Billable Items",
    value: "AddUnitsToExisting",
  },
  REMOVE_ALL_EXCEPT: {
    label: "Remove All Billable Items Except",
    value: "removeAllExcept",
  },
  REMOVE_MATCHING_PATTERN: {
    label: "Remove Any Billable Items Matching Pattern",
    value: "removeMatchingPattern",
  },
  REMOVE_CPT: {
    label: "Remove Billable Items",
    value: "removeCPT",
  },
  REMOVE_UNITS_FROM_EXISTING: {
    label: "Reduce Units On Existing Billable Items",
    value: "removeUnitsFromExisting",
  },
  SWAP_CPT: {
    label: "Replace Billable Item",
    value: "swapCPT",
  },
  SET_UNITS: {
    label: "Set Units On Existing Billable Items",
    value: "setUnits",
  },
  ADD_DOCS: {
    label: "Add Documents",
    value: "addDocs",
  },
  REMOVE_DOCS: {
    label: "Remove Documents",
    value: "removeDocs",
  },
  ADD_LOCATIONS: {
    label: "Add locations",
    value: "addLocations",
  },
  REMOVE_LOCATIONS: {
    label: "Remove Locations",
    value: "removeLocations",
  },
  ABORT: {
    label: "Abort Order",
    value: "abort",
  },
};

export const RULE_ACTIONS = [
  {
    label: "Billable Items",
    actions: [
      RULE_ACTION_VALUES.ADD_CPT,
      RULE_ACTION_VALUES.ADD_UNITS_TO_EXISTING,
      RULE_ACTION_VALUES.REMOVE_UNITS_FROM_EXISTING,
      RULE_ACTION_VALUES.REMOVE_ALL_EXCEPT,
      RULE_ACTION_VALUES.REMOVE_MATCHING_PATTERN,
      RULE_ACTION_VALUES.REMOVE_CPT,
      RULE_ACTION_VALUES.SWAP_CPT,
      RULE_ACTION_VALUES.SET_UNITS,
    ],
  },
  {
    label: "Documents",
    actions: [RULE_ACTION_VALUES.ADD_DOCS, RULE_ACTION_VALUES.REMOVE_DOCS],
  },
  {
    label: "Locations",
    actions: [
      RULE_ACTION_VALUES.ADD_LOCATIONS,
      RULE_ACTION_VALUES.REMOVE_LOCATIONS,
    ],
  },
  {
    label: "Order Actions",
    actions: [RULE_ACTION_VALUES.ABORT],
  },
];
