import { Controller, useFormContext } from "react-hook-form";

import { Checkbox } from "primereact/checkbox";

const CheckInPass = () => {
  const { control } = useFormContext();

  return (
    <div className="mt-4 flex w-full">
      <div className="w-full rounded-lg border border-slate-200 bg-white p-4 shadow-sm">
        <div className="flex items-center gap-3">
          <Controller
            name="checkInPass"
            control={control}
            render={({ field }) => (
              <Checkbox
                inputId="checkInPass"
                name={field.name}
                checked={field.value}
                onChange={(e) => {
                  field.onChange(e.checked);
                }}
              />
            )}
          />
          <label
            htmlFor="checkInPass"
            className="cursor-pointer font-semibold text-gray-800"
          >
            Check-In Pass
          </label>
        </div>
        <p className="py-2 text-xs italic text-gray-400">
          Send a Check-In pass with order details and barcode to patient. If the
          service type is{" "}
          <span className="text-xs font-semibold text-gray-500">
            "Scheduled Appointment Service"
          </span>{" "}
          then the patient will receive the check-in slip after you schedule the
          service. If the service type is{" "}
          <span className="text-xs font-semibold text-gray-500">
            {" "}
            "Walk-In Service"
          </span>{" "}
          then the patient will receive the appointment check-in slip instantly
          after completing payment and your acceptance of the order.
        </p>
      </div>
    </div>
  );
};

export default CheckInPass;
