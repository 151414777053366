import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import { ConfirmDialog } from "primereact/confirmdialog";

import useTokenData from "../../hooks/useTokenData";
import useHandleResize from "../../hooks/useHandleResize";
import {
  InviteStaffForm,
  StaffInvitations,
  StaffJoinRequests,
  StaffMembers,
  StaffProviders,
} from ".";

const ENTITY_TYPE = {
  GROUP: "1",
  INDIVIDUAL: "2",
};

function StaffDashboard({ isOrderingMode }) {
  const { isMobile } = useHandleResize();
  const { entityType } = useTokenData();

  const toast = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [refetchData, setRefetchData] = useState(false);

  useEffect(() => {
    entityType !== ENTITY_TYPE.GROUP && setActiveIndex(1);
  }, []);

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="staff-container w-full">
        <div className="btn-invite flex flex-col items-start gap-4 border-b-2 py-11 md:w-full">
          <div>
            <h5 className="font-inter text-lg font-extrabold">
              Invite Staff Member
            </h5>
            <p className="text-p text-normal-text">
              We will email them instructions and send them a link to sign up.
            </p>
          </div>
          <InviteStaffForm
            isOrderingMode={isOrderingMode}
            setRefetchData={setRefetchData}
          />
        </div>

        <div className="list-request border-b-2 border-b-gray-300 pb-11">
          {isMobile || (
            <h4 className="mb-5 mt-4 font-inter text-title font-semibold capitalize">
              Requests
            </h4>
          )}

          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
          >
            {entityType === ENTITY_TYPE.GROUP && (
              <TabPanel
                header="Join Requests"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <StaffJoinRequests
                  refetch={refetchData}
                  setRefetch={setRefetchData}
                />
              </TabPanel>
            )}
            <TabPanel
              header="Invitations"
              className="flex-1 text-sm sm:flex-initial sm:text-base"
            >
              <StaffInvitations
                refetch={refetchData}
                setRefetch={setRefetchData}
              />
            </TabPanel>
            {isMobile && (
              <TabPanel
                header="Staff"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <StaffMembers
                  refetch={refetchData}
                  setRefetch={setRefetchData}
                />
              </TabPanel>
            )}
            {isMobile && isOrderingMode && (
              <TabPanel
                header="List Of Providers"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <StaffProviders
                  refetch={refetchData}
                  setRefetch={setRefetchData}
                />
              </TabPanel>
            )}
          </TabView>
        </div>

        {isMobile || (
          <div>
            <div className="mb-5 mt-4 flex flex-wrap justify-between">
              <h4 className="font-inter text-title font-semibold capitalize">
                &nbsp;staff&nbsp;
              </h4>
            </div>
            <TabView>
              <TabPanel
                header="Staff"
                className="flex-1 text-sm sm:flex-initial sm:text-base"
              >
                <StaffMembers
                  refetch={refetchData}
                  setRefetch={setRefetchData}
                />
              </TabPanel>
              {isOrderingMode && (
                <TabPanel
                  header="Providers"
                  className="flex-1 text-sm sm:flex-initial sm:text-base"
                >
                  <StaffProviders
                    refetch={refetchData}
                    setRefetch={setRefetchData}
                  />
                </TabPanel>
              )}
            </TabView>
          </div>
        )}
      </div>
    </>
  );
}

export default StaffDashboard;
