import { useState } from "react";

import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";

import useTokenData from "../../../../hooks/useTokenData";
import useHandleResize from "../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../components/Table";
import useDelayedInputChange from "../../../../hooks/useDelayedInputChange";
import {
  formatDate,
  formatDateTime,
  getTimeDifference,
} from "../../../../utils/helpers";

export const Remits = () => {
  const { entityId } = useTokenData();
  const [selectedDate, setSelectedDate] = useState();
  const [refetch, setRefetch] = useState(false);

  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );
  const dates = [
    {
      name: "All",
      value: 0,
    },
    {
      name: "Last Day",
      value: 1,
    },
    {
      name: "Last Week",
      value: 7,
    },
    {
      name: "Last 2 Weeks",
      value: 14,
    },
    {
      name: "Last 3 Weeks",
      value: 21,
    },
    {
      name: "Last Month",
      value: 30,
    },
    {
      name: "Last 2 Months",
      value: 60,
    },
    {
      name: "Last Year",
      value: 365,
    },
  ];
  const { isMobile } = useHandleResize();

  const amountTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.charge ? (
          <>
            <div>${rowData.charge}</div>
          </>
        ) : null}
      </>
    );
  };

  const completeTransactionDateTemplate = (rowData) => {
    return (
      <>
        <div className="flex flex-row gap-2 sm:flex-col">
          <p>
            {rowData.completedTransactionDate &&
              formatDate(rowData.completedTransactionDate, true)}
          </p>
          <p className="mt-1 font-inter text-sm">
            {rowData.completedTransactionDate &&
              formatDateTime(rowData.completedTransactionDate)}
          </p>
        </div>
      </>
    );
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <p className="font-semibold">{row.serviceName}</p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordered By:&nbsp;
          </span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Transaction Number:&nbsp;
          </span>
          {row.transactionId}
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            Completed Transaction Date:&nbsp;
          </span>
          {completeTransactionDateTemplate(row)}
        </p>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Time Period</h3>
        <div className="flex flex-col gap-2">
          {dates.map((date) => {
            return (
              <div key={date.name} className="align-items-center flex">
                <RadioButton
                  inputId={date.name}
                  name="name"
                  value={date.value}
                  onChange={(e) => setSelectedDate(e.value)}
                  checked={selectedDate === date.value}
                />
                <label htmlFor={date.name} className="ml-2 capitalize">
                  {date.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between gap-4 bg-[#f8f9fa] p-4">
        <p className="font-inter">
          Here you can view completed orders for which you have received the
          payment.
        </p>
        <div className="hidden justify-between gap-1 sm:flex">
          <Dropdown
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.value);
              setRefetch(!refetch);
            }}
            options={dates}
            optionLabel="name"
            placeholder="Time Period"
            className="w-[200px]"
          />
        </div>
      </div>
      <ReusableDataList
        searchTitle="Search Service Name"
        sendSearchData={handleInputChange}
        searchValue={searchValue}
        dataLink={`/Entities/GetEntityTransactions/${entityId}?Filters=type==Remit,Order.Service.Name@=*${
          searchValue || ""
        }${
          selectedDate
            ? `,CompletedTransactionDate>=${
                selectedDate && getTimeDifference(selectedDate)
              }`
            : ""
        }`}
        columns={[
          { header: "Order Number", field: "orderNumber" },
          { header: "Service", field: "serviceName" },
          { header: "Ordered By", field: "orderedBy" },

          { header: "Transaction Number", field: "transactionId" },
          {
            template: completeTransactionDateTemplate,
            header: "Completed Transaction Date",
          },
          { template: amountTemplate, header: "Amount" },
        ]}
        mobileTemplate={cardTemplate}
        filterTemplate={filterTemplate}
        refetch={refetch}
      />
    </>
  );
};
