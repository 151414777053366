import { useEffect, useRef, useState } from "react";

import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import { ReusableDataList } from "../../../../components/Table";
import useDelayedInputChange from "../../../../hooks/useDelayedInputChange";
import {
  downloadFileFromResponse,
  formatDate,
  formatDateTime,
  ORDERING_DROPDOWN_OPTIONS,
} from "../../../../utils/helpers";
import { showErrorToast } from "../../../../utils/apiUtils";
function OrderingPending() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const buttonRef = useRef(null);
  const { entityId } = useTokenData();
  const { http } = useAxios();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");
  const [dialogHeader, setDialogHeader] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [refetch, setRefetch] = useState();
  const menuRight = useRef({});

  const handleScroll = () => {
    setVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const [selectedValue, setSelectedValue] = useState("Service.Name@=*");
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
    selectedValue,
  );
  const updateOrder = (rowData, status) => {
    setOrderData(rowData);
    setOrderStatus(status);
    setDialogMessage(
      <p className="mb-3 font-semibold capitalize">
        {" "}
        By clicking the 'Confirm' button, this order will be
        {status === 10 ? "recalled" : "cancelled"} and won't be completed
      </p>,
    );
    setDialogHeader(
      status === 10 ? "Recall This Order?" : "Cancel This Order?",
    );
    setDialogVisible(true);
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      await http.put(
        `/Orders/UpdateOrderAsyncOrderingProvider/${orderData.id}`,
        {
          status: orderStatus,
          cancelationResone: confirmMessage,
        },
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: `Order ${orderStatus === 10 ? "Recalled" : "Cancelled"} Successfully`,
        life: 3000,
      });
      setRefetch((e) => !refetch);
      setDialogVisible(false);
      setConfirmMessage("");
    } catch (error) {
      showErrorToast({ error });
    }
    setLoading(false);
  };

  const statusTemplate = (rowData) => {
    // Customize this function based on your actionTemplate logic
    return (
      <>
        {rowData.status === 8 ? (
          <span className="inline rounded-md bg-sky-100 p-2 text-center text-sm text-sky-700 sm:block sm:p-3 sm:text-base">
            Ordered
          </span>
        ) : rowData.status === 9 ? (
          <span className="inline rounded-md bg-light-rose p-2 text-center text-sm text-dark-rose sm:block sm:p-3 sm:text-base">
            Scheduled
          </span>
        ) : (
          <span className="inline rounded-md bg-[#9EC8B9] p-2 text-center text-sm text-[#1B4242] sm:block sm:p-3 sm:text-base">
            Pending Render Approval
          </span>
        )}
      </>
    );
  };

  const scheduledDateTemplate = (rowData) => {
    return (
      <>
        {rowData.scheduledDate && rowData.status !== 5 ? (
          <div className="flex flex-col">
            <p>{formatDate(rowData.scheduledDate, true)}</p>
            <p className="font-inter text-sm">
              {formatDateTime(rowData.scheduledDate)}
            </p>
          </div>
        ) : (
          <div className="border-t-[1px] border-gray-600"></div>
        )}
      </>
    );
  };

  const downloadPatientInfo = async (rowData) => {
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${rowData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const downloadServiceDocs = async (id) => {
    setIsDownloading(true);
    try {
      const response = await http.get(`/Orders/DownloadOrderDocuments/${id}`, {
        responseType: "blob",
      });
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsDownloading(false);
  };

  const getMenuItems = (rowData) => {
    const items = [];
    if (rowData.hasDocuments) {
      items.push({
        label: "Download Order Documents",
        icon: isDownloading ? "pi pi-spin pi-spinner" : "pi pi-download",
        command: () => downloadServiceDocs(rowData.id),
      });
    }
    if (rowData.status === 5) {
      items.push({
        label: "Cancel",
        icon: "pi pi-times",
        command: () => {
          updateOrder(rowData, 6);
        },
      });
    } else {
      items.push({
        label: "Recall",
        icon: "pi pi-replay",
        command: () => {
          updateOrder(rowData, 10);
        },
      });
    }

    items.push({
      label: "Download Order Summary",
      icon: "pi pi-download",
      command: () => downloadPatientInfo(rowData),
    });
    return items;
  };
  const actionTemplate = (rowData) => {
    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };
  const chargeTemplate = (rowData) => (
    <>
      <h4 className="font-inter text-sm font-semibold"> ${rowData.charge} </h4>
    </>
  );

  const dropdownValues = {
    options: ORDERING_DROPDOWN_OPTIONS,
    selectedValue: selectedValue,
    setSelectedValue: setSelectedValue,
  };
  const serviceTypeTemplate = (rowData) => {
    return (
      <>
        {rowData.serviceType === 1 ? (
          <p className="inline rounded-md bg-orange-100 p-2 text-center text-sm text-orange-600 sm:block sm:p-3 sm:text-base">
            Scheduled Appointment Service
          </p>
        ) : (
          <p
            className="inline rounded-md p-2 text-center text-sm sm:block sm:p-3 sm:text-base"
            style={{ backgroundColor: "#A0D9D9", color: "#2F4F4F" }}
          >
            Walk-In Service
          </p>
        )}
      </>
    );
  };

  const cardTemplate = (row) => {
    return (
      <>
        <div className="flex justify-between gap-2">
          <p>#{row.orderTrackingNumber}</p>
          <p className="text-slate-500">{formatDate(row.orderedDate, true)}</p>
        </div>
        <div className="flex justify-between gap-2">
          <p className="text-lg font-bold">{row.serviceName}</p>
          <p className="font-semibold">
            <i className="pi pi-dollar text-green-700" />
            <span>{row.charge}</span>
          </p>
        </div>
        <div className="font-semibold"> {serviceTypeTemplate(row)}</div>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordered By:&nbsp;
          </span>
          {row.orderedBy}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Patient Name:&nbsp;
          </span>
          {row.patientName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            &nbsp;Scheduled Date:&nbsp;
          </span>
          {row.scheduledDate && (
            <>
              <span>{formatDate(row.scheduledDate, true)}&nbsp;</span>
              <span>{formatDateTime(row.scheduledDate)}</span>
            </>
          )}
        </p>
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2 font-semibold">
            <span className="font-semibold text-slate-500">Status:&nbsp;</span>
            {statusTemplate(row)}
          </div>
          {actionTemplate(row)}
        </div>
      </>
    );
  };

  const filterTemplate = () => {
    return (
      <div>
        <h3 className="text-md mb-3 font-bold">Search By</h3>
        <div className="flex flex-col gap-2">
          {dropdownValues.options.map((option) => {
            return (
              <div key={option.name} className="align-items-center flex">
                <RadioButton
                  inputId={option.name}
                  name="name"
                  value={option}
                  onChange={(e) =>
                    dropdownValues?.setSelectedValue(e.value.value)
                  }
                  checked={dropdownValues.selectedValue === option.value}
                />
                <label htmlFor={option.name} className="ml-2 capitalize">
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />

      <Dialog
        visible={dialogVisible}
        header={dialogHeader}
        modal
        onHide={() => {
          setConfirmMessage("");
          setDialogVisible(false);
        }}
        footer={
          <div>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() => {
                setDialogVisible(false);
                setConfirmMessage("");
              }}
              className="p-button-text"
            />
            <Button
              label="Confirm"
              icon="pi pi-check"
              onClick={handleConfirm}
              loading={loading}
              disabled={!confirmMessage.trim()}
              autoFocus
            />
          </div>
        }
      >
        <p>{dialogMessage}</p>
        <span className="block capitalize"> Please provide the reason. </span>
        <p className="mt-2">
          <InputTextarea
            value={confirmMessage}
            placeholder="reason message"
            onChange={(e) => {
              setConfirmMessage(e.target.value);
            }}
            className="w-full"
          />
        </p>
      </Dialog>
      <ReusableDataList
        title={"List of orders"}
        sendSearchData={handleInputChange}
        searchValue={searchValue}
        dropdownValues={dropdownValues}
        dataLink={`/Orders/GetOrderingPracticeOrders/${entityId}?Filters=${
          searchValue ? selectedValue : ""
        }${searchValue}%2Cstatus==5|8|9`}
        columns={[
          {
            header: "Order Number",
            field: "orderTrackingNumber",
            style: { width: "10%" },
          },
          {
            header: "Patient Name",
            field: "patientName",
            style: { width: "15%" },
          },
          {
            header: "Service Name",
            field: "serviceName",
            style: { width: "15%" },
          },
          {
            header: "Service Type",
            field: "serviceType",
            template: serviceTypeTemplate,
            style: { width: "13%" },
          },
          {
            header: "Charge",
            field: "charge",
            template: chargeTemplate,
            style: { width: "10%" },
          },
          { header: "Ordered By", field: "orderedBy", style: { width: "14%" } },
          {
            header: "Assigned To",
            field: "assignedProviderName",
            style: { width: "14%" },
          },
          { header: "Offered By", field: "offeredBy", style: { width: "15%" } },
          {
            header: "Date",
            field: "orderedDate",
            template: (rowData) => formatDate(rowData.orderedDate, true),
            style: { width: "13%" },
          },
          {
            template: statusTemplate,
            header: "Status",
            style: { width: "13%" },
          },
          {
            template: scheduledDateTemplate,
            header: "Scheduled Date",
            style: { width: "13%" },
          },
          {
            template: actionTemplate,
            header: "Actions",
            frozen: true,
            alignFrozen: "right",
            style: {
              backgroundColor: "#f8f9fa",
            },
          },
        ]}
        mobileTemplate={cardTemplate}
        refetch={refetch}
        filterTemplate={filterTemplate}
      />
    </>
  );
}

export default OrderingPending;
