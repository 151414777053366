import { useRef, useState } from "react";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../hooks/useAxios";
import { useLayoutToast } from "../../../../store/store";
import useTokenData from "../../../../hooks/useTokenData";
import { ReusableDataList } from "../../../../components/Table";
import useDelayedInputChange from "../../../../hooks/useDelayedInputChange";
import { showErrorToast } from "../../../../utils/apiUtils";

const ManageAllAdmins = () => {
  const { http } = useAxios();
  const menuRight = useRef({});
  const { userId } = useTokenData();
  const { layoutToast } = useLayoutToast();

  const [refetch, setRefetch] = useState();
  const [visible, setVisible] = useState(false);

  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );

  const menuTemplate = (item) => {
    return (
      <div onClick={item.command} className="p-menuitem-content">
        <button className="align-items-center p-menuitem-link flex w-full">
          <span className={`${item.icon} ${item.styles}`} />
          <span className="mx-2">{item.label}</span>
        </button>
      </div>
    );
  };

  const getMenuItems = (rowData) => {
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash",
        styles: "text-red-500",
        command: () => handleDelete(rowData),
        template: menuTemplate,
      },
      {
        label: "Enable/Disable",
        icon: !rowData.isEnabled ? "pi pi-eye-slash" : "pi pi-eye",
        styles: !rowData.isEnabled ? "text-red-700" : "text-emerald-500",
        command: () => {
          handleToggleDisable(rowData);
        },
        template: menuTemplate,
      },
    ];

    return items;
  };

  const cardActionTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const cardTemplate = (row) => {
    const isDisabled = row.id === userId;
    return (
      <>
        <div
          className={`flex justify-between p-2 ${isDisabled ? "p-disabled" : ""}`}
        >
          <div>
            <div className="flex items-center justify-between gap-2">
              <p className="font-semibold">
                <i className="pi pi-user" />
                <span className="text-slate-500">&nbsp;name:&nbsp;</span>
                <span>{row.userName}</span>
                {isDisabled && (
                  <span className="pl-2 capitalize text-light-purple">
                    (Me)
                  </span>
                )}
              </p>
            </div>
            <div className="flex items-center justify-between gap-2">
              <p className="font-semibold">
                <i className="pi pi-user" />
                <span className="text-slate-500">&nbsp;email:&nbsp;</span>
                <span>{row.email}</span>
              </p>
            </div>
          </div>

          <div>{cardActionTemplate(row)}</div>
        </div>
      </>
    );
  };

  const deleteRow = async (rowData) => {
    try {
      const endPointUrl = `/Users/DeleteSystemAdmin/${rowData.id} `;
      const res = await http.delete(endPointUrl);
      setRefetch(!refetch);
      layoutToast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data.message,
        life: 2000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleDelete = async (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        try {
          await new Promise((resolve) => {
            deleteRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const toggleDisableRow = async (rowData) => {
    try {
      const endPointUrl = `/Users/EnableOrDisableSystemAdmin/${rowData.id} `;
      const res = await http.put(endPointUrl, {
        isEnabled: !rowData.isEnabled,
      });
      setRefetch(!refetch);
      layoutToast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data.message,
        life: 2000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleToggleDisable = async (rowData) => {
    confirmDialog({
      message: `Are you sure you want to ${rowData.isEnabled ? "disable" : " enable"}?`,
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        try {
          await new Promise((resolve) => {
            toggleDisableRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const actionsTemplate = (rowData) => {
    return (
      <>
        <Button
          onClick={() => handleDelete(rowData)}
          link
          tooltip="delete"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i
            className="pi pi-trash text-red-500"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
        <Button
          onClick={() => handleToggleDisable(rowData)}
          link
          tooltip={`${rowData.isEnabled ? "disabled" : "enabled"}`}
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i
            className={`pi ${!rowData.isEnabled ? "pi-eye-slash text-red-700" : "pi-eye text-green-500"} `}
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
      </>
    );
  };

  const EmailOwnerTemplate = (rowData) => (
    <>
      <p className="text-p">{rowData.email}</p>
      <h4 className="text-mobileTitle">
        {rowData.id === userId && (
          <div>
            <span className="text-sm font-bold capitalize text-light-purple sm:block sm:text-base">
              me
            </span>
          </div>
        )}
      </h4>
    </>
  );

  const rowClassName = (rowData) => {
    if (rowData.id === userId) {
      return "p-disabled";
    }
    return "";
  };

  const COLUMNS = [
    { header: "Name ", field: "userName", style: { width: "30%" } },
    {
      template: EmailOwnerTemplate,
      header: "Email",
      style: { width: "30%" },
    },
    { template: actionsTemplate, header: "Actions" },
  ];

  return (
    <ReusableDataList
      title="All Admins"
      fromFront={true}
      sendSearchData={handleInputChange}
      searchValue={searchValue}
      dataLink={`/Users/GetAllAdmins`}
      columns={COLUMNS}
      mobileTemplate={cardTemplate}
      refetch={refetch}
      rowClassName={rowClassName}
      cardFilters={["name", "email"]}
    />
  );
};

export default ManageAllAdmins;
