import { useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";

import { ModTemplate } from "../../../templates/ModTemplate";
import useFetchBillablesByIds from "../useFetchBillablesByIds";
import AttachBillableItemForm from "../../AttachBillableItemForm";
import { getAllBillableItemsInRules, getBillableItemsIds } from "./helpers";
import UnitsBillableTemplate from "../../../templates/UnitsBillableTemplate";
import DescriptionWithTooltipTemplate from "../DescriptionWithTooltipTemplate";
import { PriceBillableTemplate } from "../../../templates/PriceBillableTemplate";
import { useServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { CreateBillableItem } from "../../../../../components/CreateBillableItem";
import { BillableItemsAttributesTemplate } from "../../../templates/BillableItemsAttributesTemplate";

const AddBillableItem = () => {
  const { watch, setValue, getValues } = useFormContext();
  const { defaultBillableItems } = useServiceDetailsContext();
  const [tableVisible, setTableVisible] = useState(false);
  const [createBillableTableVisible, setCreateBillableTableVisible] =
    useState(false);

  const { billableItems, isLoading, getBillableItems } = useFetchBillablesByIds(
    watch("action.on").map((b) => b.id),
  );

  const COLUMNS = [
    { header: "HCPCS", field: "hcpcs" },
    {
      header: "Description",
      body: DescriptionWithTooltipTemplate,
    },
    { header: "Mods", body: ModTemplate },
    {
      header: "Attributes",
      body: BillableItemsAttributesTemplate,
    },
    {
      header: "Price per unit",
      body: PriceBillableTemplate,
    },
    {
      header: "Units",
      body: (e) => <UnitsBillableTemplate rowData={e} name="action.on" />,
    },
    {
      header: "Remove Billable items",
      body: DeleteTemplate,
    },
  ];

  const handleCreateBillableItem = (billableItem) => {
    const oldBillables = getValues("action.on");
    const newBillables = [
      ...oldBillables,
      { id: billableItem.id, units: billableItem.defaultUnits },
    ];
    setValue("action.on", newBillables, { shouldValidate: true });

    getBillableItems(newBillables.map((b) => b.id));
  };

  const allBillableItems = useMemo(
    () =>
      getBillableItemsIds([
        ...getAllBillableItemsInRules(watch("id")),
        ...defaultBillableItems,
      ]),
    [],
  );

  return (
    <div className="flex flex-col gap-2">
      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="Create Billable Item"
        visible={createBillableTableVisible}
        onHide={() => setCreateBillableTableVisible(false)}
      >
        <CreateBillableItem
          setVisible={setCreateBillableTableVisible}
          handleCreateBillableItem={handleCreateBillableItem}
        />
      </Dialog>
      <label className="font-semibold">Current Billable Items</label>
      <DataTable
        loading={isLoading}
        className="rounded-lg border-2 border-gray-200"
        emptyMessage={
          <p className="text-center">
            Currently, there are no billable items selected.
          </p>
        }
        value={billableItems.filter((b) =>
          watch("action.on")?.some((v) => v.id === b.id),
        )}
      >
        {COLUMNS.map((column) => (
          <Column
            key={column.header}
            {...column}
            align="center"
            alignHeader="center"
          />
        ))}
      </DataTable>
      <div>
        <Button
          className="mt-4 bg-light-purple"
          id="button-add-billable-item"
          label={"Add billable items"}
          type="button"
          onClick={() => {
            setTableVisible(true);
          }}
          icon="pi pi-plus"
        />
      </div>
      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="Attach Billable Items"
        visible={tableVisible}
        onHide={() => setTableVisible(false)}
      >
        <div className="flex flex-col p-4">
          <div className="flex flex-col gap-2">
            <AttachBillableItemForm
              handleSave={({ billableItems }) => {
                setTableVisible(false);
                setValue("action.on", billableItems, { shouldValidate: true });
                getBillableItems(billableItems.map((b) => b.id));
              }}
              defaultValues={watch("action.on")}
              payload={allBillableItems}
            >
              <Divider
                layout="horizontal"
                align="center"
                className="w-10/12 self-center"
              >
                <b>OR</b>
              </Divider>
              <div className="flex justify-center">
                <Button
                  id="serviceBillables"
                  label="Create new billable item"
                  type="button"
                  outlined
                  onClick={() => {
                    setTableVisible(false);
                    setCreateBillableTableVisible(true);
                  }}
                />
              </div>

              <div className="mt-2 text-end">
                <Button label="Save" id="button-save-billable-item" />
              </div>
            </AttachBillableItemForm>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const DeleteTemplate = (rowData) => {
  const { getValues, setValue } = useFormContext();
  return (
    <div className="mt-4">
      <i
        className="pi pi-times cursor-pointer text-red-400"
        min={1}
        id="delete-template"
        onClick={() => {
          setValue(
            "action.on",
            getValues("action.on").filter((b) => b.id !== rowData.id),
            { shouldValidate: true },
          );
        }}
      />
    </div>
  );
};

export default AddBillableItem;
