import useAxios from "./useAxios";
import { downloadFileFromResponse } from "../utils/helpers";
import { showErrorToast } from "../utils/apiUtils";

const useDocument = () => {
  const { http } = useAxios();

  const handleDownloadDocument = async (documentData) => {
    try {
      const response = await http.get(
        `/EntityDocuments/DownloadDocumentById/${documentData.id}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  return {
    handleDownloadDocument,
  };
};

export default useDocument;
