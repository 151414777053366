import { useNavigate } from "react-router-dom";

import { Avatar } from "primereact/avatar";
import { Tooltip } from "primereact/tooltip";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { SERVICE_TYPE } from "../../constants/constants";
import useHandleResize from "../../hooks/useHandleResize";
import { showErrorToast } from "../../utils/apiUtils";

function ServicesCard({
  setRefetch,
  service,
  practice,
  onCardClick,
  internalOnly,
  orderButtonClick,
  disableOrderButton,
  isInteractive,
}) {
  const navigate = useNavigate();
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { isMobile } = useHandleResize();

  const saveService = async (e) => {
    e.stopPropagation();
    try {
      await http.post("/Services/SaveService", {
        entityId: entityId,
        serviceId: service?.id,
      });
      setRefetch(true);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const unSaveService = async (e) => {
    e.stopPropagation();
    try {
      await http.delete(`/Services/UnSaveService/${entityId}/${service?.id}`);
      setRefetch(true);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const isScheduled = service?.serviceType === SERVICE_TYPE.SCHEDULED.value;
  const internalOnlyTemplate = internalOnly ? (
    <p
      className="flex items-center justify-center rounded-lg p-2 text-xs font-semibold"
      style={{ backgroundColor: "#DFF2EB", color: "#4A628A" }}
    >
      Internal Only
    </p>
  ) : null;
  return (
    <div
      onClick={() => {
        if (!isInteractive) return;
        onCardClick();
      }}
      className={`mb-3 rounded-md bg-white p-5 drop-shadow-sm ${isInteractive ? "cursor-pointer" : ""}`}
    >
      <div className="mb-2 flex w-full justify-between font-semibold">
        <div className="flex flex-col gap-2 text-xl sm:flex-row">
          <p>{service?.name}</p>
          <p
            className={`flex items-center justify-center rounded-lg p-2 text-xs ${isScheduled ? "bg-[#E0E7FF] text-[#6366F1]" : "bg-[#A0D9D9] text-[#2F4F4F]"}`}
          >
            {isScheduled
              ? SERVICE_TYPE.SCHEDULED.label
              : SERVICE_TYPE.WALK_IN.label}
          </p>
          {internalOnlyTemplate}
        </div>
        <Tooltip target={".tooltip-icon"} />
        <div>
          {service.isSavedService ? (
            <i
              data-pr-tooltip="Remove this service from your saved services"
              data-pr-position="top"
              onClick={unSaveService}
              className="pi pi-bookmark-fill tooltip-icon cursor-pointer rounded-full border-2 p-2 text-purple-700"
            />
          ) : (
            <i
              data-pr-tooltip="Mark this service as saved"
              data-pr-position="top"
              onClick={saveService}
              className="pi pi-bookmark tooltip-icon cursor-pointer rounded-full border-2 p-2 text-purple-700"
            />
          )}
        </div>
      </div>
      <div className="mb-2">{service?.description}</div>
      <div className="flex items-center justify-between">
        <span className="flex flex-col content-center gap-2 sm:flex-row sm:items-center">
          <span
            className="flex items-center gap-2"
            onClick={(e) => {
              if (!isInteractive) return;
              e.stopPropagation();
              navigate(`/ordering/practices/${service?.entityId}`);
            }}
          >
            {!isMobile &&
              (practice.imageSrc ? (
                <img
                  className="h-[30px] w-[30px] rounded-full"
                  alt=""
                  src={practice?.imageSrc}
                />
              ) : (
                <Avatar
                  label={practice?.name[0]}
                  shape="circle"
                  className="bg-blue-500 text-white"
                />
              ))}
            <span>{practice.name}</span>
          </span>
        </span>
        {isInteractive ? (
          <button
            type="button"
            onClick={orderButtonClick}
            className={`whitespace-nowrap rounded-md px-4 py-2 text-sm font-semibold text-white md:px-4 md:text-lg ${
              disableOrderButton ? "bg-gray-400" : "bg-green-600"
            }`}
          >
            Order Now
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default ServicesCard;
