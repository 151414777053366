import { useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BreadCrumb } from "primereact/breadcrumb";
import { ProgressSpinner } from "primereact/progressspinner";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../hooks/useAxios";
import DepositCard from "./components/DepositCard";
import useTokenData from "../../../../hooks/useTokenData";
import { trimValidation } from "../../../../utils/helpers";
import { showErrorToast } from "../../../../utils/apiUtils";

function DepositSettings({ fromSetup }) {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Deposit Method",
      template: () => (
        <Link
          to={`/${location.pathname.split("/")[1]}/settings/DepositSettings`}
        >
          Deposit Method
        </Link>
      ),
    },
  ];

  const defaultValues = {
    bankName: "",
    AccountOwnerName: "",
    bankAccountNumber: "",
    confirmBankAccountNumber: "",
    routingNumber: "",
  };
  const toast = useRef(null);
  const show = (severity, summary, detail) => {
    toast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };
  const deleteCard = async (id) => {
    try {
      confirmDialog({
        message: "Are you sure delete this card ? ",
        icon: "pi pi-exclamation-triangle",
        position: "top",
        accept: async () => {
          try {
            const res = await http.delete(`/BankAccountInfos/${id}`);
            getDepositData();
            show("success", "Success", res.data.message);
          } catch (error) {
            showErrorToast({ error });
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [cardsData, setCardsData] = useState();
  const {
    register,
    handleSubmit,
    reset,
    control,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: defaultValues });
  const { entityId } = useTokenData();
  const { http } = useAxios();
  const getDepositData = async () => {
    const res = await http.get(`/BankAccountInfos/GetBankAccounts/${entityId}`);

    setCardsData(res.data);
  };
  useEffect(() => {
    !fromSetup && getDepositData();
  }, []);
  const addCardInfo = async (e) => {
    setIsLoading(true);
    try {
      await http.post("/BankAccountInfos", {
        ...e,
        entityId: entityId,
      });
      show("success", "Success", "Card Info Added Successfully");
      reset();
      fromSetup &&
        navigate(`/Dashboard`, {
          replace: true,
          state: {
            isWelcome: true,
          },
        });
      !fromSetup && getDepositData();
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  const handleSkip = () => {
    confirmDialog({
      className: "w-full md:w-[50vw]",
      message: (
        <p>
          <span className="font font-Poppins text-gray-500">
            Busy at the moment? You can skip setting up deposits now and come
            back later.{" "}
          </span>
          <span className="font-Poppins font-medium">
            Simply visit your profile settings to complete this step whenever
            you're ready.
          </span>
        </p>
      ),
      icon: "pi pi-exclamation-triangle",
      position: "top",
      header: "Confirm Skip?",
      acceptLabel: "Skip Now",
      rejectLabel: "Continue Editing",
      accept: () => {
        navigate(`/Dashboard`, {
          replace: true,
          state: {
            isWelcome: true,
          },
        });
      },
    });
  };

  const validateName = (value) => {
    const regex = /^[A-Za-z\s]+$/;
    if (!value.match(regex)) {
      return "Name should only contain letters";
    } else if (!value.trim()) {
      return "Name can not be empty";
    }
    return true;
  };
  return (
    <>
      <ConfirmDialog />
      <Toast ref={toast} />

      {!fromSetup && (
        <BreadCrumb
          model={breadCrumbsItems}
          className="border-0 bg-transparent pb-7 font-semibold"
          pt={{
            separator: { className: "first:hidden" },
            menuitem: { className: "last:text-purple-700" },
          }}
        />
      )}
      <div className="mx-auto w-full py-4 sm:px-8 lg:w-10/12">
        {cardsData && cardsData.length > 0 ? (
          <div className="flex flex-col items-center justify-center">
            <div className="w-full">
              <h4 className="mb-4 text-center text-4xl font-bold sm:text-left">
                Deposit Method
              </h4>
              <p className="mb-3 text-center text-gray-700 sm:text-left">
                Select your preferred deposit method to receive your payments.
              </p>
              <h4 className="mb-3 mt-5 text-center text-2xl font-semibold sm:text-left">
                Saved Deposit Method
              </h4>
              <div className="grid grid-cols-1 justify-between gap-2 border-b-2 pb-9 sm:grid-cols-2">
                {cardsData?.map((cardData) => (
                  <DepositCard
                    key={cardData.id}
                    cardData={cardData}
                    getDepositData={getDepositData}
                    showToast={show}
                    deleteCard={deleteCard}
                  />
                ))}
              </div>
              <div className="mt-5">
                <h4 className="mb-2 text-center text-2xl font-semibold sm:text-left">
                  Setup Another Deposit Method
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-6 flex items-center justify-center">
            <div className="w-full">
              <h4 className="mb-4 text-center text-4xl font-bold sm:text-left">
                Deposit Method
              </h4>
              <p className="mb-3 text-center text-gray-700 sm:text-left">
                Set up your deposit method to receive payments
                {fromSetup && " as a service provider."}
              </p>
              <p className="mb-3 mt-5 text-center text-gray-700 sm:text-left">
                Please provide your bank information so when people order and
                purchase services from your practice, the funds can be deposited
                to your account electronically.
              </p>
            </div>
          </div>
        )}
        <div className="flex items-center justify-center">
          <form
            className="flex w-full justify-center"
            onSubmit={handleSubmit(addCardInfo)}
          >
            <div className="w-full rounded-3xl border bg-light-text p-8">
              <div>
                <label
                  htmlFor="Bank Name"
                  className="mb-2 block font-medium capitalize text-gray-600"
                >
                  bank name
                  <span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <InputText
                  id="Bank Name"
                  placeholder="Your Bank Name "
                  className="w-full pb-2 pt-2"
                  {...register("bankName", {
                    required: "Bank Name Is Required",

                    validate: validateName,
                  })}
                />
                {errors.bankName && (
                  <p className="mt-1 text-red-500">{errors.bankName.message}</p>
                )}
              </div>

              <div className="mt-4">
                <label
                  htmlFor="Account Owner Name"
                  className="mb-2 block font-medium capitalize text-gray-600"
                >
                  account owner name
                  <span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <InputText
                  id="Bank Owner Name"
                  placeholder="Account Owner Name"
                  className="w-full pb-2 pt-2"
                  {...register("AccountOwnerName", {
                    required: "Account Owner Name Is Required",
                    validate: validateName,
                  })}
                />
                {errors.AccountOwnerName && (
                  <p className="mt-1 text-red-500">
                    {errors.AccountOwnerName.message}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Routing Number"
                  className="mb-2 block font-medium capitalize text-gray-600"
                >
                  Routing Number
                  <span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <InputText
                  id="Routing Number"
                  placeholder="Routing Number"
                  className="w-full pb-2 pt-2"
                  keyfilter={/^\d+$/}
                  maxLength={17}
                  {...register("routingNumber", {
                    required: "Routing Number is required",
                    pattern: {
                      value: /^(?!0+$)\d+$/,
                      message: "Please enter a valid routing number",
                    },
                    validate: (value) =>
                      trimValidation(value, "Routing Number"),
                  })}
                />
                {errors.routingNumber && (
                  <p className="mt-1 text-red-500">
                    {errors.routingNumber.message}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Bank Account Number"
                  className="mb-2 block font-medium capitalize text-gray-600"
                >
                  {" "}
                  bank account number
                  <span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <Controller
                  name="bankAccountNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Bank Account Number Is Required",
                    pattern: {
                      value: /^(?!0+$)\d{1,17}$/,
                      message: "Please enter a correct account number",
                    },
                    validate: (value) =>
                      trimValidation(value, "Bank account number"),
                  }}
                  render={({ field }) => (
                    <>
                      <InputText
                        id="Bank Account Number"
                        className="w-full pb-2 pt-2"
                        maxLength={17}
                        keyfilter={/^\d+$/}
                        placeholder="Bank Account Number"
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          trigger("confirmBankAccountNumber");
                        }}
                      />
                      {errors.bankAccountNumber && (
                        <p className="mt-1 text-red-500">
                          {errors.bankAccountNumber.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="mt-4">
                <label
                  htmlFor="Confirm Bank Account Number"
                  className="mb-2 block font-medium capitalize text-gray-600"
                >
                  Confirm Bank Account Number
                  <span className="ml-1 font-bold text-red-500">*</span>
                </label>
                <Controller
                  name="confirmBankAccountNumber"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Confirm Bank Account Number is required",

                    validate: (value, formValues) => {
                      return value === formValues.bankAccountNumber
                        ? true
                        : "Bank Account Numbers don't match";
                    },
                  }}
                  render={({ field }) => (
                    <>
                      <InputText
                        id="Confirm Bank Account Number"
                        placeholder="Confirm Bank Account Number"
                        className="w-full pb-2 pt-2"
                        maxLength={17}
                        keyfilter={/^\d+$/}
                        {...field}
                      />
                      {errors.confirmBankAccountNumber && (
                        <p className="mt-1 text-red-500">
                          {errors.confirmBankAccountNumber.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="flex items-end justify-end">
                {isLoading ? (
                  <div className="mt-5 text-center">
                    <ProgressSpinner
                      strokeWidth={3}
                      style={{ width: "40px", height: "40px" }}
                    />
                  </div>
                ) : (
                  <div
                    className={`flex w-full ${
                      fromSetup
                        ? "justify-end gap-5"
                        : "justify-center sm:justify-end"
                    }`}
                  >
                    {fromSetup && (
                      <Button
                        onClick={handleSkip}
                        type="button"
                        className="mt-9 w-36 justify-center rounded-full border border-light-purple bg-white py-2.5 font-inter font-normal capitalize text-light-purple"
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      type="submit"
                      className="mt-9 w-32 justify-center rounded-full bg-light-purple py-2 font-inter font-normal capitalize text-light-text"
                    >
                      {fromSetup ? "Finish" : "Save"}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DepositSettings;
