const NumberIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.1665 11.3334V4.66669L7.83317 11.3334V4.66669M10.4998 11.3334H13.8332M10.4998 6.66669C10.4998 7.19712 10.6754 7.70583 10.988 8.0809C11.3006 8.45597 11.7245 8.66669 12.1665 8.66669C12.6085 8.66669 13.0325 8.45597 13.345 8.0809C13.6576 7.70583 13.8332 7.19712 13.8332 6.66669C13.8332 6.13625 13.6576 5.62755 13.345 5.25247C13.0325 4.8774 12.6085 4.66669 12.1665 4.66669C11.7245 4.66669 11.3006 4.8774 10.988 5.25247C10.6754 5.62755 10.4998 6.13625 10.4998 6.66669Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberIcon;
