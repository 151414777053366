import React from "react";

import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import useHandleResize from "../../../../../../../hooks/useHandleResize";

const ManageRulesFormFooter = ({ setOpen, saveLabel }) => {
  const { isMobile } = useHandleResize();

  const handleCancel = () => {
    confirmDialog({
      message: "Are you sure you want to discard the changes you have made?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Discard",
      rejectLabel: "Keep Changes",
      pt: { root: { style: { width: `${isMobile ? "90%" : ""}` } } },
      accept: () =>
        setOpen({ isVisible: false, rules: null, isNewRulesGroup: true }),
    });
  };

  return (
    <div className="flex w-full flex-col justify-end gap-2 py-4 sm:flex-row">
      <div className="flex w-full gap-2 lg:w-1/3">
        <Button
          label="Cancel"
          type="button"
          outlined
          onClick={handleCancel}
          className="w-1/2"
        />
        <Button
          label={saveLabel}
          icon="pi pi-save"
          severity="success"
          type="submit"
          className="w-1/2 p-2 md:px-5 md:py-3"
        />
      </div>
    </div>
  );
};

export default ManageRulesFormFooter;
