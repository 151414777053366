import { useEffect, useRef, useState } from "react";

import { useFormContext } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { confirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../../../hooks/useAxios";
import { Column } from "../../../../../../devlink/_Builtin";
import useDocument from "../../../../../../hooks/useDocument";
import useTokenData from "../../../../../../hooks/useTokenData";
import DocumentsLibraryDialog from "../../../components/DocumentsLibraryDialog";
import { showErrorToast } from "../../../../../../utils/apiUtils";

const AttachServiceFiles = () => {
  const { setValue, getValues, watch } = useFormContext();
  const toast = useRef();
  const { http } = useAxios();
  const { entityId } = useTokenData();

  const { handleDownloadDocument } = useDocument();
  const [refetch, setRefetch] = useState(false);
  const [visible, setVisible] = useState(false);
  const [serviceDocs, setServiceDocs] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [isDialogAppearing, setIsDialogAppearing] = useState(false);
  const serviceDocumentsIds = watch("documents", []);
  const clonedServiceDocs = serviceDocs?.filter((doc) =>
    serviceDocumentsIds.includes(doc.id),
  );

  const saveChanges = () => {
    setValue(
      "documents",
      selectedDocs.map((doc) => doc.id),
    );
    setVisible(false);
  };

  const discardChanges = () => {
    setSelectedDocs(clonedServiceDocs);
    setVisible(false);
  };

  const removeDocument = (rowData) => {
    confirmDialog({
      message: "Are you sure you want to remove this document?",
      header: "Remove Document",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        const updatedDocs = serviceDocumentsIds.filter(
          (doc) => doc !== rowData.id,
        );
        setValue("documents", updatedDocs);
      },
      acceptLabel: "Remove",
      acceptClassName: "p-button-danger",
      rejectLabel: "Cancel",
    });
  };

  const getServiceDocs = async () => {
    try {
      const response = await http.get(
        `/EntityDocuments/GetAllEntityDocuments/${entityId}`,
      );
      setServiceDocs(response.data);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleCreateNewDoc = () => {
    setVisible(false);
    setIsDialogAppearing(true);
  };

  const onDocumentCreate = (docId) => {
    const currentDocs = getValues("documents");
    setValue("documents", [...currentDocs, docId]);
  };

  useEffect(() => {
    getServiceDocs();
  }, [refetch]);

  useEffect(() => {
    visible && setSelectedDocs(clonedServiceDocs);
  }, [visible]);

  const actionTemplate = (rowData, options) => {
    return (
      <div className="flex items-center gap-4">
        {options.rowIndex === 0 && (
          <Tooltip target=".service-attachment-tooltip" />
        )}
        <i
          className="service-attachment-tooltip pi pi-download cursor-pointer text-purple-500"
          data-pr-tooltip="Download"
          data-pr-position="top"
          onClick={() => handleDownloadDocument(rowData)}
        ></i>
        <i
          className="service-attachment-tooltip pi pi-trash cursor-pointer text-red-500"
          data-pr-tooltip="Remove"
          data-pr-position="top"
          onClick={() => removeDocument(rowData)}
        ></i>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <span className="flex items-baseline gap-2">
        <label className="font-medium capitalize leading-loose text-gray-500">
          Service Documents
        </label>
        <i
          className="custom-target-icon pi pi-info-circle cursor-pointer text-sm text-gray-400"
          data-pr-tooltip="These documents will be available for the patient to download, including specific service instructions and more."
          data-pr-position="right"
        ></i>
      </span>
      <div className="flex flex-col gap-3 rounded-lg border border-slate-200 p-3">
        <div className="flex flex-col gap-3 sm:flex-row">
          <Button
            label="Attach documents"
            onClick={() => {
              setVisible(true);
              getServiceDocs();
            }}
            icon="pi pi-plus"
            className="bg-light-purple"
            type="button"
            id="serviceAttachments"
          />
        </div>

        <div>
          <DataTable
            className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
            value={clonedServiceDocs}
            scrollHeight="360px"
            scrollable
            emptyMessage={
              <p className="text-center">
                Currently, there are no documents selected.
              </p>
            }
          >
            <Column
              field="documentTitle"
              header="Title"
              align="center"
              alignHeader="center"
              style={{
                width: "30%",
              }}
            />
            <Column
              field="internalDescription"
              header="Description"
              align="center"
              alignHeader="center"
              style={{
                width: "60%",
              }}
            />
            <Column
              align="center"
              alignHeader="center"
              body={actionTemplate}
              style={{
                width: "10%",
              }}
            />
          </DataTable>
        </div>
      </div>
      <Dialog
        blockScroll
        resizable={false}
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        visible={visible}
        header="Select documents to be included with the service order"
        onHide={discardChanges}
      >
        <div className="flex flex-col pt-6 lg:gap-2">
          <div className="overflow-hidden rounded-xl border border-gray-300">
            <DataTable
              paginator
              rows={5}
              scrollHeight="275px"
              scrollable
              value={serviceDocs}
              selectionMode="multiple"
              selection={selectedDocs}
              metaKeySelection={false}
              onSelectionChange={(e) => setSelectedDocs(e.value)}
            >
              <Column field="documentTitle" header="Title" />
              <Column field="internalDescription" header="Description" />
            </DataTable>
          </div>
          <Divider
            layout="horizontal"
            align="center"
            className="w-10/12 self-center"
          >
            <b>OR</b>
          </Divider>
          <div className="mb-3 flex justify-center lg:mb-auto">
            <Button
              id="serviceDoc"
              label="Create new service document"
              outlined
              onClick={handleCreateNewDoc}
            />
          </div>
          <Button label="Save" onClick={saveChanges} className="self-end" />
        </div>
      </Dialog>
      <Dialog
        visible={isDialogAppearing}
        blockScroll
        header={"Upload Document"}
        onHide={() => {
          setIsDialogAppearing(false);
        }}
        className="w-11/12 lg:w-[70vw]"
      >
        <DocumentsLibraryDialog
          toast={toast}
          setRefetch={setRefetch}
          onDocumentCreate={onDocumentCreate}
          setIsDialogAppearing={setIsDialogAppearing}
        />
      </Dialog>
    </div>
  );
};

export default AttachServiceFiles;
