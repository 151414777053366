import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";

const TextConstraints = () => {
  const { control, watch, setValue, trigger } = useFormContext();

  const isMultiline = watch("constraints.multiline.force");
  const requireMinLength = watch("constraints.minLength.force");

  return (
    <div className="p-field">
      <Tooltip target=".custom-target-icon" className="max-w-sm text-xs" />

      <div className="flex items-center gap-2">
        <span className="text-gray-600">Text Settings</span>
        <span className="text-gray-500">
          <i
            className="custom-question-tooltip pi pi-info-circle text-xl"
            data-pr-tooltip="This settings will only appear if you chose a number-type question"
            data-pr-position="right"
            style={{ cursor: "pointer" }}
          ></i>
        </span>
      </div>
      <div className="my-4 flex flex-col gap-4 rounded-lg border border-slate-200 px-6 py-4">
        <div className="flex flex-col gap-8 py-5">
          <div className="grid grid-cols-12 items-center gap-2 gap-y-7">
            <Controller
              name="constraints.minLength.force"
              control={control}
              render={({ field }) => (
                <div className="col-span-12 gap-2 md:col-span-4">
                  <Checkbox
                    {...field}
                    inputId={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.checked);
                      const minLengthValue = e.checked ? 5 : null;
                      setValue("constraints.minLength.value", minLengthValue, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                      });
                      trigger("constraints.maxLength.value");
                    }}
                  />
                  <label htmlFor={field.name} className="cursor-pointer pl-2">
                    Required Min. Characters
                  </label>
                </div>
              )}
            />
            <Controller
              name="constraints.minLength.value"
              control={control}
              rules={{
                validate: {
                  empty: (value, formValues) => {
                    const requireMinLength =
                      formValues.constraints.minLength.force;
                    if (!requireMinLength) return true;
                    return value || "Min. Characters is required.";
                  },
                  largerThanMax: (value, formValues) => {
                    const isForceEnabled =
                      formValues.constraints.minLength.force &&
                      formValues.constraints.maxLength.force;
                    if (!isForceEnabled) return true;
                    trigger("constraints.maxLength.value");
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <div className="col-span-12 gap-2 md:col-span-4">
                    <span className="p-float-label">
                      <InputNumber
                        {...field}
                        inputId={field.name}
                        onChange={(e) => field.onChange(e.value)}
                        useGrouping={false}
                        placeholder="0"
                        min={1}
                        className={`w-full ${error ? "p-invalid" : ""}`}
                        disabled={!requireMinLength}
                      />
                      <label htmlFor={field.name}>Min. Characters</label>
                    </span>
                  </div>
                </>
              )}
            />
          </div>
          <div className="grid grid-cols-12 items-center gap-2 gap-y-7">
            <Controller
              name="constraints.multiline.force"
              control={control}
              render={({ field }) => (
                <div className="col-span-12 gap-2 md:col-span-4">
                  <Checkbox
                    {...field}
                    inputId={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.checked);
                      const maxLengthValue = e.checked ? 2048 : 150;
                      const displayLinesValue = e.checked ? 5 : null;
                      setValue("constraints.maxLength.value", maxLengthValue, {
                        shouldValidate: true,
                      });
                      setValue(
                        "constraints.multiline.value",
                        displayLinesValue,
                        {
                          shouldValidate: true,
                        },
                      );
                    }}
                  />
                  <label htmlFor={field.name} className="cursor-pointer pl-2">
                    Multi-line
                    <span
                      className="custom-target-icon"
                      data-pr-position="top"
                      data-pr-tooltip={`Enable this option to display the text field as a multiline text box, allowing users to enter longer responses.`}
                    >
                      <i className="pi pi-info-circle px-2 text-sm" />
                    </span>
                  </label>
                </div>
              )}
            />
            <Controller
              name="constraints.multiline.value"
              control={control}
              rules={{
                validate: {
                  empty: (value, formValues) => {
                    const isMultiline = formValues.constraints.multiline.force;
                    if (!isMultiline) return true;
                    return value || "No of lines is required.";
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <div className="col-span-12 gap-2 md:col-span-4">
                    <span className="p-float-label">
                      <InputNumber
                        {...field}
                        inputId={field.name}
                        onChange={(e) => field.onChange(e.value)}
                        useGrouping={false}
                        placeholder="0"
                        min={1}
                        className={`w-full ${error ? "p-invalid" : ""}`}
                        disabled={!isMultiline}
                      />
                      <label htmlFor={field.name}>Display Lines</label>{" "}
                    </span>
                  </div>
                </>
              )}
            />
          </div>
          <Controller
            name="constraints.maxLength.value"
            control={control}
            rules={{
              validate: {
                empty: (value, formValues) => {
                  const isMultiline = formValues.constraints.multiline;
                  if (!isMultiline) return true;
                  return value || "Max length is required.";
                },
                smallerThanMin: (value, formValues) => {
                  const isForceEnabled =
                    formValues.constraints.minLength.force &&
                    formValues.constraints.maxLength.force;
                  if (!isForceEnabled) return true;
                  const minValue = formValues.constraints.minLength.value;

                  return (
                    value >= minValue ||
                    "Max. Characters must be greater than Min. Characters"
                  );
                },
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="flex flex-col gap-1">
                <div className="grid grid-cols-12 items-center gap-2 gap-y-7">
                  <label
                    htmlFor={field.name}
                    className="col-span-12 cursor-pointer md:col-span-4"
                  >
                    Character Limit
                  </label>
                  <span className="p-float-label col-span-12 md:col-span-4">
                    <InputNumber
                      {...field}
                      inputId={field.name}
                      onChange={(e) => field.onChange(e.value)}
                      useGrouping={false}
                      placeholder="0"
                      min={1}
                      className={`w-full ${error ? "p-invalid" : ""}`}
                    />
                    <label htmlFor={field.name}>Max. Characters</label>
                  </span>
                </div>
                {error && (
                  <p className="w-full text-red-500">{error.message}</p>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TextConstraints);
