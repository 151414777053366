import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const useRegisterFormStore = create((set) => ({
  formData: null,
  setFormData: (data) => set({ formData: data }),
}));

export const useCapturedPhoto = create((set) => ({
  capturedPhoto: null,
  setCapturedPhoto: (imgData) => set({ capturedPhoto: imgData }),
}));

export const useSetUpGroupNextStep = create((set) => ({
  nextGroupData: {},
  identifierId: "",
  entityImage: null,
  selectedImage: null,
  userIdGroup: null,
  setNextGroupData: (data) => set({ formData: data }),
  setIdentifierId: (identifierId) => set({ identifierId: identifierId }),
  setImgIconId: (entityImage) => set({ entityImage: entityImage }),
  setSelectedImage: (selectedImage) => set({ selectedImage: selectedImage }),
  setUserIdGroup: (userId) => set({ userId: userId }),
}));

export const useStoreDataCookies = create((set) => ({
  data: {},
  setData: (newData) => set({ data: newData }),
}));

export const useSwitchOrdering = create((set) => ({
  isOrderingMode: false,
  setIsOrderingMode: (isOrderingMode) => set({ isOrderingMode }),
}));

export const useFirstTimeVisit = create((set) => ({
  isFirstTimeVisit: true,
  setIsFirstTimeVisit: (isFirstTimeVisit) => set({ isFirstTimeVisit }),
}));

export const useLocationStore = create((set) => ({
  locationsChangeBar: false,
  locationsInfo: false,
  setLocationsChangeBar: (locationsChangeBar) => set({ locationsChangeBar }),
  setLocationInfo: (locationsInfo) => set({ locationsInfo }),
}));

export const useAuthStore = create((set) => ({
  token: null,
  refreshToken: null,
  user: null,
  userLoginId: null,
  setToken: (token) => set((state) => ({ token })),
  setRefreshToken: (refreshToken) => set((state) => ({ refreshToken })),
  setUser: (user) => set((state) => ({ user })),
  setUserLoginId: (userLoginId) => set((state) => ({ userLoginId })),
}));

export const useRefreshTokenStore = create(
  persist(
    (set, get) => ({
      refreshToken: null,
      setRefreshToken: (refreshToken) => set((state) => ({ refreshToken })),
    }),
    {
      name: "refreshToken",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useTokenStore = create(
  persist(
    (set, get) => ({
      token: null,
      setToken: (token) => set((state) => ({ token })),
    }),
    {
      name: "token",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useCurrentEntity = create((set) => ({
  currentEntity: null,
  setCurrentEntity: (currentEntity) => set({ currentEntity }),
}));

export const useChecklist = create((set) => ({
  shouldUpdateProgress: false,
  setShouldUpdateProgress: (shouldUpdateProgress) =>
    set({ shouldUpdateProgress }),
}));

export const useSetEntity = create((set) => ({
  entityId: null,
  entityName: null,
  entityImg: null,
  setEntityId: (entityId) => set((state) => ({ entityId })),
  setEntityName: (entityName) => set((state) => ({ entityName })),
  setEntityImg: (entityImage) => set((state) => ({ entityImage })),
}));

export const useDefaultEntity = create((set) => ({
  defaultEntity: null,
  setDefaultEntity: (defaultEntity) => set({ defaultEntity }),
}));

export const useStoreIdFromUrl = create((set) => ({
  userId: null,
  setUserId: (userId) => set({ userId }),
}));

export const useJoinUserId = create((set) => ({
  joinUserId: null,
  joinEntityId: null,
  setJoinUserId: (joinUserId) => set({ joinUserId }),
  setJoinEntityId: (joinEntityId) => set({ joinEntityId }),
}));

export const useExternalLogin = create((set) => ({
  isCompletedRegister: true,
  setIsCompletedRegister: (isCompletedRegister) => set({ isCompletedRegister }),
}));

export const useStoreRenderCounter = create((set) => ({
  countRender: 0,
  setCountRender: (countRender) => set({ countRender }),
}));

// export const useStoreMslMicrosoft = create((set) => ({
//   msalInstance: null,
//   onMsalInstanceChange: (msalInstance) => set({ msalInstance }),
// }));

export const useStoreMslMicrosoft = create((set) => ({
  msalInstance: null,
  isMicrosoftLoggedIn: false,
  onMsalInstanceChange: (msalInstance) => set((state) => ({ msalInstance })),
  setIsMicrosoftLoggedIn: (isMicrosoftLoggedIn) =>
    set((state) => ({ isMicrosoftLoggedIn })),
}));

export const useServiceInfoTemp = create((set) => ({
  serviceName: "",
  serviceDescription: "",
  location: "",
  cptCode: "",
  setFormData: (data) => set((state) => ({ ...state, ...data })),
}));

export const useFeesEntity = create((set) => ({
  serviceFees: null,
  setServiceFees: (serviceFees) => set({ serviceFees }),
}));

export const useShowSessionExpiredDialog = create((set) => ({
  sessionExpiredData: { visible: false, action: null },
  setSessionExpiredData: (sessionExpiredData) => set({ sessionExpiredData }),
}));

export const useBlockNavigation = create((set) => ({
  blockNavigation: true,
  setBlockNavigation: (blockNavigation) => set({ blockNavigation }),
}));

export const useLayoutToast = create((set) => ({
  layoutToast: true,
  setLayoutToast: (layoutToast) => set({ layoutToast }),
}));

export const useExpireSystemAdmin = create(
  persist(
    (set, get) => ({
      isExpireAdmin: null,
      setIsExpireAdmin: (isExpireAdmin) => set((state) => ({ isExpireAdmin })),
    }),
    {
      name: "isExpireAdmin",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export const useBillableStore = create((set) => ({
  defaultBillables: [],
  setDefaultBillables: (defaultBillables) => set({ defaultBillables }),
}));

export const useLocationsStore = create((set) => ({
  defaultLocations: [],
  setDefaultLocations: (defaultLocations) => set({ defaultLocations }),
}));

// teams module
export const useTeamsStore = create((set) => ({
  selectedTeam: null,
  setSelectedTeam: (selectedTeam) => set({ selectedTeam }),
}));
