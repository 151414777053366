import { Controller, useFormContext } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { preventEnterDefault } from "../../../my-services/manage-service/utils/utils";

const UnitsBillableTemplate = ({ rowData, name = "billableItems" }) => {
  const { control, getValues } = useFormContext();

  const selectedBillables = getValues(name);

  const current = selectedBillables.find((b) => b.id === rowData.id);

  if (!current) return;

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <InputText
            id={rowData.id}
            value={current.units}
            min={1}
            className="w-full max-w-[64px]"
            onKeyDown={preventEnterDefault}
            onBlur={() => {
              field.onChange(
                field.value.map((item) =>
                  item.id === rowData.id
                    ? { ...item, units: item.units || 1 }
                    : item,
                ),
              );
            }}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10) || 0;

              if (value === current.units) return;

              field.onChange(
                field.value.map((item) =>
                  item.id === rowData.id ? { ...item, units: value } : item,
                ),
              );
            }}
          />
        )}
      />
    </div>
  );
};

export default UnitsBillableTemplate;
