import { useState } from "react";

import { useNavigate } from "react-router-dom";

import { Divider } from "primereact/divider";
import { ConfirmDialog } from "primereact/confirmdialog";

import { ServicesList } from "./components/ServicesList";
import { useServiceStore } from "./manage-service/utils/store";
import { ManageServicesHeader } from "./components/ManageServicesHeader";

export const MyServices = () => {
  const [refetch, setRefetch] = useState(false);
  const { setQuestions, setRulesGroups } = useServiceStore();
  const navigate = useNavigate();

  return (
    <div className="font-inter">
      <ConfirmDialog />
      <ManageServicesHeader
        onCreateServiceClick={() => {
          setRulesGroups([]);
          setQuestions([]);
          navigate("/services/my-services/manage-service");
        }}
      />

      <Divider />

      <div className="w-full">
        <div>
          <ServicesList refetch={refetch} setRefetch={setRefetch} />
        </div>
      </div>
    </div>
  );
};
