import { useRef, useState } from "react";

import { useFormContext } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { confirmDialog } from "primereact/confirmdialog";

import { ModTemplate } from "../templates/ModTemplate";
import { useServiceStore } from "../utils/store";
import { BillableItemsAttributesTemplate } from "../templates/BillableItemsAttributesTemplate";
import UnitsBillableTemplate from "../templates/UnitsBillableTemplate";
import { PriceBillableTemplate } from "../templates/PriceBillableTemplate";
import useHandleResize from "../../../../../../hooks/useHandleResize";
import ChooseBillableToReplaceDialog from "./ChooseBillableToReplaceDialog";
import DescriptionWithTooltipTemplate from "./rules/DescriptionWithTooltipTemplate";
import {
  getBillableItemsIds,
  isBillableItemInUse,
  replace,
  showBillableItemInUseToast,
} from "../../../my-services/manage-service/components/rules/actions/helpers";

const SelectedBillableItemsTable = ({
  isLoading,
  getBillableItems,
  selectedBillableItems,
}) => {
  const { watch, getValues, setValue } = useFormContext();
  const { rulesGroups, setRulesGroups } = useServiceStore();

  const { isMobile } = useHandleResize();
  const toast = useRef(null);
  const [dataToReplace, setDataToReplace] = useState();
  const [billableToReplaceDialogVisible, setBillableToReplaceDialogVisible] =
    useState(false);
  const selectedItems = watch("billableItems");

  const matchedItems = selectedBillableItems
    .filter((item) => selectedItems.some((selected) => selected.id === item.id))
    .map((item) => {
      const selected = selectedItems.find(
        (selected) => selected.id === item.id,
      );

      return {
        ...item,
        units: selected.units,
      };
    });

  const handleDeleteBillableItem = (id) => {
    const isUsed = isBillableItemInUse(id);
    if (isUsed) {
      showBillableItemInUseToast();
      return;
    }

    const handleAcceptDelete = () => {
      const filteredBillables = getValues("billableItems").filter(
        (b) => b.id !== id,
      );
      setValue("billableItems", filteredBillables);
    };

    confirmDialog({
      message: "Are you sure you want to remove billable item?",
      header: "Remove Billable Item",
      icon: "pi pi-exclamation-triangle",
      accept: handleAcceptDelete,
      acceptLabel: "Remove",
      acceptClassName: "p-button-danger",
      rejectLabel: "Cancel",
    });
  };
  const showConfirmReplace = (newBillable, acceptFunc) => {
    //show mods
    const getNonEmptyMods = (billable) => {
      return [
        billable.mod1,
        billable.mod2,
        billable.mod3,
        billable.mod4,
      ].filter((mod) => mod);
    };

    const oldMods = getNonEmptyMods(selectedItems);
    const newMods = getNonEmptyMods(newBillable);

    const renderMods = (mods) => {
      return mods.length ? (
        <span className="text-sm italic"> - {mods.join(" ")} -</span>
      ) : null;
    };
    confirmDialog({
      message: (
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-lg font-semibold">
              This action will remove the current billable item:
            </p>
            <strong>{dataToReplace.hcpcs}</strong> {renderMods(oldMods)}{" "}
            <span className="text-sm italic"></span>
            <span className="text-sm italic">{dataToReplace.description}</span>
          </div>
          <div>
            <p className="text-lg font-semibold">
              And replace it with the new one:
            </p>
            <strong>{newBillable.hcpcs}</strong> {renderMods(newMods)}{" "}
            <span className="text-sm italic"></span>
            <span className="text-sm italic">{newBillable.description}</span>
          </div>
          <p className="text-lg font-semibold">
            Are you sure you want to proceed?
          </p>
        </div>
      ),
      header: `Replace ${dataToReplace.hcpcs} with ${newBillable.hcpcs}`,
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, Replace",
      rejectLabel: "Cancel",
      className: "w-1/2",
      accept: async () => {
        if (acceptFunc) {
          await acceptFunc();
        } else {
          const oldId = dataToReplace.id;
          const newId = newBillable.id;

          const newBillableItems = replace(selectedItems, oldId, newId);
          const newRules = replace(rulesGroups, oldId, newId);

          setValue("billableItems", newBillableItems);
          setRulesGroups(newRules);
          setBillableToReplaceDialogVisible(false);
          getBillableItems(getBillableItemsIds(newBillableItems));

          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Item Replaced Successfully",
            life: 4000,
          });
        }
      },
      reject: () => {},
    });
  };
  const handleReplaceBillableItem = (rowData) => {
    setDataToReplace(rowData);

    setBillableToReplaceDialogVisible(true);
  };

  const actionsTemplate = (rowData) => {
    return (
      <div className="flex items-center gap-2">
        <Tooltip target=".action" />
        <i
          className="pi pi-arrow-right-arrow-left action cursor-pointer text-blue-500"
          data-pr-tooltip="Replace"
          data-pr-position="top"
          onClick={() => handleReplaceBillableItem(rowData)}
        />
        <i
          className="pi pi-trash action cursor-pointer text-red-500"
          data-pr-tooltip="Delete"
          data-pr-position="top"
          onClick={() => handleDeleteBillableItem(rowData?.id)}
        />
      </div>
    );
  };

  const COLUMNS = [
    { header: "HCPCS", field: "hcpcs" },
    {
      header: "Description",
      body: DescriptionWithTooltipTemplate,
    },
    {
      body: ModTemplate,
      header: "Mods",
    },
    {
      body: BillableItemsAttributesTemplate,
      header: "Attributes",
    },
    {
      body: PriceBillableTemplate,
      header: "Price per unit",
    },
    {
      body: (e) => <UnitsBillableTemplate rowData={e} />,
      header: "Units",
    },
    {
      body: actionsTemplate,
      header: "",
    },
  ];

  return (
    <>
      <Toast ref={toast} />
      <ChooseBillableToReplaceDialog
        billableToReplaceDialogVisible={billableToReplaceDialogVisible}
        setBillableToReplaceDialogVisible={setBillableToReplaceDialogVisible}
        showConfirmReplace={showConfirmReplace}
        dataToReplace={dataToReplace}
        getBillableItems={getBillableItems}
      />
      <div className={isMobile ? "max-h-[360px] overflow-auto" : ""}>
        <DataTable
          className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
          scrollHeight="360px"
          scrollable
          loading={isLoading}
          emptyMessage={
            <p className="text-center">
              Currently, there are no billable items selected.
            </p>
          }
          value={matchedItems}
        >
          {COLUMNS.map((column) => (
            <Column
              key={column.header}
              {...column}
              align="center"
              alignHeader="center"
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default SelectedBillableItemsTable;
