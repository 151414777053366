import { useRef } from "react";

import { Tooltip } from "primereact/tooltip";

const DescriptionWithTooltipTemplate = (rowData) => {
  const tooltip = useRef();
  return (
    <div>
      <Tooltip target={tooltip} className="max-w-sm" />
      <p
        ref={tooltip}
        className="cursor-help"
        data-pr-position="top"
        data-pr-tooltip={rowData.description}
      >
        {rowData.shortDescription}
      </p>
    </div>
  );
};

export default DescriptionWithTooltipTemplate;
