import { useEffect, useState } from "react";
import useAxios from "../../../../../../../../hooks/useAxios";
import useTokenData from "../../../../../../../../hooks/useTokenData";
import { useLocationsContext } from "../../../../../my-services/manage-service/context/LocationsContext";
import { ACTIONS_ENUM } from "../../../utils/constants";
import {
  filterDataBasedOnKey,
  getMatchedDataFromRulesGroup,
} from "../../../utils/utils";
import DisplayAbortMessage from "./DisplayAbortMessage";

import DisplayBillableItems from "./DisplayBillableItems";
import DisplayLocations from "./DisplayLocations";
import DisplaySwapBillableItems from "./DisplaySwapBillableItems";
import DisplayDocuments from "./DisplayDocuments";
import { showErrorToast } from "../../../../../../../../utils/apiUtils";

function ActionsView({ rulesGroup, dataOfAllBillableItems }) {
  const actionKey = rulesGroup.action.key;
  const { defaultLocations } = useLocationsContext();

  const filteredBillables = filterDataBasedOnKey(
    rulesGroup,
    dataOfAllBillableItems,
  );
  const filteredLocations = getMatchedDataFromRulesGroup(
    rulesGroup,
    defaultLocations,
  );

  const { http } = useAxios();
  const { entityId } = useTokenData();

  const [filteredDocs, setFilteredDocs] = useState();
  const getServiceDocs = async () => {
    try {
      const response = await http.get(
        `/EntityDocuments/GetAllEntityDocuments/${entityId}`,
      );
      setFilteredDocs(getMatchedDataFromRulesGroup(rulesGroup, response.data));
    } catch (error) {
      showErrorToast({ error });
    }
  };

  useEffect(() => {
    if (ACTIONS_ENUM.ADD_DOCS.value || ACTIONS_ENUM.REMOVE_DOCS.value) {
      getServiceDocs();
    }
  }, [rulesGroup?.action?.on]);

  switch (actionKey) {
    case ACTIONS_ENUM.ADD_CPT.value:
    case ACTIONS_ENUM.REMOVE_UNITS_FROM_EXISTING.value:
    case ACTIONS_ENUM.ADD_UNITS_TO_EXISTING.value:
    case ACTIONS_ENUM.SET_UNITS.value:
      return (
        <DisplayBillableItems
          showUnits
          dataOfAllBillableItems={filteredBillables}
        />
      );

    case ACTIONS_ENUM.REMOVE_ALL_EXCEPT.value:
    case ACTIONS_ENUM.REMOVE_CPT.value:
    case ACTIONS_ENUM.REMOVE_MATCHING_PATTERN.value:
      return (
        <DisplayBillableItems dataOfAllBillableItems={filteredBillables} />
      );
    case ACTIONS_ENUM.SWAP_CPT.value:
      return (
        <DisplaySwapBillableItems
          rulesGroup={rulesGroup}
          dataOfAllBillableItems={dataOfAllBillableItems}
        />
      );
    case ACTIONS_ENUM.ABORT.value:
      return <DisplayAbortMessage rulesGroup={rulesGroup} />;
    case ACTIONS_ENUM.ADD_LOCATIONS.value:
    case ACTIONS_ENUM.REMOVE_LOCATIONS.value:
      return <DisplayLocations filteredLocations={filteredLocations} />;

    case ACTIONS_ENUM.REMOVE_DOCS.value:
    case ACTIONS_ENUM.ADD_DOCS.value:
      return <DisplayDocuments filteredDocs={filteredDocs} />;

    default:
      return <></>;
  }
}

export default ActionsView;
