import { Dialog } from "primereact/dialog";

import { useLocationsContext } from "../context/LocationsContext";

import ManageLocationForm from "./ManageLocationForm";

const CreateNewLocationDialog = () => {
  const { locationVisible, setLocationVisible } = useLocationsContext();

  return (
    <Dialog
      blockScroll
      draggable={false}
      visible={locationVisible}
      style={{ width: "70vw" }}
      onHide={() => setLocationVisible(false)}
    >
      <div className="mt-4">
        <ManageLocationForm setLocationVisible={setLocationVisible} />
      </div>
    </Dialog>
  );
};

export default CreateNewLocationDialog;
