import AbortOrder from "./actions/AbortOrder";
import { ACTIONS_ENUM } from "../../utils/constants";
import SelectLocation from "./actions/SeletLocation";
import SelectDocsForm from "./actions/SelectDocsForm";
import AddBillableItem from "./actions/AddBillableItem";
import SwapBillableItems from "./actions/SwapBillableItems";
import RemoveBillableItem from "./actions/RemoveBillableItem";
import AdjustBillableUnits from "./actions/AdjustBillableUnits";
import { Controller, useFormContext } from "react-hook-form";
import { trimValidation } from "../../../../../../../utils/helpers";
import RemoveMatchingPattern from "./actions/RemoveMatchingPattern";

const Actions = ({ action }) => {
  const { control } = useFormContext();

  switch (action) {
    case ACTIONS_ENUM.ADD_CPT.value:
      return (
        <Controller
          name="action.on"
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 || "Please select at least one billable item",
            },
          }}
          render={({ fieldState: { error } }) => (
            <>
              <AddBillableItem />
              {error && <p className="text-red-500">{error.message}</p>}
            </>
          )}
        />
      );

    case ACTIONS_ENUM.ADD_UNITS_TO_EXISTING.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 ||
                "Please specify units to add to existing billable items",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <AdjustBillableUnits
              field={field}
              error={error}
              unitsHeader="Units to add"
            />
          )}
        />
      );

    case ACTIONS_ENUM.REMOVE_UNITS_FROM_EXISTING.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 ||
                "Please specify units to remove from existing billable items",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <AdjustBillableUnits
              field={field}
              error={error}
              unitsHeader="Units to remove"
            />
          )}
        />
      );

    case ACTIONS_ENUM.SET_UNITS.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 ||
                "Please specify units to set for existing billable items",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <AdjustBillableUnits
              field={field}
              error={error}
              unitsHeader="Units to set"
            />
          )}
        />
      );

    case ACTIONS_ENUM.ADD_LOCATIONS.value:
    case ACTIONS_ENUM.REMOVE_LOCATIONS.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 || "Please select at least one location",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <SelectLocation field={field} error={error} />
          )}
        />
      );

    case ACTIONS_ENUM.ABORT.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            required: "Please enter a valid text",
            validate: (value) => {
              return trimValidation(value.message, `Abort message`);
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <AbortOrder field={field} error={error} />
          )}
        />
      );

    case ACTIONS_ENUM.SWAP_CPT.value:
      return <SwapBillableItems />;

    case ACTIONS_ENUM.ADD_DOCS.value:
    case ACTIONS_ENUM.REMOVE_DOCS.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 || "Please Select at least one document",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <SelectDocsForm field={field} error={error} />
          )}
        />
      );

    case ACTIONS_ENUM.REMOVE_CPT.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 || "Please Select at least one billable item",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <RemoveBillableItem
              field={field}
              error={error}
              actionHeader="Billable Items To Remove"
            />
          )}
        />
      );

    case ACTIONS_ENUM.REMOVE_ALL_EXCEPT.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            validate: {
              notEmpty: (value) =>
                value.length > 0 || "Please Select at least one billable item",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <RemoveBillableItem
              field={field}
              error={error}
              actionHeader="Billable Items To Keep"
            />
          )}
        />
      );
    case ACTIONS_ENUM.REMOVE_MATCHING_PATTERN.value:
      return (
        <Controller
          name={"action.on"}
          control={control}
          rules={{
            required: "Please enter a valid pattern",
            validate: (value) => {
              return trimValidation(value.pattern, `Match pattern`);
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <RemoveMatchingPattern field={field} error={error} />
          )}
        />
      );
    default:
      return <></>;
  }
};

export default Actions;
