import { useServiceStore } from "../../../utils/store";
import { ACTIONS_ENUM } from "../../../utils/constants";
import { useLayoutToast } from "../../../../../../../../store/store";

export const getAllBillableItemsInRules = (exceptionRuleId) => {
  const { rulesGroups } = useServiceStore.getState();

  return (
    rulesGroups.flatMap((rule) => {
      if (rule.id === exceptionRuleId) return [];
      if (rule.action.key === ACTIONS_ENUM.ADD_CPT.value) {
        return rule.action.on;
      }

      if (rule.action.key === ACTIONS_ENUM.SWAP_CPT.value) {
        return rule.action.on.new;
      }

      return [];
    }) || []
  );
};

export const getBillableItemsIds = (billableItems) => {
  return billableItems.map((b) => b.id);
};

export const showBillableItemInUseToast = () => {
  const { layoutToast } = useLayoutToast.getState();
  layoutToast.current.show({
    severity: "warn",
    summary: "Warning",
    detail: (
      <p>
        A rule is applying an action on this billable item,
        <strong> Please review your rules.</strong>
      </p>
    ),
    life: 4000,
  });
};

function translatePattern(pattern) {
  // Escape regex special characters except for our wildcard and range characters
  pattern = pattern.replace(/([.+^$(){}|\\])/g, "\\$1");

  // Translate the user-friendly pattern into regex
  pattern = pattern
    .replace(/\*/g, ".*") // * => .*
    .replace(/\?/g, ".") // ? => .
    .replace(/#/g, "[0-9]") // # => [0-9]
    .replace(/\[!([^\]]+)\]/g, "[^$1]") // [!a-z] => [^a-z] for negation
    .replace(/\[([^\]]+)\]/g, "[$1]"); // [a-z] => [a-z]

  try {
    return new RegExp("^" + pattern + "$");
  } catch (error) {
    return null;
  }
}

export function matchPattern(expression, pattern) {
  const regex = translatePattern(pattern);
  try {
    return regex.test(expression);
  } catch (error) {
    return null;
  }
}

export const isBillableItemInUse = (id, ruleId) => {
  const { rulesGroups } = useServiceStore.getState();

  const filteredRules = ruleId
    ? rulesGroups.filter((rule) => rule.id !== ruleId)
    : rulesGroups;

  const rulesString = JSON.stringify(filteredRules);

  return rulesString.includes(id);
};

export const replace = (data, oldId, newId) => {
  const stringifiedData = JSON.stringify(data);

  const replacedString = stringifiedData.replace(new RegExp(oldId, "g"), newId);

  return JSON.parse(replacedString);
};
