export const formatDate = (date, short = false) => {
  if (!date) return "";

  if (short) {
    return new Date(date).toLocaleDateString("en-US", {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
    });
  } else {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};
export const formatDateTime = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const getTimestamp = (date) => {
  const finalDate = date ? date : new Date();

  const year = finalDate.getFullYear();
  const month = (finalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = finalDate.getDate().toString().padStart(2, "0");

  const hours = finalDate.getHours().toString().padStart(2, "0");
  const minutes = finalDate.getMinutes().toString().padStart(2, "0");
  const seconds = finalDate.getSeconds().toString().padStart(2, "0");

  const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.0000000`;

  return formattedTimestamp;
};

export const handleBeforeUnload = (event) => {
  event.preventDefault();
};

export const getTimeDifference = (dateRange) => {
  var originalDate = new Date();

  var result = originalDate.setDate(originalDate.getDate() - dateRange);

  return getTimestamp(new Date(result));
};

export const blockSpecialCharsPattern = /^[a-zA-Z0-9 ]*$/;

export const ORDERING_DROPDOWN_OPTIONS = [
  { name: "Service", value: "Service.Name@=*" },
  { name: "Order Number", value: "OrderTrackingNumber@=*" },
  { name: "Offered By", value: "Service.Entity.Name@=*" },
  { name: "Patient Name", value: "patientName@=*" },
];

export const RENDERING_DROPDOWN_OPTIONS = [
  { name: "Service", value: "Service.Name@=*" },
  { name: "Order Number", value: "OrderTrackingNumber@=*" },
  { name: "Ordered By", value: "Entity.Name@=*" },
  { name: "Patient Name", value: "patientName@=*" },
];

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const checkCameraAccess = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    });
    stream.getTracks().forEach((track) => track.stop());
    return null;
  } catch (error) {
    return error.message === "Permission denied"
      ? "Camera permission denied. Please enable it in browser settings."
      : error.message === "Device in use"
        ? "Can't access camera. It's in use by another application."
        : error.message;
  }
};

export const downloadFileFromResponse = (response) => {
  let fileName = "downloadedFile";
  const contentDisposition = response.headers["content-disposition"];
  if (contentDisposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, "");
    }
  }
  const blob = new Blob([response.data], {
    type: response.headers["content-type"],
  });

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

/////////////////////// Truncate File Name ///////////////////////

export function truncateFileName(fileName, maxLength) {
  const extension = fileName?.split(".").pop();
  if (extension) {
    const fileNameWithoutExtension = fileName.substring(
      0,
      fileName.length - (extension.length + 1),
    );
    const truncatedName =
      fileNameWithoutExtension.length > maxLength
        ? fileNameWithoutExtension.slice(0, maxLength - 1) + "…"
        : fileNameWithoutExtension;

    return truncatedName + "." + extension;
  } else {
    return fileName;
  }
}

export const trimValidation = (value, name) => {
  return value?.trim().length !== 0 || `${name} can not be empty`;
};

export const editorHeaderTemplate = (
  <span className="ql-formats">
    <select className="ql-size" aria-label="Font Size"></select>
    <select className="ql-font" aria-label="Font"></select>
    <button className="ql-bold" aria-label="Bold"></button>
    <button className="ql-italic" aria-label="Italic"></button>
    <button className="ql-underline" aria-label="Underline"></button>
    <select className="ql-color" aria-label="Text Color"></select>
    <select className="ql-background" aria-label="Text Highlight"></select>
    <button
      className="ql-list"
      value="ordered"
      aria-label="Text Number Marker"
    ></button>
    <button
      className="ql-list"
      value="bullet"
      aria-label="Text Points Marker"
    ></button>
    <select className="ql-align" aria-label="Text Alignment"></select>
  </span>
);

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const compressImage = async (file, maxWidth = 1280, quality = 0.8) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    const fileURL = URL.createObjectURL(file);
    img.src = fileURL;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const aspectRatio = img.width / img.height;
      canvas.width = maxWidth;
      canvas.height = maxWidth / aspectRatio;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        (blob) => {
          URL.revokeObjectURL(fileURL);
          resolve(blob);
        },
        "image/jpeg",
        quality,
      );
    };

    img.onerror = (error) => {
      URL.revokeObjectURL(fileURL);
      reject(error);
    };
  });
};

export const getBase64Src = (base64Img) => {
  if (!base64Img) return;
  return `data:${base64Img.contentType};base64,${base64Img.fileContent}`;
};

export const convertToFeetAndInches = (number) => {
  const feet = Math.floor(number);
  const inches = Math.round((number - feet) * 12);
  return `${feet} (ft) ${inches} (in)`;
};
