import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { Controller, useFormContext } from "react-hook-form";

const serviceTypeOptions = [
  { label: "Scheduled Appointment Service", value: 1 },
  { label: "Walk-In Service", value: 2 },
];

const ServiceType = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
      <label
        htmlFor="serviceType"
        className="font-medium capitalize leading-loose text-gray-500"
      >
        service Type
        <span className="ml-1 font-bold text-red-500">*</span>
      </label>
      <Tooltip target=".custom-target-icon" className="max-w-sm" />
      <div>
        <div className="flex items-center gap-5">
          <span className="grow">
            <Controller
              name="serviceType"
              control={control}
              render={({ field }) => (
                <Dropdown
                  id={field.name}
                  {...field}
                  placeholder="Service Type"
                  options={serviceTypeOptions}
                  className="md:w-14rem w-full"
                />
              )}
            />
          </span>
          <span className="flex gap-1">
            <Controller
              name="internalOnly"
              control={control}
              render={({ field }) => (
                <Checkbox
                  inputId={field.name}
                  name={field.name}
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.checked);
                  }}
                />
              )}
            />
            <label
              htmlFor="internalOnly"
              className="cursor-pointer font-semibold text-gray-800"
            >
              Internal Only
              <span
                className="custom-target-icon"
                data-pr-position="top"
                data-pr-tooltip={`Enabling this feature will make this service hidden from external ordering providers. By enabling this feature you are publishing a service that can ONLY be ordered by users associated with your practice. This is customarily done in order to publish services that you provide to your patients, where you wish to appropriately collect for them using the MedX platform, but do not wish to allow any staff or providers external to your practice from ordering such services. The default is to have this disabled, especially if you are planning on having this service to be visible and purchasable by other providers.`}
              >
                <i className="pi pi-info-circle px-2 text-sm" />
              </span>
            </label>
          </span>
        </div>
        {errors?.type?.message && (
          <p className="text-red-500">{errors.type.message}</p>
        )}
      </div>
    </div>
  );
};

export default ServiceType;
