import { useEffect } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import useTokenData from "../hooks/useTokenData";
function ExternalLoginGuard() {
  const navigate = useNavigate();
  const { userId, isCompletedRegister } = useTokenData();
  useEffect(() => {
    if (userId && isCompletedRegister === "True") {
      navigate(`/chooseEntity`, {
        replace: true,
      });
    } else if (userId && isCompletedRegister === "False") {
      navigate(`/completeRegistration`, {
        replace: true,
      });
    }
  }, [userId, isCompletedRegister, navigate]);
  return <Outlet />;
}

export default ExternalLoginGuard;
