import useTokenData from "../hooks/useTokenData";
import { Navigate, Outlet } from "react-router-dom";
import { useSwitchOrdering } from "../store/store";
import { USER_ROLES_ENUM } from "../constants/constants";

function UserRoleGuard() {
  const { userRoles, UserJourneyStatus } = useTokenData();
  const { isOrderingMode } = useSwitchOrdering();

  if (
    (userRoles === USER_ROLES_ENUM.STAFF ||
      userRoles === USER_ROLES_ENUM.ORDERING_ONLY_STAFF) &&
    isOrderingMode == true
  ) {
    return <Navigate replace to="/ordering/FindServices" />;
  } else if (
    userRoles === USER_ROLES_ENUM.STAFF &&
    isOrderingMode == false &&
    UserJourneyStatus === "2"
  ) {
    return <Navigate replace to="/Dashboard" />;
  }

  return <Outlet />;
}

export default UserRoleGuard;
