/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import useAxios from "../../../../../../../../hooks/useAxios";
import useTokenData from "../../../../../../../../hooks/useTokenData";
import useDocument from "../../../../../../../../hooks/useDocument";
import DocumentsLibraryDialog from "../../../../../components/DocumentsLibraryDialog";
import { showErrorToast } from "../../../../../../../../utils/apiUtils";

const SelectDocuments = ({
  action,
  allServiceDocuments,
  selectedDocs,
  handleSaveSelection,
  handleAddNew,
  handleRemove,
}) => {
  const toast = useRef();
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { handleDownloadDocument } = useDocument();

  const [refetch, setRefetch] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [serviceDocs, setServiceDocs] = useState([]);
  const [availableDocs, setAvailableDocs] = useState([]);
  const [selectionTableData, setSelectionTableData] = useState([]);

  const [unsavedSelectedDocs, setUnsavedSelectedDocs] = useState([]);
  const [isDialogAppearing, setIsDialogAppearing] = useState(false);

  useEffect(() => {
    getServiceDocs();
  }, [refetch]);

  useEffect(() => {
    const selectedDocuments = serviceDocs?.filter((doc) =>
      selectedDocs.includes(doc.id),
    );
    setUnsavedSelectedDocs(selectedDocuments);
    setSelectionTableData(selectedDocuments);

    if (action === "addDocs") {
      setAvailableDocs(serviceDocs);
    } else if (action === "removeDocs") {
      const allServiceDocs = Array.from(new Set(allServiceDocuments));
      const availableDocs = serviceDocs?.filter((doc) =>
        allServiceDocs.includes(doc.id),
      );
      setAvailableDocs(availableDocs);
    }
  }, [serviceDocs, action, selectedDocs]);

  const saveChanges = () => {
    handleSaveSelection(unsavedSelectedDocs);

    setVisible(false);
  };

  const discardChanges = () => {
    setVisible(false);
  };

  const getServiceDocs = async () => {
    try {
      const response = await http.get(
        `/EntityDocuments/GetAllEntityDocuments/${entityId}`,
      );
      setServiceDocs(response.data);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsFetching(false);
  };

  const handleCreateNewDoc = () => {
    setVisible(false);
    setIsDialogAppearing(true);
  };

  const actionTemplate = (rowData, options) => {
    return (
      <div className="flex items-center gap-4">
        {options.rowIndex === 0 && (
          <Tooltip target=".service-attachment-tooltip" />
        )}
        <i
          className="service-attachment-tooltip pi pi-download cursor-pointer text-purple-500"
          data-pr-tooltip="Download"
          data-pr-position="top"
          onClick={() => handleDownloadDocument(rowData)}
        ></i>
        <i
          className="service-attachment-tooltip pi pi-trash cursor-pointer text-red-500"
          data-pr-tooltip="Remove Selection"
          data-pr-position="top"
          onClick={() => handleRemove(rowData)}
        ></i>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <span className="flex items-baseline gap-2">
        <label className="font-medium capitalize leading-loose text-gray-500">
          {action === "addDocs" ? "Add" : "Remove"} Documents
        </label>
      </span>
      <div className="flex flex-col gap-3 rounded-lg border border-slate-200 p-3">
        <div className="flex flex-col gap-3 sm:flex-row">
          <Button
            type="button"
            className="bg-light-purple"
            label="Select Documents"
            onClick={() => {
              setVisible(true);
            }}
            icon="pi pi-plus"
            id="serviceAttachments"
          />
        </div>

        <div>
          <DataTable
            className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
            value={selectionTableData}
            scrollHeight="360px"
            scrollable
            loading={isFetching}
            emptyMessage={
              <p className="text-center">
                Currently, there are no documents selected.
              </p>
            }
          >
            <Column
              field="documentTitle"
              header="Title"
              align="center"
              alignHeader="center"
              style={{
                width: "30%",
              }}
            />
            <Column
              field="internalDescription"
              header="Description"
              align="center"
              alignHeader="center"
              style={{
                width: "60%",
              }}
            />
            <Column
              align="center"
              alignHeader="center"
              body={actionTemplate}
              style={{
                width: "10%",
              }}
            />
          </DataTable>
        </div>
      </div>
      <Dialog
        blockScroll
        resizable={false}
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        visible={visible}
        header="Select documents"
        onHide={discardChanges}
      >
        <div className="flex flex-col pt-6 lg:gap-2">
          <div className="overflow-hidden rounded-xl border border-gray-300">
            <DataTable
              paginator
              rows={5}
              scrollHeight="275px"
              scrollable
              value={availableDocs}
              selectionMode="multiple"
              selection={unsavedSelectedDocs}
              metaKeySelection={false}
              onSelectionChange={(e) => setUnsavedSelectedDocs(e.value)}
            >
              <Column field="documentTitle" header="Title" />
              <Column field="internalDescription" header="Description" />
            </DataTable>
          </div>

          {action === "addDocs" && (
            <>
              <Divider
                layout="horizontal"
                align="center"
                className="w-10/12 self-center"
              >
                <b>OR</b>
              </Divider>
              <div className="mb-3 flex justify-center lg:mb-auto">
                <Button
                  type="button"
                  id="serviceDoc"
                  label="Create new service document"
                  outlined
                  onClick={handleCreateNewDoc}
                />
              </div>
            </>
          )}
          <Button
            type="button"
            label="Save"
            onClick={saveChanges}
            className="self-end bg-light-purple"
          />
        </div>
      </Dialog>
      <Dialog
        visible={isDialogAppearing}
        blockScroll
        header={"Upload Document"}
        onHide={() => {
          setIsDialogAppearing(false);
        }}
        className="w-11/12 lg:w-[70vw]"
      >
        <DocumentsLibraryDialog
          toast={toast}
          setRefetch={setRefetch}
          onDocumentCreate={handleAddNew}
          setIsDialogAppearing={setIsDialogAppearing}
        />
      </Dialog>
    </div>
  );
};

export default SelectDocuments;
