import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { GeneralMetaHeaders } from "../meta";
import useTokenData from "../hooks/useTokenData";
import { useExpireSystemAdmin } from "../store/store";

export const AdminAuthGaurd = () => {
  const { SystemAdministrator } = useTokenData();
  const { isExpireAdmin } = useExpireSystemAdmin();

  if (SystemAdministrator !== "SystemAdministrator") {
    return <Navigate to={"/Dashboard"} replace />;
  } else if (isExpireAdmin == true) {
    return <Navigate to={"/admin/expired"} replace />;
  }
  return (
    <>
      <GeneralMetaHeaders />
      <Outlet />
    </>
  );
};
