import { useEffect } from "react";

import { useFormContext } from "react-hook-form";

import { Editor } from "primereact/editor";

import { QUESTION_TYPES_ENUM } from "../utils/constants";
import { checkConditionLogicalOperator } from "../utils/utils";
import {
  DisplayBoolean,
  DisplayDropDown,
  DisplayMultipleChoice,
  DisplayNumber,
  DisplayText,
} from "./userAnswerFields";

const isEmptyEditor = (value) => !value.replace(/<[^>]*>/g, "").trim();

export const RenderField = ({ question }) => {
  const { watch, setValue } = useFormContext();

  const isVisible =
    !question.isConditional ||
    checkConditionLogicalOperator(
      question.conditionalOperator,
      question.conditions,
      watch(),
    );

  useEffect(() => {
    if (!isVisible) {
      setValue(question.id, undefined);
    }
  }, [isVisible]);

  if (!isVisible) return null;

  const isMultipleChoice =
    question.type === QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value;

  const isDropDown = question.type === QUESTION_TYPES_ENUM.DROPDOWN.value;

  const isBoolean = question.type === QUESTION_TYPES_ENUM.BOOLEAN.value;

  const isNumber = [
    QUESTION_TYPES_ENUM.NUMBER.value,
    QUESTION_TYPES_ENUM.DECIMAL.value,
  ].includes(question.type);

  const isText = question.type === QUESTION_TYPES_ENUM.TEXT.value;

  return (
    <div className="flex flex-col rounded-md border p-3 shadow-sm">
      <div key={question.label} className="flex w-full flex-col gap-2">
        <label className="font-semibold text-gray-600">
          Q. {question.label}
          {question?.required && <span className="ml-1 text-red-500">*</span>}
        </label>
        <div className="w-full max-w-md">
          {isMultipleChoice && <DisplayMultipleChoice question={question} />}

          {isDropDown && <DisplayDropDown question={question} />}

          {isNumber && <DisplayNumber question={question} />}

          {isBoolean && <DisplayBoolean question={question} />}

          {isText && <DisplayText question={question} />}
        </div>
        {question?.description && !isEmptyEditor(question?.description) && (
          <div className="card">
            <Editor
              readOnly
              headerTemplate="Description"
              value={question.description}
              id="description"
              style={{ height: "128px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderField;
