import { useState } from "react";

import { Button } from "primereact/button";

export const BuggyCounter = () => {
  const [counter, setCounter] = useState(0);

  if (counter === 5) {
    throw new Error("ana el error");
  }

  return (
    process.env.REACT_APP_ENVIRONMENT === "testdev" && (
      <Button
        onClick={() => setCounter((c) => c + 1)}
        severity="danger"
        type="button"
        label="Throw error "
      >
        : {counter}
      </Button>
    )
  );
};
