export const ModTemplate = (rowData) => {
  const mods = [rowData.mod1, rowData.mod2, rowData.mod3, rowData.mod4].filter(
    (mod) => mod,
  );

  return mods.length ? (
    <span className="text-sm"> {mods.join(" | ")} </span>
  ) : (
    <span className="text-md"> - - -</span>
  );
};
