import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

function DisplayLocations({ filteredLocations }) {
  const locationBodyTemplate = (filteredLocations) => {
    return (
      <div className="px-2">
        <h4>
          <span
            className={`font-medium capitalize ${filteredLocations.isEnabled ? "text-gray-600" : "text-gray-400"}`}
          >
            {filteredLocations.name}{" "}
          </span>
          {!filteredLocations.isEnabled && (
            <span className="text-sm italic text-gray-400">(disabled)</span>
          )}
        </h4>
        <p className="font-normal capitalize text-gray-500">{`${
          filteredLocations.address.lineOne
        }, ${
          filteredLocations.address.lineTwo
            ? filteredLocations.address.lineTwo + ","
            : ""
        } ${filteredLocations.address.city}, ${filteredLocations.address.state} ${
          filteredLocations.address.zipCode
        }`}</p>
      </div>
    );
  };

  return (
    <div className="py-2">
      <DataTable
        value={filteredLocations}
        className="overflow-hidden rounded-lg border"
      >
        <Column header="Locations" body={locationBodyTemplate}></Column>
      </DataTable>
    </div>
  );
}

export default DisplayLocations;
