import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { NUMBER_TYPE_ENUM } from "../../utils/constants";
import AmericanNumericInput from "./AmericanNumericInput";
import StandardNumericInput from "./StandardNumericInput";
import { convertToFeetAndInches } from "../../../../../../../utils/helpers";
import WeightInput from "./WeightInput";
import { convertStandardToWeight } from "../../utils/numericConversions";

const NumberInputArray = ({ inputNamePath, question }) => {
  const { control, watch, trigger, getValues } = useFormContext();

  const {
    fields: answers,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: `${inputNamePath}.answers`,
  });

  const isStandard =
    question?.constraints?.type === NUMBER_TYPE_ENUM.STANDARD.value;
  const isAmerican =
    question?.constraints?.type === NUMBER_TYPE_ENUM.AMERICAN.value;
  const isWeight =
    question?.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;

  const isMoreThanTwoAnswers = answers.length > 2;

  const handleChange = async (value, newValue) => {
    const answers = getValues(`${inputNamePath}.answers`);
    if (value) {
      const duplicateIndexes = answers
        .map((answer, idx) =>
          answer.value === value || answer.value === newValue ? idx : -1,
        )
        .filter((idx) => idx !== -1);
      if (duplicateIndexes.length >= 1) {
        await trigger(
          duplicateIndexes.map(
            (idx) => `${inputNamePath}.answers.${idx}.value`,
          ),
          { shouldFocus: false },
        );
      }
    }
  };

  return (
    <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
      <span className="col-span-3 font-semibold">Values</span>
      <div className="flex w-full flex-col gap-2 md:col-span-9">
        {answers.map((answer, answerIndex) => {
          const isLastAnswer = answerIndex === answers.length - 1;
          return (
            <Controller
              key={answer.id}
              name={`${inputNamePath}.answers.${answerIndex}.value`}
              control={control}
              rules={{
                required: "Please enter a valid number",
                validate: {
                  trim: () => true,
                  unique: (value, formValues) => {
                    const answers = watch(`${inputNamePath}.answers`);
                    const occurrences = answers.filter(
                      (answer) => answer.value === value,
                    ).length;
                    const lastOccurrenceIndex = answers.findLastIndex(
                      (answer) => answer.value === value,
                    );
                    const isLastOccurrence =
                      lastOccurrenceIndex === answerIndex;
                    const isDuplicate = occurrences > 1;

                    return isLastOccurrence && isDuplicate
                      ? `The value "${
                          isAmerican
                            ? convertToFeetAndInches(value)
                            : isWeight
                              ? convertStandardToWeight(
                                  value,
                                  question?.constraints?.storeAs,
                                )
                              : value
                        }" is already entered. Please use a unique value.`
                      : true;
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-9 flex w-full flex-col gap-2">
                  <div className="grid w-full grid-cols-12 items-baseline gap-2">
                    <label
                      htmlFor={field.name}
                      className="col-span-1 font-semibold"
                    >
                      {answerIndex + 1}.&nbsp;
                    </label>
                    <div className="col-span-7">
                      {isAmerican ? (
                        <AmericanNumericInput
                          id={field.name}
                          value={field.value}
                          error={error}
                          onChange={field.onChange}
                          handleChange={handleChange}
                        />
                      ) : isWeight ? (
                        <WeightInput
                          field={field}
                          error={error}
                          unit={question?.constraints?.storeAs}
                          questionType={question.type}
                        />
                      ) : isStandard ? (
                        <StandardNumericInput
                          field={field}
                          error={error}
                          handleChange={handleChange}
                          questionType={question.type}
                        />
                      ) : null}
                    </div>

                    {isMoreThanTwoAnswers && (
                      <Button
                        type="button"
                        severity="danger"
                        icon="pi pi-trash color-red"
                        onClick={() => remove(answerIndex)}
                        className="col-span-2"
                      />
                    )}
                    {isLastAnswer && (
                      <Button
                        type="button"
                        icon={"pi pi-plus"}
                        onClick={() => append({ value: "" })}
                        className="col-span-2 bg-light-purple text-sm md:m-0 md:text-base"
                      />
                    )}
                  </div>
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default NumberInputArray;
