import React from "react";

import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { confirmDialog } from "primereact/confirmdialog";
import { TriStateCheckbox } from "primereact/tristatecheckbox";

import { useServiceStore } from "../utils/store";
import { QUESTION_TYPES_ENUM } from "../utils/constants";
import { useLayoutToast } from "../../../../../../store/store";

function ManageRenderQuestions({
  question,
  setShowManageQuestionDialog,
  setQuestionData,
}) {
  const { layoutToast } = useLayoutToast();
  const { questions, removeQuestion, rulesGroups } = useServiceStore();

  const checkQuestionInCondition = (questionId) => {
    if (!questionId) return false;

    const questionsToCheck = questions.filter(
      (_question) => _question.id !== questionId,
    );

    return JSON.stringify([questionsToCheck, rulesGroups]).includes(questionId);
  };

  const handleRemove = () => {
    const isQuestionInUse = checkQuestionInCondition(question.id);

    if (isQuestionInUse) {
      layoutToast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Question is used in another place",
        life: 4000,
      });
      return;
    }

    const handleAccept = () => {
      removeQuestion(question?.id);
    };

    confirmDialog({
      header: "Delete Question",
      message: "Are you sure you want to delete this question?",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleAccept,
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
    });
  };
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white p-9 font-inter leading-6 shadow-md">
      <div className="text-lg font-semibold text-semi-dark-purple">
        {question.label}&nbsp;
        {question.required && <span className="text-base text-red-600">*</span>}
      </div>
      <DisplayAnswer question={question} />

      <div className="flex justify-end gap-2">
        <Button
          type="button"
          icon="pi pi-pencil"
          severity="info"
          outlined
          size="small"
          onClick={() => {
            setShowManageQuestionDialog(true);
            setQuestionData(question);
          }}
        />
        <Button
          type="button"
          icon="pi pi-trash"
          outlined
          size="small"
          severity="danger"
          onClick={handleRemove}
        />
      </div>
    </div>
  );
}

export default React.memo(ManageRenderQuestions);

const DisplayAnswer = ({ question }) => {
  switch (question.type) {
    case QUESTION_TYPES_ENUM.BOOLEAN.value:
      return (
        <MultipleAnswerDisplay
          list={[{ value: "yes" }, { value: "no" }]}
          type={SELECT_TYPE.RADIO}
        />
      );
    case QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value:
      return (
        <MultipleAnswerDisplay
          list={question.answers}
          type={SELECT_TYPE.CHECKBOX}
        />
      );
    case QUESTION_TYPES_ENUM.DROPDOWN.value:
      return (
        <MultipleAnswerDisplay
          list={question.answers}
          type={SELECT_TYPE.RADIO}
        />
      );
    case QUESTION_TYPES_ENUM.NUMBER.value:
    case QUESTION_TYPES_ENUM.DECIMAL.value:
      return <NumberAnswerDisplay />;
    case QUESTION_TYPES_ENUM.TEXT.value:
      return <TextAnswerDisplay />;

    default:
      break;
  }
};

const NumberAnswerDisplay = () => {
  return (
    <div className="w-full border-b-2 border-slate-200 p-1 px-2 text-slate-400">
      Number Answer
    </div>
  );
};
const TextAnswerDisplay = () => {
  return (
    <div className="w-full border-b-2 border-slate-200 p-1 px-2 text-slate-400">
      Text Answer
    </div>
  );
};
const MultipleAnswerDisplay = ({ list, type }) => {
  return (
    <div className="w-full space-y-1 px-2">
      {list.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <RenderCheckBoxOrRadioButton type={type} />
          <label htmlFor={`answer-${index}`}>{item.value}</label>
        </div>
      ))}
    </div>
  );
};

const SELECT_TYPE = {
  RADIO: "radio",
  CHECKBOX: "checkbox",
};

const RenderCheckBoxOrRadioButton = ({ type }) =>
  type === SELECT_TYPE.RADIO ? (
    <RadioButton disabled />
  ) : (
    <TriStateCheckbox disabled />
  );
