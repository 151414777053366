import { useMemo } from "react";

import { v4 as uuidv4 } from "uuid";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";

import {
  DEFAULT_CONDITION,
  OPERATORS,
  QUESTION_TYPES_ENUM,
} from "../utils/constants";
import { useServiceStore } from "../utils/store";
import QuestionDetailsForm from "./QuestionDetailsForm";
import QuestionConditionsForm from "./QuestionConditionsForm";
import PulsingCircle from "../../../../../../iconComponents/PulsingCircle";
import { getDefaultConstraints } from "../utils/utils";
import { confirmDialog } from "primereact/confirmdialog";

const questionDetailsErrors = ["label", "type", "answers"];
const questionOptionsErrors = ["conditions", "constraints"];

const ManageQuestionForm = ({
  type,
  questionData,
  setShowManageQuestionDialog,
}) => {
  const initialAnswers = {
    [QUESTION_TYPES_ENUM.BOOLEAN.value]: [{ value: true }, { value: false }],
    [QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value]: [
      { id: uuidv4(), value: "" },
      { id: uuidv4(), value: "" },
    ],
    [QUESTION_TYPES_ENUM.DROPDOWN.value]: [
      { id: uuidv4(), value: "" },
      { id: uuidv4(), value: "" },
    ],
    [QUESTION_TYPES_ENUM.DECIMAL.value]: [],
    [QUESTION_TYPES_ENUM.NUMBER.value]: [],
    [QUESTION_TYPES_ENUM.TEXT.value]: [],
  };

  const getDefaultValues = () => {
    return {
      id: uuidv4(),
      type: type || "",
      label: "",
      description: "",
      required: true,
      answers: type ? initialAnswers[type] : [],
      isConditional: false,
      conditionalOperator: OPERATORS.ALL.value,
      conditions: [DEFAULT_CONDITION],
      constraints: getDefaultConstraints(type),
    };
  };

  const { questions, rulesGroups, updateQuestion, addQuestion } =
    useServiceStore();
  const form = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: questionData || getDefaultValues(),
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = form;

  const checkQuestionInCondition = (questionId) => {
    if (!questionId) return false;

    const questionsToCheck = questions.filter(
      (question) => question.id !== watch("id"),
    );

    return JSON.stringify([questionsToCheck, rulesGroups]).includes(questionId);
  };

  const isQuestionInCondition = useMemo(
    () => checkQuestionInCondition(questionData?.id),
    [questionData],
  );

  const onSubmit = async (formdata) => {
    if (questionData) {
      updateQuestion(questionData?.id, formdata);
      if (isQuestionInCondition) {
        confirmDialog({
          message:
            "You've edited a question that's linked to another question or rule. Please review your conditions to ensure everything remains consistent.",
          header: "Review Your Linked Conditions",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Continue",
          accept: () => {},
          style: { width: "800px" },
          draggable: false,
          rejectClassName: "hidden",
        });
      }
    } else {
      addQuestion(formdata);
    }
    setShowManageQuestionDialog(false);
    reset(getDefaultValues());
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit(onSubmit)();
        }}
        autoComplete="off"
      >
        <div className="flex flex-col p-2">
          <TabView
            renderActiveOnly={false}
            panelContainerClassName="max-h-[50vh] overflow-y-scroll"
          >
            <TabPanel
              rightIcon={
                Object.keys(errors).some((errKey) =>
                  questionDetailsErrors.includes(errKey),
                ) ? (
                  <PulsingCircle />
                ) : undefined
              }
              header="Question Details"
            >
              <QuestionDetailsForm
                isQuestionInCondition={isQuestionInCondition}
              />
            </TabPanel>
            <TabPanel
              rightIcon={
                Object.keys(errors).some((errKey) =>
                  questionOptionsErrors.includes(errKey),
                ) ? (
                  <PulsingCircle />
                ) : undefined
              }
              header="Question Options"
            >
              <QuestionConditionsForm questionData={questionData} />
            </TabPanel>
          </TabView>
          <div className="flex justify-end gap-3">
            <Button
              severity="danger"
              type="button"
              id="cancel"
              label="Cancel"
              disabled={isSubmitting}
              onClick={() => setShowManageQuestionDialog(false)}
            ></Button>
            <Button loading={isSubmitting} id="submit" label="Save"></Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

export default ManageQuestionForm;
