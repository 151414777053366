import { useEffect, useState } from "react";

import { Controller, FormProvider, useForm } from "react-hook-form";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { ModTemplate } from "../templates/ModTemplate";
import useAxios from "../../../../../../hooks/useAxios";
import { BillableItemsAttributesTemplate } from "../templates/BillableItemsAttributesTemplate";
import UnitsBillableTemplate from "../templates/UnitsBillableTemplate";
import useTokenData from "../../../../../../hooks/useTokenData";
import { Pagination } from "../../../../../../components/Table";
import { PriceBillableTemplate } from "../templates/PriceBillableTemplate";
import { blockSpecialCharsPattern } from "../../../../../../utils/helpers";
import useDelayedInputChange from "../../../../../../hooks/useDelayedInputChange";
import { preventEnterDefault } from "../utils/utils";
import { showErrorToast } from "../../../../../../utils/apiUtils";
import DescriptionWithTooltipTemplate from "./rules/DescriptionWithTooltipTemplate";

const REQUESTED_DATA_SIZE = 5;

const AttachBillableItemForm = ({
  children,
  payload = [],
  handleSave,
  defaultValues = [],
  selectionMode = "multiple",
  handleCreateBillableItemClick,
}) => {
  const { http } = useAxios();
  const { entityId } = useTokenData();

  const methods = useForm({ defaultValues: { billableItems: defaultValues } });

  const [billableItems, setBillableItems] = useState([]);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState();
  const [refetch, setRefetch] = useState(false);
  const [searchValue, handleInputChange] = useDelayedInputChange(
    refetch,
    setRefetch,
  );

  const [first, setFirst] = useState(0);

  const handlePageChange = async (event) => {
    setFirst(event.first);
    if (first !== event.first) {
      getBillableItems({ page: event.page + 1 });
    }
  };

  const getBillableItems = async ({ page = 1 }) => {
    setIsLoading(true);
    try {
      const response = await http.put(
        `/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}&Page=${page}&PageSize=${REQUESTED_DATA_SIZE}`,
        payload,
      );

      setBillableItems(response.data);
      setCount(response.data[0]?.count || 0);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBillableItems({});
  }, [refetch]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          methods.handleSubmit(handleSave)();
        }}
      >
        <div className="flex flex-col gap-2 p-4">
          <div className="overflow-hidden rounded-xl border border-gray-300 p-2">
            <Controller
              name={`billableItems`}
              control={methods.control}
              render={({ field }) => {
                return (
                  <>
                    <DataTable
                      emptyMessage={
                        <EmptyMessageTemplate
                          searchValue={searchValue}
                          handleCreateBillableItemClick={
                            handleCreateBillableItemClick
                          }
                        />
                      }
                      value={billableItems}
                      loading={isLoading}
                      pt={{ header: { className: "!border-none" } }}
                      selectionMode={selectionMode}
                      selection={billableItems?.filter((b) =>
                        field?.value?.some((v) => v.id === b.id),
                      )}
                      compareSelectionBy="id"
                      onRowClick={(e) => {
                        const isSelected = field.value.some(
                          (value) => value.id === e.data.id,
                        );

                        if (selectionMode === "single") {
                          if (isSelected) return;

                          field.onChange([
                            { id: e.data.id, units: e.data.defaultUnits },
                          ]);
                          return;
                        }

                        if (isSelected) {
                          const filteredBillables = field.value.filter(
                            (v) => v.id !== e.data.id,
                          );
                          field.onChange(filteredBillables);
                        } else {
                          field.onChange([
                            ...field.value,
                            { id: e.data.id, units: e.data.defaultUnits },
                          ]);
                        }
                      }}
                      header={
                        <HeaderTemplate
                          searchValue={searchValue}
                          handleInputChange={handleInputChange}
                        />
                      }
                      metaKeySelection={false}
                      tableStyle={{ minWidth: "50rem" }}
                    >
                      <Column field="hcpcs" header="HCPCS" />
                      <Column
                        body={DescriptionWithTooltipTemplate}
                        header="Description"
                      />

                      <Column header="Mods" body={ModTemplate} />
                      <Column
                        header="Attributes"
                        body={BillableItemsAttributesTemplate}
                      />
                      <Column header="Price" body={PriceBillableTemplate} />
                      <Column
                        header="Units"
                        body={(e) => <UnitsBillableTemplate rowData={e} />}
                      />
                    </DataTable>
                    <Pagination
                      currentPage={first}
                      totalPages={count}
                      size={REQUESTED_DATA_SIZE}
                      onPageChange={handlePageChange}
                      template={{
                        layout:
                          "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                      }}
                    />
                  </>
                );
              }}
            />
          </div>
          {children}
        </div>
      </form>
    </FormProvider>
  );
};

const HeaderTemplate = ({ searchValue, handleInputChange }) => {
  return (
    <div className="flex flex-col items-start gap-4">
      <div className="flex w-full items-center justify-between">
        <div>
          <div className="flex items-center gap-4">
            <span className="p-input-icon-left min-w-[300px]">
              <i className="pi pi-search" />
              <InputText
                onKeyDown={preventEnterDefault}
                className="w-full"
                keyfilter={blockSpecialCharsPattern}
                placeholder="Search HCPCS, Description"
                value={searchValue}
                onChange={handleInputChange}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmptyMessageTemplate = ({
  searchValue,
  handleCreateBillableItemClick,
}) => {
  return searchValue ? (
    <p className="text-center">
      No results found in your practice's configured billable items.
      Alternatively, you can{" "}
      <span
        className="cursor-pointer font-semibold text-light-purple underline"
        onClick={handleCreateBillableItemClick}
      >
        Search in National HCPCS Codes{" "}
      </span>
      and import a new item into your practice's billable items.
    </p>
  ) : (
    <p className="text-center">
      You haven't created any billable items yet. You can create a new item by
      clicking on 'Create New Billable Item'.
    </p>
  );
};

export default AttachBillableItemForm;
