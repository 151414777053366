import { useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../hooks/useAxios";
import useTokenData from "../../../hooks/useTokenData";
import { trimValidation } from "../../../utils/helpers";
import useRefreshTokenHook from "../../../hooks/useRefreshTokenHook";
import FormSkeleton from "../../../components/skeletons/FormSkeleton";
import { useRefreshTokenStore, useTokenStore } from "../../../store/store";
import { DocumentUpload, TaxonomyTree } from "../../../components/formElements";
import { showErrorToast } from "../../../utils/apiUtils";

function EditGroupPractice() {
  const [noNPI, setNoNPI] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const { http } = useAxios();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setToken } = useTokenStore();
  const [isLoading, setIsLoading] = useState(false);
  const [isEntityDataLoading, setIsEntityDataLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState();
  const [identifierId, setIdentifierId] = useState();
  // const [isDisabled, setIsDisabled] = useState(false);
  const [selectedChooseList, setSelectedChooseList] = useState([]);
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);
  //npi lest
  const [NPIList, setNPIList] = useState([{ npiNumber: "" }]);
  const AddNPI = () => {
    setNPIList([...NPIList, { npiNumber: "" }]);
    // setIsDisabled(false);
  };
  const { refresh } = useRefreshTokenHook();

  const deleteNPI = (index) => {
    setNPIList((oldNpi) => {
      const newNpi = [...oldNpi];
      newNpi.splice(index, 1);
      setValue("NPIs", newNpi);
      return newNpi;
    });
    // setIsDisabled(false);
  };

  const { entityId, impersonatedBy } = useTokenData();

  const defaultValues = {
    identification: {
      taxID: "",
    },
    entityData: {
      name: "",
      doingBusinessAsName: null || "",
      technicalSupportName: "",
      technicalSupportPhoneNumber: "",
      technicalSupportEmail: "",
      description: null || "",
      entityType: 1,
      entityPrincipalAddress: {
        lineOne: "",
        lineTwo: null || "",
        state: "",
        city: null || "",
        zipCode: "",
      },
      registeredAgentAddress: {
        lineOne: "",
        lineTwo: null || "",
        state: "",
        city: null || "",
        zipCode: "",
      },
      EntityImageFile: {
        FileDetails: null || "",
        FileType: 1,
        FilePropertyName: 3,
      },
    },
    NPIs: NPIList,
    AssignTaxonomies: selectedChooseList,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });

  //   fetch state data from api
  const [selectedState, setSelectedState] = useState([]);

  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setSelectedState(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getStates();
    getEntityData();
    // setIsDisabled(true);
  }, []);

  const getEntityData = async () => {
    setIsEntityDataLoading(true);
    try {
      const res = await http.get(
        `/Identifications/GetAssignedDueDiligenceDataByEntityId/${entityId}`,
      );
      const clonedResponse = structuredClone(res.data);
      setIdentifierId(clonedResponse.identifierId);
      setSelectedImage(clonedResponse.entityData.imageUrl);
      setSelectedOptionsList(clonedResponse.assignedTaxonomies);
      setSelectedChooseList(clonedResponse.assignedTaxonomies);
      setNPIList(
        clonedResponse.assignedNpis.length === 0
          ? [{ npiNumber: "" }]
          : clonedResponse.assignedNpis,
      );
      setValue("identification.taxID", clonedResponse.taxID);

      setValue(
        "entityData.EntityImageFile.FileDetails",
        clonedResponse.entityData.imageUrl || "",
      );
      setValue(
        "entityData.description",
        clonedResponse.entityData.description != "null"
          ? clonedResponse.entityData.description
          : "",
      );
      setValue("entityData.name", clonedResponse.entityData.name);
      setValue(
        "entityData.doingBusinessAsName",
        clonedResponse.entityData.doingBusinessAsName,
      );
      setValue(
        "entityData.entityPrincipalAddress.lineOne",
        clonedResponse.entityData.entityPrincipalAddress.lineOne,
      );
      setValue(
        "entityData.entityPrincipalAddress.lineTwo",
        clonedResponse.entityData.entityPrincipalAddress.lineTwo != "null"
          ? clonedResponse.entityData.entityPrincipalAddress.lineTwo
          : "",
      );
      setValue(
        "entityData.entityPrincipalAddress.city",
        clonedResponse.entityData.entityPrincipalAddress.city != "null"
          ? clonedResponse.entityData.entityPrincipalAddress.city
          : "",
      );
      setValue(
        "entityData.entityPrincipalAddress.state",
        clonedResponse.entityData.entityPrincipalAddress.state,
      );
      setValue(
        "entityData.entityPrincipalAddress.zipCode",
        clonedResponse.entityData.entityPrincipalAddress.zipCode != "null"
          ? clonedResponse.entityData.entityPrincipalAddress.zipCode
          : "",
      );
      setValue(
        "entityData.registeredAgentAddress.lineOne",
        clonedResponse.entityData.registeredAgentAddress.lineOne,
      );
      setValue(
        "entityData.registeredAgentAddress.lineTwo",
        clonedResponse.entityData.registeredAgentAddress.lineTwo != "null"
          ? clonedResponse.entityData.registeredAgentAddress.lineTwo
          : "",
      );
      setValue(
        "entityData.registeredAgentAddress.city",
        clonedResponse.entityData.registeredAgentAddress.city != "null"
          ? clonedResponse.entityData.registeredAgentAddress.city
          : "",
      );
      setValue(
        "entityData.registeredAgentAddress.state",
        clonedResponse.entityData.registeredAgentAddress.state != "null"
          ? clonedResponse.entityData.registeredAgentAddress.state
          : "",
      );
      setValue(
        "entityData.registeredAgentAddress.zipCode",
        clonedResponse.entityData.registeredAgentAddress.zipCode != "null"
          ? clonedResponse.entityData.registeredAgentAddress.zipCode
          : "",
      );
      setValue(
        "entityData.technicalSupportName",
        clonedResponse.entityData.technicalSupportName,
      );
      setValue(
        "entityData.technicalSupportEmail",
        clonedResponse.entityData.technicalSupportEmail,
      );
      setValue(
        "entityData.technicalSupportPhoneNumber",
        clonedResponse.entityData.technicalSupportPhoneNumber,
      );
      setValue("NPIs", clonedResponse.assignedNpis);
      setValue("AssignTaxonomies", clonedResponse.assignedTaxonomies);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsEntityDataLoading(false);
  };

  const onSubmit = async (formData) => {
    const data = new FormData();
    data.append("Identification.TaxID", formData.identification.taxID);
    data.append("EntityData.Name", formData.entityData.name);
    data.append("EntityData.EntityType", formData.entityData.entityType);
    data.append(
      "EntityData.DoingBusinessAsName",
      formData.entityData.doingBusinessAsName || "",
    );
    data.append(
      "EntityData.Description",
      formData.entityData.description || "",
    );
    data.append(
      "EntityData.TechnicalSupportName",
      formData.entityData.technicalSupportName,
    );
    data.append(
      "EntityData.technicalSupportPhoneNumber",
      formData.entityData.technicalSupportPhoneNumber,
    );
    data.append(
      "EntityData.TechnicalSupportEmail",
      formData.entityData.technicalSupportEmail,
    );
    data.append(
      "EntityData.EntityPrincipalAddress.LineOne",
      formData.entityData.entityPrincipalAddress.lineOne,
    );
    data.append(
      "EntityData.EntityPrincipalAddress.LineTwo",
      formData.entityData.entityPrincipalAddress.lineTwo || "",
    );
    data.append(
      "EntityData.EntityPrincipalAddress.State",
      formData.entityData.entityPrincipalAddress.state,
    );
    data.append(
      "EntityData.EntityPrincipalAddress.City",
      formData.entityData.entityPrincipalAddress.city,
    );
    data.append(
      "EntityData.EntityPrincipalAddress.ZipCode",
      formData.entityData.entityPrincipalAddress.zipCode,
    );
    data.append(
      "EntityData.RegisteredAgentAddress.LineOne",
      formData.entityData.registeredAgentAddress.lineOne,
    );
    data.append(
      "EntityData.RegisteredAgentAddress.LineTwo",
      formData.entityData.registeredAgentAddress.lineTwo || "",
    );
    data.append(
      "EntityData.RegisteredAgentAddress.State",
      formData.entityData.registeredAgentAddress.state,
    );
    data.append(
      "EntityData.RegisteredAgentAddress.City",
      formData.entityData.registeredAgentAddress.city,
    );
    data.append(
      "EntityData.RegisteredAgentAddress.ZipCode",
      formData.entityData.registeredAgentAddress.zipCode,
    );
    data.append(
      "EntityData.EntityImageFile.FileDetails",
      formData.entityData.EntityImageFile.FileDetails || "",
    );
    data.append(
      "EntityData.EntityImageFile.FileType",
      formData.entityData.EntityImageFile.FileType,
    );
    data.append(
      "EntityData.EntityImageFile.FilePropertyName",
      formData.entityData.EntityImageFile.FilePropertyName,
    );

    if (
      (formData?.NPIs?.length === 1 && !formData.NPIs[0].npiNumber) ||
      noNPI
    ) {
      formData.NPIs = null;
      data.append("NPIs", null);
    } else {
      if (formData?.NPIs?.length) {
        formData.NPIs = formData.NPIs.filter((npi) => npi?.npiNumber);

        if (formData.NPIs.length === 0) {
          formData.NPIs = null;
          data.append("NPIs", null);
        } else {
          formData.NPIs.forEach((npi, index) => {
            data.append(`NPIs[${index}].npiNumber`, npi.npiNumber);
          });
        }
      }
    }

    selectedChooseList.forEach((taxonomy, index) => {
      data.append(`AssignTaxonomies[${index}].taxonomyId`, taxonomy.taxonomyId);
      data.append(`AssignTaxonomies[${index}].isPrimary`, taxonomy.isPrimary);
      data.append(`AssignTaxonomies[${index}].label`, taxonomy.label);
    });

    let errors = [];

    if (errors.length > 0) {
      const error = new Error(errors.join("\n"));
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000,
      });
    } else {
      try {
        setIsLoading(true);
        const response = await http.put(
          `/Identifications/EditGroupPractice/${identifierId}`,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );

        const currentRefreshToken = JSON.parse(
          localStorage.getItem("refreshToken"),
        ).state.refreshToken;
        const refreshTokenResponse = await refresh.post(
          `/Authentication/RefreshToken/${entityId}`,
          {
            refreshToken: currentRefreshToken,
            impersonatedBy,
          },
        );
        setToken(refreshTokenResponse.data.token);
        setRefreshToken(refreshTokenResponse.data.refreshToken);
        reset(defaultValues);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.message,
          life: 4000,
        });
        setTimeout(() => {
          navigate(-1);
        }, 2000);
        setIsLoading(false);
      } catch (error) {
        showErrorToast({ error });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("AssignTaxonomies", selectedOptionsList);
  }, [selectedOptionsList, setValue]);

  const handleCancel = () => {
    navigate(-1);
  };

  const location = useLocation();
  const breadCrumbsItems = [
    {
      label: "Settings",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/Settings`}>
          Settings
        </Link>
      ),
    },
    {
      label: "Edit Group Practice ",
      template: () => (
        <Link to={`/${location.pathname.split("/")[1]}/settings/groupSettings`}>
          Edit Group Practice
        </Link>
      ),
    },
  ];

  return (
    <>
      <Toast ref={toast} />
      <BreadCrumb
        model={breadCrumbsItems}
        className="border-0 bg-transparent pb-7 font-semibold"
        pt={{
          separator: { className: "first:hidden" },
          menuitem: { className: "last:text-purple-700" },
        }}
      />
      <div className="flex items-center justify-center">
        <div className="w-full rounded-3xl bg-light-text py-8 pl-10 pr-10 shadow shadow-slate-300">
          <div className="mb-8">
            <h4 className="mb-4 text-center text-xl font-bold sm:text-left sm:text-4xl">
              Edit Group Practice
            </h4>
            <p className="mb-3 text-center text-gray-700 sm:text-left">
              Update your group practice information presented.
            </p>
          </div>
          {isEntityDataLoading ? (
            <FormSkeleton />
          ) : (
            <div className="form-container mb-3">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container mb-3 w-full md:w-9/12">
                  <div className="mb-10 border-b-2 pb-10">
                    <div className="flex w-full flex-col pb-4">
                      <p className="mb-2 text-lg text-gray-500">
                        Group Logo/Trademark
                      </p>
                      <div className="md:w-1.5/3 flex items-center sm:w-1/2 lg:w-1/3">
                        <DocumentUpload
                          name="practiceLogo"
                          fileType="image"
                          maxFileSizeInMB={10}
                          previewImage={selectedImage}
                          onUpload={(file) => {
                            setValue(
                              "entityData.EntityImageFile.FileDetails",
                              file,
                            );
                            setValue("entityData.EntityImageFile.FileType", 1);
                            setValue(
                              "entityData.EntityImageFile.FilePropertyName",
                              3,
                            );
                          }}
                          onClear={() => {
                            setValue(
                              "entityData.EntityImageFile.FileDetails",
                              "",
                            );
                          }}
                        />
                      </div>
                    </div>

                    <div className="">
                      <h4 className="font-Poppins text-base capitalize leading-loose text-gray-500">
                        group Bio
                      </h4>
                      <InputTextarea
                        {...register("entityData.description", {})}
                        placeholder="Description"
                        rows={5}
                        cols={30}
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="taxId"
                      className="font-Poppins text-base capitalize leading-loose text-gray-500"
                    >
                      group Tax ID <span className="text-red-500">*</span>
                    </label>
                    <span className="p-input-icon-right w-full">
                      <Controller
                        control={control}
                        name="identification.taxID"
                        rules={{
                          required: "Tax ID is required",
                          pattern: {
                            value: /^(?:\d-?){9}$/,
                            message: "Tax ID must be exactly 9 digits",
                          },
                        }}
                        render={({ field }) => (
                          <InputText
                            id="taxIDInput"
                            placeholder="Enter nine digit tax id number"
                            className="w-full pb-2 pt-2"
                            {...field}
                            maxLength={17}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                      {errors.identification && errors.identification.taxID && (
                        <p className="text-red-500">
                          {errors.identification.taxID.message}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className="mt-3">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      Legal Entity Name <span className="text-red-600">*</span>
                    </label>

                    <span className="w-full">
                      <Controller
                        name="entityData.name"
                        control={control}
                        rules={{
                          required: "Legal Entity Name is required",
                          minLength: {
                            value: 3,
                            message:
                              "Legal Entity Name must be at least 3 characters long",
                          },
                          validate: (value) =>
                            trimValidation(value, "Legal Entity Name"),
                        }}
                        render={({ field }) => (
                          <InputText
                            {...field}
                            placeholder="Entity Name"
                            className="w-full"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        )}
                      />
                    </span>
                    {errors.entityData && errors.entityData.name && (
                      <p className="mt-1 text-red-500">
                        {errors.entityData.name.message}
                      </p>
                    )}
                  </div>
                  <div className="mt-3">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      Doing Business as Name
                    </label>
                    <span className="w-full">
                      <InputText
                        {...register("entityData.doingBusinessAsName", {})}
                        placeholder="Doing Business as Name "
                        className="w-full"
                      />
                    </span>
                  </div>

                  <div className="mt-5">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      Entity Principle Address
                      <span className="text-red-600"> *</span>
                    </label>
                    <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                      group address line one
                    </label>
                    <span className="w-full">
                      <Controller
                        control={control}
                        name="entityData.entityPrincipalAddress.lineOne"
                        rules={{
                          required: "Address line one is required",
                          validate: (value) =>
                            trimValidation(value, "Address line one"),
                        }}
                        render={({ field }) => (
                          <InputText
                            placeholder="Address Line One"
                            className="w-full pb-2 pt-2"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </span>
                    {errors.entityData &&
                      errors.entityData.entityPrincipalAddress &&
                      errors.entityData.entityPrincipalAddress.lineOne && (
                        <p className="mt-1 text-red-500">
                          {
                            errors.entityData.entityPrincipalAddress.lineOne
                              .message
                          }
                        </p>
                      )}
                    <span className="mt-3 w-full">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        group address line two
                      </label>
                      <InputText
                        {...register(
                          "entityData.entityPrincipalAddress.lineTwo",
                          {},
                        )}
                        placeholder="Address Line Two"
                        className="mt-1 w-full pb-2 pt-2"
                      />
                    </span>

                    <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          city
                          <span className="ml-1 font-bold text-red-500">*</span>
                        </label>
                        <Controller
                          name="entityData.entityPrincipalAddress.city"
                          control={control}
                          rules={{
                            required: "City is required",
                            validate: (value) => trimValidation(value, "City"),
                          }}
                          render={({ field }) => (
                            <>
                              <InputText
                                {...field}
                                placeholder="City"
                                className="w-full"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {errors.entityData &&
                                errors.entityData.entityPrincipalAddress &&
                                errors.entityData.entityPrincipalAddress
                                  .city && (
                                  <p className="mt-1 text-red-500">
                                    {
                                      errors.entityData.entityPrincipalAddress
                                        .city.message
                                    }
                                  </p>
                                )}
                            </>
                          )}
                        />
                      </span>

                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          state
                        </label>
                        <Controller
                          name="entityData.entityPrincipalAddress.state"
                          control={control}
                          rules={{
                            required: "Please select a state",
                          }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              placeholder="State"
                              focusInputRef={field.ref}
                              options={selectedState.map((option) => ({
                                value: option.name,
                                label: option.name,
                              }))}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              className="md:w-14rem w-full"
                            />
                          )}
                        />
                      </span>
                      {errors.entityData &&
                        errors.entityData.entityPrincipalAddress &&
                        errors.entityData.entityPrincipalAddress.state && (
                          <p className="mt-1 text-red-500">
                            {
                              errors.entityData.entityPrincipalAddress.state
                                .message
                            }
                          </p>
                        )}
                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          ZIP Code
                          <span className="ml-1 font-bold text-red-500">*</span>
                        </label>
                        <Controller
                          name="entityData.entityPrincipalAddress.zipCode"
                          control={control}
                          rules={{
                            required: "ZIP Code is required",
                            pattern: {
                              value: /^\d{5}$/,
                              message: "ZIP Code must be exactly 5 digits",
                            },
                            validate: (value) =>
                              trimValidation(value, "ZIP Code"),
                          }}
                          render={({ field }) => (
                            <>
                              <InputText
                                {...field}
                                placeholder="ZIP Code"
                                className="w-full"
                                maxLength={5}
                                keyfilter="num"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {errors.entityData &&
                                errors.entityData.entityPrincipalAddress &&
                                errors.entityData.entityPrincipalAddress
                                  .zipCode && (
                                  <p className="mt-1 text-red-500">
                                    {
                                      errors.entityData.entityPrincipalAddress
                                        .zipCode.message
                                    }
                                  </p>
                                )}
                            </>
                          )}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mt-5 border-b-2 pb-10">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      Registered Agent Address
                    </label>
                    <span className="w-full">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        Registered agent address line one
                        <span className="ml-1 font-bold text-red-500">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="entityData.registeredAgentAddress.lineOne"
                        rules={{
                          required: "Address line one is required",
                          validate: (value) =>
                            trimValidation(value, "Address line one"),
                        }}
                        render={({ field }) => (
                          <InputText
                            placeholder="Registered agent address line one"
                            className="w-full pb-2 pt-2"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                      {errors.entityData &&
                        errors.entityData.registeredAgentAddress &&
                        errors.entityData.registeredAgentAddress.lineOne && (
                          <p className="mt-1 text-red-500">
                            {
                              errors.entityData.registeredAgentAddress.lineOne
                                .message
                            }
                          </p>
                        )}
                    </span>
                    <span className="mt-3 w-full">
                      <label className="mt-1 block font-inter font-normal capitalize leading-loose text-gray-500">
                        Registered agent address line two
                      </label>
                      <InputText
                        {...register(
                          "entityData.registeredAgentAddress.lineTwo",
                          {},
                        )}
                        placeholder="Registered agent address line two"
                        className="w-full pb-2 pt-2"
                      />
                    </span>

                    <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          city
                          <span className="ml-1 font-bold text-red-500">*</span>
                        </label>
                        <Controller
                          name="entityData.registeredAgentAddress.city"
                          control={control}
                          rules={{
                            required: "City is required",
                            validate: (value) => trimValidation(value, "City"),
                          }}
                          render={({ field }) => (
                            <>
                              <InputText
                                {...field}
                                placeholder="City"
                                className="w-full"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {errors.entityData &&
                                errors.entityData.registeredAgentAddress &&
                                errors.entityData.registeredAgentAddress
                                  .city && (
                                  <p className="mt-1 text-red-500">
                                    {
                                      errors.entityData.registeredAgentAddress
                                        .city.message
                                    }
                                  </p>
                                )}
                            </>
                          )}
                        />
                      </span>

                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          state
                        </label>
                        <Controller
                          name="entityData.registeredAgentAddress.state"
                          control={control}
                          rules={{ required: "Please select a state" }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              placeholder="State"
                              focusInputRef={field.ref}
                              options={selectedState.map((option) => ({
                                value: option.name,
                                label: option.name,
                              }))}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              className="md:w-14rem w-full"
                            />
                          )}
                        />

                        {errors.entityData &&
                          errors.entityData.registeredAgentAddress &&
                          errors.entityData.registeredAgentAddress.state && (
                            <p className="mt-1 text-red-500">
                              {
                                errors.entityData.registeredAgentAddress.state
                                  .message
                              }
                            </p>
                          )}
                      </span>

                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          ZIP Code
                          <span className="ml-1 font-bold text-red-500">*</span>
                        </label>

                        <Controller
                          name="entityData.registeredAgentAddress.zipCode"
                          control={control}
                          rules={{
                            required: "ZIP Code is required ",
                            pattern: {
                              value: /^\d{5}$/,
                              message: "ZIP Code must be exactly 5 digits",
                            },
                            validate: (value) =>
                              trimValidation(value, "ZIP Code"),
                          }}
                          render={({ field }) => (
                            <>
                              <InputText
                                {...field}
                                placeholder="ZIP Code"
                                className="w-full"
                                maxLength={5}
                                keyfilter="num"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                              {errors.entityData &&
                                errors.entityData.registeredAgentAddress &&
                                errors.entityData.registeredAgentAddress
                                  .zipCode && (
                                  <p className="mt-1 text-red-500">
                                    {
                                      errors.entityData.registeredAgentAddress
                                        .zipCode.message
                                    }
                                  </p>
                                )}
                            </>
                          )}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="mt-5 border-b-2 pb-9">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      Group Support Contact Information
                    </label>
                    <p className="text-md mb-4 mt-1 text-gray-500">
                      This contact information will be visible to providers that
                      order your services in the event that they have additional
                      questions.
                    </p>
                    <span className="w-full">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        General Practice Support Individual Name or Department
                        Name
                        <span className="ml-2 font-bold text-red-500">*</span>
                      </label>
                      <InputText
                        {...register("entityData.technicalSupportName", {
                          required: "Name is required",
                          validate: (value) =>
                            trimValidation(value, " technical Support Name"),
                        })}
                        placeholder="Name"
                        className="w-full pb-2 pt-2"
                      />
                      {errors.entityData &&
                        errors.entityData.technicalSupportName && (
                          <p className="mt-1 text-red-500">
                            {errors.entityData.technicalSupportName.message}
                          </p>
                        )}
                    </span>
                    <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                      General Practice Support Phone Number
                      <span className="ml-1 font-bold text-red-500">*</span>
                    </label>
                    <Controller
                      name="entityData.technicalSupportPhoneNumber"
                      control={control}
                      rules={{
                        required: "Phone number is required",
                        validate: (value) =>
                          trimValidation(value, "technical Support phone"),
                      }}
                      render={({ field }) => (
                        <span className="p-input-icon-left w-full">
                          <i className="pi pi-phone" />
                          <InputMask
                            {...field}
                            mask="999-999-9999"
                            placeholder="Phone Number"
                            onClick={(e) => {
                              if (e.target.value === "___-___-____") {
                                e.target.setSelectionRange(0, 0);
                              }
                            }}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                            className="w-full pb-2 pt-2"
                          />
                        </span>
                      )}
                    />
                    {errors.entityData &&
                      errors.entityData.technicalSupportPhoneNumber && (
                        <p className="mt-1 text-red-500">
                          {
                            errors.entityData.technicalSupportPhoneNumber
                              .message
                          }
                        </p>
                      )}
                    <label className="mt-2 block font-inter font-normal capitalize leading-loose text-gray-500">
                      General Practice Support Email Address
                      <span className="ml-2.5 font-bold text-red-500">*</span>
                    </label>
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-envelope" />
                      <InputText
                        placeholder="Email "
                        name="entityData.technicalSupportEmail"
                        {...register("entityData.technicalSupportEmail", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid Email Address",
                          },
                          validate: (value) =>
                            trimValidation(value, " Technical support email"),
                        })}
                        className="w-full pb-2 pt-2"
                      />
                    </span>
                    {errors.entityData &&
                      errors.entityData.technicalSupportEmail && (
                        <p className="mt-1 text-red-500">
                          {errors.entityData.technicalSupportEmail.message}
                        </p>
                      )}
                  </div>
                  <div className="mt-4">
                    <label className="mt-2 block font-Poppins text-base capitalize leading-loose text-gray-500">
                      NPIs associated with your group practice.
                    </label>

                    {NPIList.map((NPIs, index) => (
                      <div key={index} className="mt-1">
                        <label
                          htmlFor="NPI"
                          className="font-inter font-normal capitalize leading-loose text-gray-500"
                        >
                          NPI
                        </label>
                        <div className="grid grid-cols-6">
                          <div className="col-span-5">
                            <span className="p-input-icon-right w-full">
                              {index != 0 ? (
                                <i
                                  className="pi pi-times cursor-pointer"
                                  style={{ color: "red" }}
                                  key={`${index}BTN`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    deleteNPI(index);
                                  }}
                                />
                              ) : null}
                              <InputText
                                key={`${index}input`}
                                id="npiNumber"
                                disabled={noNPI}
                                placeholder="0000000000"
                                className="w-full"
                                maxLength={10}
                                keyfilter={/^\d+$/}
                                {...register(`NPIs[${index}].npiNumber`, {
                                  pattern: {
                                    value: /^\d{10}$/,
                                    message: "NPI must be exactly 10 digits",
                                  },
                                })}
                                value={NPIs.npiNumber}
                                onChange={(e) => {
                                  setNPIList((oldNpi) => {
                                    const newNpi = [...oldNpi];
                                    newNpi[index].npiNumber = e.target.value;
                                    return newNpi;
                                  });
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </span>
                            {errors?.NPIs && errors.NPIs[index]?.npiNumber && (
                              <p className="col-span-6 mt-1 text-red-500">
                                {errors.NPIs[index]?.npiNumber.message}
                              </p>
                            )}
                          </div>
                          <div className="col-span-1 mt-2 text-center"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {!noNPI && (
                    <div>
                      <Button
                        link
                        className="pl-1 text-base font-semibold capitalize"
                        onClick={(e) => {
                          e.preventDefault();
                          AddNPI();
                        }}
                        icon="pi pi-plus"
                        label="Add NPI"
                      />
                    </div>
                  )}
                  <div className="card justify-content-center mt-4 flex items-center gap-2 border-b-2 pb-6">
                    <Checkbox
                      onChange={(e) => {
                        if (e.checked) {
                          const updatedNpi = [{ npiNumber: "" }];

                          setValue("NPIs", updatedNpi);
                          setNPIList(updatedNpi);
                        }
                        setNoNPI(e.checked);
                      }}
                      checked={noNPI}
                      inputId="noNPI"
                      name="noNPI"
                      className="mt-1"
                    />
                    <label
                      htmlFor="noNPI"
                      className="font-medium text-light-purple"
                    >
                      My practice does not have a group National Provider
                      Identifier (NPI). Please note, that this is abnormal, as
                      most group practices have registered for and have been
                      assigned a Group Practice NPI.
                    </label>
                  </div>
                  <div className="mt-7">
                    <label className="block font-Poppins text-base capitalize leading-loose text-gray-500">
                      Taxonomies associated with your group practice
                    </label>
                    <span className="text-sm">
                      Click the arrow icon to select a taxonomy.
                    </span>
                    <TaxonomyTree
                      selectedChooseList={selectedChooseList}
                      selectedOptionsList={selectedOptionsList}
                      setSelectedChooseList={setSelectedChooseList}
                      setSelectedOptionsList={setSelectedOptionsList}
                    />
                    {!selectedChooseList[0] && (
                      <p className="mt-1 text-left text-red-500">
                        Select taxonomy & at least one is primary
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex justify-end gap-5">
                  <div className="">
                    <Button
                      onClick={handleCancel}
                      type="button"
                      className="mt-9 justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="flex items-end justify-end">
                    {isLoading ? (
                      <div className="text-center">
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        disabled={!selectedChooseList[0]}
                        className="mt-9 justify-center rounded-full bg-light-purple p-2 font-inter font-normal capitalize text-light-text md:py-2.5"
                      >
                        Save Changes
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditGroupPractice;
