import GroupRuleCard from "./GroupRuleCard";
import SingleRuleCard from "./SingleRuleCard";
import ActionHeaderView from "./ActionHeaderView";
import CardActionButtons from "./CardActionButtons";
import ActionsView from "./renderActions/ActionsView";
import { ACTIONS_ENUM, RULE_TYPES } from "../../utils/constants";

const RulesCard = ({
  rulesGroup,
  setOpen,
  dataOfAllBillableItems,
  handleMoveToTop,
  handleMoveToBottom,
}) => {
  const isRuleGroup = rulesGroup.type === RULE_TYPES.GROUP.value;
  const isSingleGroup = rulesGroup.type === RULE_TYPES.SINGLE.value;
  const isAction = rulesGroup?.type === RULE_TYPES.ACTION.value;

  const card = isRuleGroup ? (
    <GroupRuleCard
      rulesGroup={rulesGroup}
      handleMoveToTop={handleMoveToTop}
      handleMoveToBottom={handleMoveToBottom}
      dataOfAllBillableItems={dataOfAllBillableItems}
    />
  ) : isSingleGroup ? (
    <SingleRuleCard
      rulesGroup={rulesGroup}
      handleMoveToTop={handleMoveToTop}
      handleMoveToBottom={handleMoveToBottom}
      dataOfAllBillableItems={dataOfAllBillableItems}
    />
  ) : isAction ? (
    <ActionHeaderView
      rulesGroup={rulesGroup}
      handleMoveToTop={handleMoveToTop}
      handleMoveToBottom={handleMoveToBottom}
    />
  ) : null;

  return (
    <div className="flex flex-col gap-6 rounded-md bg-white p-4">
      <div>{card}</div>
      <p className="text-xl font-semibold text-[#3D3877]">
        {Object.values(ACTIONS_ENUM).find(
          (action) => action.value === rulesGroup.action.key,
        )?.label || rulesGroup.action.key}
      </p>
      <ActionsView
        dataOfAllBillableItems={dataOfAllBillableItems}
        rulesGroup={rulesGroup}
      />
      <CardActionButtons rulesGroup={rulesGroup} setOpen={setOpen} />
    </div>
  );
};

export default RulesCard;
