import CheckIcon from "../../../../../../iconComponents/CheckIcon";
import DropdownIcon from "../../../../../../iconComponents/DropdownIcon";
import NumberIcon from "../../../../../../iconComponents/NumberIcon";
import RadioIcon from "../../../../../../iconComponents/RadioIcon";
import ShortTextIcon from "../../../../../../iconComponents/ShortTextIcon";
import { v4 as uuidv4 } from "uuid";

const getUUID = () => uuidv4();

export const SERVICE_JSON_VERSION = "1.0.0";

export const SERVICE_TYPES = {
  SCHEDULED: { label: "Scheduled Appointment Service", value: 1 },
  WALK_IN: { label: "Walk-In Service", value: 2 },
};
export const RULE_TYPES = {
  ACTION: { label: "Action", value: "action" },
  SINGLE: { label: "Single", value: "single" },
  GROUP: { label: "Group", value: "group" },
};

export const OPERATORS = {
  ALL: { label: "All", value: "and" },
  ANY: { label: "Any", value: "or" },
  IN: { label: "In", value: "in" },
  EQUAL: { label: "Equal", value: "equal" },
  NOT_EQUAL: { label: "Not equal", value: "notEqual" },
  NOT_ANSWERED: { label: "Not answered", value: "notAnswered" },
  GREATER_THAN: { label: "Greater than", value: "greaterThan" },
  GREATER_OR_EQUAL: {
    label: "Greater than or equal",
    value: "GreaterThanOrEqual",
  },
  LESS_THAN: { label: "Less than", value: "lessThan" },
  LESS_OR_EQUAL: { label: "Less than or equal", value: "lessThanOrEqual" },
  BETWEEN: { label: "Between", value: "between" },
  CONTAINS: { label: "Contains", value: "contains" },
  STARTS_WITH: { label: "Starts with", value: "startsWith" },
  ENDS_WITH: { label: "Ends with", value: "endsWith" },
};

export const EQUALITY_OPERATORS = [
  OPERATORS.EQUAL,
  OPERATORS.NOT_EQUAL,
  OPERATORS.NOT_ANSWERED,
];

export const VISIBILTY_EQUALITY_OPERATORS = [
  OPERATORS.EQUAL,
  OPERATORS.NOT_EQUAL,
];

export const LOGICAL_OPERATORS = [OPERATORS.ALL, OPERATORS.ANY];

export const NUMBER_COMPARISON_OPERATORS = [
  OPERATORS.BETWEEN,
  OPERATORS.GREATER_THAN,
  OPERATORS.GREATER_OR_EQUAL,
  OPERATORS.LESS_THAN,
  OPERATORS.LESS_OR_EQUAL,
];

export const TEXT_COMPARISON_OPERATORS = [
  OPERATORS.CONTAINS,
  OPERATORS.ENDS_WITH,
  OPERATORS.STARTS_WITH,
];

export const ACTIONS_ENUM = {
  ADD_CPT: {
    label: "Add Billable Items",
    value: "addCPT",
  },
  ADD_UNITS_TO_EXISTING: {
    label: "Add Units To Existing Billable Items",
    value: "AddUnitsToExisting",
  },
  REMOVE_ALL_EXCEPT: {
    label: "Remove All Billable Items Except",
    value: "removeAllExcept",
  },
  REMOVE_MATCHING_PATTERN: {
    label: "Remove Any Billable Items Matching Pattern",
    value: "removeMatchingPattern",
  },
  REMOVE_CPT: {
    label: "Remove Billable Items",
    value: "removeCPT",
  },
  REMOVE_UNITS_FROM_EXISTING: {
    label: "Reduce Units On Existing Billable Items",
    value: "removeUnitsFromExisting",
  },
  SWAP_CPT: {
    label: "Replace Billable Item",
    value: "swapCPT",
  },
  SET_UNITS: {
    label: "Set Units On Existing Billable Items",
    value: "setUnits",
  },
  ADD_DOCS: {
    label: "Add Documents",
    value: "addDocs",
  },
  REMOVE_DOCS: {
    label: "Remove Documents",
    value: "removeDocs",
  },
  ADD_LOCATIONS: {
    label: "Add locations",
    value: "addLocations",
  },
  REMOVE_LOCATIONS: {
    label: "Remove Locations",
    value: "removeLocations",
  },
  ABORT: {
    label: "Abort Order",
    value: "abort",
  },
};

export const ACTIONS = [
  {
    label: "Billable Items",
    actions: [
      ACTIONS_ENUM.ADD_CPT,
      ACTIONS_ENUM.ADD_UNITS_TO_EXISTING,
      ACTIONS_ENUM.REMOVE_UNITS_FROM_EXISTING,
      ACTIONS_ENUM.REMOVE_ALL_EXCEPT,
      ACTIONS_ENUM.REMOVE_MATCHING_PATTERN,
      ACTIONS_ENUM.REMOVE_CPT,
      ACTIONS_ENUM.SWAP_CPT,
      ACTIONS_ENUM.SET_UNITS,
    ],
  },
  {
    label: "Documents",
    actions: [ACTIONS_ENUM.ADD_DOCS, ACTIONS_ENUM.REMOVE_DOCS],
  },
  {
    label: "Locations",
    actions: [ACTIONS_ENUM.ADD_LOCATIONS, ACTIONS_ENUM.REMOVE_LOCATIONS],
  },
  {
    label: "Order Actions",
    actions: [ACTIONS_ENUM.ABORT],
  },
];

export const QUESTION_TYPES_ENUM = {
  MULTIPLE_CHOICE: {
    label: "Multiple Choice (Checkbox)",
    value: "multipleChoice",
    icon: <CheckIcon />,
  },
  DROPDOWN: {
    label: "Single Choice (Dropdown)",
    value: "dropDown",
    icon: <DropdownIcon />,
  },
  NUMBER: { label: "Number (whole)", value: "number", icon: <NumberIcon /> },
  DECIMAL: {
    label: "Number (decimal)",
    value: "decimal",
    icon: <NumberIcon />,
  },
  TEXT: { label: "Text", value: "text", icon: <ShortTextIcon /> },
  BOOLEAN: { label: "Yes or No", value: "boolean", icon: <RadioIcon /> },
};

export const QUESTION_TYPES_LIST = [
  QUESTION_TYPES_ENUM.DROPDOWN,
  QUESTION_TYPES_ENUM.MULTIPLE_CHOICE,
  QUESTION_TYPES_ENUM.DECIMAL,
  QUESTION_TYPES_ENUM.NUMBER,
  QUESTION_TYPES_ENUM.TEXT,
  QUESTION_TYPES_ENUM.BOOLEAN,
];
export const MULTI_OPTION_QUESTION_TYPES_LIST = [
  QUESTION_TYPES_ENUM.MULTIPLE_CHOICE,
  QUESTION_TYPES_ENUM.DROPDOWN,
];

export const NUMBER_TYPE_ENUM = {
  STANDARD: { label: "Standard", value: "standard" },
  AMERICAN: { label: "American height", value: "american" },
  WEIGHT: { label: "Weight", value: "weight" },
};

export const NUMBER_TYPE_LIST = [
  NUMBER_TYPE_ENUM.STANDARD,
  NUMBER_TYPE_ENUM.AMERICAN,
  NUMBER_TYPE_ENUM.WEIGHT,
];

export const GetLogicalOperatorSeverity = {
  [OPERATORS.ALL.value]: "info",
  [OPERATORS.ANY.value]: "warning",
};

export const GetEqualityOperatorSeverity = {
  [OPERATORS.EQUAL.value]: "info",
  [OPERATORS.NOT_EQUAL.value]: "primary",
  [OPERATORS.NOT_ANSWERED.value]: "danger",
};

export const DEFAULT_QUESTIONS_IDS = {
  GENDER: "0",
  AGE: "1",
  ADDRESS: "2",
  ZIP_CODE: "3",
  PROVIDER_NPI: "4",
  PROVIDER_NAME: "5",
};

export const GENDER_ANSWER_OPTIONS = [
  {
    id: "gender.0",
    value: "Male",
  },
  {
    id: "gender.1",
    value: "Female",
  },
  {
    id: "gender.2",
    value: "Unspecified",
  },
];

//TODO: create KEY_FILTER_ENUM from
// keyfilter options = "pint" | "int" | "pnum" | "money" | "num" | "hex" | "email" | "alpha" | "alphanum" | RegExp

export const DEFAULT_QUESTIONS = [
  {
    id: DEFAULT_QUESTIONS_IDS.GENDER,
    type: QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value,
    label: "Patient Gender",
    description: "",
    required: true,
    isConditional: false,
    conditionalOperator: OPERATORS.ALL.value,
    answers: GENDER_ANSWER_OPTIONS,
    conditions: [],
    constraints: {},
  },
  {
    id: DEFAULT_QUESTIONS_IDS.AGE,
    type: QUESTION_TYPES_ENUM.NUMBER.value,
    label: "Patient Age",
    description: "",
    required: true,
    isConditional: false,
    conditionalOperator: OPERATORS.ALL.value,
    answers: [],
    conditions: [],
    constraints: {
      type: NUMBER_TYPE_ENUM.STANDARD.value,
      min: {
        force: false,
        value: "",
      },
      max: {
        force: false,
        value: "",
      },
    },
  },
  {
    id: DEFAULT_QUESTIONS_IDS.ADDRESS,
    type: QUESTION_TYPES_ENUM.DROPDOWN.value,
    label: "Patient Address State",
    description: "",
    required: true,
    isConditional: false,
    conditionalOperator: OPERATORS.ALL.value,
    answers: [],
    conditions: [],
  },
  {
    id: DEFAULT_QUESTIONS_IDS.ZIP_CODE,
    type: QUESTION_TYPES_ENUM.TEXT.value,
    label: "Patient Address Zip Code",
    description: "",
    required: true,
    isConditional: false,
    conditionalOperator: OPERATORS.ALL.value,
    answers: [],
    conditions: [],
    constraints: {
      keyfilter: "int",
      minLength: {
        force: true,
        value: 5,
      },
      maxLength: {
        force: true,
        value: 5,
      },
      multiline: {
        force: false,
        value: "",
      },
    },
  },
  {
    id: DEFAULT_QUESTIONS_IDS.PROVIDER_NPI,
    type: QUESTION_TYPES_ENUM.TEXT.value,
    label: "Ordering Provider NPI",
    description: "",
    required: true,
    isConditional: false,
    conditionalOperator: OPERATORS.ALL.value,
    answers: [],
    conditions: [],
    constraints: {
      keyfilter: "int",
      minLength: {
        force: true,
        value: 10,
      },
      maxLength: {
        force: true,
        value: 10,
      },
      multiline: {
        force: false,
        value: "",
      },
    },
  },
  {
    id: DEFAULT_QUESTIONS_IDS.PROVIDER_NAME,
    type: QUESTION_TYPES_ENUM.TEXT.value,
    label: "Ordering Provider Name",
    description: "",
    required: true,
    isConditional: false,
    conditionalOperator: OPERATORS.ALL.value,
    answers: [],
    conditions: [],
    constraints: {
      placeholder: "Last, First {Middle.}",
      minLength: {
        force: false,
        value: "",
      },
      maxLength: {
        force: false,
        value: "",
      },
      multiline: {
        force: false,
        value: "",
      },
    },
  },
];

export const DEFAULT_ANSWERS = {
  [QUESTION_TYPES_ENUM.BOOLEAN.value]: [{ value: true }, { value: false }],
  [QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value]: [
    { id: getUUID(), value: "" },
    { id: getUUID(), value: "" },
  ],
  [QUESTION_TYPES_ENUM.DROPDOWN.value]: [
    { id: getUUID(), value: "" },
    { id: getUUID(), value: "" },
  ],
  DEFAULT: [],
};

export const WEIGHT_OPTIONS_ENUM = {
  LBS: "lbs",
  KGS: "kgs",
  SELECTABLE: "selectable",
};

export const DEFAULT_CONSTRAINTS = {
  [QUESTION_TYPES_ENUM.NUMBER.value]: {
    type: NUMBER_TYPE_ENUM.STANDARD.value,
    min: { force: false, value: null },
    max: { force: false, value: null },
    captureIn: WEIGHT_OPTIONS_ENUM.LBS,
    storeAs: WEIGHT_OPTIONS_ENUM.LBS,
  },
  [QUESTION_TYPES_ENUM.DECIMAL.value]: {
    type: NUMBER_TYPE_ENUM.STANDARD.value,
    min: { force: false, value: null },
    max: { force: false, value: null },
    captureIn: WEIGHT_OPTIONS_ENUM.LBS,
    storeAs: WEIGHT_OPTIONS_ENUM.LBS,
  },
  [QUESTION_TYPES_ENUM.TEXT.value]: {
    multiline: { force: false, value: 5 },
    minLength: { force: false, value: 5 },
    maxLength: { force: true, value: 150 },
  },
  DEFAULT: undefined,
};

export const CONDITION_TYPE_ENUM = {
  QUESTION: { label: "Question", value: "question" },
  SELF_EVAL: { label: "Self Evaluating", value: "selfEval" },
};

export const CONDITION_TYPES = [
  CONDITION_TYPE_ENUM.QUESTION,
  CONDITION_TYPE_ENUM.SELF_EVAL,
];

export const DEFAULT_CONDITION = {
  type: CONDITION_TYPE_ENUM.QUESTION.value,
  questionId: "",
  equalityOperator: OPERATORS.EQUAL.value,
  comparisonOperator: OPERATORS.EQUAL.value,
  answers: [],
  trim: false,
  caseSensitive: false,
  on: "",
  parseSettings: {},
};

export const RESOURCES_ENUM = {
  BILLABLE_ITEMS: { label: "Billable Items", value: "billableItems" },
  LOCATIONS: { label: "Locations", value: "locations" },
  DOCUMENTS: { label: "Documents", value: "documents" },
};
export const RESOURCES = [
  RESOURCES_ENUM.BILLABLE_ITEMS,
  RESOURCES_ENUM.LOCATIONS,
  RESOURCES_ENUM.DOCUMENTS,
];

export const DEFAULT_SELF_EVALUATING_QUESTIONS_IDS = {
  EXISTS: "selfEval.0",
  UNIT_COMPARE: "selfEval.1",
};

export const DEFAULT_SELF_EVALUATING_QUESTIONS = [
  {
    id: DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS,
    label: "Billable Items - Check Exists",
    type: QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value,
    description: "",
    answers: [],
    // on: () => RESOURCES,
    parseSettings: {},
    initialAnswerField: { value: "" },
    constraints: {},
  },
  {
    id: DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE,
    type: QUESTION_TYPES_ENUM.NUMBER.value,
    label: "Billable Items - Unit Comparison",
    description: "",
    answers: [],
    // on: () => getBillableItems(exceptCurrentRuleId),
    parseSettings: { treatAsZero: false },
    initialAnswerField: {
      value: { id: "", units: null, min: null, max: null },
    },
    constraints: { type: NUMBER_TYPE_ENUM.STANDARD.value },
  },
];
