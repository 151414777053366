import { useEffect, useState } from "react";

import { FormProvider, useForm } from "react-hook-form";
import {
  unstable_usePrompt,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import TestService from "../components/TestService";
import useAxios from "../../../../../hooks/useAxios";
import { BuggyCounter } from "../../../../BuggyCounter";
import useTokenData from "../../../../../hooks/useTokenData";
import ServiceCheckList from "./components/ServiceCheckList";
import { LocationsProvider } from "./context/LocationsContext";
import { showErrorToast } from "../../../../../utils/apiUtils";
import ServiceDetailsForm from "./components/ServiceDetailsForm";
import { BillableProvider } from "./context/BillableItemsContext";
import { handleBeforeUnload } from "../../../../../utils/helpers";
import { ServiceDetailsProvider } from "./context/ServiceDetailsContext";
import CreateNewLocationDialog from "./components/CreateNewLocationDialog";
import { useBlockNavigation, useLayoutToast } from "../../../../../store/store";
import ServiceOptions from "../../my-services/manage-service/components/ServiceOptions";
import {
  useServiceStore,
  useStates,
} from "../../my-services/manage-service/utils/store";
import CreateServiceSkeleton from "../../../../../components/skeletons/CreateServiceSkeleton";
import {
  addStatesToDefaultQuestions,
  generateServiceBody,
} from "../../my-services/manage-service/utils/utils";

const SAVE_PROGRESS = { value: "save-progress" };

function ManageService() {
  const serviceDetailsDefaultValues = {
    name: "",
    description: "",
    serviceType: 1,
    internalOnly: false,
    checkInPass: true,
    billableItems: [],
    locations: [],
    documents: [],
  };

  const { http } = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const { serviceId } = useParams();
  const { entityId } = useTokenData();
  const { layoutToast } = useLayoutToast();
  const { blockNavigation, setBlockNavigation } = useBlockNavigation();
  const { states, setStates } = useStates();
  const { rulesGroups, questions, setQuestions, setRulesGroups } =
    useServiceStore();

  const methods = useForm({
    mode: "onChange",
    defaultValues: serviceDetailsDefaultValues,
  });
  const {
    handleSubmit,
    watch,
    trigger,
    formState: { isSubmitting },
  } = methods;

  const [isFetching, setIsFetching] = useState(!!serviceId);
  const [isFetchBillable, setIsFetchBillable] = useState(false);
  const [serviceNotFound, setServiceNotFound] = useState(false);
  const [testServiceVisible, setTestServiceVisible] = useState(false);

  const isCopy = location?.state?.isCopy;

  const getStates = async () => {
    try {
      const response = await http.get(`/States`);
      addStatesToDefaultQuestions(response.data);
      setStates(response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
      const servicesData = await http.get(
        `/Services/GetServiceWizard/${serviceId}`,
      );
      const data = JSON.parse(servicesData.data.serviceJson);
      methods.reset({
        name: isCopy ? `${data.name} - Copy` : data.name,
        description: data.description,
        serviceType: data.serviceType,
        internalOnly: data.internalOnly,
        checkInPass: data.checkInPass,
        billableItems: data.billableItems,
        locations: data.locations,
        documents: data.documents,
      });

      setIsFetchBillable(true);
      setQuestions(data.questions);
      setRulesGroups(data.rulesGroups);
    } catch (error) {
      setServiceNotFound(true);
      console.error("Error Fetching Service Data", error);
    } finally {
      setIsFetching(false);
    }
  };

  const createService = async (body) => {
    const response = await http.post(
      `/Services/CreateServiceWizard/${entityId}`,
      body,
    );
    setBlockNavigation(false);

    return response.data;
  };

  const updateService = async (body) => {
    const response = await http.put(
      `/Services/UpdateServiceWizard/${serviceId}`,
      body,
    );
    setBlockNavigation(false);

    return response.data;
  };

  const navigateBack = () => navigate("/services/my-services");

  const onSubmit = async (formData, e) => {
    const submitButtonValue = e.nativeEvent.submitter.value;
    const isSaveProgress = submitButtonValue === SAVE_PROGRESS.value;

    const body = generateServiceBody({ formData });

    try {
      if (isCopy && isSaveProgress) {
        const id = await createService(body);
        navigate(`/services/my-services/manage-service/${id}`, {
          replace: true,
        });
        setBlockNavigation(true);
      } else if (isCopy) {
        await createService(body);
        navigateBack();
      } else if (isSaveProgress) {
        if (serviceId) {
          await updateService(body);
        } else {
          const id = await createService(body);
          navigate(`/services/my-services/manage-service/${id}`, {
            replace: true,
          });
        }
        setBlockNavigation(true);
      } else if (serviceId) {
        await updateService(body);
        navigateBack();
      } else {
        await createService(body);
        navigateBack();
      }

      const message = isSaveProgress
        ? "Progress Saved Successfully"
        : isCopy || !serviceId
          ? "Service Created Successfully"
          : "Service Updated Successfully";

      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: message,
        life: 4000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const testService = async () => {
    const isValid = await trigger(undefined, { shouldFocus: true });

    if (isValid) {
      setTestServiceVisible(true);
    }
  };

  const handleCancelServiceCreation = () => {
    confirmDialog({
      message: "Are you sure you want to leave? ALL unsaved data will be LOST.",
      header: "Confirm Exit",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Yes, Leave",
      rejectLabel: "No, Stay",
      accept: () => {
        setBlockNavigation(false);
        navigateBack();
      },
      reject: () => {},
      style: { width: "500px" },
      draggable: false,
    });
  };

  useEffect(() => {
    serviceId && getData();
    if (states.length) {
      addStatesToDefaultQuestions(states);
    } else getStates();
  }, []);

  useEffect(() => {
    return () => {
      setBlockNavigation(true);
    };
  }, [setBlockNavigation]);

  unstable_usePrompt({
    message:
      "Are you sure you want to leave before saving? All your data will be discarded",
    when: ({ currentLocation, nextLocation }) =>
      blockNavigation && currentLocation.pathname !== nextLocation.pathname,
  });

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if (serviceNotFound)
    return (
      <div className="flex h-screen items-center justify-center rounded-lg border-gray-300 bg-white p-5 font-inter text-4xl text-gray-500">
        Service Not Found
      </div>
    );

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        visible={testServiceVisible}
        onHide={() => setTestServiceVisible(false)}
        header="Test Service"
        className="max-h-[95vh] w-11/12 md:w-[80vw]"
      >
        <TestService
          setVisible={setTestServiceVisible}
          serviceInfo={JSON.stringify({
            ...watch(),
            questions,
            rulesGroups,
          })}
        />
      </Dialog>
      <ServiceDetailsProvider
        defaultDocs={watch("documents")}
        defaultBillableItems={watch("billableItems")}
      >
        <LocationsProvider>
          <BillableProvider>
            <FormProvider {...methods}>
              <ConfirmDialog />
              <form onSubmit={handleSubmit(onSubmit)}>
                {!isFetching ? (
                  <div className="relative grid h-full grid-cols-12 gap-4">
                    <div
                      className="col-span-12 flex flex-col md:col-span-9"
                      style={{
                        height: "calc(100vh - 5rem)",
                      }}
                    >
                      <div className="relative flex h-full flex-col gap-4 overflow-y-auto">
                        <ServiceDetailsForm isFetchBillable={isFetchBillable} />
                        <ServiceOptions />
                      </div>
                      <div className="flex w-full flex-col gap-2 border border-gray-300 bg-slate-50 px-3 py-2 md:gap-5">
                        <div className="flex items-center justify-center gap-2 md:hidden">
                          <Button
                            id="test-service-button"
                            outlined
                            type="button"
                            label="Test Service"
                            onClick={() => {
                              testService();
                            }}
                            icon="pi pi-exclamation-triangle"
                            className="md:p-auto w-1/2 p-3 md:w-auto"
                          />
                          <Button
                            id="save-progress-button"
                            severity="info"
                            label="Save Progress"
                            className="md:p-auto w-1/2 p-3 md:w-auto"
                            icon="pi pi-save"
                            loading={isSubmitting}
                            value={SAVE_PROGRESS.value}
                          />
                        </div>
                        <div className="flex items-center justify-center gap-2 md:justify-end">
                          <Button
                            id="cancel-service-button"
                            severity="danger"
                            label="Cancel"
                            className="md:p-auto w-1/2 p-3 md:w-auto"
                            icon="pi pi-times"
                            type="button"
                            onClick={handleCancelServiceCreation}
                          />
                          <Button
                            id="create-service-button"
                            severity="success"
                            label={
                              !serviceId || isCopy
                                ? "Create Service"
                                : "Update Service"
                            }
                            loading={isSubmitting}
                            className="md:p-auto w-1/2 p-3 md:w-auto"
                            icon="pi pi-check"
                            type="submit"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="sticky top-0 hidden h-2/3 gap-4 md:col-span-3 md:flex md:flex-col">
                      <div className="max-h-[40rem] overflow-y-auto">
                        <ServiceCheckList />
                      </div>
                      <Button
                        id="save-progress-button"
                        loading={isSubmitting}
                        severity="info"
                        value={SAVE_PROGRESS.value}
                        label="Save Progress"
                        className="md:p-auto w-1/2 p-3 md:w-auto"
                        icon="pi pi-save"
                      />
                      <Button
                        id="test-service-button"
                        outlined
                        label="Test Service"
                        type="button"
                        onClick={() => {
                          testService();
                        }}
                        icon="pi pi-exclamation-triangle"
                        className="md:p-auto w-1/2 p-3 md:w-auto"
                      />
                      <BuggyCounter />
                    </div>
                  </div>
                ) : (
                  <CreateServiceSkeleton />
                )}
              </form>
            </FormProvider>
            <CreateNewLocationDialog />
          </BillableProvider>
        </LocationsProvider>
      </ServiceDetailsProvider>
    </>
  );
}

export default ManageService;
