import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { NUMBER_TYPE_ENUM } from "../../utils/constants";
import StandardNumericInput from "./StandardNumericInput";
import AmericanNumericInput from "./AmericanNumericInput";
import WeightInput from "./WeightInput";

const NumberInputRange = ({ inputNamePath, question }) => {
  const { control, getValues, trigger, watch } = useFormContext();

  const { fields: answers } = useFieldArray({
    control: control,
    name: `${inputNamePath}.answers`,
  });

  const isStandard =
    question?.constraints?.type === NUMBER_TYPE_ENUM.STANDARD.value;
  const isWeight =
    question?.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;
  const minimumIndex = 0;
  const maximumIndex = 1;

  return (
    <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
      <span className="col-span-3 font-semibold">Values</span>
      <div className="flex w-full flex-col gap-2 md:col-span-9">
        {answers.map((answer, answerIndex) => (
          <Controller
            key={answer.id}
            name={`${inputNamePath}.answers.${answerIndex}.value`}
            control={control}
            rules={{
              required: "Please enter a valid number",

              validate: {
                minMaxComparison: (value) => {
                  let condition = true;

                  const comparisonIndex =
                    answerIndex === minimumIndex ? maximumIndex : minimumIndex;
                  const comparisonValue = getValues(
                    `${inputNamePath}.answers.${comparisonIndex}.value`,
                  );

                  if (answerIndex === minimumIndex && comparisonValue) {
                    trigger(
                      `${inputNamePath}.answers.${comparisonIndex}.value`,
                    );
                  }
                  if (answerIndex === maximumIndex && comparisonValue) {
                    condition = value > comparisonValue;
                  }
                  return (
                    condition ||
                    "Minimum value must be less than maximum value."
                  );
                },
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-9 flex w-full flex-col gap-2">
                <div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
                  <label htmlFor={field.name} className="font-semibold">
                    {answerIndex === minimumIndex ? "Minimum" : "Maximum"}
                  </label>
                  <div className="flex-1">
                    {isStandard ? (
                      <StandardNumericInput
                        field={field}
                        error={error}
                        questionType={question.type}
                      />
                    ) : isWeight ? (
                      <WeightInput
                        field={field}
                        error={error}
                        unit={question?.constraints?.storeAs}
                        questionType={question.type}
                      />
                    ) : (
                      <AmericanNumericInput
                        id={field.name}
                        value={field.value}
                        error={error}
                        onChange={field.onChange}
                      />
                    )}
                  </div>
                </div>
                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default NumberInputRange;
