import { useEffect } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { InputNumber } from "primereact/inputnumber";

import WeightInput from "../formElements/WeightInput";
import { convertStandardToWeight } from "../../utils/numericConversions";
import { HeightInput } from "../../../../../../../components/formElements";
import { convertToFeetAndInches } from "../../../../../../../utils/helpers";
import {
  NUMBER_TYPE_ENUM,
  QUESTION_TYPES_ENUM,
  WEIGHT_OPTIONS_ENUM,
} from "../../../../my-services/manage-service/utils/constants";

const DisplayNumber = ({ question }) => {
  const { control, setValue, watch, clearErrors } = useFormContext();
  const isAmerican =
    question?.constraints?.type === NUMBER_TYPE_ENUM.AMERICAN.value;

  const isWeight =
    question?.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;

  const isUserSelectable =
    question?.constraints?.captureIn === WEIGHT_OPTIONS_ENUM.SELECTABLE;

  useEffect(() => {
    if (isWeight && !isUserSelectable) {
      setValue(`${question.id}.0.unit`, question?.constraints?.captureIn);
    } else if (isWeight) {
      setValue(`${question.id}.0.unit`, WEIGHT_OPTIONS_ENUM.LBS);
    }
  }, []);

  const unit = watch(`${question.id}.0.unit`);

  const isInt = question.type === QUESTION_TYPES_ENUM.NUMBER.value;

  const minWeightValue =
    isWeight &&
    convertStandardToWeight(+question?.constraints?.min?.value, unit);

  const maxWeightValue =
    isWeight &&
    convertStandardToWeight(+question?.constraints?.max?.value, unit);

  const maxValue = isAmerican
    ? convertToFeetAndInches(question?.constraints?.max?.value)
    : isWeight
      ? `${isInt ? Math.floor(maxWeightValue) : maxWeightValue}  ${unit}`
      : +question?.constraints?.max?.value;

  const minValue = isAmerican
    ? convertToFeetAndInches(question?.constraints?.min?.value)
    : isWeight
      ? `${isInt ? Math.ceil(minWeightValue) : minWeightValue}  ${unit}`
      : +question?.constraints?.min?.value;

  return (
    <Controller
      name={`${question.id}.0.value`}
      control={control}
      rules={{
        required: question?.required && "Question is required",
        min: {
          value: question?.constraints?.min?.force
            ? question?.constraints?.min?.value
            : undefined,
          message: `Answer cannot be less than ${minValue}`,
        },
        max: {
          value: question?.constraints?.max?.force
            ? question?.constraints?.max?.value
            : undefined,
          message: `Answer cannot be more than ${maxValue}`,
        },
      }}
      defaultValue={null}
      render={({ field, fieldState: { error } }) =>
        isAmerican ? (
          <>
            <HeightInput
              onHeightChange={(value) => {
                field.onChange(value > 0 ? value : null);
              }}
              heightValue={field.value > 0 ? field.value : null}
            />
            {error && <p className="text-sm text-red-500">{error.message}</p>}
          </>
        ) : isWeight ? (
          <>
            <WeightInput
              field={field}
              error={error}
              unit={question?.constraints?.captureIn}
              questionType={question.type}
              handleUserSelection={(value) => {
                setValue(`${question.id}.0.unit`, value);
                clearErrors([`${question.id}`]);
              }}
              defaultUserSelectable={unit}
            />
            {error && <p className="text-sm text-red-500">{error.message}</p>}
          </>
        ) : (
          <>
            <InputNumber
              useGrouping={false}
              minFractionDigits={
                question.type === QUESTION_TYPES_ENUM.NUMBER.value
                  ? undefined
                  : 2
              }
              maxFractionDigits={
                question.type === QUESTION_TYPES_ENUM.NUMBER.value
                  ? undefined
                  : 2
              }
              id={field.name}
              inputRef={field.ref}
              value={field.value}
              onBlur={field.onBlur}
              min={0}
              maxLength={15}
              className="w-full"
              placeholder="Enter a number"
              onValueChange={(e) => {
                field.onChange(e.value);
              }}
              onChange={(e) => {
                field.onChange(e.value);
              }}
            />
            {error && <p className="text-sm text-red-500">{error.message}</p>}
          </>
        )
      }
    />
  );
};

export default DisplayNumber;
