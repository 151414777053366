import { useMemo } from "react";

import { useFormContext } from "react-hook-form";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { ModTemplate } from "../../../templates/ModTemplate";
import useFetchBillablesByIds from "../useFetchBillablesByIds";
import DescriptionWithTooltipTemplate from "../DescriptionWithTooltipTemplate";
import { PriceBillableTemplate } from "../../../templates/PriceBillableTemplate";
import { useServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import {
  getAllBillableItemsInRules,
  getBillableItemsIds,
  matchPattern,
} from "./helpers";
import { BillableItemsAttributesTemplate } from "../../../templates/BillableItemsAttributesTemplate";

const COLUMNS = [
  { header: "HCPCS", field: "hcpcs" },
  {
    header: "Description",
    body: DescriptionWithTooltipTemplate,
  },
  { header: "Mods", body: ModTemplate },
  {
    header: "Attributes",
    body: BillableItemsAttributesTemplate,
  },
  {
    header: "Price per unit",
    body: PriceBillableTemplate,
  },
];

const RemoveMatchingPattern = ({ field, error }) => {
  const { watch } = useFormContext();
  const { defaultBillableItems } = useServiceDetailsContext();

  const allBillableItemsIds = useMemo(
    () =>
      getBillableItemsIds([
        ...getAllBillableItemsInRules(watch("id")),
        ...defaultBillableItems,
      ]),
    [],
  );
  const { billableItems } = useFetchBillablesByIds(allBillableItemsIds);

  const pattern = watch("action.on.pattern") || "";

  const matchingBillableItems = billableItems.filter((item) =>
    matchPattern(item.hcpcs, pattern),
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <label htmlFor="removeMatchingPattern" className="font-semibold">
          Pattern to Match
        </label>

        <InputText
          id="removeMatchingPattern"
          className="w-full md:w-1/2"
          placeholder="Enter a valid pattern*"
          {...field}
          value={field?.value?.pattern}
          onChange={(e) => {
            field.onChange({ pattern: e.target.value });
          }}
        />

        {error && <p className="text-red-500">{error.message}</p>}
        <div className="rounded-lg border border-slate-100 p-4">
          <div className="text-sm text-gray-500">
            Valid patterns:
            <div className="p-2">
              <span className="font-medium">*:</span> any characters
              <br /> <span className="font-medium">?:</span> a single character
              <br /> <span className="font-medium">#:</span> a single digit
              <br /> <span className="font-medium">[A-Z]:</span> any character
              in range
              <br /> <span className="font-medium">[!A-Z]:</span> any character
              not in range
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="font-semibold">Billable Items that will be removed</div>
        <DataTable
          value={matchingBillableItems}
          className="rounded-lg border-2 border-gray-200"
          emptyMessage={
            <p className="text-center">No Matching HCPCS Code(s)</p>
          }
        >
          {COLUMNS.map((column) => (
            <Column
              key={column.header}
              align="center"
              alignHeader="center"
              {...column}
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default RemoveMatchingPattern;
