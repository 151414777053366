import { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import NurseIcon from "../../iconComponents/NurseIcon";
import useImpersonating from "../../hooks/useImpersonating";
import MedicalHistoryIcon from "../../iconComponents/MedicalHistoryIcon";
import {
  useFirstTimeVisit,
  useRefreshTokenStore,
  useTokenStore,
} from "../../store/store";
import TechnicalSupportDialog from "./components/TechnicalSupportDialog";

function EmailVerifySuccess() {
  const { individualPracticeOwner, VerifyProvider } = useTokenData();
  // save user data from cookie
  const { logOut } = useAxios();
  const { setToken } = useTokenStore();
  const { isFirstTimeVisit } = useFirstTimeVisit();
  const { setRefreshToken } = useRefreshTokenStore();
  const { isSystemAdminImpersonating } = useImpersonating();
  const [TechSupportFormDialog, setTechSupportFormDialog] = useState(false);

  useEffect(() => {
    const cookieData = Cookies.get("userData");
    if (cookieData && typeof cookieData === "string") {
      //Extract token
      const tokenRegex = /JwToken:'([^']+)/;
      const tokenMatch = cookieData.match(tokenRegex);
      const jwtToken = tokenMatch ? tokenMatch[1] : null;
      //extract refresh token
      const refreshTokenRegex = /RefreshToken:'([^']+)/;
      const refreshTokenMatch = cookieData.match(refreshTokenRegex);
      const refreshToken = refreshTokenMatch ? refreshTokenMatch[1] : null;
      setToken(jwtToken);
      setRefreshToken(refreshToken);
      Cookies.remove("userData");
    }
  }, []);
  const navigate = useNavigate();

  const handleNewIndividual = () => {
    navigate(`/setUpIndividual/${2}`);
  };

  const handleNewGroup = () => {
    navigate(`/setUpGroupNextStep/${1}`);
  };
  const handleExistGroup = () => {
    navigate(`/joinAnExistingGroup`);
  };

  return (
    <>
      <TechnicalSupportDialog
        TechSupportFormDialog={TechSupportFormDialog}
        setTechSupportFormDialog={setTechSupportFormDialog}
      />
      <div className="mb-9 grid justify-items-center ">
        <div className="2xl:px-30 xl:px-30 md:px-15 mt-10 w-11/12 rounded-3xl border bg-light-text p-6 py-9 shadow shadow-slate-300 sm:px-9 lg:w-6/6 lg:px-20 2xl:w-4/6">
          {isFirstTimeVisit && (
            <>
              <h4 className="text-center font-philosopher text-title font-black capitalize text-dark-purple">
                Select your profile type
              </h4>
              <div className="mb-9 mt-1 flex items-center justify-center">
                <p className="mt-5 w-2/3 text-center font-Poppins text-sm leading-loose text-normal-text">
                  Congratulations! Your email has been successfully verified.
                  Now, you have these options to proceed with your account:
                </p>
              </div>
            </>
          )}

          <div className="flex flex-col justify-around gap-6 lg:flex-row">
            {individualPracticeOwner === "False" && (
              <div className="text-center lg:w-1/3 md:w-full w-auto">
                <span className="m-auto mb-5 flex w-max items-center justify-center rounded-full bg-gray-100 p-2">
                  <MedicalHistoryIcon />
                </span>
                <h5 className="h-11 pt-1 text-center font-Poppins font-medium capitalize text-light-purple">
                  Set up my individual provider profile
                </h5>
                <p className="mb-2 mt-4 text-center font-Poppins text-sm leading-loose text-normal-text md:h-48">
                  I’m an individual provider and want to create my individual
                  provider profile, to be able to order or/and render services,
                  customize my practice info and manage services.
                </p>
                <p className=" text-sm font-semibold  h-auto lg:h-[2.8rem] md:h-[3.8rem] text-gray-400">
                  * Select this option if I have a sole NPI registered under my
                  personal name.
                </p>
                <Button
                  onClick={() => {
                    if (VerifyProvider !== "NULL" && individualPracticeOwner) {
                      setTechSupportFormDialog(true);
                    } else {
                      handleNewIndividual();
                    }
                  }}
                  className="mt-14 md:mt-14 w-full justify-center rounded bg-transparent py-2.5 pe-2.5 ps-2.5 font-inter font-semibold capitalize text-light-purple"
                >
                  Set up my individual profile
                </Button>
              </div>
            )}

            <div className="text-center  lg:w-1/3 md:w-full w-auto">
              <span className="m-auto mb-5 flex w-max items-center justify-center rounded-full bg-gray-100 p-3">
                <NurseIcon />
              </span>
              <h5 className="h-10 text-center font-Poppins font-medium capitalize text-light-purple">
                Set up my group practice
              </h5>
              <p className="mb-2 mt-4 text-center font-Poppins text-sm leading-loose text-normal-text md:h-48">
                I’m a provider and want to create my new group practice profile,
                to be able to order or/and render services, customize my
                practice info, add staff members and manage services.
              </p>
              <p className="text-sm font-semibold  md:max-h-[3.8rem] h-auto text-gray-400">
                * Select this option if my medical practice has an individual
                NPI separate from my personal NPI.
              </p>
              <Button
                onClick={handleNewGroup}
                className="mt-14 w-full justify-center rounded bg-transparent md:whitespace-nowrap py-2.5 pe-2.5 ps-2.5 font-inter font-semibold capitalize text-light-purple"
              >
                Set up my group practice
              </Button>
            </div>

            <div className="text-center lg:w-1/3 md:w-full w-auto">
              <span className="m-auto mb-5 flex w-max items-center justify-center rounded-full bg-gray-100 p-3">
                <NurseIcon />
              </span>
              <h5 className="h-10 text-center font-Poppins font-medium capitalize text-light-purple">
                Join An Existing Group practice
              </h5>
              <p className="mb-2 mt-4 text-center font-Poppins text-sm leading-loose text-normal-text md:h-48">
                I’m a part of an existing Practice group, and want to search for
                existing practice profiles, to able to collaborate with my
                colleagues and access shared requests.
              </p>
              <p className="text-sm font-semibold max-h-[3.8rem] text-gray-400">
                * I would like to join an existing medical practice that has
                already been set-up on MedX.
              </p>
              <Button
                onClick={handleExistGroup}
                className="mt-14 w-full justify-center box-border rounded bg-transparent  whitespace-wrap md:whitespace-nowrap py-2.5 pe-2.5 ps-2.5 font-inter font-semibold capitalize text-light-purple"
              >
                Join An Existing Group practice
              </Button>
            </div>
          </div>

          <p className="mt-5 p-3 text-center font-Poppins text-p capitalize text-blue-500">
            Please choose the option that best suits your needs to continue with
            your account setup.
          </p>
          {!isSystemAdminImpersonating && (
            <div className="mt-11 text-end">
              <Button icon=" pi pi-sign-out" onClick={logOut} label="Logout" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EmailVerifySuccess;
