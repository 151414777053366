import { useRef } from "react";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import useAxios from "../../../../hooks/useAxios";
import { useLayoutToast } from "../../../../store/store";
import { ReusableDataList } from "../../../../components/Table";
import { InvitationStatusTemplate } from "../../../../components/templates/columnBodyTemplates";
import { showErrorToast } from "../../../../utils/apiUtils";

const ManageAdminInvitations = ({ refetch, setRefetch }) => {
  const { http } = useAxios();
  const { layoutToast } = useLayoutToast();

  const invitationCardMenuRef = useRef({});

  const cardTemplate = (rowData) => {
    return (
      <div className="flex flex-col gap-4 p-2">
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            <i className="pi pi-user" />
            <span className="text-slate-500">&nbsp;email:&nbsp;</span>
            <span>{rowData.email}</span>
          </p>
          {cardActionTemplate(rowData)}
        </div>
        {InvitationStatusTemplate(rowData)}
      </div>
    );
  };

  const cardActionTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-ellipsis-v"
          text
          rounded
          onClick={(event) => {
            invitationCardMenuRef.current[rowData.id].toggle(event);
          }}
          aria-controls={`popup_menu_right_${rowData.id}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (invitationCardMenuRef.current[rowData.id] = el)}
          id={`popup_menu_right_${rowData.id}`}
        />
      </>
    );
  };

  const getMenuItems = (rowData) => {
    const items = [
      {
        label: "Delete",
        icon: "pi pi-trash text-red-500",
        command: () => handleDelete(rowData),
      },
      {
        label: "Resend",
        icon: "pi pi-undo",
        command: () => {
          handleResend(rowData.id);
        },
      },
    ];

    return items;
  };

  const actionsTemplate = (rowData) => {
    return (
      <div className="flex items-center">
        <Button
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-trash"
          rounded
          text
          onClick={() => handleDelete(rowData)}
          severity="danger"
        />
        <Button
          tooltip="Resend"
          tooltipOptions={{ position: "top" }}
          icon="pi pi-undo"
          rounded
          text
          onClick={() => handleResend(rowData.id)}
        />
      </div>
    );
  };

  const handleDelete = async (rowData) => {
    confirmDialog({
      header: "Delete Invitation",
      message: "Are you sure you want to proceed?",
      icon: "pi pi-exclamation-triangle",
      accept: async () => {
        await deleteInvitation(rowData.id);
      },
    });
  };

  const deleteInvitation = async (id) => {
    try {
      const res = await http.delete(`/Invitations/${id}`);
      setRefetch(!refetch);
      layoutToast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data,
        life: 2000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleResend = async (id) => {
    try {
      const res = await http.post(
        `/Invitations/ResendSystemAdminInvitation/${id}`,
      );
      setRefetch(!refetch);
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 2000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  return (
    <ReusableDataList
      title="All Invitations"
      fromFront
      dataLink="/Invitations/GetSystemAdminInvitations"
      columns={[
        { header: "Email", field: "email", style: { width: "50%" } },
        { template: InvitationStatusTemplate, header: "Status" },
        { template: actionsTemplate, header: "Action" },
      ]}
      mobileTemplate={cardTemplate}
      refetch={refetch}
      cardFilters={["email"]}
    />
  );
};

export default ManageAdminInvitations;
