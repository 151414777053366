import { useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";

import { Tooltip } from "primereact/tooltip";
import useAxios from "../../../../hooks/useAxios";
import useTwilioStatus from "../../../../hooks/useTwilioStatus";
import { showErrorToast } from "../../../../utils/apiUtils";
import { useLayoutToast } from "../../../../store/store";

function EnvironmentSettings() {
  const toast = useRef(null);
  const { http } = useAxios();
  const { twilioStatus } = useTwilioStatus();
  const [code, setCode] = useState("");
  const [codeToolTip, setCodeToolTip] = useState({
    value: "Click to copy code",
    color: "bg-slate-200 text-black",
  });
  const timeoutRef = useRef(null);
  const abortControllerRef = useRef(null);
  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";

  const defaultValues = { status: "false" };

  const { control, handleSubmit, setValue } = useForm({
    mode: "all",
    defaultValues: defaultValues,
  });
  const { layoutToast } = useLayoutToast();
  const [isLoading, setIsLoading] = useState(false);
  const fetchVerificationCode = async () => {
    try {
      const res = await http.get("/Authentication/GetRegistrationCode");
      setCode(res.data);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const regenerateVerificationCode = async () => {
    setIsLoading(true);
    if (abortControllerRef.current) abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    try {
      const res = await http.get("/Authentication/RegenerateRegistrationCode", {
        signal: abortControllerRef.current.signal,
      });
      setCode(res.data);
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: "A new admin registration verification code has been created.",
        life: 4000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!isProduction) fetchVerificationCode();
    setValue("status", twilioStatus.toString());
  }, [twilioStatus]);

  const onSubmit = async (formData) => {
    try {
      await http.post(`/Users/ChangeTwilioStatus`, formData);
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Twilio Status updated successfully",
        life: 4000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };
  return (
    <div>
      <Tooltip
        target=".copy-code"
        content={codeToolTip.value}
        position="top"
        className={`${code?.length === 0 && "hidden"}`}
        pt={{ root: { className: codeToolTip.color } }}
      />
      <Toast ref={toast} />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div className="flex flex-col gap-4">
            <h4 className="text-center text-4xl font-bold sm:text-left">
              Environment Settings
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <div className="max-w-5xl cursor-pointer rounded-lg border border-slate-100 hover:bg-slate-50">
                    <p className="w-full p-4 text-center text-gray-600 sm:w-4/5 sm:text-left">
                      <span className="font-semibold">Enable</span> or{" "}
                      <span className="font-semibold">Disable</span> SMS
                      communication for the environment, setting this option to{" "}
                      <span className="font-semibold italic">Disabled</span>{" "}
                      will stop dispatching any messages via Twilio feed & will
                      hide the options and the buttons in the user interface
                      that have anything to do with sending an SMS.
                    </p>
                    <div className="grid w-full grid-cols-6 gap-2">
                      <div className="col-span-6 flex flex-col gap-1 p-6 pt-2 md:col-span-5 lg:col-span-4">
                        <label
                          htmlFor="status"
                          className="cursor-pointer text-lg font-semibold text-gray-800"
                        >
                          Dispatch SMS communication for the environment
                        </label>
                        <p className="text-sm text-gray-500"></p>
                      </div>

                      <div className="col-span-6 flex items-center justify-center md:col-span-1 lg:col-span-2">
                        <InputSwitch
                          inputId={field.name}
                          checked={field.value === "true" ? true : false}
                          inputRef={field.ref}
                          onChange={async (e) => {
                            field.onChange(e.value === true ? "true" : "false");
                            await handleSubmit(onSubmit)();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              />
              {!isProduction && (
                <div className="flex max-w-5xl cursor-pointer items-center gap-4 rounded-lg border border-slate-100 p-5">
                  <h5 className="text-lg font-semibold text-gray-800">
                    Admin Registration Verification Code
                  </h5>
                  <p
                    className={`copy-code w-72 truncate rounded-md border-dark-purple bg-dark-purple/10 p-4 text-center ${code?.length > 0 && "cursor-pointer"}`}
                    onClick={() => {
                      if (timeoutRef.current) clearTimeout(timeoutRef.current);
                      if (code.length > 0) {
                        timeoutRef.current = setTimeout(() => {
                          setCodeToolTip({
                            value: "Click to copy code",
                            color: "bg-slate-200 text-black",
                          });
                        }, 2000);
                        navigator.clipboard.writeText(code);
                        setCodeToolTip({
                          value: "Copied to clipboard!",
                          color: "bg-slate-200 text-black font-bold",
                        });
                      }
                    }}
                  >
                    {code}
                  </p>
                  <Button
                    type="submit"
                    onClick={regenerateVerificationCode}
                    severity="info"
                    id="generate-code"
                    label={isLoading ? "Generating..." : "Regenerate"}
                    loading={isLoading}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EnvironmentSettings;
