import { useEffect, useState } from "react";

import { useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import AttachBillableItemForm from "./AttachBillableItemForm";
import SelectedBillableItemsTable from "./SelectedBillableItemsTable";
import { CreateBillableItem } from "../../../components/CreateBillableItem";
import useFetchBillablesByIds from "./rules/useFetchBillablesByIds";
import {
  getAllBillableItemsInRules,
  getBillableItemsIds,
} from "../../../my-services/manage-service/components/rules/actions/helpers";
import { useServiceDetailsContext } from "../context/ServiceDetailsContext";

const DefaultBillableItems = ({ isFetchBillable }) => {
  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { isLoading, billableItems, getBillableItems } =
    useFetchBillablesByIds();

  const { defaultBillableItems } = useServiceDetailsContext();

  const [attachedBillableTableVisible, setAttachedBillableTableVisible] =
    useState(false);
  const [createBillableTableVisible, setCreateBillableTableVisible] =
    useState(false);
  const [collapse, setCollapse] = useState(true);

  const handleCreateBillableItem = (billableItem) => {
    const oldBillableItems = getValues("billableItems");

    const newBillableItems = [
      ...oldBillableItems,
      { id: billableItem.id, units: billableItem.defaultUnits },
    ];
    setValue("billableItems", newBillableItems);
    getBillableItems(getBillableItemsIds(newBillableItems));
  };

  useEffect(() => {
    if (isFetchBillable) {
      getBillableItems(getBillableItemsIds(defaultBillableItems));
    }
  }, [isFetchBillable]);

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="Attach Billable Items"
        visible={attachedBillableTableVisible}
        onHide={() => {
          setAttachedBillableTableVisible(false);
          setCollapse(true);
        }}
      >
        <AttachBillableItemForm
          handleSave={({ billableItems }) => {
            setValue("billableItems", billableItems);
            setAttachedBillableTableVisible(false);
            getBillableItems(getBillableItemsIds(billableItems));
          }}
          defaultValues={watch("billableItems")}
          handleCreateBillableItemClick={() => {
            setAttachedBillableTableVisible(false);
            setCreateBillableTableVisible(true);
            setCollapse(false);
          }}
          payload={getBillableItemsIds(getAllBillableItemsInRules())}
        >
          <Divider
            layout="horizontal"
            align="center"
            className="w-10/12 self-center"
          >
            <b>OR</b>
          </Divider>
          <div className="my-4 flex justify-center">
            <Button
              id="serviceBillables"
              label="Create new billable item"
              type="button"
              outlined
              onClick={() => {
                setAttachedBillableTableVisible(false);
                setCreateBillableTableVisible(true);
              }}
            />
          </div>

          <div className="flex justify-end p-4">
            <Button label="Save" />
          </div>
        </AttachBillableItemForm>
      </Dialog>

      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="create Billable Items"
        visible={createBillableTableVisible}
        onHide={() => {
          setCreateBillableTableVisible(false);
          setCollapse(true);
        }}
      >
        <CreateBillableItem
          setVisible={setCreateBillableTableVisible}
          handleCreateBillableItem={handleCreateBillableItem}
          collapse={collapse}
        />
      </Dialog>

      <div>
        <span className="flex items-baseline gap-2">
          <span className="font-medium capitalize leading-loose text-gray-500">
            default Billable Items
            <span className="ml-1 font-bold text-red-500">*</span>
          </span>
          <i
            className="custom-target-icon pi pi-info-circle cursor-pointer text-sm text-gray-400"
            data-pr-tooltip="These are the base charges that will be automatically added to
            the cart when this service is ordered. You can further
            customize charges based on order answers."
            data-pr-position="right"
          ></i>
        </span>
        <div className="flex flex-col gap-3 rounded-lg border border-slate-200 p-3">
          <div className="flex flex-col gap-3 sm:flex-row">
            <Button
              label="Attach billable items"
              type="button"
              onClick={() => {
                setAttachedBillableTableVisible(true);
              }}
              icon="pi pi-plus"
              className="bg-light-purple"
              id="serviceDefaultBillables"
            />
          </div>

          <SelectedBillableItemsTable
            isLoading={isLoading}
            getBillableItems={getBillableItems}
            selectedBillableItems={billableItems}
          />
        </div>
        {!watch("billableItems").length && (
          <p className="text-red-500">{errors?.billableItems?.root.message}</p>
        )}
      </div>
    </>
  );
};

export default DefaultBillableItems;
