import { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";

import useAxios from "../../../../hooks/useAxios";
import { NotifsForm } from "../../../../components/forms";
import { mapUserNotifsWithDetails } from "../../../../utils/notifsUtils";
import NotificationsSkeleton from "../../../../components/skeletons/NotificationsSkeleton";
import { showErrorToast } from "../../../../utils/apiUtils";

function SystemAdminNotifs() {
  const [adminNotifs, setAdminNotifs] = useState(null);

  const toast = useRef(null);
  const { http } = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    getUserNotifs();
  }, []);

  const getUserNotifs = async () => {
    try {
      const res = await http.get(`/Authentication/GetSystemAdminNotifications`);
      const notifications = mapUserNotifsWithDetails(res.data.notifications);
      const isOptedOut = res.data.isOptedOut;
      setAdminNotifs({ notifications, isOptedOut });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const onUserNotifsSubmit = async (formData) => {
    try {
      const res = await http.put(
        `/Authentication/UpdateSystemAdminNotifications`,
        formData,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 3000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const onCancel = () => {
    navigate("/admin/ourPractices");
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div>
            <span
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-slate-50"
              onClick={() => {
                navigate("/admin/ourPractices");
              }}
            >
              <i className="pi pi-arrow-left"></i>
            </span>
          </div>
          {adminNotifs?.notifications ? (
            adminNotifs?.notifications?.length > 0 && (
              <div className="flex flex-col gap-3 px-3">
                <div className="text-lg font-semibold capitalize">
                  system admin notifications
                </div>
                <NotifsForm
                  notifs={adminNotifs}
                  onSubmit={onUserNotifsSubmit}
                  onCancel={onCancel}
                />
              </div>
            )
          ) : (
            <NotificationsSkeleton />
          )}
        </div>
      </div>
    </div>
  );
}

export default SystemAdminNotifs;
