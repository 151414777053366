import React, { createContext, useContext, useState } from "react";

const BillableContext = createContext();

export const useBillableContext = () => useContext(BillableContext);

export const BillableProvider = ({ children }) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const addItem = (item) => {
    if (!selectedItems.some((i) => i.id === item.id)) {
      setSelectedItems((prevItems) => [...prevItems, { ...item, units: 1 }]);
    }
  };

  const removeItem = (itemId) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId),
    );
  };

  const updateUnits = (itemId, units) => {
    setSelectedItems((prevItems) =>
      prevItems.map((item) => (item.id === itemId ? { ...item, units } : item)),
    );
  };

  return (
    <BillableContext.Provider
      value={{ selectedItems, addItem, removeItem, updateUnits }}
    >
      {children}
    </BillableContext.Provider>
  );
};
