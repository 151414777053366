import { useCallback, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";

import useAxios from "../../../hooks/useAxios";
import { NotifsForm } from "../../../components/forms";
import { mapUserNotifsWithDetails } from "../../../utils/notifsUtils";
import NotificationsSkeleton from "../../../components/skeletons/NotificationsSkeleton";
import { showErrorToast } from "../../../utils/apiUtils";

function ManageUserNotifs() {
  const { http } = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [userNotifs, setUserNotifs] = useState(null);
  const [practiceNotifs, setPracticeNotifs] = useState(null);

  const toast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      await Promise.all([getUserNotifs(), getPracticeSpecificNotifs()]);
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getUserNotifs = async () => {
    const res = await http.get(`/Users/GetUserNotifications`);
    const notifications = mapUserNotifsWithDetails(res.data.notifications);
    const isOptedOut = res.data.isOptedOut;
    setUserNotifs({ notifications, isOptedOut });
  };

  const getPracticeSpecificNotifs = async () => {
    const res = await http.get(
      `/EntityUser/GetUserPracticeNotifications/00000000-0000-0000-0000-000000000000`,
    );
    const notifications = mapUserNotifsWithDetails(res.data.notifications);
    const isOptedOut = res.data.isOptedOut;
    setPracticeNotifs({ notifications, isOptedOut });
  };

  const onUserNotifsSubmit = async (formData) => {
    try {
      const res = await http.put(`/Users/UpdateUserNotifications`, formData);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 3000,
      });
      await getUserNotifs();
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const onPracticeNotifsSubmit = async (formData) => {
    try {
      const res = await http.put(
        `/EntityUser/UpdateUserPracticeNotifications/00000000-0000-0000-0000-000000000000`,
        formData,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: res.data.message,
        life: 3000,
      });
      await getPracticeSpecificNotifs();
    } catch (error) {
      showErrorToast({ error });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="flex w-full items-center justify-center py-4 sm:px-8">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-text p-8 shadow shadow-slate-300">
          <div>
            <span
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full hover:bg-slate-50"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="pi pi-arrow-left"></i>
            </span>
          </div>
          {isLoading ? (
            <NotificationsSkeleton />
          ) : (
            <>
              {userNotifs?.notifications?.length > 0 && (
                <div className="flex flex-col gap-3 px-3">
                  <div className="text-lg font-semibold">
                    General Notifications
                  </div>
                  <NotifsForm
                    notifs={userNotifs}
                    onSubmit={onUserNotifsSubmit}
                    cancelLabel="Reset"
                  />
                </div>
              )}
              {practiceNotifs?.notifications?.length > 0 && (
                <div className="flex flex-col gap-3 px-3">
                  <div className="text-lg font-semibold">
                    In-Practice Notifications
                  </div>
                  <NotifsForm
                    notifs={practiceNotifs}
                    onSubmit={onPracticeNotifsSubmit}
                    cancelLabel="Reset"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageUserNotifs;
