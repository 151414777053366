import { Skeleton } from "primereact/skeleton";
import useHandleResize from "../../hooks/useHandleResize";

function StepsSkeleton() {
  const {isMobile} = useHandleResize()
  return (
    <div className="mb-4 flex justify-between gap-2 p-auto md:px-6">
      {[...Array(6)].map((_, index) => (
        <div key={index} className="flex flex-col items-center gap-2 md:gap-0">
          <Skeleton shape="circle" size={isMobile ? "2rem" : "3rem"} />
          <Skeleton  width={isMobile ? "2rem" : "5rem"} height="1rem" />
        </div>
      ))}
    </div>
  );
}

export default StepsSkeleton;
