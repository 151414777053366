import React from "react";
import DisplayBillableItems from "./DisplayBillableItems";
import { Divider } from "primereact/divider";
import {
  getMatchedDataWithUnits,
  getSwapCPTAction,
} from "../../../utils/utils";

function DisplaySwapBillableItems({ rulesGroup, dataOfAllBillableItems }) {
  const { old: oldItems, new: newItems } = getSwapCPTAction(rulesGroup);

  const newBillableData = getMatchedDataWithUnits(
    dataOfAllBillableItems,
    newItems,
  );

  const oldBillableData = getMatchedDataWithUnits(
    dataOfAllBillableItems,
    oldItems,
  );

  return (
    <>
      <DisplayBillableItems dataOfAllBillableItems={oldBillableData} />
      <Divider align="center" layout="horizontal">
        New
      </Divider>
      <DisplayBillableItems
        showUnits
        dataOfAllBillableItems={newBillableData}
      />
    </>
  );
}

export default DisplaySwapBillableItems;
