import React from "react";

const NoServicesFound = () => {
  return (
    <p className="mb-2 mt-1 rounded border-b-2 bg-white p-2 text-gray-400">
      No results found
    </p>
  );
};

export default NoServicesFound;
