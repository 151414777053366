import { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import useAxios from "../../hooks/useAxios";
import { useLayoutToast } from "../../store/store";
import useTokenData from "../../hooks/useTokenData";
import { STAFF_ROLES } from "../../constants/constants";
import { showErrorToast } from "../../utils/apiUtils";
import { Tooltip } from "primereact/tooltip";

const DEFAULT_STAFF = {
  staffEmail: "",
  staffRole: STAFF_ROLES[0].value,
  isProvider: false,
};

const InviteStaffForm = ({ isOrderingMode, setRefetchData }) => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { layoutToast } = useLayoutToast();

  const [tags, setTags] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputTag, setInputTag] = useState(DEFAULT_STAFF);
  const [loadingSendInvitation, setLoadingSendInvitation] = useState(false);

  const validateFormat = (tag) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(tag);
  };

  const validateEmail = (email) => {
    if (!email.length) {
      setErrorMessage("Email field is empty.");
      return false;
    } else if (!validateFormat(email)) {
      setErrorMessage("This email is invalid.");
      return false;
    } else if (tags.length && tags.some((tag) => tag.staffEmail === email)) {
      setErrorMessage("This email is already added.");
      return false;
    }
    return true;
  };

  const addNewTag = () => {
    const email = inputTag.staffEmail.trim();

    if (validateEmail(email)) {
      setErrorMessage("");
      setInputTag({ ...inputTag, staffEmail: "", isProvider: false });
      setTags([...tags, inputTag]);
    }
  };

  const handleButtonClick = async () => {
    let allTags = tags;

    if (inputTag.staffEmail.length) {
      validateEmail(inputTag.staffEmail) && allTags.push(inputTag);
    }
    setLoadingSendInvitation(true);
    if (allTags.length === 0) {
      setErrorMessage("Email is required");
      setLoadingSendInvitation(false);
      return;
    } else if (allTags.length > 0) {
      const invalidTags = allTags.filter(
        (tag) => !validateFormat(tag.staffEmail),
      );
      if (invalidTags.length > 0) {
        setErrorMessage("Invalid email format");
        setLoadingSendInvitation(false);
        return;
      } else {
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
    }
    try {
      setLoadingSendInvitation(true);
      const response = await http.post(`/Entities/InviteStaff/${entityId}`, {
        invitations: allTags,
      });
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message || "Invitation Sent Successfully",
        life: 4000,
      });
      setRefetchData((prev) => !prev);
    } catch (error) {
      showErrorToast({ error });
    }
    setTags([]);
    setInputTag(DEFAULT_STAFF);
    setLoadingSendInvitation(false);
  };
  const ItemTemplate = (option) => {
    const tooltipRef = useRef();
    return (
      <>
        <Tooltip target={tooltipRef} className="max-w-sm text-xs">
          <div className="flex flex-col gap-1">
            <span className="text-xl">{option.label}</span>
            <span>{option.tooltip}</span>
          </div>
        </Tooltip>
        <div className="flex items-center justify-between gap-2">
          <span>{option.label}</span>
          <i ref={tooltipRef} className={`${option.icon} mr-2`}></i>
        </div>
      </>
    );
  };
  return (
    <div className="w-full gap-y-2 rounded-xl border-[.14rem] border-solid border-light-purple bg-white p-4">
      <div className="flex w-full flex-wrap items-center justify-between md:w-10/12">
        <div className="basis-3/6 md:basis-2/6">
          <InputText
            keyfilter="email"
            placeholder="Email"
            name="staffEmail"
            value={inputTag.staffEmail}
            onKeyDown={(e) => e.key === "Enter" && addNewTag()}
            onChange={(e) => {
              setInputTag({
                ...inputTag,
                [e.target.name]: e.target.value,
              });
            }}
            className={`w-11/12 ${errorMessage ? "border-2 border-red-600" : null}`}
          />
        </div>
        <div className="basis-3/6 md:basis-2/6">
          <Dropdown
            value={inputTag.staffRole}
            options={STAFF_ROLES}
            name="staffRole"
            onChange={(e) => {
              setInputTag({
                ...inputTag,
                isProvider: false,
                [e.target.name]: e.target.value,
              });
            }}
            itemTemplate={ItemTemplate}
            optionLabel="label"
            optionValue="value"
            placeholder="Select Role"
            className="w-full md:w-11/12"
          />
        </div>
        <div className="mt-4 md:my-auto md:mr-auto">
          {isOrderingMode && (
            <>
              <Checkbox
                inputId="isProvider"
                name="isProvider"
                value={inputTag.isProvider}
                checked={inputTag.isProvider}
                onChange={(e) => {
                  setInputTag({
                    ...inputTag,
                    [e.target.name]: e.target.checked,
                  });
                }}
                className="pb-5"
              />
              <label
                htmlFor="Provider"
                className="ml-2 font-semibold text-normal-text"
              >
                Invite as a provider&nbsp;
              </label>
            </>
          )}
        </div>
      </div>
      <div className="px-3 py-1">
        <span className="text-sm text-red-500">{errorMessage}</span>
      </div>
      <div className="mb-4 w-9/12">
        {tags.length > 0 && (
          <div className="col-span-12 flex flex-wrap items-center gap-3 bg-white p-4 md:col-span-9 md:rounded-lg md:border md:border-slate-300 md:bg-slate-50">
            &nbsp;
            {tags.map((tag, index) => (
              <div
                key={index}
                className="flex items-center gap-2 rounded-full bg-white px-4 py-1 shadow-md"
              >
                <span>{tag.staffEmail} </span>
                <span className="rounded-full bg-slate-200 px-2 py-1 text-sm">
                  {
                    STAFF_ROLES.find((role) => role.value === tag.staffRole)
                      .label
                  }
                </span>
                <Button
                  icon="pi pi-times"
                  rounded
                  text
                  size="small"
                  severity="danger"
                  aria-label="Cancel"
                  onClick={() => {
                    setTags(tags.filter((tag, idx) => idx !== index));
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-4 lg:flex-row lg:justify-end">
        <div className="">
          <Button
            icon="pi pi-plus"
            label="Add New Email"
            onClick={addNewTag}
            outlined
            className="w-full bg-white"
          />
        </div>
        <div className="">
          <Button
            type="submit"
            className="w-full bg-light-purple"
            onClick={handleButtonClick}
            disabled={loadingSendInvitation}
            loading={loadingSendInvitation}
            icon="pi pi-send"
            label={`Send Invitation${tags.length > 1 ? "s" : ""}`}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default InviteStaffForm;
