import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

import image from "../../../../images/Medx@2x.png";
import { convertToFeetAndInches } from "../../../../utils/helpers";
import {
  DEFAULT_QUESTIONS_IDS,
  NUMBER_TYPE_ENUM,
  QUESTION_TYPES_ENUM,
} from "../../../rendering/services/my-services/manage-service/utils/constants";
import { convertStandardToWeight } from "../../../rendering/services/my-services/manage-service/utils/numericConversions";

export const PatientInfoPDF = ({
  Fname,
  Lname,
  phone,
  email,
  ZipCode,
  birthDate,
  AddressLineTwo,
  AddressLineOne,
  State,
  City,
  gender,
  orderData,
  diagnosis,
  notes,
  questionsData,
  uniqueQuestions,
  billableItems,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 20,
      margin: 20,
      borderWidth: 1,
      borderColor: "#e0e0e0",
      borderRadius: 5,
      height: "95%",
      position: "relative",
    },
    image: {
      width: 60,
      height: 60,
      objectFit: "contain",
      marginVertical: 10,
      alignSelf: "center",
    },
    sectionTitle: {
      fontWeight: "bold",
      fontSize: 22,
      padding: 10,
      textAlign: "center",
      color: "#434dc4",
      borderBottomWidth: 1,
      borderBottomColor: "#e0e0e0",
      marginBottom: 10,
    },
    patientTitle: {
      fontWeight: "bold",
      fontSize: 22,
      padding: 10,
      textAlign: "center",
      color: "#434dc4",
      borderBottomWidth: 1,
      borderBottomColor: "#e0e0e0",
      marginBottom: 10,
      marginTop: 150,
    },
    groupTitle: {
      fontWeight: "bold",
      fontSize: 22,
      padding: 10,
      textAlign: "center",
      color: "#434dc4",
      borderBottomWidth: 1,
      borderBottomColor: "#e0e0e0",
      marginBottom: 20,
    },
    textRow: {
      marginVertical: 5,
      fontSize: 14,
      color: "#000000",
      marginBottom: 15,
      fontWeight: "800",
    },
    textValue: {
      fontWeight: "600",
      color: "#666666",
    },
    billableSection: {
      paddingVertical: 10,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingVertical: 5,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "55%",
    },
    section: {
      marginBottom: 20,
    },
    separator: {
      borderBottomWidth: 1,
      borderBottomColor: "#e0e0e0",
      marginVertical: 10,
    },
    footer: {
      position: "absolute",
      bottom: 7,
      left: 20,
      right: 20,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      fontSize: 8,
      color: "#666666",
    },
    notesText: {
      marginVertical: 10,
      fontSize: 14,
      lineHeight: 1.5,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 10,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableCol: {
      width: "30%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColUnit: {
      width: "10%",
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableCellHeader: {
      margin: 5,
      fontSize: 12,
      fontWeight: "bold",
      color: "#000",
    },
    tableCell: {
      margin: 5,
      fontSize: 10,
      color: "#666666",
    },
    bulletPoint: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 20,
      marginTop: 10,
    },
    bulletPointText: {
      fontSize: 14,
      marginLeft: 5,
      color: "#666666",
    },
  });

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  // TODO: use reduce instead of map & remove filter
  const matchedData = questionsData.map((question) => {
    const answers = uniqueQuestions[question.id];
    if (!answers) return null;
    const isDropdown =
      question?.type === QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value ||
      question?.type === QUESTION_TYPES_ENUM.DROPDOWN.value;
    const isBoolean = question?.type === QUESTION_TYPES_ENUM.BOOLEAN.value;

    const isAmerican =
      question?.constraints?.type === NUMBER_TYPE_ENUM.AMERICAN.value;
    const isWeight =
      question?.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;

    return {
      id: question.id,
      label: question ? question.label : `Unknown Question (${question.id})`,
      type: question ? question?.constraints?.type : "Unknown Type",
      answers:
        answers.length <= 0
          ? ["Not Answered"]
          : answers.map((answer) => {
              let answerValue = answer?.value;
              if (
                answerValue === "" ||
                answerValue === null ||
                answerValue === undefined
              ) {
                answerValue = "Not Answered";
              } else if (isBoolean) {
                answerValue = answer.value === true ? "Yes" : "No";
              } else if (isDropdown) {
                answerValue = question.answers.find(
                  (ans) => ans.id === answer.value,
                )?.value;
              } else if (isAmerican) {
                answerValue = convertToFeetAndInches(answer.value);
              } else if (isWeight) {
                const value = convertStandardToWeight(
                  answer.value,
                  question.constraints.storeAs,
                );
                answerValue = `${value} ${question.constraints.storeAs}`;
              }
              return answerValue;
            }),
    };
  });

  const chunkedPatientAnswers = chunkArray(matchedData.filter(Boolean), 5);
  const chunkedBillables = chunkArray(billableItems, 17);
  const chunkedDiagnoses = chunkArray(diagnosis, 17);
  const totalNumberOfPages =
    2 +
    chunkedDiagnoses.length +
    chunkedBillables.length +
    chunkedPatientAnswers.length;

  const attributeTypeMap = {
    1: "None",
    2: "NDC",
    3: "Supply Id",
    4: "Provider Type",
  };

  const Footer = ({ pageNumber, totalPages }) => (
    <View style={styles.footer}>
      <Text>Order Number:</Text>
      <Text>
        Page {pageNumber} of {totalPages}
      </Text>
    </View>
  );

  let currentPageNumber = 1;

  return (
    <Document>
      <Page size="A4" style={styles.section}>
        <View style={styles.page}>
          <Image style={styles.image} source={image} />
          <Text style={styles.sectionTitle}>Order Information</Text>

          <View>
            <Text style={[styles.textRow, { opacity: 0 }]}>Order Number:</Text>
            <Text style={styles.textRow}>
              Service Name:{" "}
              <Text style={styles.textValue}>{orderData.name}</Text>
            </Text>
            <Text style={styles.textRow}>
              Service Description:{" "}
              <Text style={styles.textValue}>{orderData.description}</Text>
            </Text>
          </View>

          <Text style={styles.patientTitle}>Patient Information</Text>

          <View style={styles.column}>
            <Text style={styles.textRow}>
              Full Name:{" "}
              <Text style={styles.textValue}>
                {Fname} {Lname}
              </Text>
            </Text>
            <Text style={styles.textRow}>
              Phone Number: <Text style={styles.textValue}>{phone}</Text>
            </Text>
            <Text style={styles.textRow}>
              Email Address: <Text style={styles.textValue}>{email}</Text>
            </Text>
            <Text style={styles.textRow}>
              Date of Birth: <Text style={styles.textValue}>{birthDate}</Text>
            </Text>
            <Text style={styles.textRow}>
              Age:{" "}
              <Text style={styles.textValue}>
                {uniqueQuestions[DEFAULT_QUESTIONS_IDS.AGE][0].value}
              </Text>
            </Text>
            <Text style={styles.textRow}>
              Gender: <Text style={styles.textValue}>{gender}</Text>
            </Text>
            <Text style={styles.textRow}>
              Address:{" "}
              <Text style={styles.textValue}>
                {AddressLineOne}
                {AddressLineTwo ? `\n` : ""}
                {AddressLineTwo ? `\n${AddressLineTwo}` : ""}
                {`\n`}
                {`\n${City}, ${State} ${ZipCode}`}
              </Text>
            </Text>
          </View>
        </View>
        <Footer
          pageNumber={currentPageNumber++}
          totalPages={totalNumberOfPages}
        />
      </Page>

      {chunkedDiagnoses.map((diagnosesChunk, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.section}>
          <View style={styles.page}>
            <Image style={styles.image} source={image} />
            <Text style={styles.groupTitle}>Diagnosis</Text>
            {diagnosesChunk.map((diag, index) => (
              <View key={`${pageIndex} ${index}`} style={styles.bulletPoint}>
                <Text style={styles.bulletPointText}>
                  • {diag.code} - {diag.label}
                </Text>
              </View>
            ))}
          </View>
          <Footer
            pageNumber={currentPageNumber++}
            totalPages={totalNumberOfPages}
          />
        </Page>
      ))}

      {chunkedBillables.map((billablesChunk, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.section}>
          <View style={styles.page}>
            <Image style={styles.image} source={image} />
            <Text style={styles.groupTitle}>Service Billable Items</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>HCPCS</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Description</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellHeader}>Attribute</Text>
                </View>
                <View style={styles.tableColUnit}>
                  <Text style={styles.tableCellHeader}>Units</Text>
                </View>
              </View>
              {billablesChunk.map((billable, index) => (
                <View key={`${pageIndex} ${index}`} style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {billable.hcpcs}
                      {[
                        billable.mod1,
                        billable.mod2,
                        billable.mod3,
                        billable.mod4,
                      ].filter(Boolean).length > 0
                        ? ` - ${[billable.mod1, billable.mod2, billable.mod3, billable.mod4].filter(Boolean).join(" ")}`
                        : ""}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {billable.shortDescription}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {attributeTypeMap[+billable.attributeType]}
                      {billable.attributeTypeValue ? ":" : ""}{" "}
                      {billable.attributeTypeValue}
                    </Text>
                  </View>
                  <View style={styles.tableColUnit}>
                    <Text style={styles.tableCell}>{billable.units}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <Footer
            pageNumber={currentPageNumber++}
            totalPages={totalNumberOfPages}
          />
        </Page>
      ))}
      {chunkedPatientAnswers.map((patientAnswersChunk, pageIndex) => (
        <Page key={pageIndex} size="A4" style={styles.section}>
          <View style={styles.page}>
            <Image style={styles.image} source={image} />
            <Text style={styles.groupTitle}>Patient Answers</Text>
            {patientAnswersChunk.map((question, index) => (
              <View
                key={`${pageIndex} ${index}`}
                style={styles.billableSection}
              >
                <Text style={styles.textRow}>
                  Question:{" "}
                  <Text style={styles.textValue}>{question.label}</Text>
                </Text>
                {question.answers.map((answer, subIndex) => {
                  return (
                    <Text
                      key={`${pageIndex} ${index} ${subIndex}`}
                      style={styles.textRow}
                    >
                      Answer: <Text style={styles.textValue}>{answer}</Text>
                    </Text>
                  );
                })}
                {index < patientAnswersChunk.length - 1 && (
                  <View style={styles.separator} />
                )}
              </View>
            ))}
          </View>
          <Footer
            pageNumber={currentPageNumber++}
            totalPages={totalNumberOfPages}
          />
        </Page>
      ))}

      {
        <Page size="A4" style={styles.section}>
          <View style={styles.page}>
            <Image style={styles.image} source={image} />
            <Text style={styles.sectionTitle}>Notes</Text>
            <Text style={styles.notesText}>
              {notes ? notes : "No notes provided."}
            </Text>
          </View>
          <Footer
            pageNumber={currentPageNumber++}
            totalPages={totalNumberOfPages}
          />
        </Page>
      }
    </Document>
  );
};
