import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { useEffect, useLayoutEffect } from "react";
import { ModTemplate } from "../../../templates/ModTemplate";
import { PriceBillableTemplate } from "../../../templates/PriceBillableTemplate";
import { OPERATORS } from "../../../utils/constants";
import DescriptionWithTooltipTemplate from "../../rules/DescriptionWithTooltipTemplate";
import useFetchBillablesByIds from "../../rules/useFetchBillablesByIds";
import StandardNumericInput from "../StandardNumericInput";
import { BillableItemsAttributesTemplate } from "../../../templates/BillableItemsAttributesTemplate";

const SelectedBillableItems = ({
  inputNamePath,
  tableInputNamePath,
  question,
  comparisonOperator,
  unitsColumn,
}) => {
  const { control, watch } = useFormContext();
  const selectedBillableItemId = watch(tableInputNamePath);

  const { isLoading, billableItems, getBillableItems } = useFetchBillablesByIds(
    [selectedBillableItemId],
  );
  useEffect(() => {
    selectedBillableItemId && getBillableItems([selectedBillableItemId]);
  }, [selectedBillableItemId]);

  const COLUMNS = [
    { header: "HCPCS", field: "hcpcs" },
    {
      header: "Description",
      body: DescriptionWithTooltipTemplate,
    },
    {
      header: "Mods",
      body: ModTemplate,
    },
    {
      header: "Attributes",
      body: BillableItemsAttributesTemplate,
    },
    {
      header: "Price per unit",
      body: PriceBillableTemplate,
    },
  ];

  return (
    <Controller
      name={tableInputNamePath}
      control={control}
      rules={{ required: "Select a billable item." }}
      render={({ fieldState: { error } }) => (
        <>
          <DataTable
            loading={isLoading}
            value={selectedBillableItemId ? billableItems : null}
          >
            {COLUMNS.map((column) => (
              <Column
                key={column.header}
                {...column}
                align="center"
                alignHeader="center"
              />
            ))}
            {unitsColumn.show && (
              <Column
                header={unitsColumn?.header}
                body={(e) => (
                  <UnitsTemplate
                    rowData={e}
                    inputNamePath={inputNamePath}
                    question={question}
                    comparisonOperator={comparisonOperator}
                  />
                )}
              />
            )}
          </DataTable>
          {error && <p className="text-red-500">{error.message}</p>}
        </>
      )}
    />
  );
};

export default SelectedBillableItems;

const UnitsTemplate = ({
  rowData,
  inputNamePath,
  question,
  comparisonOperator,
}) => {
  const { control, getValues, trigger } = useFormContext();

  if (comparisonOperator === OPERATORS.BETWEEN.value) {
    return (
      <div className="flex items-center gap-4">
        <Controller
          name={`${inputNamePath}.min`}
          control={control}
          rules={{
            required: "Please enter a valid number",
            validate: {
              minMaxComparison: () => {
                const comparisonValue = getValues(`${inputNamePath}.max`);
                if (comparisonValue) trigger(`${inputNamePath}.max`);
              },
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <div className="flex flex-col gap-2 md:items-center">
              <label htmlFor={field.name} className="font-semibold">
                Minimum
              </label>
              <div className="flex-1">
                <StandardNumericInput
                  field={field}
                  error={error}
                  questionType={question?.type}
                />
                {error && (
                  <p className="text-xs text-red-500">{error.message}</p>
                )}
              </div>
            </div>
          )}
        />
        <Controller
          name={`${inputNamePath}.max`}
          control={control}
          rules={{
            required: "Please enter a valid number",
            validate: {
              minMaxComparison: (value) => {
                let condition = true;
                const comparisonValue = getValues(`${inputNamePath}.min`);
                if (comparisonValue) condition = value > comparisonValue;
                return (
                  condition || "Minimum value must be less than maximum value."
                );
              },
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <div className="flex flex-col gap-2 md:items-center">
              <label htmlFor={field.name} className="font-semibold">
                Maximum
              </label>
              <div className="flex-1">
                <StandardNumericInput
                  field={field}
                  error={error}
                  questionType={question?.type}
                />
                {error && (
                  <p className="text-xs text-red-500">{error.message}</p>
                )}
              </div>
            </div>
          )}
        />
      </div>
    );
  } else {
    return (
      <Controller
        name={`${inputNamePath}.units`}
        control={control}
        rules={{
          required: "Please enter a valid number",
        }}
        render={({ field, fieldState: { error } }) => (
          <div>
            <StandardNumericInput
              field={field}
              error={error}
              questionType={question?.type}
            />
            {error && <p className="text-xs text-red-500">{error.message}</p>}
          </div>
        )}
      />
    );
  }
};
