import { RadioButton } from "primereact/radiobutton";
import { Controller, useFormContext } from "react-hook-form";

const isBoolean = (val) => "boolean" === typeof val;

const DisplayBoolean = ({ question }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={`${question.id}.0.value`}
      control={control}
      defaultValue=""
      rules={{
        validate: (value) =>
          question?.required && !isBoolean(value)
            ? "Question is required"
            : true,
      }}
      render={({ field, fieldState: { error } }) => (
        <>
          <div className="flex gap-5 text-gray-500">
            <label className="cursor-pointer">
              <RadioButton
                type="radio"
                value={true}
                id="yes-question-name"
                checked={field.value === true}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="mr-1"
              />
              Yes
            </label>
            <label className="cursor-pointer">
              <RadioButton
                type="radio"
                value={false}
                id="No-question-name"
                checked={field.value === false}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                className="mr-1"
              />
              No
            </label>
          </div>
          {error && <p className="text-sm text-red-500">{error.message}</p>}
        </>
      )}
    />
  );
};

export default DisplayBoolean;
