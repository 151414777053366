import { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Skeleton } from "primereact/skeleton";
import { ConfirmDialog } from "primereact/confirmdialog";

import useAxios from "../../hooks/useAxios";
import { useBlockNavigation } from "../../store/store";
import { calculateAge } from "../../utils/serviceUtils";
import { RenderField } from "../../pages/rendering/services/my-services/manage-service/components/RenderField";
import {
  ACTIONS_ENUM,
  DEFAULT_QUESTIONS,
  DEFAULT_QUESTIONS_IDS,
  GENDER_ANSWER_OPTIONS,
} from "../../pages/rendering/services/my-services/manage-service/utils/constants";
import { checkConditionLogicalOperator } from "../../pages/rendering/services/my-services/manage-service/utils/utils";
import { getBillableItemsIds } from "../../pages/rendering/services/my-services/manage-service/components/rules/actions/helpers";
import useFetchBillablesByIds from "../../pages/rendering/services/my-services/manage-service/components/rules/useFetchBillablesByIds";
import { aggregateResources } from "../../pages/ordering/orderService/utils/aggregateResources";
import { showErrorToast } from "../../utils/apiUtils";

function RequestService({
  submitRef,
  serviceId,
  isTesting = false,
  provider,
  questions,
  patientInfo,
  serviceInfo,
  setActiveIndex,
  submittedResponses,
  setSubmittedResponses,
}) {
  const navigate = useNavigate();
  const { http } = useAxios();
  const { getBillableItems } = useFetchBillablesByIds();
  const [abortDialogData, setAbortDialogData] = useState();
  const [isTechDataLoading, setIsTechDataLoading] = useState(false);
  const [abortDialogVisible, setAbortDialogVisible] = useState(false);
  const genderId = GENDER_ANSWER_OPTIONS.find(
    (opt) => opt.value === patientInfo.gender,
  ).id;

  const patientAddressIndex = DEFAULT_QUESTIONS.findIndex(
    (q) => q.id === DEFAULT_QUESTIONS_IDS.ADDRESS,
  );

  const stateId = DEFAULT_QUESTIONS[patientAddressIndex].answers.find(
    (ans) => ans.value === patientInfo.State,
  ).id;

  const formatName = ({ firstName, middleName, lastName, fullName }) => {
    return firstName
      ? `${lastName}, ${firstName}${middleName ? ` ${middleName}` : ""}`
      : fullName;
  };

  const providerNPI = isTesting ? patientInfo?.providerNPI : provider?.npi;
  const providerName = isTesting
    ? patientInfo?.providerName
    : formatName(provider);

  const defaultValues = {
    // default answers from patient info goes here
    [DEFAULT_QUESTIONS_IDS.GENDER]: [{ value: genderId }],
    [DEFAULT_QUESTIONS_IDS.AGE]: [{ value: calculateAge(patientInfo.birth) }],
    [DEFAULT_QUESTIONS_IDS.ADDRESS]: [{ value: stateId }],
    [DEFAULT_QUESTIONS_IDS.ZIP_CODE]: [{ value: patientInfo.ZipCode }],
    [DEFAULT_QUESTIONS_IDS.PROVIDER_NPI]: [{ value: providerNPI }],
    [DEFAULT_QUESTIONS_IDS.PROVIDER_NAME]: [{ value: providerName }],
  };

  const methods = useForm({ mode: "all", defaultValues });

  const { handleSubmit, watch, reset } = methods;

  useEffect(() => {
    if (submittedResponses && Object.keys(submittedResponses).length) {
      reset(submittedResponses);
    }
  }, []);

  const { setBlockNavigation } = useBlockNavigation();

  const getTechData = async () => {
    setIsTechDataLoading(true);
    try {
      const response = await http.get(
        `/Entities/GetEntityTechnicalData/${serviceId}`,
      );
      setAbortDialogData((prev) => ({ ...prev, techData: response?.data }));
    } catch (error) {
      showErrorToast({ error });
    }
    setIsTechDataLoading(false);
  };

  const openAbortDialog = (abortMessage) => {
    setBlockNavigation(false);
    setAbortDialogData((prev) => ({
      ...prev,
      abortMessage: abortMessage,
    }));
    setAbortDialogVisible(true);
    serviceId && getTechData();
  };

  const getServiceBillableItems = async (serviceId) => {
    try {
      const response = await http.get(
        `/BillableItems/GetBillableItemsForOrderingProviders/${serviceId}`,
      );
      return response.data;
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const getTestingServiceBillableItems = (serviceData, rules) => {
    const defaultBillableItemIds = getBillableItemsIds(
      serviceData?.billableItems,
    );

    const billableItemIdsSet = new Set(defaultBillableItemIds);

    rules.forEach((rule) => {
      if (rule.action.key === ACTIONS_ENUM.ADD_CPT.value) {
        rule.action.on.forEach((BI) => billableItemIdsSet.add(BI.id));
      } else if (rule.action.key === ACTIONS_ENUM.SWAP_CPT.value) {
        rule.action.on.new.forEach((BI) => billableItemIdsSet.add(BI.id));
      }
    });
    const billableItems = getBillableItems([...billableItemIdsSet]);

    return billableItems;
  };
  const onSubmit = async (data) => {
    const rules = serviceInfo.rulesGroups;
    const billableItemsWithFullData = isTesting
      ? await getTestingServiceBillableItems(serviceInfo, rules)
      : await getServiceBillableItems(serviceId);

    const filteredQuestions = Object.fromEntries(
      Object.entries(data)?.filter(([key, value]) => value),
    );
    const result = aggregateResources(
      rules,
      billableItemsWithFullData,
      filteredQuestions,
      serviceInfo,
    );

    const isAbort = result?.abortMessages?.length > 0;

    if (isAbort) {
      openAbortDialog(result.abortMessages[0]);
      return;
    }

    setSubmittedResponses(filteredQuestions);

    !isTesting && setActiveIndex((old) => old + 1);
  };

  const abortDialogFooter = (
    <div className="flex items-center justify-end gap-1">
      <Button
        label="Edit My Answers"
        icon="pi pi-pencil"
        severity="info"
        outlined
        onClick={() => {
          setBlockNavigation(true);
          setAbortDialogVisible(false);
        }}
      />
      <Button
        label="Cancel and Exit"
        icon="pi pi-times"
        onClick={() => {
          setBlockNavigation(true);
          setAbortDialogVisible(false);
          if (isTesting) return;
          navigate("/ordering/FindServices");
        }}
        autoFocus
      />
    </div>
  );

  const visibleQuestionsCount = useMemo(
    () =>
      questions.filter(
        (question) =>
          !question.isConditional ||
          checkConditionLogicalOperator(
            question.conditionalOperator,
            question.conditions,
            watch(),
          ),
      ).length,
    [],
  );

  useEffect(() => {
    if (!visibleQuestionsCount) onSubmit(watch());
  }, []);

  return (
    <>
      {!isTesting && <ConfirmDialog />}
      <Dialog
        visible={abortDialogVisible}
        header="Oops! It looks like there's an issue with your order."
        footer={abortDialogFooter}
        className="w-[50vw]"
        closable={false}
      >
        <div className="flex flex-col gap-8">
          <p className="text-lg font-semibold">
            We're sorry, but it seems that your order doesn't meet the necessary
            requirements at this time.
          </p>
          <Editor
            id="abort-message"
            value={abortDialogData?.abortMessage}
            readOnly
            headerTemplate={
              <span>
                <i className="pi pi-info-circle mr-2 text-red-400"></i>
                Provider Message
              </span>
            }
            style={{ height: "120px" }}
          />
          {isTechDataLoading ? (
            <div className="flex flex-col gap-2">
              <Skeleton width="70%" height="24px" />
              <div className="grid grid-cols-3 gap-4">
                <Skeleton width="100%" height="24px" />
                <Skeleton width="100%" height="24px" />
                <Skeleton width="100%" height="24px" />
              </div>
            </div>
          ) : (
            abortDialogData?.techData && (
              <div className="flex flex-col">
                <p className="font-semibold">
                  For any questions or assistance, please contact our technical
                  support:
                </p>
                <div className="flex flex-wrap items-center gap-6 pt-1">
                  {abortDialogData?.techData?.technicalSupportName && (
                    <div className="flex items-center gap-2">
                      <i className="pi pi-user" />
                      <span className="text-md italic text-gray-500">
                        {abortDialogData?.techData?.technicalSupportName}
                      </span>
                    </div>
                  )}
                  {abortDialogData?.techData?.technicalSupportPhoneNumber && (
                    <div className="flex items-center gap-2">
                      <i className="pi pi-phone text-light-purple" />
                      <span className="text-md italic text-gray-500">
                        {abortDialogData?.techData?.technicalSupportPhoneNumber}
                      </span>
                    </div>
                  )}
                  {abortDialogData?.techData?.technicalSupportEmail && (
                    <div className="flex items-center gap-2">
                      <i className="pi pi-envelope text-blue-500" />
                      <span className="text-md italic text-gray-500">
                        {abortDialogData?.techData?.technicalSupportEmail}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </Dialog>
      <FormProvider {...methods}>
        <form
          ref={submitRef}
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-2"
        >
          {visibleQuestionsCount > 0 ? (
            questions.map((question) => (
              <RenderField key={question.id} question={question} />
            ))
          ) : (
            <p className="text-center text-gray-500">
              There are no questions required at this stage. <br />
              Click 'Next' to proceed with your request.
            </p>
          )}
        </form>
      </FormProvider>
    </>
  );
}

export default RequestService;
