import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";

import WeightInput from "./formElements/WeightInput";
import AmericanNumericInput from "./formElements/AmericanNumericInput";
import StandardNumericInput from "./formElements/StandardNumericInput";
import {
  NUMBER_TYPE_ENUM,
  NUMBER_TYPE_LIST,
  WEIGHT_OPTIONS_ENUM,
} from "../utils/constants";

const NumericConstraints = () => {
  const { control, watch, setValue, trigger, clearErrors } = useFormContext();

  const resetConstraintValues = (setValue) => {
    setValue("constraints.min.value", null);
    setValue("constraints.max.value", null);
    setValue("constraints.min.force", "");
    setValue("constraints.max.force", "");
    setValue("constraints.captureIn", WEIGHT_OPTIONS_ENUM.LBS);
    setValue("constraints.storeAs", WEIGHT_OPTIONS_ENUM.LBS);
    clearErrors("constraints");
  };

  const isStandard =
    watch("constraints.type") === NUMBER_TYPE_ENUM.STANDARD.value;
  const isWeight = watch("constraints.type") === NUMBER_TYPE_ENUM.WEIGHT.value;

  return (
    <div className="p-field">
      <div className="flex items-center gap-2">
        <span className="text-gray-600">Numeric Settings</span>
        <span className="text-gray-500">
          <i
            className="custom-question-tooltip pi pi-info-circle text-xl"
            data-pr-tooltip="This settings will only appear if you chose a number-type question"
            data-pr-position="right"
            style={{ cursor: "pointer" }}
          ></i>
        </span>
      </div>
      <div className="my-4 flex flex-col gap-4 rounded-lg border border-slate-200 px-6 py-4">
        <Controller
          name="constraints.type"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div className="grid grid-cols-12 items-center gap-2 md:grid-cols-12">
              <label
                htmlFor={field.name}
                className="col-span-12 font-semibold md:col-span-3"
              >
                Display type
              </label>
              <div className="col-span-12 flex flex-col gap-2 md:col-span-4">
                <Dropdown
                  {...field}
                  inputId={field.name}
                  options={NUMBER_TYPE_LIST}
                  onChange={(e) => {
                    resetConstraintValues(setValue);
                    field.onChange(e.value);
                  }}
                  placeholder={"Display Type"}
                  pt={{ item: { className: "capitalize" } }}
                  className="w-full capitalize"
                />
                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            </div>
          )}
        />
        {/* radio button weight  */}
        {isWeight && (
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-12 items-center gap-2">
              <div className="col-span-12 flex gap-2 md:col-span-3">
                <label
                  htmlFor="constraints.min.force"
                  className="cursor-pointer"
                >
                  Capture value in
                </label>
              </div>

              <Controller
                name="constraints.captureIn"
                control={control}
                render={({ field }) => (
                  <div className="col-span-12 flex gap-3 md:col-span-4">
                    <div className="flex items-center gap-2">
                      <RadioButton
                        {...field}
                        inputId="capture-pounds"
                        inputRef={field.ref}
                        value={WEIGHT_OPTIONS_ENUM.LBS}
                        checked={field.value === WEIGHT_OPTIONS_ENUM.LBS}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        className="font-semibold capitalize text-gray-500"
                        htmlFor="capture-pounds"
                      >
                        pounds
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <RadioButton
                        {...field}
                        inputId="capture-KG"
                        inputRef={field.ref}
                        value={WEIGHT_OPTIONS_ENUM.KGS}
                        checked={field.value === WEIGHT_OPTIONS_ENUM.KGS}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        className="font-semibold capitalize text-gray-500"
                        htmlFor="capture-KG"
                      >
                        Kilograms
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <RadioButton
                        {...field}
                        inputId="capture-selectable"
                        inputRef={field.ref}
                        value="selectable"
                        checked={field.value === "selectable"}
                        onChange={(e) => {
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        className="font-semibold capitalize text-gray-500"
                        style={{
                          textWrap: "nowrap",
                        }}
                        htmlFor="capture-selectable"
                      >
                        User selectable
                      </label>
                    </div>
                  </div>
                )}
              />
            </div>
            <div className="grid grid-cols-12 items-center gap-2">
              <div className="col-span-12 flex gap-2 md:col-span-3">
                <label
                  htmlFor="constraints.min.force"
                  className="cursor-pointer"
                >
                  Store value as
                </label>
              </div>
              <Controller
                name="constraints.storeAs"
                control={control}
                render={({ field }) => (
                  <div className="col-span-12 flex gap-3 md:col-span-4">
                    <div className="flex items-center gap-2">
                      <RadioButton
                        {...field}
                        inputId="store-pounds"
                        inputRef={field.ref}
                        value={WEIGHT_OPTIONS_ENUM.LBS}
                        checked={field.value === WEIGHT_OPTIONS_ENUM.LBS}
                        onChange={(e) => {
                          setValue("constraints.min.value", null);
                          setValue("constraints.max.value", null);
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        className="font-semibold capitalize text-gray-500"
                        htmlFor="store-pounds"
                      >
                        pounds
                      </label>
                    </div>

                    <div className="flex items-center gap-2">
                      <RadioButton
                        {...field}
                        inputId="store-KG"
                        inputRef={field.ref}
                        value={WEIGHT_OPTIONS_ENUM.KGS}
                        checked={field.value === WEIGHT_OPTIONS_ENUM.KGS}
                        onChange={(e) => {
                          setValue("constraints.min.value", null);
                          setValue("constraints.max.value", null);
                          field.onChange(e.value);
                        }}
                      />
                      <label
                        className="font-semibold capitalize text-gray-500"
                        htmlFor="store-KG"
                      >
                        Kilograms
                      </label>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        )}
        {/* end radio button  */}

        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-12 items-center gap-2">
            <div className="col-span-12 flex gap-2 md:col-span-3">
              <Controller
                name="constraints.min.force"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    inputId={field.name}
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.checked);
                      if (!e.checked) {
                        setValue("constraints.min.value", null, {
                          shouldValidate: true,
                        });
                      }
                    }}
                  />
                )}
              />
              <label htmlFor="constraints.min.force" className="cursor-pointer">
                Force minimum
              </label>
            </div>

            <Controller
              name="constraints.min.value"
              control={control}
              rules={{
                validate: {
                  empty: (value, formValues) => {
                    const isForceEnabled = formValues.constraints.min.force;
                    if (!isForceEnabled) return true;
                    return value === null ? "Minimum value is required." : true;
                  },
                  largerThanMax: (value, formValues) => {
                    const isForceEnabled =
                      formValues.constraints.min.force &&
                      formValues.constraints.max.force;
                    if (!isForceEnabled) return true;
                    trigger("constraints.max.value");
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-12 flex flex-col gap-2 md:col-span-4">
                  {isStandard ? (
                    <StandardNumericInput
                      field={field}
                      error={error}
                      questionType={watch("type")}
                      disabled={!watch("constraints.min.force")}
                    />
                  ) : isWeight ? (
                    <WeightInput
                      unit={watch("constraints.storeAs")}
                      field={field}
                      error={error}
                      questionType={watch("type")}
                      disabled={!watch("constraints.min.force")}
                    />
                  ) : (
                    <AmericanNumericInput
                      id={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={error}
                      disabled={!watch("constraints.min.force")}
                    />
                  )}
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>

          <div className="grid grid-cols-12 items-center gap-2">
            <div className="col-span-12 flex gap-2 md:col-span-3">
              <Controller
                name="constraints.max.force"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    inputId={field.name}
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.checked);
                      if (!e.checked) {
                        setValue("constraints.max.value", null, {
                          shouldValidate: true,
                        });
                      }
                    }}
                  />
                )}
              />
              <label htmlFor="constraints.max.force" className="cursor-pointer">
                Force maximum
              </label>
            </div>

            <Controller
              name="constraints.max.value"
              control={control}
              rules={{
                validate: {
                  empty: (value, formValues) => {
                    const isForceEnabled = formValues.constraints.max.force;
                    if (!isForceEnabled) return true;
                    return value === null ? "Maximum value is required." : true;
                  },
                  smallerThanMin: (value, formValues) => {
                    const isForceEnabled = formValues.constraints.max.force;
                    if (!isForceEnabled) return true;
                    const minValue = formValues.constraints.min.value;

                    return (
                      value > minValue ||
                      "Maximum value must be greater than minimum value."
                    );
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-12 flex flex-col gap-2 md:col-span-4">
                  {watch("constraints.type") ===
                  NUMBER_TYPE_ENUM.STANDARD.value ? (
                    <StandardNumericInput
                      field={field}
                      error={error}
                      questionType={watch("type")}
                      disabled={!watch("constraints.max.force")}
                    />
                  ) : isWeight ? (
                    <WeightInput
                      unit={watch("constraints.storeAs")}
                      field={field}
                      error={error}
                      questionType={watch("type")}
                      disabled={!watch("constraints.max.force")}
                    />
                  ) : (
                    <AmericanNumericInput
                      id={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      error={error}
                      disabled={!watch("constraints.max.force")}
                    />
                  )}
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(NumericConstraints);
