import { useState } from "react";

import { v4 as uuidv4 } from "uuid";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";

import ManageRule from "./ManageRule";
import ManageActions from "./ManageActions";
import { useServiceStore } from "../../utils/store";
import { isBillableItemInUse } from "./actions/helpers";
import ManageRulesFormHeader from "./ManageRulesFormHeader";
import ManageRulesFormFooter from "./ManageRulesFormFooter";
import { useLayoutToast } from "../../../../../../../store/store";
import ManageConditionalGroupHeader from "./ManageConditionalGroupHeader";
import {
  ACTIONS_ENUM,
  DEFAULT_CONDITION,
  OPERATORS,
  RULE_TYPES,
} from "../../utils/constants";
import PulsingCircle from "../../../../../../../iconComponents/PulsingCircle";
import ManageInlineActionHeader from "./ManageInlineActionHeader";

const initialConditionalGroup = {
  id: uuidv4(),
  operator: OPERATORS.ALL.value,
  conditions: [DEFAULT_CONDITION],
};

const ManageRulesForm = ({ rulesGroup, isNewRulesGroup, setOpen }) => {
  const { layoutToast } = useLayoutToast();
  const { addRuleGroup, updateRuleGroup } = useServiceStore();
  const form = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: rulesGroup,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const isRuleGroup = rulesGroup?.type === RULE_TYPES.GROUP.value;
  const isAction = rulesGroup?.type === RULE_TYPES.ACTION.value;

  const {
    fields: rules,
    append,
    remove,
  } = useFieldArray({
    control: form.control,
    name: "conditionalGroups",
    rules: {
      minLength: {
        value: isRuleGroup ? 2 : 1,
        message: `Enter ${isRuleGroup ? "two or more rules" : "at least one rule"}.`,
      },
    },
  });

  const showBillableItemInUseToast = () => {
    layoutToast.current.show({
      severity: "error",
      summary: "Error",
      detail: (
        <p id="error-message">
          Changes to this rule will affect a billable item that is already used
          by another rule.
          <strong> Please review your rule settings before saving.</strong>
        </p>
      ),
      life: 4000,
    });
  };

  const onsubmit = (data) => {
    const currentAction = data.action;
    const previousAction = rulesGroup.action;

    const isAddCPTAction = previousAction.key === ACTIONS_ENUM.ADD_CPT.value;
    const isSwapCPTAction = previousAction.key === ACTIONS_ENUM.SWAP_CPT.value;

    if (!isNewRulesGroup) {
      if (isAddCPTAction || isSwapCPTAction) {
        const currentBillableItem = isAddCPTAction
          ? previousAction.on
          : previousAction.on.new;

        const usedBillableItems = currentBillableItem.filter((b) =>
          isBillableItemInUse(b.id, rulesGroup.id),
        );

        if (usedBillableItems.length) {
          if (
            ![ACTIONS_ENUM.ADD_CPT.value, ACTIONS_ENUM.SWAP_CPT.value].includes(
              currentAction.key,
            )
          ) {
            showBillableItemInUseToast();
            return;
          } else {
            const isUsedBillableItemRemoved = !usedBillableItems.every((b) => {
              const BIS =
                ACTIONS_ENUM.ADD_CPT.value === currentAction.key
                  ? currentAction.on
                  : currentAction.on.new;
              return BIS.some((_b) => _b.id === b.id);
            });

            if (isUsedBillableItemRemoved) {
              showBillableItemInUseToast();
              return;
            }
          }
        }
      }
    }

    // VALIDATE
    isNewRulesGroup ? addRuleGroup(data) : updateRuleGroup(rulesGroup.id, data);
    setOpen({ isVisible: false, rules: null, isNewRulesGroup: true });
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit(onsubmit)();
        }}
        autoComplete="off"
      >
        <div className="rounded-lg border">
          {isRuleGroup && <ManageRulesFormHeader />}
          {isAction && <ManageInlineActionHeader />}
          <div className="p-3">
            {!isAction && (
              <div className="flex flex-col rounded-lg border-gray-200 md:border md:p-5">
                <Accordion
                  onTabChange={(e) => {
                    const className =
                      typeof e.originalEvent.target.className === "string"
                        ? e.originalEvent.target.className
                        : e.originalEvent.target.className?.baseVal;

                    const isExpandable = !className.includes("non-expandable");
                    if (isRuleGroup && isExpandable) setActiveIndex(e.index);
                  }}
                  activeIndex={activeIndex}
                >
                  {rules.map((rule, ruleIndex) => (
                    <AccordionTab
                      headerClassName={`border rounded-md ${form.formState.errors?.conditionalGroups?.[ruleIndex] && "border-[#e24c4c]"}`}
                      key={rule.id}
                      headerTemplate={
                        <Controller
                          name={`conditionalGroups.${ruleIndex}`}
                          control={form.control}
                          rules={{
                            validate: {
                              ruleValid: (value) =>
                                value.conditions.every(
                                  (condition) =>
                                    condition.questionId.trim().length > 0 &&
                                    (condition.equalityOperator ===
                                      OPERATORS.NOT_ANSWERED.value ||
                                      condition.answers.every(
                                        (answer) =>
                                          answer?.value?.toString().trim()
                                            .length > 0,
                                      )),
                                ),
                            },
                          }}
                          render={({ fieldState: { error } }) => (
                            <>
                              <ManageConditionalGroupHeader
                                rules={rules}
                                ruleIndex={ruleIndex}
                                remove={remove}
                              />
                              {error && <PulsingCircle />}
                            </>
                          )}
                        />
                      }
                    >
                      <ManageRule key={rule.id} ruleIndex={ruleIndex} />
                    </AccordionTab>
                  ))}
                </Accordion>
                {isRuleGroup && (
                  <Button
                    type="button"
                    icon="pi pi-plus"
                    label="Add Rule"
                    id="add-rule"
                    onClick={() =>
                      append({ ...initialConditionalGroup, id: uuidv4() })
                    }
                    className="my-2 self-end bg-light-purple"
                  />
                )}
              </div>
            )}
            <ManageActions />
          </div>
        </div>
        <ManageRulesFormFooter
          saveLabel={isAction ? "Save Action" : "Save Rule"}
          setOpen={setOpen}
        />
      </form>
    </FormProvider>
  );
};

export default ManageRulesForm;
