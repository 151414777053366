import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useHandleResize from "../../../../../../../hooks/useHandleResize";
import { trimValidation } from "../../../../../../../utils/helpers";

const ManageConditionalGroupHeader = ({ rules, ruleIndex, remove }) => {
  const { control, watch } = useFormContext();
  const { isMobile } = useHandleResize();

  const isMoreThanTwoRules = rules.length > 2;

  return (
    <div className="accordion flex w-full items-center justify-between gap-2">
      <Controller
        name={`conditionalGroups.${ruleIndex}.title`}
        control={control}
        defaultValue={`Rule-${watch(`conditionalGroups.${ruleIndex}.id`).split("-")[0]}`}
        rules={{
          required: "Enter Rule Name",
          validate: (value) => trimValidation(value, "Rule Name"),
        }}
        render={({ field, fieldState: { error } }) => (
          <div className="flex items-center gap-2">
            <label htmlFor={field.name} className="font-medium">
              Rule name:
            </label>
            <InputText id={field.name} {...field} className="non-expandable" />
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        )}
      />
      {isMoreThanTwoRules && (
        <div className="mt-2 flex justify-center">
          <Button
            label={!isMobile && "Delete Rule"}
            icon="pi pi-trash"
            id="delete-role"
            type="button"
            outlined
            onClick={() => remove(ruleIndex)}
            className="non-expandable"
          />
        </div>
      )}
    </div>
  );
};

export default ManageConditionalGroupHeader;
