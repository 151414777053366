import { useLocation } from "react-router-dom";

import { BuggyCounter } from "../../../BuggyCounter";
import useHandleResize from "../../../../hooks/useHandleResize";
import { CompletionChecklist } from "../../../../components/cards";
import AroundTheWorld from "../../../../iconComponents/AroundTheWorld";
import { SERVICE_LOCATION_STATUS } from "../../../../constants/constants";
import { ServiceLocationsSkeleton } from "../../../../components/skeletons/ServiceLocationsSkeleton";

const Sidebar = ({ currentServiceLocations, serviceLocationsStatus }) => {
  const { isDesktop } = useHandleResize();
  const location = useLocation();
  const isWelcome = location?.state?.isWelcome;
  return (
    <div className="flex flex-col gap-4">
      <BuggyCounter />

      {isDesktop && <CompletionChecklist view="order" isWelcome={isWelcome} />}
      <div className="sticky top-0 hidden w-[22.5rem] rounded-md bg-white p-5 font-inter lg:block">
        <p className="mb-4 text-xl font-extrabold text-[#3D3877]">
          Service Locations
        </p>

        <div className="max-h-[16rem] overflow-y-auto">
          {serviceLocationsStatus === SERVICE_LOCATION_STATUS.SUCCESS ? (
            currentServiceLocations.map((location, i) => (
              <div key={i} className="flex w-[100%] items-center gap-x-2">
                <i className="pi pi-map-marker text-[#594FC4]" />
                <p className="mt-2 p-2 text-justify font-inter text-sm font-normal capitalize text-[#434343]">{`${location.address.lineOne}. ${location.address.city}, ${location.address.state} ${location.address.zipCode}`}</p>
              </div>
            ))
          ) : serviceLocationsStatus === SERVICE_LOCATION_STATUS.LOADING ? (
            <ServiceLocationsSkeleton />
          ) : serviceLocationsStatus === SERVICE_LOCATION_STATUS.NO_DATA ? (
            <p className="rounded bg-white p-5 text-gray-400">
              No results found
            </p>
          ) : (
            <div>
              <p className="mb-4 text-justify font-semibold text-gray-400">
                By clicking on a service card, the locations where the service
                is available will be displayed here.
              </p>
              <div className="flex justify-center">
                <AroundTheWorld />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
