import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";

import useAxios from "../../hooks/useAxios";
import { Column } from "../../devlink/_Builtin";
import useTokenData from "../../hooks/useTokenData";
import { showErrorToast } from "../../utils/apiUtils";
import { ProviderTemplate } from "../templates/columnBodyTemplates";

export default function AddTeamMemberDialog({ onSave, currentMembers }) {
  const { http } = useAxios();
  const { entityId } = useTokenData();

  const [allMembers, setAllMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableStaff, setAvailableStaff] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [openAddNewMemberDialog, setOpenAddNewMemberDialog] = useState(false);

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "role",
      header: "Role",
      template: ProviderTemplate,
      style: { width: "15%" },
    },
  ];

  useEffect(() => {
    getAllEntityMembers();
  }, []);

  const getAllEntityMembers = async () => {
    setIsLoading(true);
    try {
      const res = await http.get(`/Teams/GetEntityUsersToJoinTeam/${entityId}`);
      setAllMembers(res.data);
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAvailableStaff(
      allMembers.filter(
        (member) => !currentMembers.includes(member.entityUserId),
      ),
    );
  }, [currentMembers, allMembers, openAddNewMemberDialog]);

  const handleSave = (selectedMembers) => {
    onSave(selectedMembers);
    setSelectedMembers([]);
    setOpenAddNewMemberDialog(false);
  };

  const onCancel = () => {
    setSelectedMembers([]);
    setOpenAddNewMemberDialog(false);
  };

  return (
    <>
      <Dialog
        visible={openAddNewMemberDialog}
        className="w-11/12 md:w-2/3"
        header="Add New Member"
        modal
        closable
        onHide={() => setOpenAddNewMemberDialog(false)}
      >
        <DataTable
          value={availableStaff}
          pt={{
            loadingOverlay: {
              style: {
                background: "#C6C1FF40",
              },
            },
          }}
          selection={selectedMembers}
          onSelectionChange={(e) => {
            setSelectedMembers(e.value);
          }}
          emptyMessage={<div className="text-center">No members available</div>}
          className="rounded-md border border-gray-200 p-2"
          loading={isLoading}
        >
          <Column selectionMode="multiple"></Column>
          {columns.map((column) => (
            <Column
              key={column.field}
              field={column.field}
              header={column.header}
              body={column.template}
              align="center"
            />
          ))}
        </DataTable>

        <div className="my-3 grid grid-cols-11 gap-4 justify-self-end">
          <Button
            type="button"
            severity="danger"
            className="col-span-5"
            onClick={onCancel}
            label={"Cancel"}
          />
          <Button
            label="Add"
            className="col-span-6 bg-light-purple"
            onClick={() => {
              handleSave(selectedMembers);
            }}
          ></Button>
        </div>
      </Dialog>
      <Button
        severity="info"
        outlined
        icon="pi pi-plus"
        label="Add New Member"
        type="button"
        onClick={() => setOpenAddNewMemberDialog(true)}
      />
    </>
  );
}
