import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

import CheckInPass from "./CheckInPass";
import ServiceType from "./ServiceType";
import LocationMultiSelect from "./LocationMultiSelect";
import DefaultBillableItems from "../../../my-services/manage-service/components/DefaultBillableItems";
import { trimValidation } from "../../../../../../utils/helpers";
import ServicesIcon from "../../../../../../iconComponents/ServicesIcon";
import AttachServiceFiles from "../components/AttachServiceFiles";

function ServiceDetailsForm({ isFetchBillable }) {
  const { control } = useFormContext();

  // register billable items as a required field
  useFieldArray({
    control,
    name: "billableItems",
    rules: { required: "Please select billable item" },
  });

  return (
    <div className="flex flex-col gap-4 rounded-md bg-white p-5">
      {/* name */}
      <div>
        <label
          htmlFor="name"
          className="font-medium capitalize leading-loose text-gray-500"
        >
          service name
          <span className="ml-1 font-bold text-red-500">*</span>
        </label>
        <div>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Enter Service Name",
              validate: (value) => trimValidation(value, "Service Name"),
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <span className="p-input-icon-left w-full">
                  <ServicesIcon />
                  <InputText
                    id={field.name}
                    {...field}
                    maxLength={999}
                    placeholder="Enter service name"
                    className="w-full"
                  />
                </span>
                {error?.message && (
                  <p className="text-red-500">{error.message}</p>
                )}
              </>
            )}
          />
        </div>
      </div>
      {/* description */}
      <div>
        <label
          htmlFor="description"
          className="font-medium capitalize leading-loose text-gray-500"
        >
          service description
          <span className="ml-1 font-bold text-red-500">*</span>
        </label>
        <span className="w-full">
          <Controller
            name="description"
            control={control}
            rules={{
              required: "Service description is required",
              validate: (value) => trimValidation(value, "Service Description"),
              maxLength: {
                value: 999,
                message: "The description must be less than 1000 characters.",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputTextarea
                  id={field.name}
                  {...field}
                  placeholder="Enter a description for your service"
                  rows={5}
                  cols={30}
                  className="w-full"
                />
                {error?.message && (
                  <p className="text-red-500">{error.message}</p>
                )}
              </>
            )}
          />
        </span>
      </div>

      {/* service Type  */}
      <ServiceType />

      {/* check-in pass  */}
      <CheckInPass />

      {/* LOCATIONS */}
      <LocationMultiSelect />

      {/* Billable Items not completed */}
      <DefaultBillableItems isFetchBillable={isFetchBillable} />

      {/* Documents  */}
      <AttachServiceFiles />
    </div>
  );
}

export default ServiceDetailsForm;
