import { MultiSelect } from "primereact/multiselect";

import { useLocationsStore } from "../../../../../../../../store/store";

const SelectLocation = ({ field, error }) => {
  const { defaultLocations } = useLocationsStore();

  return (
    <div className="flex flex-col gap-2">
      <div>
        <label className="font-semibold">Filter Locations</label>
        <p className="text-xs text-gray-400">
          * Please note that locations flagged as disabled will not be visible
          to order providers.
        </p>
      </div>
      <div id="serviceLocation">
        <MultiSelect
          {...field}
          filter
          filterTemplate={() => (
            <span className="ml-2 font-inter text-sm">Select All</span>
          )}
          className="md:w-14rem w-full"
          placeholder="Choose Locations"
          options={defaultLocations}
          optionLabel="name"
          optionValue="id"
          {...field}
          value={field.value.map((v) => v.id)}
          onChange={(e) => {
            field.onChange(e.value.map((id) => ({ id })));
          }}
          itemTemplate={(option) => (
            <div>
              <h4
                className={`font-medium capitalize ${option.isEnabled ? "text-gray-600" : "text-gray-400"}`}
              >
                {option.name}{" "}
              </h4>
              <p
                className={`font-normal capitalize ${option.isEnabled ? "text-gray-600" : "text-gray-400"}`}
              >
                {option.address.lineOne},{" "}
                {option.address.lineTwo ? option.address.lineTwo + "," : ""}{" "}
                {option.address.city}, {option.address.state}{" "}
                {option.address.zipCode}
              </p>
            </div>
          )}
        />
      </div>
      {error && <p className="text-red-500">{error.message}</p>}
    </div>
  );
};

export default SelectLocation;
