import { Controller, useFormContext } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";

import { useServiceStore } from "../utils/store";
import AnswerInput from "./formElements/AnswerInput";
import { getComparisonOperators } from "../utils/utils";
import {
  DEFAULT_QUESTIONS,
  OPERATORS,
  QUESTION_TYPES_ENUM,
  VISIBILTY_EQUALITY_OPERATORS,
} from "../utils/constants";
import TextOptions from "./TextOptions";
import DisplayConstraints from "./formElements/DisplayConstraints";

const ConditionForm = ({ conditionIndex, questionData }) => {
  const { control, clearErrors, watch, setValue } = useFormContext();

  const { questions } = useServiceStore();

  // get all questions except the question that already uses another question
  const questionsPool = [...DEFAULT_QUESTIONS, ...questions].filter(
    (question) =>
      question?.id !== questionData?.id &&
      !question.conditions.some(
        (condition) => condition.questionId === questionData?.id,
      ),
  );

  const questionId = watch(`conditions.${conditionIndex}.questionId`);
  const question = questionsPool.find((question) => question.id === questionId);

  const isConditional = watch(`isConditional`);

  return (
    <div className="col-span-10 flex flex-col gap-3 rounded-lg border p-3 lg:grid lg:grid-cols-12 lg:items-center">
      <label className="col-span-2 font-semibold">Condition</label>
      <div className="col-span-4">
        <Controller
          name={`conditions.${conditionIndex}.questionId`}
          control={control}
          rules={{
            validate: (value, formValues) => {
              return !formValues.isConditional || !!value;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Dropdown
                {...field}
                placeholder={"Select question"}
                optionLabel="label"
                optionValue="id"
                inputId={field.name}
                options={questionsPool}
                onChange={(e) => {
                  field.onChange(e);

                  const question = questionsPool.find(
                    (question) => question.id === e.value,
                  );
                  setValue(
                    `conditions.${conditionIndex}.comparisonOperator`,
                    OPERATORS.EQUAL.value,
                  );
                  if (question.type === QUESTION_TYPES_ENUM.BOOLEAN.value) {
                    setValue(`conditions.${conditionIndex}.answers`, [
                      { value: false },
                    ]);
                  } else {
                    setValue(`conditions.${conditionIndex}.answers`, [
                      { value: "" },
                    ]);
                  }
                  clearErrors(`conditions.${conditionIndex}.answers`);
                }}
                className={`w-full ${error ? "p-invalid" : ""} `}
                disabled={!watch("isConditional")}
              />
            </div>
          )}
        />
      </div>
      <div className="col-span-3">
        <Controller
          name={`conditions.${conditionIndex}.equalityOperator`}
          control={control}
          rules={{
            validate: (value, formValues) => {
              return !formValues.isConditional || !!value;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <div>
              <Dropdown
                {...field}
                placeholder={"Equality Operator"}
                optionLabel="label"
                optionValue="value"
                options={VISIBILTY_EQUALITY_OPERATORS}
                className={`w-full ${error ? "p-invalid" : ""} `}
                disabled={!watch("isConditional")}
              />
            </div>
          )}
        />
      </div>
      {isConditional && questionId && (
        <>
          <div className="col-span-3">
            <Controller
              name={`conditions.${conditionIndex}.comparisonOperator`}
              control={control}
              rules={{
                validate: (value, formValues) => {
                  return !formValues.isConditional || !!value;
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <Dropdown
                    {...field}
                    placeholder={"Comparison Operator"}
                    optionLabel="label"
                    optionValue="value"
                    options={getComparisonOperators(question?.type)}
                    onChange={(e) => {
                      field.onChange(e);

                      const isMultiAnswerQuestion =
                        question.type ===
                          QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value ||
                        question.type === QUESTION_TYPES_ENUM.DROPDOWN.value;

                      const isMultiOptionalComparison =
                        e.value === OPERATORS.IN.value ||
                        e.value === OPERATORS.BETWEEN.value;

                      clearErrors(`conditions.${conditionIndex}.answers`);
                      if (isMultiOptionalComparison) {
                        if (isMultiAnswerQuestion) {
                          setValue(`conditions.${conditionIndex}.answers`, []);
                        } else {
                          setValue(`conditions.${conditionIndex}.answers`, [
                            { value: "" },
                            { value: "" },
                          ]);
                        }
                      } else {
                        if (
                          question.type === QUESTION_TYPES_ENUM.BOOLEAN.value
                        ) {
                          setValue(`conditions.${conditionIndex}.answers`, [
                            { value: false },
                          ]);
                        } else {
                          setValue(`conditions.${conditionIndex}.answers`, [
                            { value: "" },
                          ]);
                        }
                      }
                    }}
                    className={`w-full ${error ? "p-invalid" : ""} `}
                    disabled={
                      !watch("isConditional") ||
                      !watch(`conditions.${conditionIndex}.equalityOperator`)
                    }
                  />
                </div>
              )}
            />
          </div>
          <div className="col-span-8">
            <AnswerInput
              inputNamePath={`conditions.${conditionIndex}`}
              question={question}
            />
          </div>
          {question?.type === QUESTION_TYPES_ENUM.TEXT.value && (
            <div className="col-span-10 col-start-3">
              <TextOptions path={`conditions.${conditionIndex}`} />
            </div>
          )}
          <div className="col-span-8 col-start-3 py-3">
            <DisplayConstraints question={question} />
          </div>
        </>
      )}
    </div>
  );
};

export default ConditionForm;
