import React from "react";
import { InputNumber } from "primereact/inputnumber";
import { QUESTION_TYPES_ENUM } from "../../utils/constants";

const StandardNumericInput = ({
  field,
  error,
  questionType,
  disabled = false,
  handleChange,
}) => {
  const isInt = questionType === QUESTION_TYPES_ENUM.NUMBER.value;
  const minFractionDigits = isInt ? undefined : 2;
  const maxFractionDigits = isInt ? undefined : 2;

  return (
    <InputNumber
      {...field}
      inputId={field.name}
      onChange={(e) => {
        field.onChange(e.value);
        handleChange && handleChange(field.value, e.value);
      }}
      useGrouping={false}
      placeholder={isInt ? "0" : "0.00"}
      minFractionDigits={minFractionDigits}
      maxFractionDigits={maxFractionDigits}
      className={`w-full flex-1 ${error ? "p-invalid" : ""}`}
      disabled={disabled}
    />
  );
};

export default StandardNumericInput;
