import { useEffect, useState } from "react";

import axios from "axios";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

import ExpiredLink from "./ExpiredLink";
import CompleteIdentityVerificationForm from "./components/CompleteIdentityVerificationForm";
import { showErrorToast } from "../../utils/apiUtils";

function CompleteProviderVerification() {
  const [identifierId, setIdentifierId] = useState(null);
  const [sessionStatus, setSessionStatus] = useState("pending");
  const [jwtToken, setJwtToken] = useState(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const linkId = searchParams.get("lid");
  useEffect(() => {
    const cookieData = Cookies.get("userData");
    if (cookieData && typeof cookieData === "string") {
      //Extract token
      const tokenRegex = /JwToken:'([^']+)/;
      const tokenMatch = cookieData.match(tokenRegex);
      const token = tokenMatch ? tokenMatch[1] : null;
      setJwtToken(token);
    }
  }, []);

  useEffect(() => {
    if (jwtToken) {
      getSessionDetails(linkId);
    }
  }, [linkId, jwtToken]);

  const getSessionDetails = async (linkId) => {
    try {
      const response = await axios.get(
        `/Identifications/GetIdentifierIdByLink/${linkId}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        },
      );
      setIdentifierId(response.data);
      setSessionStatus("valid");
    } catch (error) {
      setSessionStatus("expired");
      showErrorToast({ error });
    }
  };
  return (
    <div>
      {sessionStatus === "pending" ? (
        <></>
      ) : sessionStatus === "valid" ? (
        <>
          <CompleteIdentityVerificationForm
            linkId={linkId}
            identifierId={identifierId}
            token={jwtToken}
          />
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <ExpiredLink />
        </div>
      )}
    </div>
  );
}

export default CompleteProviderVerification;
