import { useState } from "react";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";

import useAxios from "../../hooks/useAxios";
import { ReusableDataList } from "../Table";
import { formatDate } from "../../utils/helpers";
import { useLayoutToast } from "../../store/store";
import useTokenData from "../../hooks/useTokenData";
import { showErrorToast } from "../../utils/apiUtils";
import { STAFF_ROLES } from "../../constants/constants";

const StaffDashboardJoinRequests = ({ refetch, setRefetch }) => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { layoutToast } = useLayoutToast();

  const [selectedRoles, setSelectedRoles] = useState({});

  const handleRoleChange = (event, userId) => {
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: event.target.value,
    }));
  };

  const handleApprove = async (userId) => {
    try {
      confirmDialog({
        message: "Are you sure you want to approve?",
        icon: "pi pi-exclamation-triangle",
        position: "center",
        accept: async () => {
          try {
            const joinReqBody = {
              status: 2,
              role: +selectedRoles[userId],
            };
            const response = await http.put(
              `JoiningRequests/UpdateJoiningRequestStatus/${userId}`,
              joinReqBody,
            );
            layoutToast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 4000,
            });
            setRefetch((prev) => !prev);
          } catch (error) {
            showErrorToast({ error });
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDecline = async (userId) => {
    try {
      confirmDialog({
        message: "Are you sure you want to decline?",
        icon: "pi pi-exclamation-triangle",
        position: "center",

        accept: async () => {
          try {
            const joinReqBody = {
              status: 3,
              role: 1,
            };
            const response = await http.put(
              `JoiningRequests/UpdateJoiningRequestStatus/${userId}`,
              joinReqBody,
            );
            setRefetch((prev) => !prev);

            layoutToast.current.show({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 4000,
            });
          } catch (error) {
            showErrorToast({ error });
          }
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const dropdownTemplate = (rowData) => (
    <Dropdown
      value={(selectedRoles && rowData && selectedRoles[rowData.id]) || ""}
      options={STAFF_ROLES}
      onChange={(event) => handleRoleChange(event, rowData.id)}
      placeholder="Select a role"
      className="w-9/12"
    />
  );

  const cardRequestsTemplate = (row) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">{row.name}</p>
          <span>{dropdownTemplate(row)}</span>
        </div>
        <p className="text-slate-500">{row.email}</p>

        <div>{actionsTemplate(row)}</div>
      </>
    );
  };

  const actionsTemplate = (rowData) => (
    <>
      <div className="flex gap-2">
        <Button
          className="p-button-success rounded-full py-2.5"
          icon="pi pi-check pr-1"
          onClick={() => handleApprove(rowData.id)}
          disabled={!selectedRoles[rowData.id]}
        >
          {selectedRoles[rowData.id] ? "Approve" : "Select Role"}
        </Button>
        <Button
          className="rounded-full border-none bg-gray-300 py-2.5 text-gray-600"
          icon="pi pi-ban pr-1"
          onClick={() => handleDecline(rowData.id)}
        >
          Decline
        </Button>
      </div>
    </>
  );

  const TABLE_COLUMNS = [
    { field: "userName", header: "Name", style: { width: "15%" } },
    { field: "userEmail", header: "Email", style: { width: "25%" } },
    {
      field: "requestDate",
      header: "Date",
      template: (rowData) => formatDate(rowData.requestDate, true),
      style: { width: "15%" },
    },
    {
      template: dropdownTemplate,
      header: "Roles",
      style: { width: "20%" },
    },
    {
      template: actionsTemplate,
      header: "Actions",
      style: { width: "25%" },
    },
  ];
  return (
    <ReusableDataList
      title={"All Join Requests"}
      fromFront={true}
      dataLink={`/JoiningRequests/GetJoiningRequestsByEntityId/${entityId}`}
      columns={TABLE_COLUMNS}
      searchTitle="search by name"
      refetch={refetch}
      cardFilters={["name"]}
      mobileTemplate={cardRequestsTemplate}
    />
  );
};

export default StaffDashboardJoinRequests;
