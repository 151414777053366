import { useState } from "react";

import { ConfirmDialog } from "primereact/confirmdialog";

import InviteAdminForm from "./components/InviteAdminForm";
import ManageAdminInvitations from "./components/ManageAdminInvitations";
import ManageAllAdmins from "./components/ManageAllAdmins";

export default function ManageAdmins() {
  const [refetch, setRefetch] = useState(false);

  return (
    <>
      <ConfirmDialog />
      <div className="left mr-8 mt-4 min-w-[345px]">
        <h5 className="font-inter text-lg font-extrabold">Invite New Admin</h5>
        <p className="text-p text-normal-text">
          We will email him/her instructions and send a link to sign up.
        </p>
      </div>
      <InviteAdminForm setRefetch={setRefetch} />
      <div className="flex flex-col gap-10">
        <ManageAdminInvitations refetch={refetch} setRefetch={setRefetch} />
        <ManageAllAdmins />
      </div>
    </>
  );
}
