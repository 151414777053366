import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import TeamCards from "./TeamCards";
import useAxios from "../../hooks/useAxios";
import ManageTeamForm from "./ManageTeamForm";
import useTokenData from "../../hooks/useTokenData";
import { showErrorToast, showSuccessToast } from "../../utils/apiUtils";
import { TeamCardSkeleton } from "../skeletons/TeamCardSkeleton";

export default function TeamsDashboard() {
  const { http } = useAxios();
  const navigate = useNavigate();
  const { entityId } = useTokenData();
  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const [openCreateNewTeamDialog, setOpenCreateNewTeamDialog] = useState(false);
  useEffect(() => {
    if (shouldRefetch) {
      getAllTeams();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const getAllTeams = async () => {
    setIsLoading(true);
    try {
      const res = await http.get(`/Teams/GetEntityTeams/${entityId}`);
      setTeams(res.data);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  const createNewTeam = () => {
    setOpenCreateNewTeamDialog(true);
  };

  const handleManageTeam = (teamData) => {
    const { id } = teamData;
    navigate(`/ordering/teams/${id}`);
  };

  const handleDeleteTeam = (teamId) => {
    const confirmDelete = async () => {
      try {
        const res = await http.delete(`/Teams/DeleteEntityTeam/${teamId}`);
        setShouldRefetch(true);
        showSuccessToast({ message: res.data.message });
      } catch (error) {
        showErrorToast({ error });
      }
    };

    confirmDialog({
      header: "Delete Team",
      icon: "pi pi-exclamation-triangle",
      message: "Are you sure you want to delete this team?",
      position: "center",
      acceptClassName: "p-button-danger",
      acceptLabel: "Delete",
      rejectLabel: "Cancel",
      accept: confirmDelete,
    });
  };

  const hideDialog = () => {
    setOpenCreateNewTeamDialog(false);
  };

  return (
    <div className="card flex w-full flex-col gap-3 rounded-3xl bg-light-text p-6">
      <ConfirmDialog />

      <Dialog
        visible={openCreateNewTeamDialog}
        className="w-11/12 md:w-2/3"
        header="Create New Team"
        modal
        closable
        onHide={() => setOpenCreateNewTeamDialog(false)}
      >
        <ManageTeamForm
          setShouldRefetch={setShouldRefetch}
          setOpenCreateNewTeamDialog={setOpenCreateNewTeamDialog}
          submitCallback={hideDialog}
        />
      </Dialog>

      <Tooltip target=".custom-target-icon" className="max-w-xl" />

      <div className="flex w-full flex-col justify-between rounded-2xl border border-slate-200 p-6">
        <div className="my-4 flex flex-col justify-between gap-3 sm:flex-row">
          <div className="flex flex-col">
            <label className="text-title font-bold">
              Teams
              <span
                className="custom-target-icon"
                data-pr-position="right"
                data-pr-tooltip={`The Teams feature allows you to create groups of staff and providers to limit access in the ordering and rendering views. When staff is put in a team, they will only be able to see and receive notifications about orders related to the providers also in the same team. A staff member that is not in a team will be able to see and interact with all orders in the system (across multiple teams). An administrative user can not be added to any teams, because their access can't be limited without compromising usability in the system. If a team has only staff and no providers, then naturally they will only be able to place internal orders with existing orders on file in your practice EMR, as they will not have access to any provider records to initiate new orders. Most large practices use this feature when they have distinct provider teams that assist the provider with submitting orders for his or her review and approval (such as nurses assigned specifically to the provider). A staff member can be part of one or more teams and assist multiple providers in your practice.`}
              >
                <i className="pi pi-info-circle px-2 text-sm" />
              </span>
            </label>
            <p className="mt-1 text-sm text-gray-600">
              Organize your staff and providers into teams to streamline access
              and notifications for orders.
            </p>
          </div>

          <div>
            <Button
              className="w-full px-12"
              onClick={createNewTeam}
              icon="pi pi-plus"
              label="Create New Team"
            />
          </div>
        </div>
        <div></div>
      </div>

      {/* team cards */}
      <div className="grid w-full grid-cols-12 gap-y-4 rounded-2xl border border-slate-200 p-6 sm:gap-x-4">
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <TeamCardSkeleton key={`skeleton-${index}`} />
          ))
        ) : (
          <TeamCards
            teams={teams}
            handleManageTeam={handleManageTeam}
            handleDeleteTeam={handleDeleteTeam}
          />
        )}
      </div>
    </div>
  );
}
