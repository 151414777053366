import TypeCard from "./TypeCard";
import SortButtons from "./SortButtons";
import { filteredGroupQuestionsByIds } from "../../utils/utils";
import {
  DEFAULT_QUESTIONS,
  DEFAULT_SELF_EVALUATING_QUESTIONS,
  RULE_TYPES,
} from "../../utils/constants";
import { useServiceStore } from "../../utils/store";

import ConditionCardRender from "./ConditionCardRender";

const GroupRuleCard = ({
  rulesGroup,
  handleMoveToTop,
  handleMoveToBottom,
  dataOfAllBillableItems,
}) => {
  const { questions } = useServiceStore();

  const questionsInRule = filteredGroupQuestionsByIds(rulesGroup, [
    ...questions,
    ...DEFAULT_QUESTIONS,
    ...DEFAULT_SELF_EVALUATING_QUESTIONS,
  ]);

  return (
    <div>
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <span className="text-xl font-bold capitalize text-[#3D3877]">
            {rulesGroup.title}
          </span>
          <TypeCard
            type={RULE_TYPES[rulesGroup.type]?.label || rulesGroup.type}
          />
        </div>
        <div className="flex items-center gap-4">
          <SortButtons
            handleMoveToTop={handleMoveToTop}
            handleMoveToBottom={handleMoveToBottom}
          />
        </div>
      </div>

      <div>
        <div className="flex flex-col gap-3 overflow-hidden rounded-md border border-gray-100 px-4 py-3 shadow-sm">
          {rulesGroup.conditionalGroups.map((group, groupIndex) => (
            <div key={group.id} className="card">
              <div className="flex flex-col gap-3 overflow-hidden rounded-md border border-gray-100 px-4 py-3 shadow-sm">
                <h3 className="text-md font-semibold capitalize text-[#3D3877]">
                  {group.title}
                </h3>
                {group.conditions.map((condition, conditionIndex) => (
                  <ConditionCardRender
                    key={conditionIndex}
                    condition={condition}
                    conditionIndex={conditionIndex}
                    questionsInRule={questionsInRule}
                    conditions={group.conditions}
                    ruleLogicalOperator={group.operator}
                    dataOfAllBillableItems={dataOfAllBillableItems}
                  />
                ))}
              </div>

              {groupIndex < rulesGroup.conditionalGroups.length - 1 && (
                <div className="card-operator mt-3 text-center text-base font-bold">
                  {rulesGroup.operator.toUpperCase()}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupRuleCard;
