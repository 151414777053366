import { useRef } from "react";

import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";

import { useServiceStore } from "../../../utils/store";
import { DEFAULT_QUESTIONS } from "../../../utils/constants";
import { Column } from "../../../../../../../../devlink/_Builtin";
import DescriptionWithTooltipTemplate from "../DescriptionWithTooltipTemplate";
import { ModTemplate } from "../../../../../my-services/manage-service/templates/ModTemplate";
import { PriceBillableTemplate } from "../../../../../my-services/manage-service/templates/PriceBillableTemplate";
import { BillableItemsAttributesTemplate } from "../../../../../my-services/manage-service/templates/BillableItemsAttributesTemplate";

const DisplayBillableItems = ({ dataOfAllBillableItems, showUnits }) => {
  return (
    <DataTable value={dataOfAllBillableItems} emptyMessage="No items found">
      <Column
        align="center"
        alignHeader="center"
        field="hcpcs"
        header="HCPCS"
      />
      <Column
        align="center"
        alignHeader="center"
        field="description"
        body={DescriptionWithTooltipTemplate}
        header="Description"
      />
      <Column
        align="center"
        alignHeader="center"
        header="Mods"
        body={ModTemplate}
      />
      <Column
        align="center"
        alignHeader="center"
        header="Attributes"
        body={BillableItemsAttributesTemplate}
      />
      {showUnits && (
        <Column
          align="center"
          alignHeader="center"
          header="Unit Value"
          field="units"
          body={(rowData) => <UnitsTemplate rowData={rowData} />}
        />
      )}
      <Column
        align="center"
        alignHeader="center"
        header="Charge Amount"
        body={PriceBillableTemplate}
      />
    </DataTable>
  );
};

const UnitsTemplate = ({ rowData }) => {
  const tooltip = useRef();
  const { units, questionId } = rowData;
  const { questions } = useServiceStore();

  const allQuestions = [...questions, ...DEFAULT_QUESTIONS];

  const question = questionId && allQuestions.find((q) => q.id === questionId);
  return questionId ? (
    <>
      <Tooltip target={tooltip} className="text-sm" />
      <p
        ref={tooltip}
        className="flex cursor-help items-center justify-center gap-1 text-sm text-gray-500"
        data-pr-position="top"
        data-pr-tooltip={question?.label}
      >
        <i className="pi pi-link" />
      </p>
    </>
  ) : (
    units
  );
};

export default DisplayBillableItems;
