import { Controller, useFormContext } from "react-hook-form";

import { InputText } from "primereact/inputtext";

import { trimValidation } from "../../../../../../../utils/helpers";

const ManageInlineActionHeader = () => {
  const { control, watch } = useFormContext();
  return (
    <Controller
      name="title"
      control={control}
      defaultValue={`Action-${watch("id").split("-")[0]}`}
      rules={{
        required: "Enter Action Name",
        validate: (value) => trimValidation(value, "Action Name"),
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="flex items-center gap-2 rounded-t-md bg-slate-100 p-4">
          <label htmlFor={field.name} className="font-medium">
            Action name:
          </label>
          <InputText id={field.name} {...field} className="non-expandable" />
          {error && <p className="text-red-500">{error.message}</p>}
        </div>
      )}
    />
  );
};

export default ManageInlineActionHeader;
