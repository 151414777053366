import { MultiSelect } from "primereact/multiselect";
import { Controller, useFormContext } from "react-hook-form";

const DisplayMultipleChoice = ({ question }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={`${question.id}`}
      control={control}
      defaultValue={[]}
      rules={{
        required: question?.required && "Question is required",
      }}
      render={({ field, fieldState: { error } }) => (
        <>
          <MultiSelect
            {...field}
            value={field?.value?.map((v) => v.value)}
            onChange={(e) => {
              const selectedAnswers = e.value.map((value) => ({
                value,
              }));
              field.onChange(selectedAnswers);
            }}
            options={question.answers}
            id="multipleChoice-question-name"
            optionLabel="value"
            optionValue="id"
            display="chip"
            placeholder="Select answers"
            maxSelectedLabels={3}
            className="w-full"
          />
          {error && <p className="text-sm text-red-500">{error.message}</p>}
        </>
      )}
    />
  );
};

export default DisplayMultipleChoice;
