import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";

import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";

import { SORT_BY } from "../utils/constants";
import PinStatusPanel from "./PinStatusPanel";
import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import { useCurrentEntity } from "../../../../store/store";
import { ReusableFilter } from "../../../../components/Table";
import useHandleResize from "../../../../hooks/useHandleResize";
import useImpersonating from "../../../../hooks/useImpersonating";
import { CompletionChecklist } from "../../../../components/cards";
import { blockSpecialCharsPattern } from "../../../../utils/helpers";
import ProviderNotificationPanel from "../../../../components/banners/ProviderNotificationPanel";

const Header = ({
  searchService,
  setSearchService,
  sort,
  setSort,
  selectedState,
  setSelectedState,
  setFirst,
  getServices,
}) => {
  const { http } = useAxios();
  const { currentEntity } = useCurrentEntity();
  const { isSystemAdminImpersonating } = useImpersonating();
  const { HasPin, VerifyProvider } = useTokenData();
  const { isMobile, isDesktop } = useHandleResize();
  const location = useLocation();
  const [cities, setCities] = useState([]);

  const getCities = async () => {
    try {
      const response = await http.get(`/States/GetStates`);
      setCities(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCities();
  }, []);

  const isWelcome = location?.state?.isWelcome;

  const isProviderApproved = VerifyProvider === "Approved";
  const isProviderPending = VerifyProvider === "Pending";
  const hasPin = HasPin === "True";

  const showCreatePinPanel =
    !isSystemAdminImpersonating &&
    !hasPin &&
    (isProviderApproved || isProviderPending);

  const filterTemplate = () => (
    <div className="mb-20 flex flex-col gap-4 overflow-y-auto">
      <div>
        <h3 className="text-md mb-3 font-bold">Sort By</h3>
        <div className="flex flex-col gap-2">
          {SORT_BY.map((s) => {
            return (
              <div key={s.label} className="flex items-center">
                <RadioButton
                  inputId={s.label}
                  value={s.value}
                  onChange={(e) => setSort(e.target.value)}
                  checked={sort === s.value}
                />
                <label htmlFor={s.label} className="ml-2 capitalize">
                  {s.label}
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-1">
        <h3 className="text-md mb-3 font-bold">Location</h3>
        <div className="card justify-content-center flex flex-1">
          <ListBox
            filter
            value={selectedState}
            onChange={(e) => setSelectedState(e.target.value)}
            options={cities}
            className="w-full"
            listStyle={{ height: "280px" }}
            virtualScrollerOptions={{ itemSize: 38 }}
          />
        </div>
      </div>
      <Button
        onClick={() => setSelectedState(null)}
        label="Clear"
        outlined
        className="flex justify-center p-6"
      />
    </div>
  );

  return (
    <>
      {showCreatePinPanel && <PinStatusPanel />}
      {currentEntity?.providerExist === false && <ProviderNotificationPanel />}
      {!isDesktop ? (
        <CompletionChecklist view="order" isWelcome={isWelcome} />
      ) : null}
      <div className="flex gap-2">
        {!isMobile && (
          <div className="hidden w-fit py-5 sm:flex">
            <Dropdown
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
              options={cities}
              showClear={selectedState ?? false}
              placeholder="Select State"
              className="w-44"
            />
          </div>
        )}
        <div className="flex-1">
          <ReusableFilter
            searchTitle={
              "Search by Doing business as name, Services, HCPCs and Group practice name."
            }
            filterTemplate={filterTemplate}
            searchValue={searchService}
            keyFilter={blockSpecialCharsPattern}
            sendSearchData={(e) => setSearchService(e.target.value)}
            onSearch={[() => setFirst(0), getServices]}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
