import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { trimValidation } from "../../../../../../../utils/helpers";

const SingleSelectDropdown = ({ inputNamePath, question }) => {
  const { control } = useFormContext();
  const singleAnswerIndex = 0;

  return (
    <Controller
      name={`${inputNamePath}.answers.${singleAnswerIndex}.value`}
      control={control}
      rules={{
        required: "Please select a valid value",
        validate: (answer) => trimValidation(answer.value, `Answer`),
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
          <label htmlFor={field.name} className="col-span-3 font-semibold">
            Value
          </label>
          <div className="col-span-9 flex flex-col gap-2">
            <Dropdown
              {...field}
              inputId={field.name}
              options={question.answers}
              optionLabel="value"
              optionValue="id"
              placeholder={"Select An Answer"}
              className={`w-full ${error ? "p-invalid" : ""} `}
            />
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        </div>
      )}
    />
  );
};

export default SingleSelectDropdown;
