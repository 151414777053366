import { useRef } from "react";

import { useNavigate } from "react-router-dom";

import { Toast } from "primereact/toast";
import { Avatar } from "primereact/avatar";

import useHandleResize from "../../../../hooks/useHandleResize";
import { ReusableDataList } from "../../../../components/Table";

function RemitsPending() {
  const toast = useRef(null);
  const Transaction = async (rowData, navigate) => {
    navigate(`/admin/worklists/complete/${rowData.entityId}`, {
      state: { rowData },
    });
  };
  const { isMobile } = useHandleResize();

  const ActionTemplateComponent = ({ rowData }) => {
    const navigate = useNavigate();
    const handleTransactionClick = () => {
      Transaction(rowData, navigate);
    };

    return (
      <div
        onClick={handleTransactionClick}
        className="text-md will-change sm:px-auto flex w-auto cursor-pointer items-center justify-center rounded-full bg-green-500 px-4 py-1 font-semibold capitalize text-white hover:bg-green-600 sm:w-[70%] sm:py-3"
      >
        Go To Transaction
        <i className="pi pi-arrow-right ml-2"></i>
      </div>
    );
  };

  const nameImagTemplate = (rowData) => (
    <>
      <div className="flex items-center">
        {rowData.imageUrl ? (
          <img
            src={rowData.imageUrl}
            alt={rowData.practiceName}
            className="mr-2 h-[30px] w-[30px] rounded-full"
          />
        ) : (
          <Avatar
            label={rowData.practiceName[0]}
            shape="circle"
            className="mr-2 bg-blue-500 text-white"
          />
        )}
        <h4 className="font-inter text-sm font-semibold">
          {rowData.practiceName}
        </h4>
      </div>
    </>
  );

  const columns = [
    { header: "Name", field: "practiceName", template: nameImagTemplate },
    {
      header: "Outstanding Transactions Count",
      field: "numberOfResultedOrders",
    },
    {
      template: (rowData) => <ActionTemplateComponent rowData={rowData} />,
      header: "Action",
    },
  ];

  const cardTemplate = (row) => {
    return (
      <>
        {nameImagTemplate(row)}
        <p className="font-semibold">
          <span className="text-slate-500">
            Number of Outstanding Transactions:&nbsp;
          </span>
          {row.numberOfResultedOrders}
        </p>

        <div className="flex flex-col gap-2 sm:items-center">
          <div className="flex gap-2 font-semibold">
            <span className="text-slate-500">Action:&nbsp;</span>
          </div>
          <ActionTemplateComponent rowData={row} />
        </div>
      </>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <ReusableDataList
        title={"List of rendering providers"}
        fromFront={true}
        dataLink={"/Entities/GetRemitsWorkList"}
        cardFilters={["practiceName"]}
        columns={columns}
        mobileTemplate={cardTemplate}
      />
    </>
  );
}

export default RemitsPending;
