import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { MultiSelect } from "primereact/multiselect";

const MultiSelectDropdown = ({ inputNamePath, question }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={`${inputNamePath}.answers`}
      control={control}
      rules={{
        validate: {
          empty: (answers) =>
            answers.length > 1 || "Select two or more answers.",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
          <label htmlFor={field.name} className="col-span-3 font-semibold">
            Value
          </label>
          <div className="col-span-9 flex flex-col gap-2">
            <MultiSelect
              {...field}
              inputId={field.name}
              value={field.value.map((obj) => obj.value)}
              onChange={(e) => {
                field.onChange(e.value?.map((value) => ({ value: value })));
              }}
              options={question.answers}
              optionLabel="value"
              optionValue="id"
              placeholder={"Select Answers"}
              className={`w-full ${error ? "p-invalid" : ""} `}
            />
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        </div>
      )}
    />
  );
};

export default MultiSelectDropdown;
