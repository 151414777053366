import React, { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import AddTeamMemberDialog from "./AddTeamMemberDialog";
import { showErrorToast, showSuccessToast } from "../../utils/apiUtils";
import {
  IsCertifiedTemplate,
  ProviderTemplate,
} from "../templates/columnBodyTemplates";

export default function ManageTeamForm({
  teamId,
  setShouldRefetch,
  submitCallback,
}) {
  const { http } = useAxios();
  const { entityId } = useTokenData();

  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    if (teamId) {
      getTeamData(teamId);
    } else {
      setIsLoading(false);
    }
  }, [teamId]);

  const getTeamData = async (teamId) => {
    setIsLoading(true);
    try {
      const response = await http.get(`/Teams/GetTeamData/${teamId}`);
      setValue("name", response.data.team.name);
      setMembers(response.data.entityUsers);
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMember = (selectedMembers) => {
    setMembers((prev) => [...prev, ...selectedMembers]);
  };

  const handleDeleteMember = (entityUserId) => {
    setMembers((prev) =>
      prev.filter((member) => member.entityUserId !== entityUserId),
    );
  };

  const onCancel = () => {
    submitCallback();
  };

  const onSubmit = async (formData) => {
    const modifiedFormData = {
      ...formData,
      teamEntityUsersIds: members.map((member) => member.entityUserId),
    };

    try {
      let response;
      if (teamId) {
        response = await http.put(
          `/Teams/UpdateEntityTeam/${teamId}`,
          modifiedFormData,
        );
      } else {
        response = await http.post(
          `/Teams/CreateEntityTeam/${entityId}`,
          modifiedFormData,
        );
      }

      setShouldRefetch && setShouldRefetch(true);
      showSuccessToast({ message: response.data.message });
      submitCallback();
      reset();
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div>
        <i
          onClick={() => {
            handleDeleteMember(rowData.entityUserId);
          }}
          className="pi pi-times mr-2 cursor-pointer text-red-600 hover:font-bold"
          data-pr-tooltip="Delete"
          data-pr-position="bottom"
        />
      </div>
    );
  };

  const columns = [
    { field: "name", header: "Name" },
    {
      field: "role",
      header: "Role",
      template: ProviderTemplate,
      style: { width: "15%" },
    },
    {
      field: "isCertified",
      header: "Certified",
      template: IsCertifiedTemplate,
    },
    { field: "action", header: "Action", template: actionTemplate },
  ];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)(e);
      }}
    >
      <div className="flex flex-col gap-4 p-3">
        <div>
          <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-row">
            <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-center">
              <label className="text-md font-semibold">
                Team Name<span className="text-red-500">*</span> &nbsp;
              </label>
              {isLoading ? (
                <Skeleton width="12rem" height="3rem" className="" />
              ) : (
                <InputText
                  type="text"
                  placeholder="Team Name"
                  disabled={isSubmitting}
                  {...register("name", {
                    required: "Team name is required",
                  })}
                  className={`${errors?.name ? "p-invalid" : ""}`}
                />
              )}
            </div>

            <AddTeamMemberDialog
              onSave={handleAddMember}
              isLoading={isLoading}
              currentMembers={members.map((member) => member.entityUserId)}
            />
          </div>
          <div className="ml-[7rem] h-7">
            {errors?.name && (
              <p className="text-red-500">{errors?.name?.message}</p>
            )}
          </div>
          <DataTable
            value={members}
            pt={{
              loadingOverlay: {
                style: {
                  background: "#C6C1FF40",
                },
              },
            }}
            loading={isLoading}
            emptyMessage={
              <div className="text-center">No members available</div>
            }
            className="rounded-md border border-gray-200 p-2"
          >
            {columns.map((column) => (
              <Column
                key={column.field}
                field={column.field}
                header={column.header}
                body={column.template}
                align="center"
              />
            ))}
          </DataTable>
        </div>

        <div className="flex flex-col gap-4 sm:flex-row sm:self-end">
          <Button
            disabled={isSubmitting}
            type="button"
            severity="danger"
            onClick={onCancel}
            label="Cancel"
          />
          <Button
            label={teamId ? "Save Changes" : "Create Team"}
            loading={isSubmitting}
          ></Button>
        </div>
      </div>
    </form>
  );
}
