import { useLocation } from "react-router-dom";

import { TabPanel, TabView } from "primereact/tabview";

import PendingOrders from "./components/PendingOrders";
import ArchivedOrders from "./components/ArchivedOrders";
import FinalizedOrders from "./components/FinalizedOrders";
import { InboundOrders } from "./components/InboundOrders";

function OrderingProvider() {
  const location = useLocation();
  const activeIndex = location.state?.activeIndex;

  return (
    <>
      <div>
        <TabView
          activeIndex={activeIndex || 0}
          panelContainerClassName="!bg-[#edf1f5] sm:p-auto !p-0"
        >
          <TabPanel
            header="Inbound"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <InboundOrders />
          </TabPanel>
          <TabPanel
            header="Pending"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <PendingOrders />
          </TabPanel>
          <TabPanel
            header="Finalized"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <FinalizedOrders />
          </TabPanel>
          <TabPanel
            header="Archived"
            pt={{
              root: {
                className: "flex-1 sm:flex-initial",
              },
            }}
          >
            <ArchivedOrders />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}

export default OrderingProvider;
