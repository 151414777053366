import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { RadioButton } from "primereact/radiobutton";

const ToggleInput = ({ inputNamePath }) => {
  const { control } = useFormContext();
  const singleAnswerIndex = 0;
  return (
    <Controller
      name={`${inputNamePath}.answers.${singleAnswerIndex}.value`}
      control={control}
      rules={{
        required: { value: false },
        validate: (answer) => answer !== null || "Please enter a valid value",
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
          <label htmlFor={field.name} className="col-span-3 font-semibold">
            Value
          </label>
          <div className="flex flex-col gap-2 md:col-span-9">
            <div className="flex w-full items-center justify-between gap-2 capitalize">
              <label
                htmlFor={`${field.name}-false`}
                className={`align-items-center flex flex-1 cursor-pointer rounded-md border ${field.value === false ? "border-dark-purple bg-slate-50 shadow-md" : "border-transparent"} px-4 py-2`}
              >
                <RadioButton
                  {...field}
                  inputId={`${field.name}-false`}
                  value={false}
                  checked={field.value === false}
                />
                <span className="ml-2">No</span>
              </label>
              <label
                htmlFor={`${field.name}-true`}
                className={`align-items-center flex flex-1 cursor-pointer rounded-md border ${field.value === true ? "border-dark-purple bg-slate-50 shadow-md" : "border-transparent"} px-4 py-2`}
              >
                <RadioButton
                  {...field}
                  inputId={`${field.name}-true`}
                  value={true}
                  checked={field.value === true}
                />
                <span className="ml-2">Yes</span>
              </label>
            </div>
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        </div>
      )}
    />
  );
};

export default ToggleInput;
