import { useEffect, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { confirmDialog } from "primereact/confirmdialog";

import RulesCard from "./RulesCard";
import ManageRulesForm from "./ManageRulesForm";
import { useServiceStore } from "../../utils/store";
import useFetchBillablesByIds from "./useFetchBillablesByIds";
import { useServiceDetailsContext } from "../../context/ServiceDetailsContext";
import useHandleResize from "../../../../../../../hooks/useHandleResize";
import {
  getAllBillableItemsInRules,
  getBillableItemsIds,
} from "./actions/helpers";
import {
  ACTIONS_ENUM,
  DEFAULT_CONDITION,
  OPERATORS,
  RULE_TYPES,
} from "../../utils/constants";

const RulesGroups = () => {
  const initialConditionalGroup = {
    id: uuidv4(),
    operator: OPERATORS.ALL.value,
    conditions: [DEFAULT_CONDITION],
  };

  const initialGroupRule = {
    id: uuidv4(),
    type: RULE_TYPES.GROUP.value,
    operator: OPERATORS.ALL.value,
    action: { key: ACTIONS_ENUM.ADD_CPT.value, on: [] },
    conditionalGroups: [
      { ...initialConditionalGroup, id: uuidv4() },
      { ...initialConditionalGroup, id: uuidv4() },
    ],
  };

  const initialSingleRule = {
    id: uuidv4(),
    type: RULE_TYPES.SINGLE.value,
    operator: OPERATORS.ALL.value,
    action: { key: ACTIONS_ENUM.ADD_CPT.value, on: [] },
    conditionalGroups: [{ ...initialConditionalGroup, id: uuidv4() }],
  };

  const initialAction = {
    id: uuidv4(),
    type: RULE_TYPES.ACTION.value,
    action: { key: ACTIONS_ENUM.ADD_CPT.value, on: [] },
  };

  const { rulesGroups, setRulesGroups } = useServiceStore();
  const { isMobile } = useHandleResize();
  const [open, setOpen] = useState({
    isVisible: false,
    rules: null,
    isNewRulesGroup: true,
  });

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedRulesGroups = Array.from(rulesGroups);
    const [removed] = updatedRulesGroups.splice(result.source.index, 1);
    updatedRulesGroups.splice(result.destination.index, 0, removed);
    setRulesGroups(updatedRulesGroups);
  };

  const handleCloseDialog = () => {
    confirmDialog({
      message: "Are you sure you want to discard the changes you have made?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Discard",
      rejectLabel: "Keep Changes",
      pt: { root: { style: { width: `${isMobile ? "90%" : ""}` } } },
      accept: () =>
        setOpen({ isVisible: false, rules: null, isNewRulesGroup: true }),
    });
  };

  const { billableItems, getBillableItems } = useFetchBillablesByIds();

  const { defaultBillableItems } = useServiceDetailsContext();
  useEffect(() => {
    const allBillableItems = getBillableItemsIds([
      ...getAllBillableItemsInRules(),
      ...defaultBillableItems,
    ]);
    getBillableItems(allBillableItems);
  }, [rulesGroups]);

  const handleMoveToTop = (index) => {
    if (index <= 0) return;
    const updatedRulesGroups = [...rulesGroups];
    const [movedItem] = updatedRulesGroups.splice(index, 1);
    updatedRulesGroups.unshift(movedItem);
    setRulesGroups(updatedRulesGroups);
  };

  const handleMoveToBottom = (index) => {
    if (index >= rulesGroups.length - 1) return;
    const updatedRulesGroups = [...rulesGroups];
    const [movedItem] = updatedRulesGroups.splice(index, 1);
    updatedRulesGroups.push(movedItem);
    setRulesGroups(updatedRulesGroups);
  };

  return (
    <>
      <Dialog
        draggable={false}
        blockScroll
        visible={open.isVisible}
        className="w-11/12 lg:w-4/5"
        onHide={handleCloseDialog}
        header={
          open?.rules?.type === RULE_TYPES.ACTION.value
            ? "Manage Actions"
            : "Manage Rules"
        }
      >
        <ManageRulesForm
          rulesGroup={open.rules}
          isNewRulesGroup={open.isNewRulesGroup}
          setOpen={setOpen}
        />
      </Dialog>
      <div className="space-y-4 p-2">
        <div className="flex justify-between">
          <span className="flex items-center gap-2">
            <i className="pi pi-cog text-lg"></i>
            <h2 className="text-lg font-semibold text-gray-600">
              Manage Rules
            </h2>
          </span>
          <div className="flex items-center gap-4">
            <Button
              type="button"
              label="Add Action"
              icon="pi pi-plus"
              outlined
              className="w-full sm:w-auto"
              severity="success"
              onClick={() =>
                setOpen({
                  isVisible: true,
                  rules: { ...initialAction, id: uuidv4() },
                  isNewRulesGroup: true,
                })
              }
            />
            <Button
              type="button"
              label="Add Rule"
              icon="pi pi-plus"
              outlined
              className="w-full sm:w-auto"
              severity="info"
              onClick={() =>
                setOpen({
                  isVisible: true,
                  rules: { ...initialSingleRule, id: uuidv4() },
                  isNewRulesGroup: true,
                })
              }
            />
            <Button
              type="button"
              label="Add Group Of Rules "
              icon="pi pi-plus"
              severity="info"
              className="w-full sm:w-auto"
              onClick={() =>
                setOpen({
                  isVisible: true,
                  rules: { ...initialGroupRule, id: uuidv4() },
                  isNewRulesGroup: true,
                })
              }
            />
          </div>
        </div>
        <div className="rounded-xl border border-gray-100 bg-slate-50 p-4">
          {rulesGroups.length === 0 && (
            <div className="flex h-16 items-center justify-center">
              <p>
                Looks like you haven't added any rules yet. Add a new question
                to get started.
              </p>
            </div>
          )}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="rules">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-4"
                >
                  {rulesGroups.map((rulesGroup, cardIndex) => (
                    <Draggable
                      key={rulesGroup.id}
                      draggableId={rulesGroup.id}
                      index={cardIndex}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RulesCard
                            dataOfAllBillableItems={billableItems}
                            rulesGroup={rulesGroup}
                            cardIndex={cardIndex}
                            setOpen={setOpen}
                            handleMoveToTop={() => handleMoveToTop(cardIndex)}
                            handleMoveToBottom={() =>
                              handleMoveToBottom(cardIndex)
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};

export default RulesGroups;
