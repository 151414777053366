import { useState } from "react";

import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { ReusableDataList } from "../Table";
import useAxios from "../../hooks/useAxios";
import { formatDate } from "../../utils/helpers";
import { useLayoutToast } from "../../store/store";
import useTokenData from "../../hooks/useTokenData";
import {
  InvitationStatusTemplate,
  ProviderTemplate,
} from "../templates/columnBodyTemplates";
import { showErrorToast } from "../../utils/apiUtils";

const StaffDashboardInvitations = ({ refetch, setRefetch }) => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { layoutToast } = useLayoutToast();

  const [loadingResendInvitation, setLoadingResendInvitation] = useState({});

  const handleResendInvitation = async (rowData) => {
    setLoadingResendInvitation((prevState) => ({
      ...prevState,
      [rowData.id]: true,
    }));
    try {
      const response = await http.post(
        `/Entities/ResendStaffInvitation/${rowData.id}`,
      );
      const data = response.data;
      layoutToast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: data.message,
        life: 3000,
      });
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setLoadingResendInvitation((prevState) => ({
        ...prevState,
        [rowData.id]: false,
      }));
    }
  };

  const handleDelete = async (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        try {
          await new Promise((resolve) => {
            deleteRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const deleteRow = async (rowData) => {
    try {
      const endPointUrl = `/Invitations/${rowData.id}`;

      const res = await http.delete(endPointUrl);

      setRefetch((prev) => !prev);
      layoutToast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data,
        life: 2000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const actionInvitationTemplate = (rowData) => {
    return (
      <>
        <Button
          onClick={() => handleDelete(rowData)}
          link
          tooltip="delete"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i
            className="pi pi-trash text-red-500"
            style={{ fontSize: "1rem" }}
          ></i>
        </Button>
        <Button
          onClick={() => handleResendInvitation(rowData)}
          disabled={loadingResendInvitation[rowData.id]}
          loading={loadingResendInvitation[rowData.id]}
          link
          tooltip="Resend Invitation"
          tooltipOptions={{
            showDelay: 1000,
            hideDelay: 300,
            position: "bottom",
          }}
        >
          <i className="pi pi-undo" style={{ fontSize: "1rem" }}></i>
        </Button>
      </>
    );
  };

  const cardInvitationsTemplate = (row) => {
    return (
      <>
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">{row.invitedUserEmail}</p>
          <div className="flex">{actionInvitationTemplate(row)}</div>
        </div>
        <div className="flex justify-between gap-2">
          <p className="text-slate-500">{formatDate(row.invitationDate)}</p>
        </div>

        <div className="flex items-center gap-2 font-semibold">
          <span className="font-semibold text-slate-500">Status:&nbsp;</span>
          {InvitationStatusTemplate(row)}
        </div>
        <div className="flex items-center gap-2 font-semibold">
          <span className="font-semibold text-slate-500">Status:&nbsp;</span>
          {ProviderTemplate(row)}
        </div>
      </>
    );
  };
  const TABLE_COLUMNS = [
    { field: "invitedUserEmail", header: "Email", style: { width: "25%" } },
    {
      field: "invitationDate",
      header: "Date",
      template: (rowData) => formatDate(rowData.invitationDate, true),
      style: { width: "15%" },
    },
    {
      template: InvitationStatusTemplate,
      header: "Status",
    },
    {
      template: ProviderTemplate,
      header: "Role",
      style: { width: "15%" },
    },
    {
      template: actionInvitationTemplate,
      header: "Actions",
    },
  ];

  return (
    <ReusableDataList
      title={"All Invitations"}
      fromFront={true}
      dataLink={`/Invitations/GetInvitationRequestsByEntityId/${entityId}`}
      columns={TABLE_COLUMNS}
      searchTitle="search by email"
      mobileTemplate={cardInvitationsTemplate}
      cardFilters={["invitedUserEmail"]}
      refetch={refetch}
      setRefetch={setRefetch}
    />
  );
};

export default StaffDashboardInvitations;
