import { useEffect, useState } from "react";

import { Button } from "primereact/button";

import useAxios from "../../hooks/useAxios";
import { useCapturedPhoto } from "../../store/store";
import { showErrorToast } from "../../utils/apiUtils";

function CapturedPhotoPreview({
  setCapturedViaMobilePhoto,
  sessionId,
  setShowCapturedPhotoPreview,
}) {
  const { http } = useAxios();

  const { capturedPhoto, setCapturedPhoto } = useCapturedPhoto();
  const [driverLicenseFront, setDriverLicenseFront] = useState(null);
  const [driverLicenseBack, setDriverLicenseBack] = useState(null);

  const endCaptureSession = async () => {
    try {
      await http.put(
        `/Identifications/ExpireCaptureIdentityViaMobileSession/${sessionId}`,
      );
      setShowCapturedPhotoPreview(false);
      if (capturedPhoto?.filePropertyName === 1) {
        setCapturedViaMobilePhoto([driverLicenseFront, driverLicenseBack]);
      } else {
        setCapturedViaMobilePhoto(capturedPhoto);
      }
      setCapturedPhoto(null);
    } catch (error) {
      showErrorToast({ error });
    }
  };
  useEffect(() => {
    if (capturedPhoto?.filePropertyName === 1) {
      if (capturedPhoto.driverLicenseType === "front") {
        setDriverLicenseFront(capturedPhoto);
      } else {
        setDriverLicenseBack(capturedPhoto);
      }
    }
  }, [capturedPhoto]);

  return (
    <div className="flex flex-col items-center">
      <div>
        {capturedPhoto?.filePropertyName === 1 ? (
          <div className="grid grid-cols-2 gap-3">
            <div className="col-span-1 flex min-h-[12rem]">
              {driverLicenseFront?.driverLicenseType === "front" ? (
                <img
                  src={driverLicenseFront?.url}
                  alt="Captured"
                  className="w-80 rounded-lg border"
                />
              ) : (
                <div className="flex h-full w-80 items-center justify-center rounded-lg border">
                  Driver's License Front Side
                </div>
              )}
            </div>
            <div className="col-span-1 flex min-h-[12rem]">
              {driverLicenseBack?.driverLicenseType === "back" ? (
                <img
                  src={driverLicenseBack?.url}
                  alt="Captured"
                  className="w-80 rounded-lg border"
                />
              ) : (
                <div className="flex h-full w-80 items-center justify-center rounded-lg border">
                  Driver's License Back Side
                </div>
              )}
            </div>
          </div>
        ) : (
          <img
            src={capturedPhoto?.url}
            alt="Captured"
            className="w-80 rounded-lg border"
          />
        )}
      </div>
      <div className="pt-4">
        <Button
          onClick={endCaptureSession}
          label="Accept & End Session"
          severity="info"
          icon="pi pi-check"
          type="button"
        />
      </div>
    </div>
  );
}

export default CapturedPhotoPreview;
