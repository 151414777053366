export const SORT_BY = [
  {
    label: "price (Low to High)",
    value: "minFees",
  },
  {
    label: "price (High to Low)",
    value: "-minFees",
  },
];
