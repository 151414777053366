import { Controller, useFormContext } from "react-hook-form";

import WeightInput from "./WeightInput";
import { NUMBER_TYPE_ENUM } from "../../utils/constants";
import StandardNumericInput from "./StandardNumericInput";
import AmericanNumericInput from "./AmericanNumericInput";

const NumberInput = ({ inputNamePath, question }) => {
  const { control } = useFormContext();
  const singleAnswerIndex = 0;

  const isStandard =
    question?.constraints?.type === NUMBER_TYPE_ENUM.STANDARD.value;
  const isWeight =
    question?.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;
  return (
    <Controller
      name={`${inputNamePath}.answers.${singleAnswerIndex}.value`}
      control={control}
      rules={{
        required: "Please enter a valid number",
        validate: () => true,
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
          <label htmlFor={field.name} className="col-span-3 font-semibold">
            Value
          </label>
          <div className="flex flex-col gap-2 md:col-span-9">
            <div className="flex-1">
              {isStandard ? (
                <StandardNumericInput
                  field={field}
                  error={error}
                  questionType={question.type}
                />
              ) : isWeight ? (
                <WeightInput
                  field={field}
                  error={error}
                  unit={question?.constraints?.storeAs}
                  questionType={question.type}
                />
              ) : (
                <AmericanNumericInput
                  id={field.name}
                  value={field.value}
                  error={error}
                  onChange={field.onChange}
                />
              )}
            </div>
          </div>
          <div className="col-span-9 col-start-4">
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        </div>
      )}
    />
  );
};

export default NumberInput;
