import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import useTokenData from "../../hooks/useTokenData";
import LocationServicesDialog from "../../pages/rendering/locations/components/LocationServicesDialog";

function LocationCard({
  location,
  confirmDelete,
  handleEyeToggle,
  entityPage = false,
}) {
  const [showServices, setShowServices] = useState(false);

  const navigate = useNavigate();

  const { userRoles } = useTokenData();

  const showRelatedServices = (location) => {
    setShowServices(true);
  };

  return (
    <>
      <Tooltip target=".locTooltip" />
      <div
        className={`flex max-w-full rounded-lg border p-4 ${
          location.isEnabled ? "bg-white" : "bg-gray-50"
        }`}
      >
        <Dialog
          blockScroll
          draggable={false}
          visible={showServices}
          onHide={() => setShowServices(false)}
          header={`Services Rendered at ${location.name}`}
          className="w-11/12 lg:w-[60vw]"
        >
          <LocationServicesDialog location={location} />
        </Dialog>
        <div className="flex w-9/12 flex-col gap-2">
          <h3 className="text-xl font-bold capitalize text-gray-700">
            {location.name}{" "}
          </h3>
          <div className="flex items-baseline gap-1">
            <i className="pi pi-map-marker text-gray-700" />
            <p className="inline text-gray-600">{`${location.address.city}, ${location.address.state}, ${location.address.zipCode}`}</p>
          </div>
        </div>
        <div className="flex w-3/12 items-end justify-end gap-3">
          {!entityPage ? (
            userRoles === "Admin" ? (
              <>
                {!location.isPrincipal && (
                  <>
                    <i
                      className="pi pi-list locTooltip mr-2 cursor-pointer text-purple-700"
                      onClick={() => showRelatedServices(location)}
                      data-pr-tooltip="Show Services"
                      data-pr-position="bottom"
                    />
                    <i
                      className="pi pi-pencil locTooltip mr-2 cursor-pointer text-purple-700"
                      onClick={() => {
                        navigate(`/rendering/mylocations/locationForm`, {
                          state: { locationData: location },
                        });
                      }}
                      data-pr-tooltip="Edit"
                      data-pr-position="bottom"
                    />

                    <i
                      onClick={() => {
                        confirmDelete(location.id);
                      }}
                      className="pi pi-trash locTooltip mr-2 cursor-pointer text-red-600"
                      data-pr-tooltip="Delete"
                      data-pr-position="bottom"
                    />

                    {!location.isEnabled ? (
                      <>
                        <i
                          className="pi pi-eye-slash locTooltip cursor-pointer"
                          onClick={() => {
                            handleEyeToggle(location);
                          }}
                          data-pr-tooltip="Enable"
                          data-pr-position="bottom"
                        />
                      </>
                    ) : (
                      <>
                        <i
                          className="pi pi-eye locTooltip cursor-pointer"
                          onClick={() => {
                            handleEyeToggle(location);
                          }}
                          data-pr-tooltip="Disable"
                          data-pr-position="bottom"
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : null
          ) : null}
        </div>
      </div>
    </>
  );
}

export default LocationCard;
