import { TabView, TabPanel } from "primereact/tabview";
import QuestionBank from "./QuestionBank";
import RulesGroups from "./rules/RulesGroups";

const ServiceOptions = () => {
  return (
    <div>
      <TabView>
        <TabPanel header="Question Bank">
          <QuestionBank />
        </TabPanel>
        <TabPanel header="Conditions and Actions">
          <RulesGroups />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default ServiceOptions;
