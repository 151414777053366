import { v4 as uuidv4 } from "uuid";
import { Controller, useFormContext } from "react-hook-form";

import { Editor } from "primereact/editor";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import AnswerOptions from "./AnswerOptions";
import {
  MULTI_OPTION_QUESTION_TYPES_LIST,
  QUESTION_TYPES_ENUM,
  QUESTION_TYPES_LIST,
} from "../utils/constants";
import { getDefaultConstraints } from "../utils/utils";
import { Tooltip } from "primereact/tooltip";
import { useServiceStore } from "../utils/store";

const QuestionDetailsForm = ({ isQuestionInCondition }) => {
  const { watch, control, setValue, clearErrors } = useFormContext();
  const { questions } = useServiceStore();

  const selectedTypeTemplate = (type, props) => {
    if (type) return typeOptionTemplate(type);

    return <span>{props.placeholder}</span>;
  };

  const typeOptionTemplate = (type) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        {type.icon}
        <div>{type.label}</div>
      </div>
    );
  };
  return (
    <div className="flex flex-col gap-2 overflow-y-auto">
      <Tooltip target=".question-info" />
      <div className="flex flex-col gap-3 rounded-lg border p-5">
        <div className="grid grid-cols-12 gap-3">
          <Controller
            control={control}
            rules={{
              required: "Question label is required",
              validate: (value, formValues) =>
                !questions
                  .filter((question) => question.id !== formValues.id)
                  .some((question) => question.label === value) ||
                "Question already exists.",
            }}
            name="label"
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-12 flex flex-col gap-1 lg:col-span-8">
                <label htmlFor={field.name} className="font-semibold">
                  Question
                </label>
                <InputText className="w-full" {...field} id={field.name} />
                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
          <Controller
            control={control}
            name="type"
            rules={{
              required: "Please choose question type",
            }}
            render={({ field, fieldState: { error } }) => (
              <div className="col-span-12 flex flex-col gap-1 lg:col-span-4">
                <span className="flex gap-1">
                  <label htmlFor={field.name} className="font-semibold">
                    Choose Question Type:
                  </label>
                  {isQuestionInCondition && (
                    <i
                      className="pi pi-info-circle question-info mr-1 text-sm text-gray-600"
                      data-pr-position="top"
                      data-pr-tooltip="Question is linked to another question or rule"
                    ></i>
                  )}
                </span>
                <Dropdown
                  id={field.name}
                  {...field}
                  options={
                    [
                      QUESTION_TYPES_ENUM.DROPDOWN.value,
                      QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value,
                    ].includes(field.value) && isQuestionInCondition
                      ? MULTI_OPTION_QUESTION_TYPES_LIST
                      : QUESTION_TYPES_LIST
                  }
                  valueTemplate={selectedTypeTemplate}
                  itemTemplate={typeOptionTemplate}
                  onChange={(e) => {
                    setValue("constraints", getDefaultConstraints(e.value));
                    clearErrors("constraints");
                    if (
                      e.value === QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value ||
                      e.value === QUESTION_TYPES_ENUM.DROPDOWN.value
                    ) {
                      if (
                        watch("type") !==
                          QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value &&
                        watch("type") !== QUESTION_TYPES_ENUM.DROPDOWN.value
                      ) {
                        setValue("answers", [
                          { id: uuidv4(), value: "" },
                          { id: uuidv4(), value: "" },
                        ]);
                      }
                    } else if (e.value === QUESTION_TYPES_ENUM.BOOLEAN.value) {
                      setValue("answers", [{ value: "yes" }, { value: "no" }]);
                    } else {
                      setValue("answers", []);
                    }

                    field.onChange(e.value);
                  }}
                  placeholder="Select Type"
                  className="w-full"
                  disabled={
                    isQuestionInCondition &&
                    ![
                      QUESTION_TYPES_ENUM.DROPDOWN.value,
                      QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value,
                    ].includes(field.value)
                  }
                />
                {error && <p className="text-red-500">{error.message}</p>}
              </div>
            )}
          />
        </div>
        <div className="grid grid-cols-12 gap-3">
          <Controller
            control={control}
            name="required"
            render={({ field }) => (
              <div className="col-span-12 lg:col-span-8">
                <Checkbox {...field} inputId="required" checked={field.value} />
                <label htmlFor="required" className="cursor-pointer">
                  &nbsp;Require an Answer to this question
                </label>
              </div>
            )}
          />
        </div>
        <AnswerOptions questionType={watch("type")} />
        <div className="grid grid-cols-12 gap-3">
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <div className="col-span-12 flex flex-col gap-1">
                <label htmlFor={field.name} className="font-semibold">
                  Question Description
                </label>
                <Editor
                  id={field.name}
                  value={field.value}
                  onTextChange={(e) => field.onChange(e.htmlValue)}
                  style={{
                    height: "164px",
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionDetailsForm;
