import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// admin routes
import SystemExpire from "./pages/admin/expired/SystemExpire";
import AdminLogin from "./pages/admin/login/AdminLogin";
import ManageAdmins from "./pages/admin/manageAdmins/ManageAdmins";
import OurPractices from "./pages/admin/practices/OurPractices";
import PracticeDetails from "./pages/admin/practices/practiceDetails/PracticeDetails";
import AdminRegistration from "./pages/admin/registration/AdminRegistration";
import EnvironmentSettings from "./pages/admin/settings/environment/EnvironmentSettings";
import AdminGlobalInfo from "./pages/admin/settings/globalInformations/AdminGlobalInfo";
import SystemAdminNotifs from "./pages/admin/settings/notifications/SystemAdminNotifs";
import AdminChangePassword from "./pages/admin/settings/password/AdminChangePassword";
import TransactionTracking from "./pages/admin/transactionTracking/TransactionTracking";
import { WorkLists } from "./pages/admin/workLists/WorkLists";
import RemitsComplete from "./pages/admin/workLists/completeTransaction/RemitsComplete";
import { Provider } from "./pages/admin/workLists/providerInfo/Provider";

// global routes
import CaptureViaMobile from "./pages/captureViaMobile/CaptureViaMobile";
import EmbeddedStripeCheckout from "./pages/checkout/EmbeddedStripeCheckout";
import ChooseEntity from "./pages/chooseEntity/ChooseEntity";
import OAuthRegistration from "./pages/completeOAuthRegister/OAuthRegistration";
import VerifyEmail from "./pages/emailVerification/VerifyEmail";
import { ForgetPassword } from "./pages/forgetPassword/ForgetPassword";
import { LandingPage } from "./pages/landing/LandingPage";
import Login from "./pages/login/Login";
import NotFound from "./pages/notFound/NotFound";
import SignUp from "./pages/register/SignUp";
import { ResetPassword } from "./pages/resetPassword/ResetPassword";
import EmailVerifySuccess from "./pages/setupPractice/EmailVerifySuccess";
import GroupPracticeFinishStep from "./pages/setupPractice/groupPractice/GroupPracticeFinishStep";
import GroupPracticeNextStep from "./pages/setupPractice/groupPractice/GroupPracticeNextStep";
import IndividualFinishStep from "./pages/setupPractice/individualPractice/IndividualFinishStep";
import IndividualNextStep from "./pages/setupPractice/individualPractice/IndividualNextStep";
import ExistingGroupPractices from "./pages/setupPractice/joinPractice/ExistingGroupPractices";
import JoinEntity from "./pages/setupPractice/joinPractice/JoinEntity";
import EmbeddedStripeReturn from "./pages/stripePaymentSuccess/EmbeddedStripeReturn";
import ViewInvitation from "./pages/viewInvitation/ViewInvitation";
import Welcome from "./pages/welcome/Welcome";
import WelcomeProvider from "./pages/welcomeProvider/WelcomeProvider";

// backend routes
import Seq from "./pages/seq/Seq";

// legal routes
import Legal from "./pages/legal/Legal";
import PrivacyPolicy from "./pages/legal/privacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/legal/tos/TermsAndConditions";

// rendering routes
import DashboardRendering from "./pages/rendering/dashboard/Dashboard";
import LocationsExplore from "./pages/rendering/locations/LocationsExplore";
import LocationForm from "./pages/rendering/locations/locationForm/LocationForm";
import OrderingProvider from "./pages/rendering/orders/OrderingProvider";
import OrderDetails from "./pages/rendering/orders/orderDetails/OrderDetails";
import { BillableItem } from "./pages/rendering/services/billableItems/BillableItem";
import BillableItemsReport from "./pages/rendering/services/billableItems/report/BillableItemsReport";
import DocumentsLibrary from "./pages/rendering/services/documents/DocumentsLibrary";
import { MyServices } from "./pages/rendering/services/my-services/MyServices";
import DepositSettings from "./pages/rendering/settings/deposit/DepositSettings";
import { OrderSettings } from "./pages/rendering/settings/orders/OrderSettings";
import RenderingStaff from "./pages/rendering/staff/RenderingStaff";
import { Transactions } from "./pages/rendering/transactions/Transactions";

// ordering routes
import FindServices from "./pages/ordering/findServices/FindServices";
import { OrderService } from "./pages/ordering/orderService/OrderService";
import OrderingOrders from "./pages/ordering/orders/OrderingOrders";
import FavoritePractices from "./pages/ordering/practices/FavoritePractices";
import EntityPage from "./pages/ordering/practices/practiceInfo/EntityPage";
import VerifyProvider from "./pages/ordering/settings/providerVerification/VerifyProvider";
import OrderingStaff from "./pages/ordering/staff/OrderingStaff";

// shared settings
import Settings from "./pages/settings/Settings";
import UserNotificationSettings from "./pages/settings/accountNotifications/UserNotificationSettings";
import EditGroupPractice from "./pages/settings/groupPractice/EditGroupPractice";
import EditIndividualPractice from "./pages/settings/individualPractice/EditIndividualPractice";
import PracticeNotificationSettings from "./pages/settings/practiceNotifications/PracticeNotificationSettings";
import PrivacyPreferences from "./pages/settings/privacy/PrivacyPreferences";
import ProfileSettings from "./pages/settings/profile/ProfileSettings";
import Security from "./pages/settings/security/Security";

// redirects
import AcceptInvitation from "./pages/redirect/AcceptInvitation";
import CompleteProviderVerification from "./pages/redirect/CompleteProviderVerification";
import CompleteVerificationSuccess from "./pages/redirect/CompleteVerificationSuccess";
import DeclineInvitation from "./pages/redirect/DeclineInvitation";
import DeclinedInvitation from "./pages/redirect/DeclinedInvitation";
import EmailOrderUpdate from "./pages/redirect/EmailOrderUpdate";
import ExpiredLink from "./pages/redirect/ExpiredLink";
import ManageOrderFromProvider from "./pages/redirect/ManageOrderFromProvider";
import OrderAccepted from "./pages/redirect/OrderAccepted";
import OrderDenied from "./pages/redirect/OrderDenied";
import ProviderAlreadyAccepted from "./pages/redirect/ProviderAlreadyAccepted";
import ProviderAlreadyDenied from "./pages/redirect/ProviderAlreadyDenied";

// guards
import { AdminAuthGaurd } from "./guards/AdminAuthGaurd";
import { AdminLoginAuthGaurd } from "./guards/AdminLoginAuthGaurd";
import AuthGuard from "./guards/AuthGuard";
import EmailSuccessGuard from "./guards/EmailSuccessGuard";
import ExpireSystemAdminGuard from "./guards/ExpireSystemAdminGuard";
import ExternalLoginGuard from "./guards/ExternalLoginGuard";
import IsHiddenGuard from "./guards/IsHiddenGuard";
import LandingPageGuard from "./guards/LandingPageGuard";
import { PersonateAuthGaurd } from "./guards/PersonateAuthGaurd";
import ProtectedRoute from "./guards/ProtectedRoute";
import UserRoleGuard from "./guards/UserRoleGuard";

// hooks
import useTokenData from "./hooks/useTokenData";
import OrderCompletedAction from "./pages/redirect/OrderCompletedAction";
import ManageService from "./pages/rendering/services/my-services/manage-service/ManageService";
import RenderingGuard from "./guards/RenderingGuard";
import OrderingTeams from "./pages/ordering/teams/OrderingTeams";
import ManageTeam from "./components/teams/ManageTeam";

const AppRoutes = () => {
  const location = useLocation();
  const { isCompletedRegister } = useTokenData();
  const isAdminRegistrationRoute =
    location.pathname === "/admin/registration" &&
    location.search.includes("I=");

  return (
    <Routes>
      <Route element={<ExpireSystemAdminGuard />}>
        <Route path="/admin/expired" element={<SystemExpire />} />
      </Route>

      <Route element={<LandingPageGuard />}>
        <Route path="/" element={<LandingPage />} />
      </Route>
      <Route element={<AuthGuard />}>
        <Route element={<RenderingGuard />}>
          <Route path="/welcome" element={<Welcome />} />
        </Route>
        <Route path="/welcome-provider" element={<WelcomeProvider />} />
      </Route>
      <Route path="/completeRegRedirect" element={<OAuthRegistration />} />
      <Route
        exact
        path="/completeRegistration"
        element={
          isCompletedRegister == "False" ? (
            <Navigate to="/completeRegRedirect" replace={false} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/verifyEmail" element={<VerifyEmail />} />
      <Route element={<EmailSuccessGuard />}>
        <Route path="/verifySuccess" element={<EmailVerifySuccess />} />
      </Route>
      <Route
        path="/setUpIndividual/:entityType"
        element={<IndividualNextStep />}
      />
      <Route path="/setUpIndividualFinish" element={<IndividualFinishStep />} />
      <Route
        path="/setUpDeposit"
        RenderingGuard
        element={<DepositSettings fromSetup={true} />}
      />
      <Route
        path="/setUpGroupNextStep/:entityType"
        element={<GroupPracticeNextStep />}
      />

      <Route
        path="/setUpGroupFinishStep"
        element={<GroupPracticeFinishStep />}
      />

      <Route path="/joinAnExistingGroup" element={<ExistingGroupPractices />} />

      <Route path="/login" element={<ExternalLoginGuard />}>
        <Route index element={<Login />} />
      </Route>
      {/* Embedded stripe checkout */}
      <Route path="/checkout" element={<EmbeddedStripeCheckout />} />
      <Route path="/return" element={<EmbeddedStripeReturn />} />

      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route
        path="/admin/forgetPassword"
        element={<ForgetPassword isAdmin />}
      />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/admin/resetPassword" element={<ResetPassword isAdmin />} />
      <Route path="/joinEntity" element={<JoinEntity />} />
      <Route path="/registration" element={<SignUp />} />
      <Route path="/ViewInvitation" element={<ViewInvitation />} />
      <Route path="/:admin/ViewInvitation" element={<ViewInvitation />} />
      <Route path="/declineInvitation" element={<DeclineInvitation />} />
      <Route path="/declinedInvitation" element={<DeclinedInvitation />} />
      <Route path="/acceptInvitation" element={<AcceptInvitation />} />
      <Route path="/order-accepted" element={<OrderAccepted />} />
      <Route path="/order-denied" element={<OrderDenied />} />
      <Route path="/expired-link" element={<ExpiredLink />} />
      <Route
        path="/complete-provider-data"
        element={<CompleteProviderVerification />}
      />
      <Route
        path="/verified-success"
        element={<CompleteVerificationSuccess />}
      />
      <Route
        path="/order-already-Accepted"
        element={<ProviderAlreadyAccepted />}
      />
      <Route path="/order-confirmation" element={<ManageOrderFromProvider />} />
      <Route path="/order-already-denied" element={<ProviderAlreadyDenied />} />
      <Route path="/redundent-link" element={<OrderCompletedAction />} />

      {/* start admin */}
      <Route path="/admin/login" element={<AdminLoginAuthGaurd />}>
        <Route index element={<AdminLogin />} />
      </Route>

      <Route element={<PersonateAuthGaurd />}>
        <Route
          path="/chooseEntity"
          element={
            isCompletedRegister == "False" ? (
              <Navigate to="/completeRegRedirect" replace={false} />
            ) : (
              <ChooseEntity />
            )
          }
        />
        <Route
          path="/admin/registration"
          element={
            isAdminRegistrationRoute ? (
              <AdminRegistration />
            ) : (
              <Navigate
                to={
                  location.pathname.includes("admin")
                    ? "/admin/login"
                    : "/login"
                }
              />
            )
          }
        />
      </Route>
      {/* end admin */}
      <Route element={<ProtectedRoute />}>
        {/* start admin */}
        <Route element={<AdminAuthGaurd />}>
          <Route path="/admin/worklists" element={<WorkLists />} />
          <Route path="/admin/worklists/provider/:id" element={<Provider />} />
          <Route path="/admin/ourPractices" element={<OurPractices />} />
          <Route
            path="/admin/settings/passwordSetting"
            element={<AdminChangePassword />}
          />
          <Route
            path="/admin/settings/environment"
            element={<EnvironmentSettings />}
          />
          <Route
            path="/admin/settings/notificationsSettings"
            element={<SystemAdminNotifs />}
          />
          <Route
            path="/admin/transactionTracking"
            element={<TransactionTracking />}
          />
          <Route
            path="/admin/settings/admin-information"
            element={<AdminGlobalInfo />}
          />
          <Route
            path="/admin/worklists/complete/:id"
            element={<RemitsComplete />}
          />
          <Route path="/admin/ourPractices/:id" element={<PracticeDetails />} />
          <Route path="/admin/manageAdmins" element={<ManageAdmins />} />
        </Route>
        {/* end admin */}
        <Route element={<PersonateAuthGaurd />}>
          {/*start render view  */}
          <Route element={<RenderingGuard />}>
            <Route path="/Dashboard" element={<DashboardRendering />} />
            <Route element={<UserRoleGuard />}>
              <Route path="/rendering/staff" element={<RenderingStaff />} />
              <Route
                path="/rendering/mylocations/locationForm"
                element={<LocationForm />}
              />
              <Route
                path="/rendering/settings/individualSetting"
                element={<EditIndividualPractice />}
              />
              <Route
                path="/rendering/settings/DepositSettings"
                element={<DepositSettings />}
              />

              {/* (start) SERVICE WIZARD REFACTOR */}

              <Route
                path="/services/my-services/manage-service/:serviceId?"
                element={<ManageService />}
              />

              {/* (end) SERVICE WIZARD REFACTOR */}

              <Route path="/services/my-services" element={<MyServices />} />

              <Route
                path="/services/billable-items"
                element={<BillableItem />}
              />

              <Route
                path="/services/billable-items/report"
                element={<BillableItemsReport />}
              />
              <Route
                path="/services/documents"
                element={<DocumentsLibrary />}
              />
              <Route
                path="/rendering/Transactions"
                element={<Transactions />}
              />
            </Route>
            <Route
              path="/rendering/mylocations"
              element={<LocationsExplore />}
            />
            <Route path="/rendering/Settings" element={<Settings />} />
            <Route
              path="/rendering/settings/privacy"
              element={<PrivacyPreferences />}
            />
            <Route
              path="/rendering/settings/ProfileSettings"
              element={<ProfileSettings />}
            />
            <Route path="/rendering/settings/security" element={<Security />} />
            <Route
              path="/rendering/settings/groupSettings"
              element={<EditGroupPractice />}
            />
            <Route
              path="/rendering/settings/OrderSettings"
              element={<OrderSettings />}
            />
            <Route
              path="/rendering/settings/userNotifications"
              element={<UserNotificationSettings />}
            />
            <Route
              path="/rendering/settings/practiceNotifications"
              element={<PracticeNotificationSettings />}
            />
            <Route path="/rendering/Orders" element={<OrderingProvider />} />
            <Route
              path="/rendering/Orders/:orderId"
              element={<OrderDetails />}
            />
          </Route>
          {/* end render view  */}
          {/* start order view  */}
          <Route element={<UserRoleGuard />}>
            <Route path="/ordering/staff" element={<OrderingStaff />} />
            <Route
              path="/ordering/settings/individualSetting"
              element={<EditIndividualPractice />}
            />
            <Route path="/ordering/teams" element={<OrderingTeams />} />
          </Route>
          <Route element={<IsHiddenGuard />}>
            <Route path="/ordering/FindServices" element={<FindServices />} />
            <Route path="/ordering/practices" element={<FavoritePractices />} />
          </Route>
          <Route
            path="/ordering/practices/:practiceId"
            element={<EntityPage />}
          />
          <Route path="/ordering/Settings" element={<Settings />} />
          <Route
            path="/ordering/settings/privacy"
            element={<PrivacyPreferences />}
          />
          <Route
            path="/ordering/settings/ProfileSettings"
            element={<ProfileSettings />}
          />
          <Route path="/ordering/settings/security" element={<Security />} />
          <Route path="/ordering/Orders" element={<OrderingOrders />} />
          <Route
            path="/ordering/settings/groupSettings"
            element={<EditGroupPractice />}
          />
          <Route
            path="/ordering/settings/ProfileSettings"
            element={<ProfileSettings />}
          />

          <Route
            path="/ordering/settings/verifyProvider"
            element={<VerifyProvider />}
          />

          <Route
            path="/ordering/settings/userNotifications"
            element={<UserNotificationSettings />}
          />
          <Route
            path="/ordering/settings/practiceNotifications"
            element={<PracticeNotificationSettings />}
          />
          <Route path="/ordering/teams/:teamId" element={<ManageTeam />} />
          {/* end order vie  */}
        </Route>
        <Route
          path="/ordering/RequestService/:serviceId"
          element={<OrderService />}
        />
      </Route>
      <Route path="/legal/tos" element={<TermsAndConditions />} />
      <Route path="/legal/privacy" element={<PrivacyPolicy />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/EmailOrderUpdate" element={<EmailOrderUpdate />} />
      <Route path="/CaptureViaMobile" element={<CaptureViaMobile />} />
      <Route path="/seq" element={<Seq />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
