import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import { trimValidation } from "../../../../utils/helpers";
import { showPinConfirmation } from "../../../../utils/pinUtils";
import { TaxonomyTree } from "../../../../components/formElements";
import { IdentityVerification } from "../../../../components/forms";
import useRefreshTokenHook from "../../../../hooks/useRefreshTokenHook";
import FormSkeleton from "../../../../components/skeletons/FormSkeleton";
import { CompletePracticeStatusPanel } from "../../../../components/banners";
import { useRefreshTokenStore, useTokenStore } from "../../../../store/store";
import { showErrorToast } from "../../../../utils/apiUtils";

function VerifyProvider({
  userIdTable,
  identifierIdTable,
  statusTable,
  setProviderFormDialog,
  refetchData,
  setRefetchData,
}) {
  const [resEntityId, setResEntityId] = useState();
  const [entityData, setEntityData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [IncomingData, setIncomingData] = useState();
  const [identifierId, setIdentifierId] = useState();
  const { entityId, VerifyProvider, impersonatedBy, HasPin } = useTokenData();
  const [isCompleted, setIsCompleted] = useState(true);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedChooseList, setSelectedChooseList] = useState([]);
  const [isEntityDataLoading, setIsEntityDataLoading] = useState();
  const [selectedOptionsList, setSelectedOptionsList] = useState([]);

  const toast = useRef(null);
  const { http } = useAxios();
  const navigate = useNavigate();
  const location = useLocation();
  const identityRef = useRef(null);
  const { userId } = useTokenData();
  const { setToken } = useTokenStore();
  const { refresh } = useRefreshTokenHook();
  const { setRefreshToken } = useRefreshTokenStore();
  //set pin after create verify

  const fromSetup = location?.state?.fromSetup;

  const state = {
    isWelcome: true,
    fromSetup: fromSetup,
    activeIndex: 1,
  };

  // Check if the event key is a keyboard key you want to ignore
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        return;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //   fetch state data from api
  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setSelectedState(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const validateLicenseNumber = (value, allValues) => {
    const licenseNumbers = allValues.medicalLicenses.map(
      (license) => license.licenseNumber,
    );

    const count = licenseNumbers.filter((num) => num === value).length;
    if (count > 1) {
      return "License Number must be unique";
    } else if (!value.trim()) {
      return "License Number can not be empty";
    }

    return undefined;
  };

  const defaultValues = {
    identification: {
      taxID: "",
      modelType: 2,
      IdentityImageFile: {
        FileDetails: null,
        FileType: 1,
        FilePropertyName: null,
      },
      SelfieImageFile: {
        FileDetails: null,
        FileType: 1,
        FilePropertyName: 4,
      },
      entityId: entityId,
      applicationUserId: userId,
    },
    entityImageFile: {
      FileDetails: null,
      FileType: 1,
      FilePropertyName: 3,
    },

    primaryAddress: {
      lineOne: "",
      lineTwo: null,
      state: "",
      city: null,
      zipCode: "",
    },
    npIs: [
      {
        npiNumber: "",
      },
    ],
    assignTaxonomies: selectedOptionsList,
    medicalLicenses: [
      {
        licenseNumber: "",
        stateId: "",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "medicalLicenses", // unique name for your Field Array
  });

  const addMedicalLicense = () => {
    append({
      licenseNumber: "",
      stateId: "",
    });
  };

  useEffect(() => {
    getStates();
  }, []);

  const getEntityData = async () => {
    setIsEntityDataLoading(true);
    try {
      const res = await http.get(
        `/Identifications/GetVerifyProviderData/${userIdTable || userId}`,
      );
      setEntityData(res);
      if (
        !res?.data?.identification?.identityImage ||
        !res?.data?.identification?.selfieImage
      ) {
        setIsCompleted(false);
      }
      setIncomingData(res.data);
      setIdentifierId(res.data.identifierId);
      setSelectedOptionsList(res.data.assignTaxonomies);
      setSelectedChooseList(res.data.assignTaxonomies);
      setResEntityId(res.data.identification.entityId);
      setValue("identification.taxID", res.data.identification.taxID);
      setValue("primaryAddress.lineOne", res.data.primaryAddress.lineOne);
      setValue("primaryAddress.lineTwo", res.data.primaryAddress.lineTwo);
      setValue("primaryAddress.city", res.data.primaryAddress.city);
      setValue("primaryAddress.state", res.data.primaryAddress.state);
      setValue("primaryAddress.zipCode", res.data.primaryAddress.zipCode);
      setValue(
        "identification.IdentityImageFile.FileDetails",
        res.data.identification.identityImage,
      );
      setValue(
        "identification.SelfieImageFile.FileDetails",
        res.data.identification.selfieImage,
      );
      setValue("medicalLicenses", res.data.medicalLicenses);
      setValue("npIs", res.data.npIs);
      setValue("assignTaxonomies", res.data.assignTaxonomies);
      res.data.identification.identityImageType === "Passport"
        ? setValue("identification.IdentityImageFile.FilePropertyName", 2)
        : setValue("identification.IdentityImageFile.FilePropertyName", 1);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsEntityDataLoading(false);
  };

  const scrollToIdentitySection = () => {
    if (isCompleted) return;
    if (!identityRef.current) return;
    identityRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });
  };

  useEffect(scrollToIdentitySection, [isCompleted]);

  useEffect(() => {
    if (userIdTable === undefined) {
      if (VerifyProvider !== "NULL") {
        getEntityData();
      }
    } else {
      if (statusTable !== "Null") {
        getEntityData();
      }
    }
  }, [VerifyProvider]);

  const onSubmit = async (formData) => {
    const url = identifierId
      ? `/Identifications/EditIndividual/${identifierIdTable || identifierId}`
      : `/Identifications/VerifyProvider/${userIdTable || userId}`;
    const data = new FormData();
    if ((VerifyProvider !== "NULL" || statusTable !== "Null") && resEntityId) {
      data.append("Identification.EntityId", resEntityId);
    }
    data.append("Identification.ApplicationUserId", userIdTable || userId);
    data.append("Identification.TaxID", formData.identification.taxID);
    data.append("EntityImageFile", null);
    data.append("Identification.ModelType", formData.identification.modelType);
    data.append(
      "PrimaryAddress.LineOne",
      formData?.primaryAddress?.lineOne || "",
    );
    data.append(
      "PrimaryAddress.LineTwo",
      formData?.primaryAddress?.lineTwo || "",
    );
    data.append("PrimaryAddress.City", formData?.primaryAddress?.city || null);
    data.append(
      "PrimaryAddress.State",
      formData?.primaryAddress?.state || null,
    );
    data.append(
      "PrimaryAddress.ZipCode",
      formData?.primaryAddress?.zipCode || null,
    );
    data.append(
      "TechnicalSupportEmail",
      entityData?.data?.technicalSupportEmail || null,
    );
    data.append(
      "TechnicalSupportPhoneNumber",
      entityData?.data?.technicalSupportPhoneNumber || null,
    );
    data.append(
      "TechnicalSupportName",
      entityData?.data?.technicalSupportName || null,
    );
    data.append(
      "Identification.IdentityImageFile.FileDetails",
      formData.identification.IdentityImageFile.FileDetails || null,
    );
    data.append(
      "Identification.IdentityImageFile.FileType",
      formData.identification.IdentityImageFile.FileType,
    );
    data.append(
      "Identification.IdentityImageFile.FilePropertyName",
      formData.identification.IdentityImageFile.FilePropertyName || 0,
    );
    data.append(
      "Identification.SelfieImageFile.FileDetails",
      formData.identification.SelfieImageFile.FileDetails,
    );
    data.append(
      "Identification.SelfieImageFile.FileType",
      formData.identification.SelfieImageFile.FileType,
    );
    data.append(
      "Identification.SelfieImageFile.FilePropertyName",
      formData.identification.SelfieImageFile.FilePropertyName || 0,
    );
    formData.npIs.forEach((npi, index) => {
      data.append(`NPIs[${index}].NpiNumber`, npi.npiNumber);
    });

    formData.medicalLicenses.forEach((medicalLicense, index) => {
      data.append(
        `medicalLicenses[${index}].licenseNumber`,
        medicalLicense.licenseNumber,
      );
      data.append(`medicalLicenses[${index}].stateId`, medicalLicense.stateId);
    });
    selectedChooseList.forEach((taxonomy, index) => {
      data.append(`assignTaxonomies[${index}].taxonomyId`, taxonomy.taxonomyId);
      data.append(`assignTaxonomies[${index}].isPrimary`, taxonomy.isPrimary);
      data.append(`assignTaxonomies[${index}].label`, taxonomy.label);
    });
    let errors = [];
    if (!selectedChooseList.length) {
      errors.push("Please select at least one taxonomy");
    }
    if (errors.length > 0) {
      const error = new Error(errors.join("\n"));
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 4000,
      });
    } else {
      try {
        setIsLoading(true);
        const response = await http.request({
          method: identifierId ? "put" : "post",
          url: url,
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        const currentRefreshToken = JSON.parse(
          localStorage.getItem("refreshToken"),
        ).state.refreshToken;
        const refreshTokenResponse = await refresh.post(
          `/Authentication/RefreshToken/${entityId}`,
          {
            refreshToken: currentRefreshToken,
            impersonatedBy,
          },
        );
        setToken(refreshTokenResponse.data.token);
        setRefreshToken(refreshTokenResponse.data.refreshToken);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.message,
          life: 4000,
        });

        if (!userIdTable) {
          HasPin === "True"
            ? navigate("/ordering/settings")
            : showPinConfirmation(handleAccept, handleReject);
        } else {
          setTimeout(() => {
            setProviderFormDialog(false);
          }, 1000);
          setRefetchData(!refetchData);
        }
      } catch (error) {
        showErrorToast({ error });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setValue("assignTaxonomies", selectedOptionsList);
  }, [selectedOptionsList, setValue]);

  const handleCancel = () => {
    if (!userIdTable) {
      navigate(-1);
    } else {
      setProviderFormDialog(false);
    }
  };

  // show confirm message set pin if create verify and change order view which no pin
  const handlePinNavigate = (path) => {
    if (path) {
      navigate(path, { state });
    }
  };
  const handleAccept = () => handlePinNavigate("/ordering/settings/Security");
  const handleReject = () => navigate("/ordering/settings");

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog draggable={false} />

      <div className="flex items-center justify-center">
        <div className="w-full rounded-3xl bg-light-text py-8 pl-10 pr-10 shadow shadow-slate-300">
          <div className="mb-8">
            <h4 className="mb-4 text-center text-xl font-bold capitalize sm:text-left sm:text-4xl">
              verify provider profile
            </h4>
          </div>
          {isEntityDataLoading ? (
            <FormSkeleton />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-container mt-3 w-11/12">
                <div className="border-b-2 pb-11">
                  <label
                    htmlFor="ssn"
                    className="font-Poppins text-base capitalize leading-loose text-gray-500"
                  >
                    Tax ID <span className="text-red-500">*</span>
                  </label>
                  <span className="p-input-icon-right w-full">
                    <Controller
                      control={control}
                      name="identification.taxID"
                      rules={{
                        required: "Tax ID is required",
                        pattern: {
                          value: /^(?:\d-?){9}$/,
                          message: "Tax ID must be exactly 9 digits",
                        },
                        validate: (value) => trimValidation(value, "Tax ID"),
                      }}
                      render={({ field }) => (
                        <InputText
                          id="taxIDInput"
                          placeholder="Enter nine digit tax id number"
                          className="w-full pb-2 pt-2"
                          {...field}
                          maxLength={17}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </span>
                  {errors.identification && errors.identification.taxID && (
                    <p className="text-red-500">
                      {errors.identification.taxID.message}
                    </p>
                  )}

                  <div className="mt-3">
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      Home/Primary Address{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <span className="p-input-icon-right w-full">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        address line one <span className="text-red-500">*</span>
                      </label>
                      <Controller
                        control={control}
                        name="primaryAddress.lineOne"
                        rules={{
                          required: "Address line one is required",
                          validate: (value) =>
                            trimValidation(value, "Address line one"),
                        }}
                        render={({ field }) => (
                          <InputText
                            placeholder="address line one"
                            className="w-full pb-2 pt-2"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </span>
                    {errors.primaryAddress && errors.primaryAddress.lineOne && (
                      <p className="mt-1 text-red-500">
                        {errors.primaryAddress.lineOne.message}
                      </p>
                    )}
                    <span className="p-input-icon-right mt-3 w-full">
                      <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                        address line Two
                      </label>
                      <InputText
                        placeholder="Address Line Two"
                        className="w-full pb-2 pt-2"
                        {...register("primaryAddress.lineTwo", {})}
                      />
                    </span>

                    <div
                      ref={identityRef}
                      className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3"
                    >
                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          City <span className="text-red-500">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="primaryAddress.city"
                          rules={{
                            required: "City is required",
                            validate: (value) => trimValidation(value, "City"),
                          }}
                          render={({ field }) => (
                            <InputText
                              placeholder="City"
                              className="w-full"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            />
                          )}
                        />
                        {errors.primaryAddress &&
                          errors.primaryAddress.city && (
                            <p className="mt-1 text-red-500">
                              {errors.primaryAddress.city.message}
                            </p>
                          )}
                      </span>

                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          state<span className="text-red-500">*</span>
                        </label>
                        <Controller
                          name="primaryAddress.state"
                          control={control}
                          rules={{ required: "Please select a state" }}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              value={field.value}
                              placeholder="State"
                              focusInputRef={field.ref}
                              options={selectedState.map((option) => ({
                                value: option.name,
                                label: option.name,
                              }))}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              className="md:w-14rem w-full"
                            />
                          )}
                        />
                        {errors.primaryAddress &&
                          errors.primaryAddress.state && (
                            <p className="mt-1 text-red-500">
                              {errors.primaryAddress.state.message}
                            </p>
                          )}
                      </span>

                      <span className="col-span-1">
                        <label className="block font-inter font-normal capitalize leading-loose text-gray-500">
                          ZIP Code<span className="text-red-500">*</span>
                        </label>
                        <Controller
                          control={control}
                          name="primaryAddress.zipCode"
                          rules={{
                            required: "ZIP Code is required",
                            pattern: {
                              value: /^\d{5}$/,
                              message: "ZIP Code must be exactly 5 digits",
                            },
                            validate: (value) =>
                              trimValidation(value, "ZIP Code"),
                          }}
                          render={({ field }) => (
                            <InputText
                              placeholder="ZIP Code"
                              className="w-full"
                              maxLength={5}
                              keyfilter="num"
                              {...field}
                              onChange={(e) => {
                                field.onChange(e);
                              }}
                            />
                          )}
                        />
                        {errors.primaryAddress &&
                          errors.primaryAddress.zipCode && (
                            <p className="mt-1 text-red-500">
                              {errors.primaryAddress.zipCode.message}
                            </p>
                          )}
                      </span>
                    </div>
                  </div>
                </div>
                {!userIdTable ? (
                  <>
                    {!isCompleted && <CompletePracticeStatusPanel />}
                    <div
                      className={`mt-5 border-b-2 pb-10 ${isCompleted ? "" : "rounded-lg border-2 border-[#434dc4] p-5"}`}
                    >
                      <IdentityVerification
                        setValue={setValue}
                        verificationData={IncomingData}
                      />
                    </div>
                  </>
                ) : null}

                <div className="mt-4">
                  <label
                    htmlFor="ssn"
                    className="font-Poppins text-base capitalize leading-loose text-gray-500"
                  >
                    NPI associated with your individual practice
                    <span className="ml-1 font-bold text-red-500">*</span>
                  </label>
                  <span className="p-input-icon-right w-full">
                    <InputText
                      id="npiNumber"
                      placeholder="0000000000"
                      className="w-full"
                      keyfilter={/^\d+$/}
                      {...register("npIs[0].npiNumber", {
                        required: "NPI is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "NPI must be exactly 10 digits",
                        },
                      })}
                      maxLength={10}
                    />
                  </span>
                  {errors.npIs &&
                    errors.npIs[0] &&
                    errors.npIs[0].npiNumber && (
                      <p className="mt-1 text-red-500">
                        {errors.npIs[0].npiNumber.message}
                      </p>
                    )}
                </div>
                <label className="mt-4 block font-Poppins text-base capitalize leading-loose text-gray-500">
                  medical license
                </label>
                <div ref={identityRef}>
                  {fields.map((field, index) => {
                    return (
                      <div
                        key={field.id}
                        className="grid grid-cols-12 gap-2 py-2"
                      >
                        <div className="col-span-12 flex flex-col gap-2 md:col-span-4">
                          <Controller
                            name={`medicalLicenses.${index}.stateId`}
                            control={control}
                            rules={{
                              required: "Please select a state",
                            }}
                            render={({ field }) => (
                              <div key={field.id}>
                                <Dropdown
                                  name={field.name}
                                  id={field.name}
                                  value={field.value}
                                  placeholder="State"
                                  focusInputRef={field.ref}
                                  options={selectedState.map((option) => ({
                                    value: option.id,
                                    label: option.name,
                                  }))}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                  }}
                                  className="w-full"
                                />
                              </div>
                            )}
                          />
                          {errors?.medicalLicenses?.[index]?.stateId && (
                            <div className="text-red-500">
                              {
                                errors?.medicalLicenses?.[index]?.stateId
                                  ?.message
                              }
                            </div>
                          )}
                        </div>
                        <div className="col-span-12 flex flex-col gap-2 md:col-span-4">
                          <Controller
                            name={`medicalLicenses.${index}.licenseNumber`}
                            control={control}
                            rules={{
                              validate: validateLicenseNumber,
                              required: "License Number is required",
                            }}
                            render={({ field }) => (
                              <InputText
                                {...field}
                                placeholder="E-12345"
                                className="w-full"
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                            )}
                          />
                          {errors?.medicalLicenses?.[index]?.licenseNumber && (
                            <div className="text-red-500">
                              {
                                errors?.medicalLicenses?.[index]?.licenseNumber
                                  ?.message
                              }
                            </div>
                          )}
                        </div>
                        <div className="col-span-12 flex flex-col gap-2 md:col-span-4">
                          <div className="flex items-center gap-2">
                            {fields.length > 1 && (
                              <span
                                className="flex items-center justify-center py-3"
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <i
                                  className="pi pi-trash cursor-pointer text-red-500"
                                  style={{ fontSize: "1.3rem" }}
                                ></i>
                              </span>
                            )}
                            {fields.length - 1 === index && (
                              <Button
                                type="button"
                                link
                                onClick={(e) => {
                                  addMedicalLicense();
                                }}
                                label="Add medical license"
                                icon="pi pi-plus"
                              ></Button>
                            )}
                          </div>
                          <div>&nbsp;</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-5">
                  <label className="mt-4 block font-Poppins text-base capitalize leading-loose text-gray-500">
                    Taxonomies associated with your individual practice
                  </label>
                  <TaxonomyTree
                    selectedChooseList={selectedChooseList}
                    selectedOptionsList={selectedOptionsList}
                    setSelectedChooseList={setSelectedChooseList}
                    setSelectedOptionsList={setSelectedOptionsList}
                  />
                  {!selectedChooseList[0] && (
                    <p className="mt-1 text-left text-red-500">
                      Select taxonomy & at least one is primary
                    </p>
                  )}
                </div>

                <div className="flex flex-col-reverse gap-5 md:flex-row md:justify-end">
                  <div className="">
                    <Button
                      onClick={handleCancel}
                      type="button"
                      className="w-full justify-center rounded-full py-2.5 font-inter font-medium capitalize text-light-purple md:mt-9 md:w-auto"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="flex w-full items-end justify-end md:w-auto">
                    {isLoading ? (
                      <div className="text-center">
                        <ProgressSpinner
                          strokeWidth={3}
                          style={{ width: "40px", height: "40px" }}
                        />
                      </div>
                    ) : (
                      <Button
                        type="submit"
                        className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text md:w-auto"
                      >
                        Save Changes
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}

export default VerifyProvider;
