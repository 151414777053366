import React from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { trimValidation } from "../../../../../../../utils/helpers";

const TextInputArray = ({ inputNamePath, question }) => {
  const { control, watch, trigger, getValues } = useFormContext();

  const {
    fields: answers,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: `${inputNamePath}.answers`,
  });
  const isMoreThanTwoAnswers = answers.length > 2;

  const handleChange = async (value, newValue) => {
    const answers = getValues(`${inputNamePath}.answers`);
    if (value) {
      const duplicateIndexes = answers
        .map((answer, idx) =>
          answer.value === value || answer.value === newValue ? idx : -1,
        )
        .filter((idx) => idx !== -1);
      if (duplicateIndexes.length >= 1) {
        await trigger(
          duplicateIndexes.map(
            (idx) => `${inputNamePath}.answers.${idx}.value`,
          ),
          { shouldFocus: false },
        );
      }
    }
  };
  return (
    <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
      <span className="col-span-3 font-semibold">Values</span>
      <div className="flex w-full flex-col gap-2 md:col-span-9">
        {answers.map((answer, answerIndex) => {
          const isLastAnswer = answerIndex === answers.length - 1;
          return (
            <Controller
              key={answer.id}
              name={`${inputNamePath}.answers.${answerIndex}.value`}
              control={control}
              rules={{
                required: "Please enter a valid value",
                validate: {
                  trim: (value) => trimValidation(value, `Answer`),
                  unique: (value, formValues) => {
                    const answers = watch(`${inputNamePath}.answers`);
                    const normalizedValue = value?.trim();
                    if (normalizedValue === "")
                      return `Answer Option can not be empty `;
                    const occurrences = answers.filter(
                      (answer) => answer.value === value,
                    ).length;
                    const lastOccurrenceIndex = answers.findLastIndex(
                      (answer) => answer.value === value,
                    );
                    const isLastOccurrence =
                      lastOccurrenceIndex === answerIndex;
                    const isDuplicate = occurrences > 1;

                    return isLastOccurrence && isDuplicate
                      ? `The value "${value}" is already entered. Please use a unique value.`
                      : true;
                  },
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <div className="col-span-9 flex w-full flex-col gap-2">
                  <div className="grid w-full grid-cols-12 items-baseline gap-2">
                    <label
                      htmlFor={field.name}
                      className="col-span-1 font-semibold"
                    >
                      {answerIndex + 1}.&nbsp;
                    </label>
                    <InputText
                      {...field}
                      id={field.name}
                      keyfilter={question?.constraints?.keyfilter}
                      placeholder={
                        question?.constraints?.placeholder || "Enter Value"
                      }
                      className="col-span-7"
                      onChange={(e) => {
                        field.onChange(e);
                        handleChange(field.value, e.target.value);
                      }}
                    />
                    {isMoreThanTwoAnswers && (
                      <Button
                        type="button"
                        severity="danger"
                        icon="pi pi-trash color-red"
                        onClick={() => remove(answerIndex)}
                        className="col-span-2"
                      />
                    )}
                    {isLastAnswer && (
                      <Button
                        type="button"
                        icon={"pi pi-plus"}
                        onClick={() => append({ value: "" })}
                        className="col-span-2 bg-light-purple text-sm md:m-0 md:text-base"
                      />
                    )}
                  </div>
                  {error && (
                    <p
                      className="text-bala text-red-500"
                      style={{
                        textWrap: "balance",
                      }}
                    >
                      {error.message}
                    </p>
                  )}
                </div>
              )}
            />
          );
        })}
        {question?.constraints?.placeholder && (
          <p className="text-xs italic text-gray-400">
            Ensure the name follows the format:{" "}
            {question?.constraints?.placeholder}
          </p>
        )}
      </div>
    </div>
  );
};

export default TextInputArray;
