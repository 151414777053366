function ProviderIcon() {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 1136.000000 1280.000000"
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
    >
      <g
        transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          fill="currentColor"
          d="M5480 11873 c-251 -25 -548 -115 -756 -228 -578 -315 -957 -851
    -1060 -1500 -22 -135 -24 -437 -5 -575 85 -603 435 -1140 953 -1462 192 -119
    432 -214 668 -263 154 -33 475 -45 637 -26 697 85 1293 511 1597 1142 270 559
    270 1204 2 1764 -303 633 -911 1065 -1611 1145 -84 9 -345 11 -425 3z"
        />
        <path
          fill="currentColor"
          d="M4196 7730 c-148 -57 -166 -69 -301 -205 -227 -228 -405 -518 -520
    -845 -80 -231 -144 -567 -148 -784 l-2 -109 65 -13 c406 -84 746 -531 850
    -1117 24 -136 42 -374 30 -397 -5 -9 -50 -51 -99 -94 -82 -70 -90 -79 -80 -98
    18 -33 6 -130 -20 -166 -71 -100 -225 -92 -287 14 -61 104 5 243 121 255 17 2
    51 0 77 -4 45 -8 49 -6 122 45 87 60 83 46 66 233 -57 608 -365 1086 -765
    1190 -71 18 -218 21 -295 4 -72 -15 -204 -76 -271 -126 -129 -96 -269 -275
    -349 -446 -103 -220 -170 -514 -170 -741 l0 -65 74 -50 c40 -28 76 -48 79 -45
    11 12 107 5 134 -10 70 -36 110 -118 93 -190 -11 -51 -38 -86 -86 -113 -138
    -79 -297 62 -239 213 9 25 16 46 14 47 -11 7 -155 134 -162 142 -11 14 0 218
    19 340 86 557 375 1002 744 1144 44 17 84 31 89 31 5 0 12 42 16 93 48 667
    239 1182 588 1590 27 31 46 57 42 57 -17 0 -222 -122 -358 -213 -970 -647
    -1609 -1689 -1787 -2912 -31 -217 -33 -205 61 -337 118 -164 249 -316 401
    -464 414 -401 913 -695 1538 -905 520 -174 1059 -271 1714 -310 261 -15 980
    -6 1206 15 576 55 1016 142 1465 291 657 218 1153 514 1585 945 168 167 263
    279 384 449 78 111 77 104 41 334 -158 1010 -659 1906 -1441 2578 -222 190
    -526 398 -754 514 -34 18 -34 18 -20 -2 8 -11 52 -65 98 -119 245 -291 428
    -652 530 -1044 l27 -104 73 -17 c218 -49 387 -199 470 -417 24 -61 26 -80 26
    -207 0 -127 -2 -146 -26 -207 -62 -164 -171 -289 -310 -357 -121 -59 -189 -74
    -322 -69 -126 5 -204 27 -298 85 -313 189 -407 593 -209 895 74 113 175 196
    304 249 59 23 59 23 53 60 -20 125 -105 360 -201 554 -186 377 -483 724 -814
    953 l-54 37 -16 -24 c-9 -14 -313 -551 -677 -1195 -399 -708 -666 -1171 -675
    -1171 -8 0 -257 454 -651 1190 -351 655 -643 1191 -650 1192 -7 1 -71 -20
    -142 -47z m2794 -2879 c6 -12 10 -98 10 -210 l0 -191 198 0 c141 0 201 -3 210
    -12 8 -8 12 -62 12 -178 0 -116 -4 -170 -12 -178 -9 -9 -69 -12 -210 -12
    l-198 0 0 -187 c0 -104 -3 -198 -6 -211 -6 -22 -7 -22 -188 -20 l-181 3 -5
    205 -5 205 -200 3 c-174 2 -202 4 -212 19 -16 21 -18 315 -3 344 10 18 23 19
    215 19 l205 0 0 203 c0 112 3 207 7 210 3 4 84 7 179 7 161 0 174 -1 184 -19z"
        />
        <path
          fill="currentColor"
          d="M8129 5967 c-165 -56 -267 -188 -277 -357 -10 -175 75 -317 237 -394
    60 -28 75 -31 166 -31 92 0 106 3 167 32 241 114 311 414 145 623 -103 130
    -280 181 -438 127z"
        />
      </g>
    </svg>
  );
}

export default ProviderIcon;
