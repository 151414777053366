import { useMemo, useState } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import AttachBillableItemForm from "../../AttachBillableItemForm";
import useTokenData from "../../../../../../../../hooks/useTokenData";
import { useServiceDetailsContext } from "../../../../../my-services/manage-service/context/ServiceDetailsContext";
import { getAllBillableItemsInRules, getBillableItemsIds } from "./helpers";
import SwappedBillableTable from "../../../../../my-services/components/SwappedBillableTable";
import { CreateBillableItem } from "../../../../../components/CreateBillableItem";
import useDelayedInputChange from "../../../../../../../../hooks/useDelayedInputChange";
import SelectSingleBillableTable from "../../../../../my-services/components/SelectSingleBillableTable";

const SwapBillableItems = () => {
  const { control, watch, setValue } = useFormContext();
  const { defaultBillableItems } = useServiceDetailsContext();

  const { entityId } = useTokenData();
  const [billableToSwapDialogVisible, setBillableToSwapDialogVisible] =
    useState(false);
  const [swappedBillableDialogVisible, setSwappedBillableDialogVisible] =
    useState(false);
  const [createBillableTableVisible, setCreateBillableTableVisible] =
    useState(false);

  const [refetch, setRefetch] = useState(false);
  const [searchValue, handleInputChange, setSearchValue] =
    useDelayedInputChange(refetch, setRefetch);

  const hideDialog = () => {
    setSearchValue("");
    setSwappedBillableDialogVisible(false);
  };

  const allBillableItems = useMemo(
    () =>
      getBillableItemsIds([
        ...getAllBillableItemsInRules(watch("id")),
        ...defaultBillableItems,
      ]),
    [],
  );

  const handleCreateBillableItem = (billableItem) => {
    setValue(
      "action.on.new",
      [
        {
          id: billableItem.id,
          units: billableItem.defaultUnits,
        },
      ],
      { shouldValidate: true },
    );
  };

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="create Billable Items"
        visible={createBillableTableVisible}
        onHide={() => {
          setCreateBillableTableVisible(false);
        }}
      >
        <CreateBillableItem
          setVisible={setCreateBillableTableVisible}
          handleCreateBillableItem={handleCreateBillableItem}
        />
      </Dialog>

      <Dialog
        blockScroll
        draggable={false}
        visible={billableToSwapDialogVisible}
        onHide={() => {
          setBillableToSwapDialogVisible(false);
        }}
        header="Choose a billable item to remove"
        className="w-11/12 lg:w-[80vw]"
      >
        <Controller
          name="action.on.old.0"
          control={control}
          render={({ field }) => (
            <SelectSingleBillableTable
              link={`/BillableItems/GetBillableItemsByIds/${entityId}?Filters=${searchValue}`}
              payload={allBillableItems}
              searchValue={searchValue}
              handleInputChange={handleInputChange}
              refetch={refetch}
              selection={{}}
              onSelectionChange={(e) => {
                setBillableToSwapDialogVisible(false);
                field.onChange({ id: e.value.id });
              }}
            />
          )}
        />
      </Dialog>
      <Dialog
        blockScroll
        draggable={false}
        visible={swappedBillableDialogVisible}
        onHide={hideDialog}
        header="Choose a billable item to add"
        className="w-11/12 lg:w-[80vw]"
      >
        <div className="flex flex-col">
          <Controller
            name="action.on.new.0"
            control={control}
            render={({ field }) => (
              <AttachBillableItemForm
                handleSave={({ billableItems }) => {
                  field.onChange(billableItems[0]);
                }}
                selectionMode="single"
                defaultValues={[watch("action.on.new.0")]}
                payload={allBillableItems}
              >
                <Divider
                  layout="horizontal"
                  align="center"
                  className="w-10/12 self-center"
                >
                  <b>OR</b>
                </Divider>
                <div className="flex justify-center">
                  <Button
                    id="serviceBillables"
                    label="Create new billable item"
                    type="button"
                    outlined
                    onClick={() => {
                      hideDialog();
                      setCreateBillableTableVisible(true);
                    }}
                  />
                </div>
                <Button
                  className="self-end"
                  label="Save"
                  onClick={hideDialog}
                />
              </AttachBillableItemForm>
            )}
          />
        </div>
      </Dialog>
      <div className="flex flex-col gap-3">
        <p className="flex items-center gap-2 font-inter text-lg font-bold text-gray-500">
          <i className="pi pi-minus text-red-500"></i>
          Billable Item To Remove
        </p>
        <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
          <Controller
            name="action.on.old.0"
            control={control}
            rules={{
              validate: (value) => {
                return (
                  value.id?.length > 0 ||
                  "Select a billable item to be replaced."
                );
              },
            }}
            render={({ fieldState: { error } }) => (
              <>
                <SwappedBillableTable
                  hideUnits
                  selectedBillable={watch("action.on.old")[0]}
                />
                {error && <p className="text-red-500">{error.message}</p>}
              </>
            )}
          />
          <Button
            type="button"
            className="self-end bg-light-purple"
            label="Choose a billable item to remove"
            onClick={() => setBillableToSwapDialogVisible(true)}
          />
        </div>
        <p className="flex items-center gap-2 font-inter text-lg font-bold text-gray-500">
          <i className="pi pi-plus text-green-500"></i>
          Billable Item To Add
        </p>
        <div className="flex flex-col gap-2 rounded-lg border border-slate-100 p-5">
          <Controller
            name="action.on.new.0"
            control={control}
            rules={{
              validate: (value) => {
                return (
                  value.id?.length > 0 || "Select a billable item to replace."
                );
              },
            }}
            render={({ fieldState: { error } }) => (
              <>
                <SwappedBillableTable
                  selectedBillable={watch("action.on.new")[0]}
                />

                {error && <p className="text-red-500">{error.message}</p>}
              </>
            )}
          />
          <Button
            type="button"
            className="self-end bg-light-purple"
            label="Choose a billable item to add"
            onClick={() => setSwappedBillableDialogVisible(true)}
          />
        </div>
      </div>
    </>
  );
};

export default SwapBillableItems;
