import { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../../hooks/useAxios";
import { Pagination } from "../../../../../components/Table";
import { showErrorToast } from "../../../../../utils/apiUtils";
import { BillableItemsAttributesTemplate } from "../manage-service/templates/BillableItemsAttributesTemplate";
import { ModTemplate } from "../manage-service/templates/ModTemplate";
import { PriceBillableTemplate } from "../manage-service/templates/PriceBillableTemplate";

const SelectSingleBillableTable = ({
  link,
  value,
  payload,
  refetch,
  searchValue,
  emptyMessage,
  onSelectionChange,
  handleInputChange,
}) => {
  const { http } = useAxios();
  const [first, setFirst] = useState(0);
  const [tableData, setTableData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    if (link) {
      setFirst(0);
      fetchData(0);
    }
  }, [refetch]);

  const fetchData = async (currPage) => {
    setIsLoading(true);
    try {
      const url = link + `&Page=${currPage + 1}&PageSize=5`;
      const response = await http.put(url, payload);
      setTableData(response.data);
      setTotalPages(response.data[0]?.count);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  const handlePageChange = (event) => {
    setFirst(event.first);
    if (first !== event.first) fetchData(event.page);
  };

  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const header = (
    <div className="flex items-center justify-end">
      <span className="p-input-icon-left min-w-[300px]">
        <i className="pi pi-search" />
        <InputText
          className="w-full"
          placeholder={"Search"}
          value={searchValue}
          onChange={handleInputChange}
        />
      </span>
    </div>
  );

  const columns = [
    { name: "HCPCS", value: "hcpcs" },
    {
      name: "Description",
      template: descriptionWithTooltipTemplate,
    },
    { header: "Mods", template: ModTemplate },
    {
      header: "Attributes",
      template: BillableItemsAttributesTemplate,
    },
    {
      name: "Price per unit",
      template: PriceBillableTemplate,
      header: "Price per unit",
    },
  ];

  return (
    <>
      <DataTable
        header={link ? header : undefined}
        loading={isLoading}
        value={tableData}
        emptyMessage={
          emptyMessage ? (
            emptyMessage
          ) : (
            <p className="text-center">
              Currently, there are no billable items selected.
            </p>
          )
        }
        selection={{}}
        selectionMode="single"
        onSelectionChange={onSelectionChange}
        paginator={value}
        rows={5}
        pt={{ loadingOverlay: { style: { backgroundColor: "#b9b5ff60" } } }}
        className="rounded-lg border-2 border-gray-200"
      >
        {columns.map((column) => (
          <Column
            key={column.name}
            header={column.name}
            align="center"
            alignHeader="center"
            style={column.style ? column.style : null}
            body={(rowData) => {
              if (column.template && typeof column.template === "function") {
                return column.template(rowData);
              } else {
                return rowData[column.value];
              }
            }}
          />
        ))}
      </DataTable>
      {link && (
        <Pagination
          currentPage={first}
          totalPages={totalPages}
          size={5}
          onPageChange={handlePageChange}
          template={{
            layout:
              "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
          }}
        />
      )}
    </>
  );
};

export default SelectSingleBillableTable;
