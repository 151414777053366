import { useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { confirmDialog } from "primereact/confirmdialog";

import useAxios from "../../hooks/useAxios";
import { fileTyper } from "../../utils/fileTypesUtils";
import UploadFile from "../../iconComponents/UploadFile";
import { downloadFileFromResponse } from "../../utils/helpers";
import { showErrorToast } from "../../utils/apiUtils";

function UploadFiles({
  orderData,
  uploadDialog = false,
  setUploadDialog,
  uploadedFiles,
  setUploadedFiles,
  storedFiles,
  setStoredFiles,
  refetch,
  setRefetch,
}) {
  const toast = useRef();
  const { http } = useAxios();
  const [savingStatus, setSavingStatus] = useState("idle");
  const [savingResultedStatus, setSavingResultedStatus] = useState("idle");
  let isSaveResultedClicked = false;
  const preventDefault = (event) => {
    event.preventDefault();
  };
  const handleFilesUpload = (files) => {
    Array.from(files).forEach((file) => {
      if (fileTyper(file.type) && isValidFileSize(file)) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            fileDetails: file,
            fileType: fileTyper(file.type),
            filePropertyName: file.name,
          },
        ]);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid file type or size.",
          life: 3000,
        });
      }
    });
  };

  const handleDownloadResultFile = async (resultFileId) => {
    try {
      const response = await http.get(
        `/Orders/DownloadResultFileById/${resultFileId}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
  };
  const saveTemplate = () => {
    <Button
      label="Save"
      onClick={() => {
        saveFiles();
      }}
    />;
  };
  const isValidFileSize = (file) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    return file.size <= maxSizeInBytes;
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    Array.from(files).forEach((file) => {
      if (fileTyper(file.type) && isValidFileSize(file)) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            fileDetails: file,
            fileType: fileTyper(file.type),
            filePropertyName: file.name,
          },
        ]);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid file type or size.",
          life: 3000,
        });
      }
    });
  };
  const saveFiles = async () => {
    const data = new FormData();

    if (orderData.status === 9 && !isSaveResultedClicked) {
      data.append("Status", 9);
      data.append("scheduledDate", orderData.scheduledDate);
    } else if (
      orderData.status === 8 &&
      !isSaveResultedClicked &&
      orderData.serviceType === 2
    ) {
      data.append("Status", 8);
    } else {
      data.append("Status", 12);
    }

    if (uploadedFiles[0] || (isSaveResultedClicked && storedFiles[0])) {
      isSaveResultedClicked
        ? setSavingResultedStatus("saving")
        : setSavingStatus("saving");
      uploadedFiles.forEach((ele, index) => {
        data.append(`ResultFiles[${index}].fileDetails`, ele.fileDetails);
        data.append(
          `ResultFiles[${index}].fileType`,
          fileTyper(ele.fileDetails.type),
        );
        data.append(`ResultFiles[${index}].filePropertyName`, 6);
      });

      try {
        await http.put(`/Orders/UpdateOrder/${orderData?.id}`, data);
        // Dismess The Dialog
        setUploadDialog(false);
        setRefetch(!refetch);
        setUploadedFiles([]);
        setStoredFiles([]);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Files Saved Successfully",
          life: 3000,
        });
        isSaveResultedClicked
          ? setSavingResultedStatus("success")
          : setSavingStatus("success");
      } catch (error) {
        showErrorToast({ error });
        isSaveResultedClicked
          ? setSavingResultedStatus("error")
          : setSavingStatus("error");
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "You Must Upload At Least One File",
        life: 3000,
      });
    }
    isSaveResultedClicked = false;
  };
  const deleteFile = (deletedFile) => {
    let newFiles = uploadedFiles.filter((file) => file !== deletedFile);
    setUploadedFiles(newFiles);
  };
  const deleteStoredFile = async (fileId) => {
    confirmDialog({
      message: "Are you sure delete this file? ",
      icon: "pi pi-exclamation-triangle",
      position: "top",
      accept: async () => {
        try {
          await http.delete(`/Orders/DeleteResultUploadedFile/${fileId}`);
          const filesAfterDelete = storedFiles.filter(
            (file) => file.orderResultId !== fileId,
          );
          setStoredFiles(filesAfterDelete);
          if (!filesAfterDelete[0]) {
            setRefetch(!refetch);
          }
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "File deleted.",
            life: 3000,
          });
        } catch (error) {
          showErrorToast({ error });
        }
      },
    });
  };
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        blockScroll
        header="Upload"
        visible={uploadDialog}
        position={"right"}
        className="w-full md:h-screen md:w-[50vw]"
        // style={{ width: "50vw", height: "100vh" }}
        onHide={() => {
          setUploadDialog(false);
          setUploadedFiles([]);
          setStoredFiles([]);
        }}
        draggable={false}
        footer={saveTemplate}
        resizable={false}
      >
        <div
          className="flex h-auto w-full flex-col items-center justify-center rounded border-2 border-dashed border-gray-500 md:h-[200px]"
          onDrop={handleDrop}
          onDragOver={preventDefault}
          onDragEnter={preventDefault}
        >
          <div>
            <UploadFile />
          </div>
          <div className="mt-2 text-center font-light">
            Please upload a copy of service result in DCM, GIF, TIFF, PNG, JPEG,
            JPG, DOCX, DOC, TXT, CSV, XLSX, SVG or PDF format. No larger than 5
            MB in size.
          </div>
          <Button
            className="mt-2"
            onClick={() => {
              const inputElement = document.getElementById("fileInput");
              if (inputElement) {
                inputElement.click();
              }
            }}
            label={"Upload"}
          />
          <input
            id="fileInput"
            type="file"
            onChange={(event) => {
              const files = event.target.files;
              handleFilesUpload(files);
            }}
            className="mt-5 hidden"
          />
        </div>
        <div className="mt-4">
          {uploadedFiles?.map((file, index) => {
            return (
              <div
                key={index}
                className="mb-2 mt-2 flex items-center justify-between"
              >
                <div className="flex items-center">
                  <i className="pi pi-file-o pr-2 text-[30px]" />
                  <span> {file.fileDetails.name}</span>
                </div>
                <i
                  onClick={() => deleteFile(file)}
                  className="pi pi-times-circle cursor-pointer"
                  style={{ color: "red" }}
                ></i>
              </div>
            );
          })}
        </div>
        <Button
          severity="success"
          label={savingStatus === "saving" ? "Saving..." : "Save"}
          disabled={
            savingStatus === "saving" || savingResultedStatus === "saving"
          }
          onClick={() => {
            saveFiles();
          }}
        />
        {orderData?.status === 9 ||
        orderData?.status === 4 ||
        orderData?.status === 12 ||
        orderData?.status === 8 ? (
          <Button
            className="ml-4"
            label={
              savingResultedStatus === "saving" ? "Saving..." : "Save & Result"
            }
            disabled={
              savingStatus === "saving" || savingResultedStatus === "saving"
            }
            onClick={() => {
              isSaveResultedClicked = true;
              saveFiles();
            }}
          />
        ) : null}
        <Divider />
        <div className="flex flex-col gap-1">
          {storedFiles?.map((file, index) => (
            <div
              key={index}
              className="grid grid-cols-12 rounded-md bg-white p-2 shadow-sm hover:bg-slate-50"
            >
              <div className="col-span-1 flex items-center justify-center">
                <i className="pi pi-file text-lg" />
              </div>
              <div className="col-span-9">{file.fileName}</div>
              <div className="col-span-1 flex items-center justify-center">
                <i
                  onClick={() => handleDownloadResultFile(file.orderResultId)}
                  className="pi pi-download cursor-pointer"
                ></i>
              </div>
              <div className="col-span-1 flex items-center justify-center">
                <i
                  onClick={() => deleteStoredFile(file.orderResultId)}
                  className="pi pi-times-circle cursor-pointer text-rose-500"
                ></i>
              </div>
            </div>
          ))}
        </div>
      </Dialog>
    </>
  );
}

export default UploadFiles;
