import { useNavigate, useParams } from "react-router-dom";
import ManageTeamForm from "./ManageTeamForm";

export default function ManageTeam() {
  const { teamId } = useParams();
  const navigate = useNavigate();

  const redirect = () => {
    navigate(-1);
  };

  return (
    <div className="card w-full rounded-3xl bg-light-text p-6">
      <ManageTeamForm teamId={teamId} submitCallback={redirect} />
    </div>
  );
}
