import { useState } from "react";

export const CopyToClipboard = ({ label, text }) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div
      className="col-span-4 flex cursor-pointer justify-center rounded-md bg-slate-50 p-4 hover:bg-white"
      onClick={async () => {
        if (isCopied) return;
        // ** This feature is available only in secure contexts (HTTPS)
        try {
          await navigator.clipboard.writeText(text);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 2500);
        } catch (error) {
          console.error(error);
        }
      }}
    >
      <div className="flex items-center gap-1 px-3">
        <i
          className={`pi pi-${isCopied ? "check" : "copy"} `}
          style={{ fontSize: "1.125rem" }}
        ></i>
        {label && (
          <p className="m-0 font-semibold text-gray-800">
            {isCopied ? "Copied!" : label}
          </p>
        )}
      </div>
    </div>
  );
};
