import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import { useServiceStore } from "../../utils/store";
import { ACTIONS_ENUM, RULE_TYPES } from "../../utils/constants";
import { useLayoutToast } from "../../../../../../../store/store";

const CardActionButtons = ({ rulesGroup, setOpen }) => {
  const { rulesGroups, removeRuleGroup } = useServiceStore();
  const { layoutToast } = useLayoutToast();
  const isAction = rulesGroup?.type === RULE_TYPES.ACTION.value;
  const isRuleGroup = rulesGroup?.type === RULE_TYPES.GROUP.value;

  const isBillableItemInUse = () => {
    const isAddCPTAction =
      rulesGroup?.action?.key === ACTIONS_ENUM.ADD_CPT.value;
    const isSwapCPTAction =
      rulesGroup?.action?.key === ACTIONS_ENUM.SWAP_CPT.value;

    let filteredRules;
    let stringifiedRules;

    if (isAddCPTAction || isSwapCPTAction) {
      filteredRules = rulesGroups.filter((rule) => rule.id !== rulesGroup.id);
      stringifiedRules = JSON.stringify(filteredRules);
    }

    if (isAddCPTAction) {
      return rulesGroup.action.on.some((b) => stringifiedRules.includes(b.id));
    }

    if (isSwapCPTAction) {
      return rulesGroup.action.on.new.some((b) =>
        stringifiedRules.includes(b.id),
      );
    }

    return false;
  };

  const handleDelete = () => {
    if (isBillableItemInUse()) {
      layoutToast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: (
          <p>
            Deleting this rule will affect a billable item that is already used
            by another rule.
            <strong> Please review your rule settings before saving.</strong>
          </p>
        ),
        life: 4000,
      });

      return;
    }

    const handleAccept = () => {
      removeRuleGroup(rulesGroup.id);
      layoutToast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: "Rule deleted successfully",
        life: 3000,
      });
    };

    const singleRuleIndex = 0;

    confirmDialog({
      header:
        isRuleGroup || isAction
          ? rulesGroup.title
          : rulesGroup.conditionalGroups[singleRuleIndex].title,
      message: `Do you wish to delete this ${isAction ? "action" : "rule"}?`,
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: handleAccept,
    });
  };

  return (
    <div className="flex justify-end gap-2">
      <Button
        type="button"
        icon="pi pi-pencil"
        severity="info"
        outlined
        size="small"
        onClick={() =>
          setOpen({
            isVisible: true,
            rules: rulesGroup,
            isNewRulesGroup: false,
          })
        }
        tooltip="Edit rule"
        tooltipOptions={{
          showDelay: 1000,
          hideDelay: 300,
          position: "bottom",
        }}
      />
      <Button
        type="button"
        icon="pi pi-trash"
        outlined
        size="small"
        severity="danger"
        onClick={handleDelete}
        tooltip="Delete rule"
        tooltipOptions={{
          showDelay: 1000,
          hideDelay: 300,
          position: "bottom",
        }}
      />
    </div>
  );
};

export default CardActionButtons;
