import { TabPanel, TabView } from "primereact/tabview";

import { Remits } from "./components/Remits";
import { Claims } from "./components/Claims";
import { useLocation } from "react-router-dom";

export const Transactions = () => {
  const location = useLocation();
  const activeIndex = location.state?.activeIndex;
  return (
    <div className="rounded-t-lg">
      <TabView activeIndex={activeIndex || 0}>
        <TabPanel header="Claims">
          <Claims />
        </TabPanel>
        <TabPanel header="Remits">
          <Remits />
        </TabPanel>
      </TabView>
    </div>
  );
};
