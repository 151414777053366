import { Navigate, Outlet } from "react-router-dom";
import { USER_ROLES_ENUM } from "../constants/constants";
import useTokenData from "../hooks/useTokenData";

function RenderingGuard() {
  const { userRoles } = useTokenData();
  if (userRoles === USER_ROLES_ENUM.ORDERING_ONLY_STAFF) {
    return <Navigate to={"/ordering/FindServices"} />;
  } else {
    return <Outlet />;
  }
}

export default RenderingGuard;
