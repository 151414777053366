import { useFieldArray, useFormContext } from "react-hook-form";

import { RadioButton } from "primereact/radiobutton";

import { QUESTION_TYPES_ENUM } from "../utils/constants";
import AnswerOptionInput from "./formElements/AnswerOptionInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AnswerOptions = ({ questionType }) => {
  let answerOptions = null;
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    rules: { minLength: 2 },
    name: "answers",
  });

  const dragEnded = (param) => {
    const { source, destination } = param;
    if (!destination || destination.index === source.index) return;
    move(source.index, destination.index);
  };

  switch (questionType) {
    case QUESTION_TYPES_ENUM.BOOLEAN.value:
      answerOptions = (
        <div className="flex flex-col gap-2 rounded-lg border p-5">
          <label className="font-semibold">Answer Options</label>

          <div className="flex cursor-default flex-col gap-2 p-2">
            <div>
              <RadioButton
                inputId="yes"
                className="mr-1 cursor-default"
                disabled
              />
              <label htmlFor="yes" className="font-semibold">
                Yes
              </label>
            </div>

            <div>
              <RadioButton
                inputId="no"
                className="mr-1 cursor-default"
                disabled
              />
              <label htmlFor="no" className="font-semibold">
                No
              </label>
            </div>
          </div>
        </div>
      );
      break;
    case QUESTION_TYPES_ENUM.DROPDOWN.value:
    case QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value:
      answerOptions = (
        <div className="flex flex-col gap-2 rounded-lg border p-5">
          <label className="font-semibold">Answer Options</label>
          <DragDropContext onDragEnd={dragEnded}>
            <Droppable droppableId="answer-options">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-2"
                >
                  {fields.map((answer, index) => {
                    return (
                      <AnswerOptionInput
                        key={answer.id}
                        fields={fields}
                        answer={answer}
                        index={index}
                        append={append}
                        remove={remove}
                        move={move}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      );
      break;
    default:
      break;
  }

  return answerOptions;
};

export default AnswerOptions;
