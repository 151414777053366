import { useEffect, useRef, useState } from "react";

import { useLocation } from "react-router-dom";

import { Chart } from "primereact/chart";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../../../hooks/useAxios";
import { BuggyCounter } from "../../BuggyCounter";
import useTokenData from "../../../hooks/useTokenData";
import DashboardCard from "./components/DashboardCard";
import { showErrorToast } from "../../../utils/apiUtils";
import OrderIcon from "../../../iconComponents/OrderIcon";
import ResultIcon from "../../../iconComponents/ResultIcon";
import ReorderIcon from "../../../iconComponents/ReorderIcon";
import RevenueIcon from "../../../iconComponents/RevenueIcon";
import { CompletionChecklist } from "../../../components/cards";
import { InboundOrders } from "../orders/components/InboundOrders";
import TopOrderingProviders from "./components/TopOrderingProviders";

export default function Dashboard() {
  const toast = useRef();
  const { http } = useAxios();
  const buttonRef = useRef(null);
  const location = useLocation();
  const newOrdersRef = useRef(null);
  const { entityId } = useTokenData();
  const componentMount = useRef(false);
  const [cardData, setCardData] = useState(null);
  const [chartData, setChartData] = useState({});
  const [selectedDay, setSelectedDay] = useState(0);
  const [chartOptions, setChartOptions] = useState({});
  const [menuvisible, setMenuVisible] = useState(false);
  const [refetchTopOrdering, setRefetchTopOrdering] = useState(false);

  const isWelcome = location?.state?.isWelcome;
  // range of days
  const days = [
    {
      name: "All",
      value: 0,
    },
    {
      name: "Last Day",
      value: 1,
    },
    {
      name: "Last Week",
      value: 7,
    },
    {
      name: "Last 2 Weeks",
      value: 14,
    },
    {
      name: "Last 3 Weeks",
      value: 21,
    },
    {
      name: "Last Month",
      value: 30,
    },
    {
      name: "Last 2 Months",
      value: 60,
    },
    {
      name: "Last Year",
      value: 365,
    },
  ];

  const fetchBestSellingServicesData = async () => {
    try {
      const response = await http.get(
        selectedDay
          ? `/Entities/GetBestSellingServices/${entityId}?days=${Number(
              selectedDay,
            )}`
          : `/Entities/GetBestSellingServices/${entityId}`,
      );
      createChart(response.data);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const createChart = (chartData) => {
    const dataChart = {
      labels: chartData.map((item) => item.serviceName),
      datasets: [
        {
          data: chartData.map((item) => item.ordersCount),
        },
      ],
    };
    const options = {
      cutout: "60%",
    };
    setChartData(dataChart);
    setChartOptions(options);
  };

  const getCardData = async (url) => {
    try {
      const response = await http.get(url);
      setCardData(response.data);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleScroll = () => {
    setMenuVisible(false);
  };

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: handleScroll,
      options: { passive: true },
      when: menuvisible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  useEffect(() => {
    setCardData(null);
    const url = selectedDay
      ? `/Entities/GetDashboardCards/${entityId}?days=${selectedDay}`
      : `/Entities/GetDashboardCards/${entityId}`;
    getCardData(url);
    fetchBestSellingServicesData();
    componentMount.current && setRefetchTopOrdering(!refetchTopOrdering);
    componentMount.current = true;
  }, [entityId, selectedDay]);

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-col gap-5">
        <div className="flex grid-cols-12 flex-col-reverse gap-5 md:grid">
          <div className="col-span-8 flex flex-col gap-4 xl:col-span-9">
            <div className="flex items-center justify-between">
              <h1 className="text-lg font-semibold">Overview</h1>
              <BuggyCounter />
              <Dropdown
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.value)}
                options={days}
                optionLabel="name"
                className="h-10 w-52 items-center"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2 md:col-auto">
                <DashboardCard
                  title="New Orders"
                  value={cardData && cardData.newOrdersCount}
                  icon={<OrderIcon />}
                  colorClass="text-pink-600"
                  navigateTo="/rendering/Orders"
                  activeIndex={0}
                  loading={!cardData}
                />

                <DashboardCard
                  title="Resulted Services"
                  value={cardData && cardData.resultedOrdersCount}
                  icon={<ResultIcon />}
                  colorClass="text-[#009B2B]"
                  navigateTo="/rendering/Orders"
                  activeIndex={2}
                  loading={!cardData}
                />
              </div>
              <div className="col-span-2 md:col-auto">
                <DashboardCard
                  title="In-progress Orders"
                  value={
                    cardData &&
                    `${cardData.inProgressOrdersCount} ${cardData.maxOrdersQuantity ? "/ " + cardData.maxOrdersQuantity : ""}`
                  }
                  icon={<ReorderIcon />}
                  colorClass="text-[#594FC4]"
                  navigateTo="/rendering/Orders"
                  activeIndex={1}
                  loading={!cardData}
                />

                <DashboardCard
                  title="Revenue"
                  value={
                    cardData && (
                      <div className="flex items-center">
                        <i className="pi pi-dollar"></i>
                        <p className="mr-1">{cardData.revenue}</p>
                      </div>
                    )
                  }
                  navigateTo="/rendering/Transactions"
                  activeIndex={1}
                  icon={<RevenueIcon />}
                  colorClass="text-[#0EA5E9]"
                  loading={!cardData}
                />
              </div>
            </div>
          </div>
          <div className="col-span-4 flex xl:col-span-3">
            <CompletionChecklist view="render" isWelcome={isWelcome} />
          </div>
        </div>

        {/* Table with diagram */}
        <div className="flex grid-cols-12 flex-col gap-5 md:grid">
          <div className="col-span-8 xl:col-span-9">
            <TopOrderingProviders
              entityId={entityId}
              selectedDay={selectedDay}
              refetchTopOrdering={refetchTopOrdering}
            />
          </div>

          <div className="col-span-4 flex-col gap-3 rounded-lg border border-slate-100 bg-white p-4 xl:col-span-3">
            <h1 className="mb-2 font-semibold">Best Selling Services</h1>
            <div className="w-full">
              {chartData?.labels && chartData?.labels[0] ? (
                <Chart
                  type="doughnut"
                  data={chartData}
                  options={chartOptions}
                  className="flex justify-center align-middle md:max-h-[20rem]"
                />
              ) : (
                <p className="pt-[130px] text-center">
                  You haven't sold any services yet...
                </p>
              )}
            </div>
          </div>
        </div>
        <div ref={newOrdersRef}>
          <InboundOrders />
        </div>
      </div>
    </>
  );
}
