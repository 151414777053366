const attributeType = {
  1: "None",
  2: "NDC",
  3: "Supply Id",
  4: "Provider Type",
};

export const BillableItemsAttributesTemplate = (rowData) => {
  if (rowData.attributeType) {
    return (
      <span>
        {attributeType[rowData.attributeType]}
        {rowData.attributeTypeValue ? ` / ${rowData.attributeTypeValue}` : ""}
      </span>
    );
  }
};
