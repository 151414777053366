import { Controller, useFormContext } from "react-hook-form";

import { Checkbox } from "primereact/checkbox";
import {
  CONDITION_TYPE_ENUM,
  DEFAULT_SELF_EVALUATING_QUESTIONS_IDS,
  OPERATORS,
  QUESTION_TYPES_ENUM,
} from "../../utils/constants";
import ToggleInput from "../formElements/ToggleInput";
import MultiSelectDropdown from "./MultiSelectDropdown";
import NumberInput from "./NumberInput";
import NumberInputArray from "./NumberInputArray";
import NumberInputRange from "./NumberInputRange";
import SelectedBillableItems from "./selfEvaluating/SelectedBillableItems";
import ChooseBillableItems from "./selfEvaluating/ChooseBillableItems";
import SingleSelectDropdown from "./SingleSelectDropdown";
import TextInput from "./TextInput";
import TextInputArray from "./TextInputArray";

const AnswerInput = ({ inputNamePath, question }) => {
  const { control, watch } = useFormContext();
  const comparisonOperator = watch(`${inputNamePath}.comparisonOperator`);
  let component = (
    <TextInput inputNamePath={inputNamePath} question={question} />
  );

  const conditionType = watch(`${inputNamePath}`).type;

  const isSelfEvaluatingCondition =
    conditionType === CONDITION_TYPE_ENUM.SELF_EVAL.value;
  if (isSelfEvaluatingCondition) {
    const singleAnswerIndex = 0;

    switch (question.id) {
      case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS: {
        component = (
          <>
            <ChooseBillableItems
              inputNamePath={`${inputNamePath}.answers.${singleAnswerIndex}.value`}
            />
            <SelectedBillableItems
              tableInputNamePath={`${inputNamePath}.answers.${singleAnswerIndex}.value`}
              unitsColumn={{
                show: false,
                header: "",
              }}
            />
          </>
        );
        break;
      }
      case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE: {
        component = (
          <>
            <Controller
              name={`${inputNamePath}.parseSettings.treatAsZero`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
                  <label
                    htmlFor={field.name}
                    className="col-span-3 font-semibold"
                  >
                    0 Units if Not Present
                  </label>
                  <div className="flex flex-col gap-2 md:col-span-9">
                    <div className="flex-1">
                      <Checkbox
                        {...field}
                        id={field.name}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.checked)}
                      />
                    </div>
                  </div>
                  <div className="col-span-9 col-start-4">
                    {error && <p className="text-red-500">{error.message}</p>}
                  </div>
                </div>
              )}
            />
            <ChooseBillableItems
              inputNamePath={`${inputNamePath}.answers.[${singleAnswerIndex}].value.id`}
            />
            <SelectedBillableItems
              inputNamePath={`${inputNamePath}.answers.[${singleAnswerIndex}].value`}
              tableInputNamePath={`${inputNamePath}.answers.[${singleAnswerIndex}].value.id`}
              question={question}
              comparisonOperator={comparisonOperator}
              unitsColumn={{
                show: true,
                header: "Compared to Units",
              }}
            />
          </>
        );
        break;
      }
      default:
        break;
    }
  } else {
    switch (question?.type) {
      case QUESTION_TYPES_ENUM.BOOLEAN.value:
        component = <ToggleInput inputNamePath={inputNamePath} />;
        break;
      case QUESTION_TYPES_ENUM.DROPDOWN.value:
      case QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value:
        if (comparisonOperator === OPERATORS.IN.value) {
          component = (
            <MultiSelectDropdown
              inputNamePath={inputNamePath}
              question={question}
            />
          );
        } else {
          component = (
            <SingleSelectDropdown
              inputNamePath={inputNamePath}
              question={question}
            />
          );
        }
        break;
      case QUESTION_TYPES_ENUM.TEXT.value:
        if (comparisonOperator === OPERATORS.IN.value) {
          component = (
            <TextInputArray inputNamePath={inputNamePath} question={question} />
          );
        } else {
          component = (
            <TextInput inputNamePath={inputNamePath} question={question} />
          );
        }
        break;
      case QUESTION_TYPES_ENUM.NUMBER.value:
      case QUESTION_TYPES_ENUM.DECIMAL.value:
        if (comparisonOperator === OPERATORS.BETWEEN.value) {
          component = (
            <NumberInputRange
              inputNamePath={inputNamePath}
              question={question}
            />
          );
        } else if (comparisonOperator === OPERATORS.IN.value) {
          component = (
            <NumberInputArray
              inputNamePath={inputNamePath}
              question={question}
            />
          );
        } else {
          component = (
            <NumberInput inputNamePath={inputNamePath} question={question} />
          );
        }
        break;
      default:
        component = (
          <TextInput inputNamePath={inputNamePath} question={question} />
        );
        break;
    }
  }

  return component;
};

export default AnswerInput;
