import { useFormContext } from "react-hook-form";

import TextConstraints from "./TextConstraints";
import NumericConstraints from "./NumericConstraints";
import { QUESTION_TYPES_ENUM } from "../../../my-services/manage-service/utils/constants";

const QuestionConstraints = () => {
  const { watch } = useFormContext();
  const type = watch("type");

  switch (type) {
    case QUESTION_TYPES_ENUM.DECIMAL.value:
    case QUESTION_TYPES_ENUM.NUMBER.value:
      return <NumericConstraints />;
    case QUESTION_TYPES_ENUM.TEXT.value:
      return <TextConstraints />;
    default:
      return;
  }
};

export default QuestionConstraints;
