import { Tooltip } from "primereact/tooltip";
import { useRef } from "react";

export const IsCertifiedTemplate = ({ isCertified }) => {
  const ref = useRef();
  const tooltipText = isCertified === true ? "Certified" : "Not Certified";
  return (
    <>
      <Tooltip target={ref} />
      <span
        className="cursor-pointer text-light-purple"
        data-pr-tooltip={tooltipText}
        data-pr-position="top"
        ref={ref}
      >
        {isCertified === true ? (
          <i className={`pi pi-verified text-green-800`} />
        ) : (
          <i className={`pi pi-exclamation-circle text-red-600`} />
        )}
      </span>
    </>
  );
};
