import { useEffect, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Tree } from "primereact/tree";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Tooltip } from "primereact/tooltip";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../../hooks/useAxios";
import { showErrorToast } from "../../../../utils/apiUtils";
import useHandleResize from "../../../../hooks/useHandleResize";
import SquarePlusIcon from "../../../../iconComponents/SquarePlusIcon";
import SquareMinusIcon from "../../../../iconComponents/SquareMinusIcon";

function OrderDetails({
  submitRef,
  setActiveIndex,
  uploadedFiles,
  setUploadedFiles,
  formData,
  setFormData,
  selectedNodes,
  setSelectedNodes,
  setSelectedOptionsList,
  selectedOptionsList,
  setSelectedChooseList,
  selectedChooseList,
}) {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const toast = useRef();
  const { http } = useAxios();
  const [treeData, setTreeData] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [delayTimer, setDelayTimer] = useState(null);
  const [diagnosisStatus, setDiagnosisStatus] = useState("idle");
  const [isDisabledButtonDiagnosis, setIsDisabledButtonDiagnosis] =
    useState(true);
  const { isMobile } = useHandleResize();

  const getDiagnosis = async (filterValue, signal) => {
    const trimmedValue = filterValue.trim();
    if (trimmedValue === "") {
      setDiagnosisStatus("idle");
      setTreeData([]);
      return;
    }
    setDiagnosisStatus("loading");
    try {
      const response = await http.get(
        `/Diagnosis/GetDiagnosis?Filters=${trimmedValue}`,
        { signal },
      );
      const responseData = response.data;
      setTreeData(responseData);
      if (response.data.length == 0) {
        setDiagnosisStatus("no-data");
      } else {
        setDiagnosisStatus("success");
      }
    } catch (error) {
      setTreeData([]);
      setDiagnosisStatus("no-data");
      showErrorToast({ error });
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    clearTimeout(delayTimer);
    setDelayTimer(
      setTimeout(() => {
        getDiagnosis(filterValue, signal);
      }, 700),
    );
    return () => {
      controller.abort();
      clearTimeout(delayTimer);
    };
  }, [filterValue]);

  const onSubmit = async (formData) => {
    if (!selectedChooseList[0]) {
      return;
    }
    setSelectedOptionsList(selectedChooseList);
    setFormData({ ...formData, selectedNodes: selectedChooseList });
    setActiveIndex((old) => old + 1);
  };

  useEffect(() => {
    formData && reset(formData);
    setSelectedNodes(formData?.selectedNodes || []);
  }, []);

  // new delete diagnosis by ky
  const handleDeleteOption = (index) => {
    const optionToDelete =
      selectedOptionsList[index] || selectedChooseList[index];
    const optionKey = optionToDelete ? optionToDelete.key : null;
    setSelectedOptionsList((prevOptions) => {
      return prevOptions.filter((_, i) => i !== index);
    });
    setSelectedChooseList((prevOptions) => {
      return prevOptions.filter((_, i) => i !== index);
    });
    if (optionKey) {
      setSelectedNodes((prevNodes) => {
        const modifiedState = { ...prevNodes };
        delete modifiedState[optionKey];
        return modifiedState;
      });
    }
  };

  const handleLabelClick = (e, node) => {
    e.stopPropagation();
    const clone = structuredClone(treeData);
    const updatedClone = toggleNodeExpansion(clone, node.key);
    setTreeData(updatedClone);
  };

  const toggleNodeExpansion = (data, key) => {
    return data.map((item) => {
      if (item.key === key) {
        return {
          ...item,
          expanded: !item.expanded,
        };
      } else if (item.children) {
        return {
          ...item,
          children: toggleNodeExpansion(item.children, key),
        };
      }
      return item;
    });
  };

  // new category change select one by one
  const onCategoryChange = (e, node) => {
    e.stopPropagation();
    const selectedValue = node;
    const newObj = {
      key: selectedValue.key,
      label: selectedValue.label,
      code: selectedValue.Code,
    };
    setSelectedOptionsList([newObj]);
  };

  // new handleSetSelectedChosenList change select one by one
  const handleSetSelectedChosenList = () => {
    setSelectedChooseList((prevList) => {
      const newSelections = selectedOptionsList.filter(
        (option) => !prevList.some((chosen) => chosen.key === option.key),
      );

      return [...prevList, ...newSelections];
    });
    setSelectedOptionsList([]);
  };

  const arraysAreEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    const keys1 = arr1.map((item) => item.key).sort();
    const keys2 = arr2.map((item) => item.key).sort();
    return JSON.stringify(keys1) === JSON.stringify(keys2);
  };

  useEffect(() => {
    setIsDisabledButtonDiagnosis(
      arraysAreEqual(selectedOptionsList, selectedChooseList),
    );
  }, [selectedOptionsList, selectedChooseList]);
  const nodeTemplate = (node, options) => {
    const hasChildren = node.children && node.children.length > 0;
    const isSelected = selectedOptionsList.some(
      (option) => option.key === node.key,
    );

    const labelClassName = isSelected ? "text-light-purple bg-gray-200 " : "";

    return (
      <div
        className={`align-items-center flex w-full rounded px-2 py-2 ${labelClassName}`}
        onClick={(e) => onCategoryChange(e, node)}
      >
        {hasChildren && (
          <i
            className={node.expanded ? "icon-expanded" : "icon-collapsed"}
            onClick={(e) => {
              handleLabelClick(e, node);
            }}
          >
            {node.expanded ? <SquareMinusIcon /> : <SquarePlusIcon />}
          </i>
        )}
        <label
          htmlFor={node.key}
          className={`ml-2 cursor-pointer ${labelClassName}`}
        >
          {node.Code} - {node.label}
        </label>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <Toast ref={toast} />
      <Tooltip target=".order-info" className="max-w-sm" />
      <ConfirmDialog />

      <div>
        <form
          ref={submitRef}
          className="w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div>
            <div>
              <h1 className="font-inter font-extrabold capitalize">
                diagnosis{" "}
              </h1>
              <p className="pb-3 pt-1 text-p text-gray-600">
                Enter the medical diagnosis for the patient.{" "}
              </p>
            </div>
            <div className="parent-taxonomy sm:gap-auto flex w-full flex-col gap-2 rounded-lg border p-3 sm:flex-row">
              <div className="child-taxonomy basis-1/2 overflow-hidden rounded-lg border">
                <div className="text-left" style={{ height: "350px" }}>
                  <Controller
                    name="selectedDiagnosis"
                    control={control}
                    render={({ field }) => (
                      <>
                        <Tree
                          contentClassName="pt-2"
                          pt={{
                            content: {
                              style: { padding: "0", border: "none" },
                            },
                            root: { style: { border: "none" } },
                            container: {
                              style: {
                                height:
                                  diagnosisStatus === "success"
                                    ? "max-content"
                                    : "0",
                                maxHeight: "260px",
                              },
                            },
                          }}
                          value={treeData}
                          selectionMode="single"
                          nodeTemplate={nodeTemplate}
                          filterValue={filterValue}
                          filterTemplate={(options) => (
                            <>
                              <div className="flex">
                                {options.element}
                                <Button
                                  text
                                  rounded
                                  severity="secondary"
                                  onClick={options.filterOptions.reset}
                                  icon="pi pi-times"
                                  type="button"
                                />
                              </div>
                            </>
                          )}
                          header={({ className, filterElement, ...opts }) => {
                            return (
                              <>
                                <div className={className}>
                                  <div className="mb-2 h-10 w-full">
                                    <div className="p-input-icon-left p-input-icon-right mb-3 w-full">
                                      <i className="pi pi-search" />
                                      <InputText
                                        type="text"
                                        className="p-inputtext p-component w-full"
                                        value={filterValue}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          setFilterValue(value);
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder="Search"
                                      />
                                      <i
                                        className="pi pi-times"
                                        onClick={(e) => {
                                          setFilterValue("");
                                          setTreeData([]);
                                          setDiagnosisStatus("idle");
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                  {diagnosisStatus === "loading" ? (
                                    <div className="flex items-center justify-center p-5">
                                      <ProgressSpinner
                                        strokeWidth={3}
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                      />
                                    </div>
                                  ) : diagnosisStatus === "no-data" ? (
                                    <div className="flex justify-center">
                                      <p className="mt-5 w-9/12 text-center font-Poppins text-base capitalize">
                                        No result found
                                      </p>
                                    </div>
                                  ) : diagnosisStatus === "idle" ? (
                                    <div className="flex justify-center">
                                      <p className="mt-5 w-9/12 text-center font-Poppins text-sm capitalize text-gray-600">
                                        To view diagnoses, please enter a search
                                        term in the search field above.
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            );
                          }}
                          filter
                          metaKeySelection={false}
                          className="custom-arrow-icon w-full"
                          placeholder="Select taxonomy"
                        />
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-2 px-4 text-gray-400">
                <Button
                  onClick={() => {
                    handleSetSelectedChosenList();
                  }}
                  disabled={isDisabledButtonDiagnosis}
                  type="button"
                  icon={
                    isMobile
                      ? "pi pi-angle-double-down text-lg font-semibold"
                      : "pi pi-angle-double-right text-lg font-semibold"
                  }
                  className="justify-center bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
                />
              </div>
              <div className="child-list basis-1/2 rounded-lg border p-3">
                <label className="mb-4 mt-4 block font-Poppins text-base capitalize leading-loose text-gray-500">
                  selected diagnosis
                </label>
                <div className="mt-3 h-52 overflow-y-auto">
                  {selectedChooseList.length ? (
                    selectedChooseList.map((option, index) => (
                      <div key={index} className="mt-4 grid grid-cols-4">
                        <div className="col-span-3 w-full text-left">
                          {option.code} - {option.label}{" "}
                        </div>
                        <div className="col-span-1 text-center">
                          <i
                            className="pi pi-times cursor-pointer text-red-500"
                            style={{ fontSize: "1.3rem" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleDeleteOption(index);
                            }}
                          ></i>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="capitalize text-gray-500">
                      no diagnosis selected{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {!selectedChooseList[0] && (
              <p className="mt-1 text-left text-red-500">
                Please select at least one diagnosis
              </p>
            )}
          </div>
          <Divider />
          <div>
            <h1 className="mb-2 font-inter font-extrabold capitalize">
              Order Notes{" "}
              <i
                className="pi pi-info-circle order-info mr-1 text-sm text-gray-500"
                data-pr-position="top"
                data-pr-tooltip="Please include any notes related to this order request or to the patient that should be included in the order. Please note that this information will be included in the order and will be visible to the rendering provider and to the patient (if records are requested by the patient), so please do not include any information that you do not wish for the patient to see."
              ></i>
            </h1>
            <InputTextarea
              {...register("notes", {
                maxLength: {
                  value: 400,
                  message: "Note cannot be more than 400 characters",
                },
              })}
              rows={5}
              cols={100}
              onInput={(e) => {
                const nonASCII = /[^\x20-\x7E]/g;
                e.target.value = e.target.value.replace(nonASCII, "");
              }}
              placeholder="Write Order Notes Here"
              className="w-full"
            />
            {errors.notes && (
              <p className="text-red-500">{errors.notes.message}</p>
            )}
          </div>
          <Divider />
          <div>
            <h1 className="mb-2 font-inter font-extrabold capitalize">
              Confidential Internal Notes{" "}
              <i
                className="pi pi-info-circle order-info mr-1 text-sm text-gray-500"
                data-pr-position="top"
                data-pr-tooltip="Please include any confidential and internal notes related to this order request or to the patient that should be included with the order, but not disclosed in the order document. This information will only be visible in the MedX system to the rendering provider and will not be disclosed on the official order document. Here you can provide any sensitive information that you would like to share with the rendering provider, such as any special behavioral needs that the patient expects during the appointment or any information that the rendering provider would find useful in optimizing the care they deliver to the patient that may have special needs or expectations or may be a prominent member of the community, etc."
              ></i>
            </h1>
            <InputTextarea
              {...register("confidentialInternalNote", {
                maxLength: {
                  value: 400,
                  message: "Note cannot be more than 400 characters",
                },
              })}
              rows={5}
              cols={100}
              onInput={(e) => {
                const nonASCII = /[^\x20-\x7E]/g;
                e.target.value = e.target.value.replace(nonASCII, "");
              }}
              placeholder="Write any Confidential Internal Notes Here"
              className="w-full"
            />
            {errors.confidentialInternalNote && (
              <p className="text-red-500">
                {errors.confidentialInternalNote.message}
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrderDetails;
