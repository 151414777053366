import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import ComparisonCondition from "./ComparisonCondition";
import useHandleResize from "../../../../../../../hooks/useHandleResize";
import {
  CONDITION_TYPE_ENUM,
  CONDITION_TYPES,
  DEFAULT_QUESTIONS,
  DEFAULT_SELF_EVALUATING_QUESTIONS,
  DEFAULT_SELF_EVALUATING_QUESTIONS_IDS,
  EQUALITY_OPERATORS,
  OPERATORS,
  QUESTION_TYPES_ENUM,
  RESOURCES_ENUM,
} from "../../utils/constants";
import { useServiceStore } from "../../utils/store";

const ConditionCard = ({
  conditions,
  append,
  remove,
  ruleIndex,
  conditionIndex,
}) => {
  const { control, watch, setValue } = useFormContext();

  const { questions } = useServiceStore();

  const { isMobile } = useHandleResize();

  const conditionType = watch(
    `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.type`,
  );

  const questionId = watch(
    `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.questionId`,
  );

  const equalityOperator = watch(
    `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.equalityOperator`,
  );

  const isSelfEvaluatingCondition =
    conditionType === CONDITION_TYPE_ENUM.SELF_EVAL.value;

  const questionsPool = isSelfEvaluatingCondition
    ? [...DEFAULT_SELF_EVALUATING_QUESTIONS]
    : [...DEFAULT_QUESTIONS, ...questions];

  const question = questionsPool?.find((q) => q.id === questionId);

  const isAnswered = equalityOperator !== OPERATORS.NOT_ANSWERED.value;
  const isMultipleCondition = conditions.length > 1;
  const isLastCondition = conditionIndex === conditions.length - 1;

  const equalityOperators = isSelfEvaluatingCondition
    ? [OPERATORS.EQUAL, OPERATORS.NOT_EQUAL]
    : EQUALITY_OPERATORS;

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-11">
      <div className="col-span-8 rounded-xl border border-l-8 border-slate-200 border-l-light-purple bg-white p-2 shadow-md md:p-4">
        <div className="font-bold">Condition {conditionIndex + 1}</div>
        <div className="flex flex-col gap-1 p-3">
          <Controller
            name={`conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.type`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
                <label
                  htmlFor={field.name}
                  className="col-span-3 font-semibold"
                >
                  Type
                </label>
                <div className="flex flex-col gap-2 md:col-span-9">
                  <Dropdown
                    {...field}
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue(
                        `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.questionId`,
                        "",
                      );
                    }}
                    options={CONDITION_TYPES}
                    defaultValue={CONDITION_TYPE_ENUM.QUESTION.value}
                    pt={{ item: { className: "capitalize" } }}
                    className="w-full capitalize"
                  />
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              </div>
            )}
          />
          <Controller
            name={`conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.questionId`}
            control={control}
            rules={{ required: "Select a Question" }}
            render={({ field, fieldState: { error } }) => (
              <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
                <label
                  htmlFor={field.name}
                  className="col-span-3 font-semibold"
                >
                  If
                </label>
                <div className="flex flex-col gap-2 md:col-span-9">
                  <Dropdown
                    {...field}
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue(
                        `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.equalityOperator`,
                        OPERATORS.EQUAL.value,
                      );
                      setValue(
                        `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.comparisonOperator`,
                        OPERATORS.EQUAL.value,
                      );

                      const question = questionsPool.find(
                        (q) => q.id === e.value,
                      );

                      const isCheckExists =
                        question.id ===
                        DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS;

                      /**
                       * force check exists on billable items only.
                       * condition for future conditions if we would check if location or document exists
                       *  */
                      if (isCheckExists) {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.on`,
                          RESOURCES_ENUM.BILLABLE_ITEMS.value,
                        );
                      } else {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.on`,
                          "",
                        );
                      }

                      if (isSelfEvaluatingCondition) {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [question.initialAnswerField],
                        );
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.parseSettings`,
                          question.parseSettings,
                        );
                      } else {
                        if (
                          question.type === QUESTION_TYPES_ENUM.BOOLEAN.value
                        ) {
                          setValue(
                            `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                            [{ value: false }],
                          );
                        } else {
                          setValue(
                            `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                            [{ value: "" }],
                          );
                        }
                      }
                    }}
                    options={questionsPool}
                    optionLabel="label"
                    optionValue="id"
                    placeholder="Select A Question"
                    className="w-full"
                  />
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              </div>
            )}
          />
          <Controller
            name={`conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.equalityOperator`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
                <label
                  htmlFor={field.name}
                  className="col-span-3 font-semibold"
                >
                  State
                </label>
                <div className="flex flex-col gap-2 md:col-span-9">
                  <Dropdown
                    {...field}
                    inputId={field.name}
                    options={equalityOperators}
                    onChange={(e) => {
                      field.onChange(e.value);
                      if (e.value === OPERATORS.NOT_ANSWERED.value)
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [],
                        );
                    }}
                    pt={{ item: { className: "capitalize" } }}
                    className="w-full capitalize"
                  />
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              </div>
            )}
          />

          {questionId && isAnswered && (
            <ComparisonCondition
              ruleIndex={ruleIndex}
              conditionIndex={conditionIndex}
              question={question}
            />
          )}
        </div>
      </div>
      <div className="col-span-3 flex items-center gap-4 p-6">
        {isMultipleCondition && (
          <Button
            type="button"
            severity="danger"
            icon="pi pi-trash color-red"
            onClick={remove}
          />
        )}
        {isLastCondition && (
          <Button
            type="button"
            icon={!isMobile && "pi pi-plus"}
            label={isMultipleCondition ? "" : "Add new condition"}
            onClick={append}
            className="bg-light-purple text-sm md:m-0 md:text-base"
          />
        )}
      </div>
    </div>
  );
};

export default ConditionCard;
