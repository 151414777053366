import { useEffect, useMemo, useState } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";

import { SORT_BY } from "./utils/constants";
import useAxios from "../../../hooks/useAxios";
import { useLayoutToast } from "../../../store/store";
import useTokenData from "../../../hooks/useTokenData";
import { ServicesCard } from "../../../components/cards";
import Header from "./components/Header";
import NoServicesFound from "./components/NoServicesFound";
import useHandleResize from "../../../hooks/useHandleResize";
import Sidebar from "./components/Sidebar";
import { ServicesCardSkeleton } from "../../../components/skeletons/ServicesCardSkeleton";
import {
  REQUESTED_DATA_SIZE,
  SERVICE_LOCATION_STATUS,
  SERVICE_TAB,
} from "../../../constants/constants";
import { showErrorToast } from "../../../utils/apiUtils";

function FindServices() {
  const { http } = useAxios();
  const navigate = useNavigate();
  const [services, setServices] = useState();
  const { layoutToast } = useLayoutToast();
  const { entityId, IsEntityApproved, userTeamHasProvider } = useTokenData();
  const { isMobile } = useHandleResize();
  const [refetch, setRefetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchService, setSearchService] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [servicesCount, setServicesCount] = useState(null);
  const [currentServiceLocations, setCurrentServiceLocations] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [serviceLocationsStatus, setServiceLocationsStatus] = useState(
    SERVICE_LOCATION_STATUS.IDLE,
  );
  const defaultTab =
    userTeamHasProvider === "false" ? SERVICE_TAB.OUR : SERVICE_TAB.ALL;
  const activeTab = searchParams.get("tab");

  const [sort, setSort] = useState(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(REQUESTED_DATA_SIZE);

  const isAllServices = activeTab === SERVICE_TAB.ALL;

  const isEntityPending = IsEntityApproved === "Pending";
  const isEntityDenied = IsEntityApproved === "Denied";
  const isEntityIncomplete = IsEntityApproved === "InComplete";
  const isEntityUnapproved =
    isEntityPending || isEntityDenied || isEntityIncomplete;

  const getCurrentServiceLocations = async (serviceId) => {
    setServiceLocationsStatus(SERVICE_LOCATION_STATUS.LOADING);

    const url = `/Services/GetServiceLocations/${serviceId}`;
    try {
      const response = await http.get(url);

      if (response.data.length === 0) {
        setServiceLocationsStatus(SERVICE_LOCATION_STATUS.NO_DATA);
      } else {
        setServiceLocationsStatus(SERVICE_LOCATION_STATUS.SUCCESS);
      }
      setCurrentServiceLocations(response.data);
    } catch (error) {
      setServiceLocationsStatus(SERVICE_LOCATION_STATUS.IDLE);
      showErrorToast({ error });
    }
  };

  const getServices = async (
    serviceTab,
    sortBy,
    page = 1,
    pageCount = REQUESTED_DATA_SIZE,
    reset,
  ) => {
    setServices(null);
    setIsLoading(true);

    if (serviceLocationsStatus !== SERVICE_LOCATION_STATUS.IDLE)
      setServiceLocationsStatus(SERVICE_LOCATION_STATUS.IDLE);

    if (sortBy !== sort) setSort(sortBy);

    if (reset) {
      setFirst(0);
      setSearchService("");
      setSelectedState("");
    }
    const isSavedServices = serviceTab === SERVICE_TAB.SAVED;
    const isAllServices = serviceTab === SERVICE_TAB.ALL;

    const searchState = reset ? "" : selectedState || "";
    const filters = reset ? "" : searchService;

    const pathname = isSavedServices
      ? `GetEntitySavedServices/${entityId}?`
      : isAllServices
        ? "FindServices?"
        : `FindServices?myServicesOnly=true&`;

    const servicesUrl = `/Services/${pathname}Filters=${filters}&searchState=${searchState}&Sorts=${sortBy}&Page=${page}&PageSize=${pageCount}`;
    try {
      const response = await http.get(servicesUrl);

      const services = isSavedServices
        ? response.data.savedServices.map((s) => s.serviceData)
        : response.data.services;
      setServices(services);
      setServicesCount(response.data.count);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  const onPageChange = (event) => {
    if (first === event.first) return;
    setFirst(event.first);
    setRows(event.rows);
    const page = event.page + 1;
    getServices(activeTab, sort, page, event.rows, false);
  };

  const changeTab = (tab) => {
    setSearchParams({ tab }, { replace: true });
  };

  const isTabExists = useMemo(
    () => Object.values(SERVICE_TAB).includes(activeTab),
    [activeTab],
  );

  useEffect(() => {
    if (!activeTab || !isTabExists) {
      changeTab(defaultTab);
    } else if (
      userTeamHasProvider === "false" &&
      activeTab === SERVICE_TAB.ALL
    ) {
      changeTab(SERVICE_TAB.OUR);
    }
  }, [defaultTab]);

  useEffect(() => {
    if (activeTab && isTabExists) {
      getServices(activeTab, "", 1, REQUESTED_DATA_SIZE, true);
    }
  }, [searchParams, entityId]);

  useEffect(() => {
    if (refetch) {
      const page = first / REQUESTED_DATA_SIZE + 1;
      getServices(activeTab, sort, page, REQUESTED_DATA_SIZE, false);
      setRefetch(false);
    }
  }, [refetch]);

  const handleDisabledAction = (serviceId) => {
    if (isEntityUnapproved) {
      layoutToast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: isEntityIncomplete
          ? `Ordering is disabled until you complete your practice data and be approved`
          : `Ordering is disabled until your practice is approved`,
        life: 3000,
      });
      return;
    } else {
      navigate(`/ordering/RequestService/${serviceId}`);
    }
  };

  return (
    <>
      <Header
        searchService={searchService}
        setSearchService={setSearchService}
        sort={sort}
        setSort={setSort}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        setFirst={setFirst}
        getServices={() =>
          getServices(activeTab, sort, 1, REQUESTED_DATA_SIZE, false)
        }
      />

      <div className="flex gap-0 lg:gap-8">
        <div className="w-full">
          <div className="mb-5 flex justify-between">
            <div className="flex flex-1 sm:flex-auto">
              {userTeamHasProvider !== "false" && (
                <div
                  onClick={() => {
                    if (isAllServices) return;
                    changeTab(SERVICE_TAB.ALL);
                  }}
                  className={`flex basis-1/2 cursor-pointer items-center justify-center p-2 pl-4 pr-4 sm:basis-auto ${
                    isAllServices
                      ? "bg-light-purple font-semibold text-white"
                      : ""
                  } `}
                >
                  All Services
                </div>
              )}
              <div
                onClick={() => {
                  if (activeTab === SERVICE_TAB.OUR) return;
                  changeTab(SERVICE_TAB.OUR);
                }}
                className={`flex basis-1/2 cursor-pointer items-center justify-center p-2 pl-4 pr-4 sm:basis-auto ${
                  activeTab === SERVICE_TAB.OUR
                    ? "bg-light-purple font-semibold text-white"
                    : ""
                } `}
              >
                Our Services
              </div>
              <div
                onClick={() => {
                  if (activeTab === SERVICE_TAB.SAVED) return;
                  changeTab(SERVICE_TAB.SAVED);
                }}
                className={`flex basis-1/2 cursor-pointer items-center justify-center p-2 pl-4 pr-4 sm:basis-auto ${
                  activeTab === SERVICE_TAB.SAVED
                    ? "bg-light-purple font-semibold text-white"
                    : null
                }`}
              >
                Saved Services
              </div>
            </div>

            <div className="hidden sm:block">
              <Dropdown
                value={sort}
                options={SORT_BY}
                onChange={(e) =>
                  getServices(
                    activeTab,
                    e.target.value,
                    1,
                    REQUESTED_DATA_SIZE,
                    false,
                  )
                }
                placeholder="Sort By"
                className="min-w-[200px]"
              />
            </div>
          </div>
          <div className="flex w-[100%] flex-col">
            {isLoading || !services ? (
              Array(8)
                .fill(0)
                .map((n, i) => <ServicesCardSkeleton key={i} />)
            ) : services.length === 0 ? (
              <NoServicesFound />
            ) : (
              services?.map((service) => {
                return (
                  <div key={service?.id}>
                    <ServicesCard
                      service={service}
                      practice={{
                        name: service?.entityName,
                        imageSrc: service?.entityImage,
                      }}
                      internalOnly={service?.internalOnly}
                      isInteractive={true}
                      setRefetch={setRefetch}
                      disableOrderButton={isEntityUnapproved}
                      onCardClick={() =>
                        getCurrentServiceLocations(service?.id)
                      }
                      orderButtonClick={() => handleDisabledAction(service?.id)}
                    />
                  </div>
                );
              })
            )}
          </div>
          {servicesCount > 0 && (
            <Paginator
              className="rounded-t-none"
              first={first}
              rows={rows}
              totalRecords={servicesCount}
              onPageChange={onPageChange}
              template={
                isMobile
                  ? { layout: "PrevPageLink CurrentPageReport NextPageLink" }
                  : {
                      layout:
                        "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                    }
              }
            />
          )}
        </div>
        <Sidebar
          serviceLocationsStatus={serviceLocationsStatus}
          currentServiceLocations={currentServiceLocations}
        />
      </div>
    </>
  );
}

export default FindServices;
