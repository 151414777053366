import { create } from "zustand";

export const useServiceStore = create((set) => ({
  questions: [],
  setQuestions: (data) => set({ questions: data }),
  addQuestion: (data) =>
    set((state) => ({ questions: [...state.questions, data] })),
  updateQuestion: (questionId, updatedQuestion) =>
    set((state) => ({
      questions: state.questions.map((question) =>
        question.id === questionId ? updatedQuestion : question,
      ),
    })),
  removeQuestion: (questionId) =>
    set((state) => ({
      questions: state.questions.filter(
        (question) => question.id !== questionId,
      ),
    })),

  rulesGroups: [],

  setRulesGroups: (data) => set({ rulesGroups: data }),
  addRuleGroup: (rule) =>
    set((state) => ({ rulesGroups: [...state.rulesGroups, rule] })),
  updateRuleGroup: (ruleId, updatedRule) =>
    set((state) => ({
      rulesGroups: state.rulesGroups.map((rule) =>
        rule.id === ruleId ? updatedRule : rule,
      ),
    })),
  removeRuleGroup: (ruleId) =>
    set((state) => ({
      rulesGroups: state.rulesGroups.filter((rule) => rule.id !== ruleId),
    })),
}));

export const useStates = create((set) => ({
  states: [],
  setStates: (states) => set({ states }),
}));
