import { ProgressSpinner } from "primereact/progressspinner";
import React from "react";
import { useNavigate } from "react-router-dom";

function DashboardCard({
  title,
  value,
  icon,
  colorClass,
  navigateTo,
  activeIndex,
  loading,
}) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        navigateTo &&
        navigate(navigateTo, {
          state: { activeIndex },
        })
      }
      className={`mt-4 rounded-md bg-white p-4 ${navigateTo ? "cursor-pointer" : ""}`}
    >
      <div className="mb-3 flex justify-between">
        <h1 className={`text-3xl font-bold ${colorClass}`}>
          {loading ? (
            <ProgressSpinner
              strokeWidth={3}
              style={{ width: "30px", height: "30px" }}
            />
          ) : (
            value
          )}
        </h1>
        {icon}
      </div>
      <p className="text-base font-medium">{title}</p>
    </div>
  );
}

export default DashboardCard;
