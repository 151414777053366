import { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { RadioButton } from "primereact/radiobutton";

import useAxios from "../../../../hooks/useAxios";
import useDocument from "../../../../hooks/useDocument";
import { arrayEquals } from "../../../../utils/helpers";
import { ACTIONS_ENUM } from "../../../rendering/services/my-services/manage-service/utils/constants";
import { getBillableItemsIds } from "../../../rendering/services/my-services/manage-service/components/rules/actions/helpers";
import useFetchBillablesByIds from "../../../rendering/services/my-services/manage-service/components/rules/useFetchBillablesByIds";
import { aggregateResources } from "../utils/aggregateResources";
import { showErrorToast } from "../../../../utils/apiUtils";

function ServiceCart({
  submittedResponses,
  cost,
  setCost,
  isTesting,
  serviceFees,
  maxFeesEntity,
  selectedLoc,
  setSelectedLoc,
  serviceId,
  serviceType,
  setLocationsBucket,
  setServiceUnavailableDialogVisible,
  serviceInfo,
  setCptBucket,
  setOrderDocs,
}) {
  const { http } = useAxios();
  const { handleDownloadDocument } = useDocument();

  const [locationData, setLocationData] = useState([]);
  const [isLocationsLoading, setIsLocationsLoading] = useState(false);

  const [documentsData, setDocumentsData] = useState([]);
  const [isDocsLoading, setIsDocsLoading] = useState(false);

  const { isLoading, getBillableItems } = useFetchBillablesByIds();

  const [matchedBIs, setMatchedBIs] = useState([]);

  const getServiceBillableItems = async (serviceId) => {
    try {
      const response = await http.get(
        `/BillableItems/GetBillableItemsForOrderingProviders/${serviceId}`,
      );
      return response.data;
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const getTestingServiceBillableItems = (serviceData, rules) => {
    const defaultBillableItemIds = getBillableItemsIds(
      serviceData?.billableItems,
    );

    const billableItemIdsSet = new Set(defaultBillableItemIds);
    rules.forEach((rule) => {
      if (rule.action.key === ACTIONS_ENUM.ADD_CPT.value) {
        rule.action.on.forEach((BI) => billableItemIdsSet.add(BI.id));
      } else if (rule.action.key === ACTIONS_ENUM.SWAP_CPT.value) {
        rule.action.on.new.forEach((BI) => billableItemIdsSet.add(BI.id));
      }
    });
    const billableItems = getBillableItems([...billableItemIdsSet]);

    return billableItems;
  };

  useEffect(() => {
    const rules = serviceInfo.rulesGroups;
    const calculateCost = async () => {
      const BillableItemsWithFullData = isTesting
        ? await getTestingServiceBillableItems(serviceInfo, rules)
        : await getServiceBillableItems(serviceId);

      const result = aggregateResources(
        rules,
        BillableItemsWithFullData,
        submittedResponses,
        serviceInfo,
      );

      const matchedBIs = BillableItemsWithFullData.reduce((acc, BI) => {
        const matchedBI = result.billableItems.find((_BI) => _BI.id === BI.id);
        if (matchedBI) {
          acc.push({ ...BI, units: matchedBI.units });
        }
        return acc;
      }, []);

      setMatchedBIs(matchedBIs);
      getLocations(result.locations);

      if (!isTesting) {
        setCptBucket(matchedBIs);
        setLocationsBucket(result.locations);
        setOrderDocs(result.documents);
      } else {
        getFinalDocs(result.documents);
      }

      const cost = matchedBIs.reduce(
        (acc, curr) => curr.units * curr.chargeAmount + acc,
        0,
      );

      setCost(cost);
      if (cost === 0) {
        setServiceUnavailableDialogVisible(true);
        return;
      }
    };

    submittedResponses &&
      Object.keys(submittedResponses).length &&
      calculateCost();
  }, [submittedResponses]);

  const getLocations = async (locations) => {
    if (!locations) return;

    setIsLocationsLoading(true);
    let locationsFormatted = locations.map((id) => id.replace(/'/g, '"'));
    try {
      const getLocationsUrl = !isTesting
        ? `/Locations/GetServiceEnabledLocations/${serviceId}`
        : serviceId && locationsFormatted.length > 0
          ? `/Locations/GetAllServiceLocations/${serviceId}`
          : "/Locations/GetLocationsByIds";
      const response = await http.put(getLocationsUrl, locationsFormatted);
      const updatedLocations = response.data.map((loc) => loc.id);
      response?.data?.length <= 0 &&
        setServiceUnavailableDialogVisible &&
        setServiceUnavailableDialogVisible(true);
      setLocationData(response.data);
      !arrayEquals(updatedLocations, locations) &&
        setLocationsBucket(updatedLocations);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLocationsLoading(false);
  };

  const getFinalDocs = async (docsArr) => {
    setIsDocsLoading(true);
    if (docsArr.length > 0) {
      try {
        const response = await http.put(
          "/EntityDocuments/GetDocumentsByIds",
          docsArr,
        );
        setDocumentsData(response.data);
      } catch (error) {
        showErrorToast({ error });
      }
    } else {
      setDocumentsData([]);
    }
    setIsDocsLoading(false);
  };

  let totalCost = cost + (serviceFees / 100) * cost;
  let totalCostWithMax = cost + maxFeesEntity;
  let totalFee = parseFloat((totalCost - cost)?.toFixed(2));

  // Calculate the total original cost of all rows
  const totalOriginalCost = (rows) =>
    rows.reduce((sum, row) => sum + row.chargeAmount * row.units, 0);

  // Distribute fee based on ratio
  const distributeFee = (rows, fee) => {
    const totalCost = totalOriginalCost(rows);
    return rows.map((row) => {
      const rowCost = row.chargeAmount * row.units;
      const proportion = rowCost / totalCost;
      const distributedFee = fee * proportion;
      return {
        ...row,
        distributedFee: distributedFee,
      };
    });
  };

  // Determine the fee to distribute
  let feeToDistribute = totalFee > maxFeesEntity ? maxFeesEntity : totalFee;
  let rowsWithDistributedFee = distributeFee(matchedBIs, feeToDistribute);

  const descriptionWithTooltipTemplate = (rowData) => {
    return (
      <div>
        <Tooltip target=".description" className="max-w-sm" />
        <p
          className="description cursor-help"
          data-pr-position="top"
          data-pr-tooltip={rowData.description}
        >
          {rowData.shortDescription}
        </p>
      </div>
    );
  };

  const locationBodyTemplate = (locationData) => {
    return (
      <div className="flex">
        {!isTesting && serviceType === 1 && (
          <div key={locationData.id} className="flex items-center pr-2">
            <RadioButton
              inputId={locationData.id}
              name="location"
              value={locationData.id}
              onChange={(e) => {
                setSelectedLoc(e.value);
              }}
              checked={selectedLoc === locationData.id}
            />
          </div>
        )}

        <div className="px-2">
          <h4>
            <span
              className={`font-medium capitalize ${locationData.isEnabled ? "text-gray-600" : "text-gray-400"}`}
            >
              {locationData.name}{" "}
            </span>
            {!locationData.isEnabled && (
              <span className="text-sm italic text-gray-400">(disabled)</span>
            )}
          </h4>
          <p className="font-normal capitalize text-gray-500">{`${
            locationData.address.lineOne
          }, ${
            locationData.address.lineTwo
              ? locationData.address.lineTwo + ","
              : ""
          } ${locationData.address.city}, ${locationData.address.state} ${
            locationData.address.zipCode
          }`}</p>
        </div>
      </div>
    );
  };

  //show mods template
  const modTemplate = (rowData) => {
    const mods = [
      rowData.mod1,
      rowData.mod2,
      rowData.mod3,
      rowData.mod4,
    ].filter((mod) => mod);
    return mods.length ? mods.join(" | ") : "- - - - ";
  };

  // Cost template function
  const costTemplate = (rowData) => {
    const totalCost = rowData.chargeAmount * rowData.units;
    const totalCostWithFees = totalCost + rowData.distributedFee;
    let rowCost = isTesting ? totalCost : totalCostWithFees;

    return (
      <div>
        <span>${rowCost > 0 ? rowCost?.toFixed(2) : 0}</span>
      </div>
    );
  };

  return (
    <div className="mt-5">
      <div className="mb-5 font-inter">
        <h4 className="text-title font-extrabold capitalize text-dark-purple">
          cart Information
        </h4>
        <p>
          Below, you will find the detailed breakdown of your order, including
          the specified services, associated locations based on your order form
          responses, and the total charge.
        </p>
      </div>

      <div className="card flex flex-col gap-5">
        <div>
          <DataTable
            value={rowsWithDistributedFee}
            rowGroupMode="rowspan"
            groupRowsBy="id"
            sortField="id"
            sortOrder={1}
            loading={isLoading}
          >
            <Column
              key="shortDescription"
              field="shortDescription"
              header="Description"
              body={descriptionWithTooltipTemplate}
            />
            <Column field="hcpcs" header="HCPCS"></Column>
            <Column header="Mods" body={modTemplate}></Column>
            <Column field="units" header="Units"></Column>
            <Column header="Cost" body={costTemplate}></Column>
          </DataTable>

          <div className="flex flex-col gap-1 p-4 text-end">
            <div>
              <span className="font-bold">
                {isTesting
                  ? "Total Cost with fees excluded: "
                  : "Total Cost with fees included: "}
              </span>
              <span>$</span>
              <span>
                {isTesting
                  ? cost?.toFixed(2)
                  : isNaN(totalCost)
                    ? 0
                    : totalFee > maxFeesEntity
                      ? totalCostWithMax?.toFixed(2)
                      : totalCost?.toFixed(2)}
              </span>
            </div>
            {isTesting && (
              <span className="text-xs italic text-gray-500">
                (Fees may vary based on the ordering provider)
              </span>
            )}
          </div>
        </div>
        <div>
          {!isTesting && serviceType === 1 && (
            <h4 className="text-title font-extrabold capitalize text-dark-purple">
              Select Your preferred location
            </h4>
          )}
          <DataTable loading={isLocationsLoading} value={locationData}>
            <Column
              header="Available Locations"
              body={locationBodyTemplate}
            ></Column>
          </DataTable>

          {isTesting && (
            <>
              <Divider />
              <div className="w-full">
                <h2 className="p-2 font-bold">Available Documents</h2>
                <DataTable
                  showGridlines
                  scrollable
                  loading={isDocsLoading}
                  scrollHeight="400px"
                  className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
                  value={documentsData}
                  emptyMessage={<p className="text-center">No results found</p>}
                >
                  <Column
                    field="documentTitle"
                    header="Title"
                    align="center"
                    alignHeader="center"
                    style={{ width: "30%" }}
                  />
                  <Column
                    field="documentDescription"
                    header="Description"
                    align="center"
                    alignHeader="center"
                    style={{ width: "60%" }}
                  />
                  <Column
                    header="Actions"
                    align="center"
                    alignHeader="center"
                    style={{ width: "10%" }}
                    body={(rowData, options) => (
                      <i
                        className="doc-action pi pi-download cursor-pointer text-gray-500"
                        data-pr-tooltip="Download Document"
                        data-pr-position="top"
                        onClick={() => {
                          handleDownloadDocument(rowData);
                        }}
                      ></i>
                    )}
                  />
                </DataTable>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ServiceCart;
