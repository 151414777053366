import { Dropdown } from "primereact/dropdown";
import { Controller, useFormContext } from "react-hook-form";

const DisplayDropDown = ({ question }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={`${question.id}.0.value`}
      control={control}
      defaultValue=""
      rules={{
        required: question?.required && "Question is required",
      }}
      render={({ field, fieldState: { error } }) => (
        <>
          <Dropdown
            {...field}
            options={question.answers}
            id="dropdown-question"
            optionLabel="value"
            optionValue="id"
            placeholder="Select answers"
            className="w-full"
          />
          {error && <p className="text-sm text-red-500">{error.message}</p>}
        </>
      )}
    />
  );
};

export default DisplayDropDown;
