import { useEffect, useRef, useState } from "react";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";
import { useOverlayScrollListener } from "primereact/hooks";

import useAxios from "../../hooks/useAxios";
import { ReusableDataList } from "../Table";
import { useLayoutToast } from "../../store/store";
import useTokenData from "../../hooks/useTokenData";
import { STAFF_ROLES } from "../../constants/constants";
import {
  EmailOwnerTemplate,
  LastCertifiedTemplate,
  NameEmailTemplate,
} from "../templates/columnBodyTemplates";
import { showErrorToast } from "../../utils/apiUtils";

const StaffDashboardStaff = ({ refetch, setRefetch }) => {
  const { http } = useAxios();
  const { layoutToast } = useLayoutToast();
  const { userId, entityId } = useTokenData();

  const menuRight = useRef({});
  const buttonRef = useRef(null);
  const [visible, setVisible] = useState(false);

  const [bindOverlayScrollListener, unbindOverlayScrollListener] =
    useOverlayScrollListener({
      target: buttonRef.current,
      listener: () => setVisible(false),
      options: { passive: true },
      when: visible,
    });

  useEffect(() => {
    bindOverlayScrollListener();

    return () => {
      unbindOverlayScrollListener();
    };
  }, [bindOverlayScrollListener, unbindOverlayScrollListener]);

  const isRowSelectable = (event) => {
    if (event.data) {
      return !event.data.disabled;
    }
    return true;
  };

  const rowClassName = (data) => {
    if (data.isOwner === true || data.applicationUserId === userId) {
      return "p-disabled";
    }
    return "";
  };

  const accept = async (rowData, message) => {
    const { isCertified, isEnabled } = rowData;
    const selectedOption = STAFF_ROLES.find(
      (option) => option.label === rowData.role,
    );
    const updatedCertified = {
      role: selectedOption.value,
      isEnabled: rowData.isEnabled,
      isCertified: rowData.isCertified,
    };
    if (
      message == "Would you like to enable this staff as certified member ?" &&
      !isCertified &&
      !isEnabled
    ) {
      updatedCertified.isCertified = true;
      updatedCertified.isEnabled = true;
    } else if (
      message ==
        "Would you like to disable this staff as uncertified member ?" &&
      isCertified &&
      isEnabled
    ) {
      updatedCertified.isCertified = false;
      updatedCertified.isEnabled = false;
    }

    try {
      const response = await http.put(
        `/EntityUser/${rowData.entityUserId}`,
        updatedCertified,
      );
      const data = response.data;
      layoutToast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: data,
        life: 3000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
    setRefetch(!refetch);
  };

  const deleteRow = async (rowData) => {
    try {
      const endPointUrl = `/EntityUser/${rowData.entityUserId}`;

      const res = await http.delete(endPointUrl);

      setRefetch((prev) => !prev);
      layoutToast.current.show({
        severity: "success",
        summary: "success",
        detail: res.data,
        life: 2000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleDelete = async (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete?",
      icon: "pi pi-exclamation-triangle",
      position: "center",
      accept: async () => {
        try {
          await new Promise((resolve) => {
            deleteRow(rowData);
            resolve();
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const confirm = (position, message, rowData) => {
    confirmDialog({
      message: message,
      icon: "pi pi-info-circle",
      position,
      accept: () => accept(rowData, message),
    });
  };

  const getMenuItems = (rowData) => {
    const items = [];

    if (rowData.isCertified === true) {
      items.push({
        label: "Deactivate",
        icon: "pi pi-ban",
        command: () =>
          confirm(
            "top-right",
            "Would you like to disable this staff as uncertified member ?",
            rowData,
          ),
      });
    } else {
      items.push({
        label: "Activate",
        icon: "pi pi-user",
        command: () =>
          confirm(
            "top-right",
            "Would you like to enable this staff as certified member ?",
            rowData,
          ),
      });
    }

    items.push({
      label: "Delete",
      icon: "pi pi-trash",
      command: () => handleDelete(rowData),
    });

    return items;
  };

  const dropdownEditor = (props) => {
    const onDropdownChange = (e) => {
      const updatedRole = {
        applicationUserId: props.applicationUserId,
        entityId: entityId,
        role: STAFF_ROLES.find((role) => role.key === e.value).value,
      };
      const UpdatedRole = async () => {
        try {
          const response = await http.put(
            "/EntityUser/UpdateEntityUserRole",
            updatedRole,
          );
          layoutToast.current.show({
            severity: "success",
            summary: "success",
            detail: response.data.message,
            life: 4000,
          });
          setRefetch(!refetch);
        } catch (error) {
          showErrorToast({ error });
        }
      };
      UpdatedRole();
    };

    return (
      <Dropdown
        value={props.role}
        options={STAFF_ROLES}
        onChange={onDropdownChange}
        optionLabel="label"
        optionValue="key"
        placeholder={props.role}
        className="w-full"
        disabled={!props.isEnabled}
      />
    );
  };

  const actionTemplate = (rowData) => {
    const { entityUserId } = rowData;

    return (
      <>
        <Button
          ref={buttonRef}
          icon="pi pi-ellipsis-v"
          text
          onClick={(event) => {
            setVisible(true);
            menuRight.current[entityUserId].toggle(event);
          }}
          aria-controls={`popup_menu_right_${entityUserId}`}
          aria-haspopup
        />
        <Menu
          model={getMenuItems(rowData)}
          popup
          ref={(el) => (menuRight.current[entityUserId] = el)}
          id={`popup_menu_right_${entityUserId}`}
          className={visible ? "" : "hidden"}
        />
      </>
    );
  };

  const cardListOfStaffTemplate = (row) => {
    const isDisabled = row.isOwner === true || row.applicationUserId === userId;
    return (
      <>
        <div className={`${isDisabled ? "p-disabled" : ""}`}>
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <div>{NameEmailTemplate(row)}</div>
            </div>
            <div className="flex">{actionTemplate(row)}</div>
          </div>
          <p className="font-semibold">
            <span className="font-semibold text-slate-500">Role:&nbsp;</span>
            {row.role}
          </p>

          <p className="flex flex-col gap-4 font-semibold md:flex-row md:gap-2">
            <span className="font-semibold text-slate-500">
              Last Verified Date:&nbsp;
            </span>
            {LastCertifiedTemplate(row)}
          </p>
        </div>
      </>
    );
  };

  const TABLE_COLUMNS = [
    { field: "name", header: "Name", style: { width: "20%" } },
    {
      template: EmailOwnerTemplate,
      header: "Emails",
      style: { width: "20%" },
    },
    {
      template: dropdownEditor,
      header: "Roles",
      style: { width: "20%" },
    },
    {
      template: LastCertifiedTemplate,
      header: "Last Certified Date",
      style: { width: "20%" },
    },
    {
      template: actionTemplate,
      header: "Actions",
      style: { width: "20%" },
    },
  ];
  return (
    <ReusableDataList
      title={"All Members"}
      fromFront={true}
      dataLink={`/Entities/GetEntityStaff/${entityId}`}
      columns={TABLE_COLUMNS}
      searchTitle="search by name"
      isRowSelectable={isRowSelectable}
      rowClassName={rowClassName}
      refetch={refetch}
      setRefetch={setRefetch}
      mobileTemplate={cardListOfStaffTemplate}
      cardFilters={["name", "email"]}
    />
  );
};

export default StaffDashboardStaff;
