import { useEffect, useRef, useState } from "react";

import { Steps } from "primereact/steps";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import useAxios from "../../../../../hooks/useAxios";
import { PatientInfo, RequestService } from "../../../../../components/forms";
import ServiceCart from "../../../../ordering/orderService/components/ServiceCart";
import { Dialog } from "primereact/dialog";
import { showErrorToast } from "../../../../../utils/apiUtils";

export default function TestService({ serviceId, serviceInfo }) {
  const [patientInfoData, setPatientInfoData] = useState(null);
  const onSubmitCallback = (formData) => {
    setPatientInfoData(formData);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const stepsLabel = [
    {
      label: "Order Information",
      disabled: false,
      command: (event) => {
        setActiveIndex(event.index);
        setCost(0);
        setSubmittedResponses({});
      },
    },
    {
      label: "Answer Questions",
      disabled: activeIndex === 0,
      command: (event) => {
        setActiveIndex(event.index);
      },
    },
  ];
  const submitRef = useRef();
  const [questions, setQuestions] = useState([]);
  const [services, setServices] = useState({});
  const [generalInfo, setGeneralInfo] = useState({});
  const [orderData, setOrderData] = useState();
  const [cost, setCost] = useState(0);
  const [serviceUnavailableDialogVisible, setServiceUnavailableDialogVisible] =
    useState(false);
  const [submittedResponses, setSubmittedResponses] = useState({});

  const { http } = useAxios();

  const getData = async () => {
    let data;
    try {
      if (serviceInfo) {
        data = JSON.parse(serviceInfo);
      } else {
        const servicesData = await http.get(
          `/Services/GetServiceWizard/${serviceId}`,
        );
        data = JSON.parse(servicesData.data.serviceJson);
      }

      setOrderData(data);
      setQuestions(data.questions);
      setServices(data.services);
      setGeneralInfo(data.generalInfo);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Dialog
        visible={serviceUnavailableDialogVisible}
        modal
        closable={false}
        header="Oops! It looks like there's an issue with your order."
        footer={
          <Button
            label="Ok"
            icon="pi pi-check"
            onClick={() => {
              setServiceUnavailableDialogVisible(false);
            }}
            autoFocus
          />
        }
        style={{ width: "50rem" }}
      >
        <div className="flex items-center gap-2">
          <i className="pi pi-info-circle mr-2 text-3xl text-red-400"></i>
          <p className="text-lg font-semibold">
            We're sorry, but it seems that your order doesn't meet the necessary
            requirements at this time.
          </p>
        </div>
      </Dialog>
      <div className="px-4">
        <Steps model={stepsLabel} activeIndex={activeIndex} readOnly={false} />
        {activeIndex === 0 ? (
          <div className="flex h-full max-h-[70vh] flex-col gap-4">
            <div className="h-full overflow-y-auto p-4">
              <PatientInfo
                patientInfoData={patientInfoData}
                isTesting={true}
                submitRef={submitRef}
                setActiveIndex={setActiveIndex}
                onSubmitCallback={onSubmitCallback}
              />
            </div>
            <div className="flex justify-end">
              <Button
                label="Next"
                onClick={() => {
                  submitRef.current.requestSubmit();
                }}
              />
            </div>
          </div>
        ) : activeIndex === 1 ? (
          <div className="flex h-full">
            <div className="flex max-h-[70vh] flex-1 flex-col gap-4">
              <div className="h-full overflow-y-auto pr-2">
                <RequestService
                  isTesting={true}
                  services={services}
                  serviceId={serviceId}
                  questions={questions}
                  submitRef={submitRef}
                  generalInfo={generalInfo}
                  serviceInfo={orderData}
                  patientInfo={patientInfoData}
                  setActiveIndex={setActiveIndex}
                  setSubmittedResponses={setSubmittedResponses}
                />
              </div>

              <div className="flex justify-end px-5">
                <Button
                  outlined
                  label="Calculate Cost"
                  id="calc-cost"
                  onClick={() => {
                    submitRef.current.requestSubmit();
                  }}
                />
              </div>
            </div>
            <Divider layout="vertical" />
            <div className="h-full max-h-[70vh] flex-1 overflow-y-auto pr-2">
              <ServiceCart
                submittedResponses={submittedResponses}
                cost={cost}
                setCost={setCost}
                isTesting={true}
                serviceId={serviceId}
                serviceInfo={orderData}
                setServiceUnavailableDialogVisible={
                  setServiceUnavailableDialogVisible
                }
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
