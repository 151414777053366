import { useRef } from "react";

import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../hooks/useAxios";
import useTokenData from "../../../../hooks/useTokenData";
import { showErrorToast } from "../../../../utils/apiUtils";

function AddExternalProviderForm({
  setIsAddExternalProviderDialogOpen,
  setFetchExternalProviders,
  handleAddExternalProvider,
}) {
  const toast = useRef();
  const { http } = useAxios();
  const { entityId } = useTokenData();

  const defaultValues = {
    npi: "",
    firstName: "",
    middleName: "",
    LastName: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const onSubmit = async (formData) => {
    try {
      const response = await http.post(
        `/Orders/CreateExternalProvider/${entityId}`,
        formData,
      );
      setFetchExternalProviders(true);
      setIsAddExternalProviderDialogOpen(false);
      handleAddExternalProvider(response.data);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const handleCancel = () => {
    setIsAddExternalProviderDialogOpen(false);
  };

  return (
    <div>
      <Toast ref={toast} />

      <form
        className="flex w-full flex-col gap-3"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
          e.stopPropagation();
        }}
      >
        <div className="flex flex-col gap-1">
          <div className="flex gap-3">
            <label htmlFor="npi" className="font-semibold text-gray-800">
              Ordering Provider NPI <span className="text-red-500">*</span>
            </label>

            <Link
              to={"https://npiregistry.cms.hhs.gov/search/"}
              target="_blank"
              className="italic hover:underline"
            >
              Look up an NPI?
            </Link>
          </div>

          <Controller
            name="npi"
            control={control}
            rules={{
              required: "Ordering Provider NPI is required",
              pattern: {
                value: /^\d{10}$/,
                message: "NPI must be exactly 10 digits",
              },
            }}
            render={({ field }) => (
              <InputText
                id="npi"
                type="text"
                keyfilter="num"
                placeholder="Ordering Provider NPI"
                {...field}
                maxLength={10}
                className="w-full"
              />
            )}
          />
          {errors.npi && (
            <div className="text-red-500">{errors.npi.message}</div>
          )}
        </div>
        <div className="flex flex-col gap-1">
          <label htmlFor="NPI" className="font-semibold text-gray-800">
            Ordering Provider Name
          </label>

          <div className="flex flex-col gap-2 rounded-md border p-4 md:flex-row">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="firstName"
                className="font-semibold text-gray-800"
              >
                First Name <span className="text-red-500">*</span>
              </label>

              <Controller
                name="firstName"
                control={control}
                rules={{ required: "First Name is required" }}
                render={({ field }) => (
                  <InputText
                    id="firstName"
                    type="text"
                    placeholder="First Name"
                    {...field}
                    className="w-full"
                  />
                )}
              />
              {errors.firstName && (
                <div className="text-red-500">{errors.firstName.message}</div>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="firstName"
                className="font-semibold text-gray-800"
              >
                Middle Name
              </label>
              <Controller
                name="middleName"
                control={control}
                render={({ field }) => (
                  <InputText
                    id="middleName"
                    type="text"
                    placeholder="Middle Name"
                    {...field}
                    className="w-full"
                  />
                )}
              />
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="firstName"
                className="font-semibold text-gray-800"
              >
                Last Name <span className="text-red-500">*</span>
              </label>
              <Controller
                name="LastName"
                control={control}
                rules={{ required: "Last Name is required" }}
                render={({ field }) => (
                  <InputText
                    id="LastName"
                    type="text"
                    placeholder="Last Name"
                    {...field}
                    className="w-full"
                  />
                )}
              />
              {errors.LastName && (
                <div className="text-red-500">{errors.LastName.message}</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <Button
            label="Cancel"
            severity="danger"
            type="button"
            disabled={isSubmitting}
            onClick={handleCancel}
          />
          <Button label="Submit" loading={isSubmitting} />
        </div>
      </form>
    </div>
  );
}

export default AddExternalProviderForm;
