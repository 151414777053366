import { Dialog } from "primereact/dialog";
import { useState } from "react";

import ManageQuestionForm from "./ManageQuestionForm";
// import { useQuestionStore } from "../../components/_private/utils/store";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useServiceStore } from "../utils/store";
import ManageRenderQuestions from "./ManageRenderQuestions";
import { SplitButton } from "primereact/splitbutton";
import { QUESTION_TYPES_ENUM } from "../utils/constants";

const QuestionBank = () => {
  const [showManageQuestionDailog, setShowManageQuestionDialog] =
    useState(false);
  const [questionData, setQuestionData] = useState();
  const [questionType, setQuestionType] = useState(null);

  const handleAddNewQuestion = () => {
    setShowManageQuestionDialog(true);
    setQuestionData(null);
    setQuestionType(null);
  };

  const { questions, setQuestions } = useServiceStore();

  const dragEnded = (param) => {
    const { source, destination } = param;
    if (!destination || destination.index === source.index) return;
    const updatedQuestions = Array.from(questions);
    const [dragged] = updatedQuestions.splice(source.index, 1);
    updatedQuestions.splice(destination.index, 0, dragged);
    setQuestions(updatedQuestions);
  };
  const quickAddQuestion = (type) => {
    setQuestionData(null);
    setQuestionType(type);
    setShowManageQuestionDialog(true);
  };
  const items = [
    {
      label: QUESTION_TYPES_ENUM.DROPDOWN.label,
      icon: QUESTION_TYPES_ENUM.DROPDOWN.icon,
      command: () => {
        quickAddQuestion(QUESTION_TYPES_ENUM.DROPDOWN.value);
      },
    },
    {
      label: QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.label,
      icon: QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.icon,
      command: () => {
        quickAddQuestion(QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value);
      },
    },
    {
      label: QUESTION_TYPES_ENUM.DECIMAL.label,
      icon: QUESTION_TYPES_ENUM.DECIMAL.icon,
      command: () => {
        quickAddQuestion(QUESTION_TYPES_ENUM.DECIMAL.value);
      },
    },
    {
      label: QUESTION_TYPES_ENUM.NUMBER.label,
      icon: QUESTION_TYPES_ENUM.NUMBER.icon,
      command: () => {
        quickAddQuestion(QUESTION_TYPES_ENUM.NUMBER.value);
      },
    },
    {
      label: QUESTION_TYPES_ENUM.TEXT.label,
      icon: QUESTION_TYPES_ENUM.TEXT.icon,
      command: () => {
        quickAddQuestion(QUESTION_TYPES_ENUM.TEXT.value);
      },
    },
    {
      label: QUESTION_TYPES_ENUM.BOOLEAN.label,
      icon: QUESTION_TYPES_ENUM.BOOLEAN.icon,
      command: () => {
        quickAddQuestion(QUESTION_TYPES_ENUM.BOOLEAN.value);
      },
    },
  ];

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        className="w-4/5"
        header="Manage Question"
        visible={showManageQuestionDailog}
        onHide={() => setShowManageQuestionDialog(false)}
      >
        <ManageQuestionForm
          questionData={questionData}
          type={questionType}
          setShowManageQuestionDialog={setShowManageQuestionDialog}
        />
      </Dialog>
      <div className="space-y-4 p-2">
        <div className="flex justify-between">
          <span className="flex items-center gap-2">
            <i className="pi pi-cog text-lg"></i>
            <h2 className="text-lg font-semibold text-gray-600">
              Manage Questions
            </h2>
          </span>
          <SplitButton
            icon="pi pi-plus"
            label="Add A New Question"
            type="button"
            severity="info"
            className="bg-light-purple"
            onClick={handleAddNewQuestion}
            model={items}
            pt={{ menuLabel: { className: "ml-2" } }}
          />
        </div>

        <div className="rounded-xl border border-gray-100 bg-slate-50 p-4">
          {questions.length === 0 && (
            <div className="flex h-16 items-center justify-center">
              <p>
                Looks like you haven't added any questions yet. Add a new
                question to get started.
              </p>
            </div>
          )}
          <DragDropContext onDragEnd={dragEnded}>
            <Droppable droppableId="questions-wrapper">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="space-y-4"
                >
                  {questions.map((question, index) => (
                    <Draggable
                      key={question.id}
                      draggableId={question.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ManageRenderQuestions
                            setQuestionData={setQuestionData}
                            setShowManageQuestionDialog={
                              setShowManageQuestionDialog
                            }
                            question={question}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </>
  );
};

export default QuestionBank;
