import { useState } from "react";

import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import { emailRegex } from "../../utils/regex";
import { trimValidation } from "../../utils/helpers";
import { EXTERNAL_AUTH_ATTR_ENUM } from "../../constants/constants";
import ProviderAuthentication from "../../components/providerAuthentication/ProviderAuthentication";
import {
  useAuthStore,
  useRefreshTokenStore,
  useRegisterFormStore,
  useTokenStore,
} from "../../store/store";

function Login() {
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);
  const { setUserLoginId } = useAuthStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setToken } = useTokenStore();
  const setFormData = useRegisterFormStore((state) => state.setFormData);

  const [passwordEye, setPasswordEye] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = {
    email: "",
    password: "",
    checked: false,
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues });

  const onSubmit = async (formData) => {
    setIsLoading(true);

    try {
      const localToken = JSON.parse(localStorage.getItem("token"));
      if (localToken.state.token) {
        navigate(`/chooseEntity`, { replace: true });
      }

      const response = await axios.post("/Authentication/login", formData);
      const data = response.data;

      if (data.isAuthenticated) {
        setToken(data.token);
        setRefreshToken(data.refreshToken);
        setUser(data);
        setUserLoginId(data.userId);
        setErrorMessage(null);
      }
    } catch (error) {
      if (error?.response?.data?.message === "Email needs to be confirmed") {
        setFormData(formData);
        navigate("/verifyEmail", {
          state: { email: error?.response?.data?.email },
          replace: true,
        });
      } else {
        setErrorMessage(error?.response?.data?.message);
        setTimeout(() => setErrorMessage(null), 5000);
      }
    }

    setIsLoading(false);
  };

  const loginUrl = "/Authentication/ExternalLogin";

  return (
    <div className="mb-9 flex items-center justify-center">
      <div className="Xl:w-1/3 mb-2 mt-10 w-11/12 rounded-3xl border border-solid border-light-purple bg-light-text p-5 md:mb-0 md:w-6/12 md:px-10 md:py-5 lg:w-1/3 2xl:w-1/3">
        <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
          login in to MedX
        </h4>
        <div className="mb-14 mt-8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <label htmlFor="email">
              Email<span className="ml-1 font-bold text-red-500">*</span>
            </label>
            <span className="p-input-icon-left w-full">
              <i className="pi pi-user" />
              <InputText
                {...register("email", {
                  required: "Please enter your email",
                  pattern: {
                    value: emailRegex,
                    message: "Invalid email address",
                  },
                })}
                id="email"
                autoComplete="current-email"
                placeholder="Email Address"
                className="w-full pb-2 pt-2"
              />
            </span>
            {errors.email && (
              <p className="text-red-500">{errors.email.message}</p>
            )}
            <div className="mt-4">
              <label htmlFor="password">
                Password<span className="ml-1 font-bold text-red-500">*</span>
              </label>
              <span className="p-input-icon-left p-input-icon-right w-full">
                <i className="pi pi-lock" />
                <InputText
                  {...register("password", {
                    required: "Password is required",
                    validate: (value) => trimValidation(value, "Password"),
                  })}
                  id="password"
                  type={!passwordEye ? "password" : "text"}
                  placeholder="Password (8 or more characters)"
                  className="w-full pb-2 pt-2"
                />
                <i
                  className={`pi ${passwordEye ? "pi-eye" : "pi-eye-slash"}`}
                  onClick={() => setPasswordEye(!passwordEye)}
                />
              </span>
              {errors.password && (
                <p className="mt-1 text-red-500">{errors.password.message}</p>
              )}

              {errorMessage && (
                <Message
                  severity="error"
                  text={errorMessage + ". Please try again!"}
                  className="mt-9 w-full font-bold"
                />
              )}
            </div>

            <div className="mt-8 flex flex-col justify-between sm:flex-row">
              <div className="flex gap-1">
                <Controller
                  name="checked"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Checkbox
                        inputId={field.name}
                        checked={field.value}
                        inputRef={field.ref}
                        onChange={(e) => field.onChange(e.checked)}
                        className="pt-1"
                      />
                      <label htmlFor={field.name}>Keep me logged in</label>
                    </>
                  )}
                />
              </div>
              <div
                onClick={() => navigate("/forgetPassword")}
                className="text-right"
              >
                <span className="cursor-pointer text-light-purple">
                  Forgot password?
                </span>
              </div>
            </div>
            {isLoading ? <LoadingSpinner /> : <SubmitButton />}
            <div className="mb-5 mt-6 grid grid-cols-3 items-center text-gray-400">
              <hr className="border-gray-400" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-400" />
            </div>
          </form>

          <ProviderAuthentication
            authenticationUrl={loginUrl}
            authTypeKey={EXTERNAL_AUTH_ATTR_ENUM.SIGN_IN}
          />

          <div className="mt-5 text-gray-400">
            <p className="text-center text-sm capitalize">
              don't have an account?
            </p>
          </div>
          <div className="text-center">
            <Link to={"/registration"}>
              <Button className="mt-3 justify-center rounded-full bg-transparent py-2.5 font-inter font-semibold capitalize text-dark-purple">
                sign up
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-row justify-between">
          <span className="mr-3 text-center text-sm text-gray-600 md:text-left">
            ©
            <span id="currentYear">&nbsp;{new Date().getFullYear()}&nbsp;</span>
            MedX™. All Rights Reserved.
          </span>
          <a href="/legal" target="_blank">
            <span className="text-center text-sm text-gray-600 hover:underline md:text-left">
              Legal Notices and Terms
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;

const LoadingSpinner = () => (
  <div className="text-center">
    <ProgressSpinner
      strokeWidth={3}
      style={{ width: "40px", height: "40px" }}
    />
  </div>
);

const SubmitButton = () => (
  <Button
    type="submit"
    className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
  >
    Login
  </Button>
);
