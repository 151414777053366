import { Tooltip } from "primereact/tooltip";
import { USER_ROLES_ENUM } from "../../../constants/constants";
import ProviderIcon from "../../../iconComponents/ProviderIcon";
import { useRef } from "react";

export const ProviderTemplate = ({ role, isProvider }) => {
  const roleObj = {
    [USER_ROLES_ENUM.ADMIN]: "Admin",
    [USER_ROLES_ENUM.STAFF]: "Staff",
    [USER_ROLES_ENUM.ORDERING_ONLY_STAFF]: "Ordering Only Staff",
    [USER_ROLES_ENUM.SYSTEM_ADMIN]: "System Admin",
  };
  const ref = useRef();
  return (
    <>
      <Tooltip target={ref} />
      <div className="flex items-center gap-3">
        <div className="w-1/2">{roleObj[role]}</div>
        {isProvider === true ? (
          <span
            className="cursor-pointer text-light-purple"
            data-pr-tooltip="Provider"
            data-pr-position="top"
            ref={ref}
          >
            <ProviderIcon />
          </span>
        ) : null}
      </div>
    </>
  );
};
