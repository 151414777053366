import { Link } from "react-router-dom";

import { Button } from "primereact/button";

import SignUpForm from "./components/SignUpForm";
import ProviderAuthentication from "../../components/providerAuthentication/ProviderAuthentication";
import { EXTERNAL_AUTH_ATTR_ENUM } from "../../constants/constants";

function SignUp() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchParams = {
    email: urlSearchParams.get("E"),
    EId: urlSearchParams.get("EId"),
    date: urlSearchParams.get("IDate"),
    role: urlSearchParams.get("SR"),
    isProvider: urlSearchParams.get("IP") || "",
    InvId: urlSearchParams.get("InvID"),
  };

  const registrationUrl = !searchParams.EId
    ? "/Authentication/ExternalLogin"
    : `/Authentication/ExternalLogin?InvitationId=${searchParams.InvId}&EntityId=${searchParams.EId}&InvitationDate=${searchParams.date}&staffRole=${searchParams.role}&IsProvider=${searchParams.isProvider}`;

  return (
    <>
      <div className="mb-9 flex items-center justify-center">
        <div className="xsm:w-9/12 mt-10 w-11/12 rounded-3xl bg-light-text py-8 pl-10 pr-10 shadow shadow-slate-300 md:w-6/12 lg:w-5/12 xl:w-5/12 2xl:w-5/12">
          <h4 className="text-center font-philosopher text-title font-extrabold text-dark-purple">
            Sign Up to MedX
          </h4>

          <ProviderAuthentication
            authenticationUrl={registrationUrl}
            invId={searchParams.InvId}
            authTypeKey={EXTERNAL_AUTH_ATTR_ENUM.SIGN_UP}
          />

          <div className="grid grid-cols-3 items-center p-6 text-gray-400">
            <hr className="border-gray-500" />
            <p className="text-center text-base">OR</p>
            <hr className="border-gray-500" />
          </div>
          <SignUpForm searchParams={searchParams} />

          <h5 className="text-center">
            Already have an account?
            <Button className="p-0" link>
              <Link to={"/login"} className="ml-1">
                Login
              </Link>
            </Button>
          </h5>
          <div className="mt-20 flex flex-row justify-between">
            <span className="mr-3 text-center text-sm text-gray-600 md:text-left">
              ©
              <span id="currentYear">
                &nbsp;{new Date().getFullYear()}&nbsp;
              </span>
              MedX™. All Rights Reserved.
            </span>
            <a href="/legal" target="_blank">
              <span className="text-center text-sm text-gray-600 underline md:text-left">
                Legal Notices and Terms
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
