import { createContext, useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import useAxios from "../../../../../../hooks/useAxios";
import useTokenData from "../../../../../../hooks/useTokenData";
import { useLocationsStore } from "../../../../../../store/store";
import { showErrorToast } from "../../../../../../utils/apiUtils";

const LocationsContext = createContext();

export const useLocationsContext = () => useContext(LocationsContext);

export const LocationsProvider = ({ children, isCopy = false }) => {
  const { http } = useAxios();
  const { serviceId } = useParams();
  const { entityId } = useTokenData();

  const [locationVisible, setLocationVisible] = useState(false);
  const [isFetchingLocations, setIsFetchingLocations] = useState(false);
  const {
    defaultLocations,
    setDefaultLocations,
    selectedLocations,
    setSelectedLocations,
  } = useLocationsStore();

  const getLocations = async () => {
    try {
      setIsFetchingLocations(true);
      const res = await http.get(
        `/Locations/GetLocationsByEntityId/${entityId}${serviceId ? "?serviceId=" : ""}${serviceId && !isCopy ? serviceId : ""}`,
      );
      setDefaultLocations(res.data);
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setIsFetchingLocations(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <LocationsContext.Provider
      value={{
        defaultLocations,
        setDefaultLocations,
        selectedLocations,
        setSelectedLocations,
        locationVisible,
        setLocationVisible,
        getLocations,
        isFetchingLocations,
      }}
    >
      {children}
    </LocationsContext.Provider>
  );
};
