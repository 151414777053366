import { matchPattern } from "../../../rendering/services/my-services/manage-service/components/rules/actions/helpers";
import {
  ACTIONS_ENUM,
  RULE_TYPES,
} from "../../../rendering/services/my-services/manage-service/utils/constants";
import { checkRuleLogicalOperator } from "../../../rendering/services/my-services/manage-service/utils/utils";
import { convertStandardToWeight } from "../../../rendering/services/my-services/manage-service/utils/numericConversions";

const isNullOrUndefined = (value) => [null, undefined].includes(value);

export const aggregateResources = (
  rules,
  billableItemsWithFullData,
  userAnswers,
  serviceData,
) => {
  const clonedBillableItems = structuredClone(serviceData.billableItems);

  let resources = {
    locations: serviceData.locations || [],
    documents: serviceData.documents || [],
    billableItems: clonedBillableItems || [],
    abortMessages: [],
  };

  rules.every((rule) => {
    const isAction = rule.type === RULE_TYPES.ACTION.value;

    const isMatchedRule =
      isAction ||
      checkRuleLogicalOperator(
        rule.operator,
        rule.conditionalGroups,
        userAnswers,
        resources,
      );

    if (isMatchedRule) {
      resources = updateResources(
        rule,
        resources,
        billableItemsWithFullData,
        userAnswers,
        serviceData,
      );
    }

    const isAbort = resources?.abortMessages?.length > 0;
    return isAbort ? false : true;
  });

  resources.billableItems = resources.billableItems.filter(
    (BI) => BI.units > 0,
  );

  return resources;
};

const updateResources = (
  matchedRule,
  resources,
  billableItemsWithFullData,
  userAnswers,
  serviceData,
) => {
  const getValueByQuestion = (questionId) => {
    const questions = serviceData.questions;
    const answer = userAnswers[questionId]?.[0];
    const storeAs =
      answer?.unit &&
      questions.find((q) => q?.id === questionId)?.constraints?.storeAs;

    return !isNullOrUndefined(answer.value)
      ? answer?.unit
        ? convertStandardToWeight(answer?.value, storeAs)
        : answer?.value
      : null;
  };

  const getUnits = (billableItem) => {
    const questionId = billableItem?.questionId;

    const units = questionId
      ? getValueByQuestion(questionId)
      : billableItem?.units;

    return units;
  };

  const { key, on } = matchedRule.action;
  let { locations, documents, billableItems, abortMessages } = resources;

  const clonedOn = structuredClone(on);
  if (key === ACTIONS_ENUM.ADD_CPT.value) {
    billableItems = [...billableItems, ...clonedOn];
  } else if (key === ACTIONS_ENUM.SET_UNITS.value) {
    billableItems = billableItems.map((billableItemToUpdate) => {
      const matchedBI = on.find(
        (billableItemToApplyActionOn) =>
          billableItemToUpdate.id === billableItemToApplyActionOn.id,
      );

      const units = getUnits(matchedBI);

      if (isNullOrUndefined(units)) return billableItemToUpdate;

      return matchedBI
        ? { ...billableItemToUpdate, units: Math.ceil(units) }
        : billableItemToUpdate;
    });
  } else if (key === ACTIONS_ENUM.ADD_UNITS_TO_EXISTING.value) {
    on.forEach((billableItemToApplyActionOn) => {
      const billableItemToUpdateIndex = billableItems.findIndex(
        (billableItemToUpdate) =>
          billableItemToUpdate.id === billableItemToApplyActionOn.id,
      );

      if (billableItemToUpdateIndex === -1) return;

      const units = getUnits(billableItemToApplyActionOn);

      if (isNullOrUndefined(units)) return;

      billableItems[billableItemToUpdateIndex] = {
        ...billableItems[billableItemToUpdateIndex],
        units:
          billableItems[billableItemToUpdateIndex].units + Math.ceil(units),
      };
    });
  } else if (key === ACTIONS_ENUM.REMOVE_UNITS_FROM_EXISTING.value) {
    on.forEach((billableItemToApplyActionOn) => {
      const billableItemToUpdateIndex = billableItems.findIndex(
        (billableItemToUpdate) =>
          billableItemToUpdate.id === billableItemToApplyActionOn.id,
      );

      if (billableItemToUpdateIndex === -1) return;

      const units = getUnits(billableItemToApplyActionOn);

      if (isNullOrUndefined(units)) return;

      billableItems[billableItemToUpdateIndex] = {
        ...billableItems[billableItemToUpdateIndex],
        units:
          billableItems[billableItemToUpdateIndex].units - Math.ceil(units) > 0
            ? billableItems[billableItemToUpdateIndex].units - Math.ceil(units)
            : 0,
      };
    });
  } else if (key === ACTIONS_ENUM.REMOVE_CPT.value) {
    billableItems = billableItems.filter(
      (billableItemToUpdate) =>
        !on.some(
          (billableItemToApplyActionOn) =>
            billableItemToUpdate.id === billableItemToApplyActionOn.id,
        ),
    );
  } else if (key === ACTIONS_ENUM.REMOVE_ALL_EXCEPT.value) {
    billableItems = billableItems.filter((billableItemToUpdate) =>
      on.some(
        (billableItemToApplyActionOn) =>
          billableItemToUpdate.id === billableItemToApplyActionOn.id,
      ),
    );
  } else if (key === ACTIONS_ENUM.REMOVE_MATCHING_PATTERN.value) {
    const billableItemsWithDataToRemove = billableItemsWithFullData.filter(
      (billableItemToUpdate) =>
        matchPattern(billableItemToUpdate.hcpcs, on.pattern),
    );

    billableItems = billableItems.filter(
      (billableItemToUpdate) =>
        !billableItemsWithDataToRemove.some(
          (billableItemsWithData) =>
            billableItemsWithData.id === billableItemToUpdate.id,
        ),
    );
  } else if (key === ACTIONS_ENUM.SWAP_CPT.value) {
    const indexToReplace = billableItems.findIndex(
      (billableItemToUpdate) => billableItemToUpdate.id === on.old[0].id,
    );
    const itemToPlace = on.new[0];
    if (indexToReplace !== -1) {
      billableItems.splice(indexToReplace, 1, itemToPlace);
    }
  } else if (key === ACTIONS_ENUM.ADD_LOCATIONS.value) {
    locations = [...locations, ...on.map((loc) => loc.id)];
  } else if (key === ACTIONS_ENUM.ADD_DOCS.value) {
    documents = [...documents, ...on.map((doc) => doc.id)];
  } else if (key === ACTIONS_ENUM.REMOVE_LOCATIONS.value) {
    locations = locations.filter(
      (locId) => !on.some((loc) => loc.id === locId),
    );
  } else if (key === ACTIONS_ENUM.REMOVE_DOCS.value) {
    documents = documents.filter(
      (docId) => !on.some((doc) => docId === doc.id),
    );
  } else if (key === ACTIONS_ENUM.ABORT.value) {
    abortMessages.push(on.message);
  }

  locations = [...new Set(locations)];
  documents = [...new Set(documents)];

  return {
    locations,
    documents,
    billableItems,
    abortMessages,
  };
};
