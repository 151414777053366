import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";

import { DialogCreatePin } from "../../components/dialogs";
import { VIEW_TYPE } from "../../constants/constants";
import useAxios from "../../hooks/useAxios";
import useTokenData from "../../hooks/useTokenData";
import { useRefreshTokenStore, useTokenStore } from "../../store/store";
import { showPinConfirmation } from "../../utils/pinUtils";

export default function Welcome() {
  const { http } = useAxios();
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { entityId = {}, HasPin, individualPracticeOwner } = useTokenData();
  const [showDialogCreatePin, setShowDialogCreatePin] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: { view: VIEW_TYPE.RENDER.value },
  });

  const state = { isWelcome: true, fromSetup: location?.state?.fromSetup };

  const onPinCreationComplete = () => {
    setShowDialogCreatePin(false);
    navigate("/ordering/FindServices", { state });
  };

  const onSubmit = async (formData) => {
    try {
      const response = await http.post(
        `/Authentication/GetToken/${entityId}/${formData.view}`,
      );
      setToken(response.data.token);
      setRefreshToken(response.data.refreshToken);

      if (formData.view === VIEW_TYPE.RENDER.value) {
        navigate("/setUpDeposit", { state });
      } else if (formData.view === VIEW_TYPE.ORDER.value) {
        if (individualPracticeOwner === "True" && HasPin === "False") {
          const handleAccept = () => setShowDialogCreatePin(true);
          const handleReject = () => {
            navigate("/ordering/FindServices", { state });
          };
          showPinConfirmation(handleAccept, handleReject);
        } else {
          navigate("/ordering/FindServices", { state });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ConfirmDialog draggable={false} />
      <Dialog
        visible={showDialogCreatePin}
        modal
        draggable={false}
        style={{ width: "40rem" }}
        headerStyle={{ paddingBottom: "0px" }}
        onHide={() => {
          if (!showDialogCreatePin) return;
          setShowDialogCreatePin(false);
        }}
      >
        <DialogCreatePin
          setShowDialogCreatePin={setShowDialogCreatePin}
          onComplete={onPinCreationComplete}
        />
      </Dialog>

      <div className="flex h-full items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex max-w-xl flex-col gap-5 rounded-lg border border-dark-purple bg-white p-10 text-center">
            <h1 className="font-philosopher text-2xl font-extrabold text-dark-purple">
              Welcome! Let's Set Up Your Default View.
            </h1>
            <p className="max-w-md self-center text-gray-600">
              Choose your preferred default view for your account. <br /> Please
              select one of the following options
            </p>
            <div className="grid gap-5 sm:grid-cols-2">
              <Controller
                name="view"
                control={control}
                render={({ field }) => (
                  <>
                    <label
                      htmlFor={VIEW_TYPE.RENDER.label}
                      className={`col-span-1 cursor-pointer rounded-lg border p-4 hover:bg-slate-100 hover:shadow-md ${field.value === VIEW_TYPE.RENDER.label ? "border-dark-purple bg-slate-50 shadow-md" : "border-slate-300"}`}
                    >
                      <div className="flex gap-3">
                        <RadioButton
                          inputId={VIEW_TYPE.RENDER.label}
                          {...field}
                          value={VIEW_TYPE.RENDER.value}
                          checked={field.value === VIEW_TYPE.RENDER.value}
                        />
                        <p className="font-semibold text-gray-800">
                          Render Services
                        </p>
                      </div>
                      <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
                        Select this option if you primarily render services and
                        want to focus on managing your practice offerings.
                      </p>
                    </label>
                    <label
                      htmlFor={VIEW_TYPE.ORDER.label}
                      className={`col-span-1 cursor-pointer rounded-lg border p-4 hover:bg-slate-100 hover:shadow-md ${field.value === VIEW_TYPE.ORDER.label ? "border-dark-purple bg-slate-50 shadow-md" : "border-slate-300"}`}
                    >
                      <div className="flex gap-3">
                        <RadioButton
                          inputId={VIEW_TYPE.ORDER.label}
                          {...field}
                          value={VIEW_TYPE.ORDER.value}
                          checked={field.value === VIEW_TYPE.ORDER.value}
                        />
                        <p className="font-semibold text-gray-800">
                          Order Services
                        </p>
                      </div>
                      <p className="px-5 py-2 text-justify text-sm italic text-gray-500">
                        Choose this option if you mainly order services and want
                        to focus on browsing and placing orders.
                      </p>
                    </label>
                  </>
                )}
              />
            </div>
            <div className="flex justify-end">
              <Button label="Save and Proceed" loading={isSubmitting} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
