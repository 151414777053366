import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { trimValidation } from "../../../../../../utils/helpers";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import useAxios from "../../../../../../hooks/useAxios";
import { Toast } from "primereact/toast";
import useTokenData from "../../../../../../hooks/useTokenData";
import { useLocation } from "react-router-dom";
import { useLocationsContext } from "../context/LocationsContext";
import { showErrorToast } from "../../../../../../utils/apiUtils";

function ManageLocationForm({ setLocationVisible }) {
  const toast = useRef(null);
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const [states, setStates] = useState([]);
  const { getLocations } = useLocationsContext();

  const locationData = useLocation()?.state?.locationData;
  const toastFunction = (severity, summary, detail) => {
    toast.current.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: 3000,
    });
  };
  const defaultValues = {
    name: "",
    address: {
      lineOne: "",
      lineTwo: null,
      state: "",
      city: "",
      zipCode: "",
    },
  };
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  const getStates = async () => {
    try {
      const response = await http.get("/States");
      const statesData = response.data;
      setStates(statesData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const submitForm = async (e) => {
    let response;
    try {
      !locationData
        ? (response = await http.post(
            `/Locations/CreateEntityLocation/${entityId}`,
            {
              name: e.name,
              address: e.address,
            },
          ))
        : (response = await http.put(
            `/Locations/UpdateEntityLocation/${locationData.id}`,
            {
              name: e.name,
              address: e.address,
            },
          ));

      toastFunction(
        "success",
        "Success",
        locationData
          ? "Location Edited Successfully"
          : "Location Added Successfully",
      );
      //  add logic to select this location in multi select from response data
      reset();
      setLocationVisible(false);
      await getLocations();
    } catch (error) {
      showErrorToast({ error });
    }
  };
  const handleCancel = () => {
    setLocationVisible(false);
  };

  useEffect(() => {
    getStates();
    if (locationData) {
      reset(locationData);
    }
  }, [locationData, reset]);

  return (
    <>
      <Toast ref={toast} />

      <div className="p-0 sm:pl-10 sm:pr-10">
        <div className="form-container mb-3">
          <h3 className="mb-2 text-xl font-bold">
            {locationData ? "Edit Location" : "Set up Locations"}
          </h3>
          <p className="mb-7">
            {locationData
              ? "Edit locations where you currently operate your services."
              : "Add locations where you currently operate your services."}
          </p>

          <form className="flex flex-col" onSubmit={handleSubmit(submitForm)}>
            <div>
              <div className="mt-5">
                <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                  Location Name<span className="ml-1 text-red-600">*</span>
                </label>
                <span className="w-full">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: "Location name is required",
                      validate: (value) =>
                        trimValidation(value, "Location Name"),
                    }}
                    render={({ field }) => (
                      <InputText
                        {...field}
                        placeholder="Location Name"
                        className="w-full pb-2 pt-2"
                      />
                    )}
                  />
                  {errors.name && (
                    <p className="mt-1 text-red-500">{errors.name.message}</p>
                  )}
                </span>

                <div className="mt-4">
                  <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                    Address Line One<span className="ml-1 text-red-600">*</span>
                  </label>
                  <span className="w-full">
                    <Controller
                      name="address.lineOne"
                      control={control}
                      rules={{
                        required: "Address line one is required",
                        validate: (value) =>
                          trimValidation(value, "Address Line One"),
                      }}
                      render={({ field }) => (
                        <InputText
                          {...field}
                          placeholder="Address line one"
                          className="w-full pb-2 pt-2"
                        />
                      )}
                    />
                    {errors.address?.lineOne && (
                      <p className="mt-1 text-red-500">
                        {errors.address.lineOne.message}
                      </p>
                    )}
                  </span>
                </div>

                <div className="mt-3">
                  <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                    Address Line Two
                  </label>
                  <span className="w-full">
                    <Controller
                      name="address.lineTwo"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          {...field}
                          placeholder="Address line two"
                          className="w-full pb-2 pt-2"
                        />
                      )}
                    />
                  </span>
                </div>

                <div className="mt-3 grid gap-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                  <div>
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      City<span className="ml-1 text-red-600">*</span>
                    </label>
                    <span className="col-span-1">
                      <Controller
                        name="address.city"
                        control={control}
                        rules={{
                          required: "City is required",
                          validate: (value) => trimValidation(value, "City"),
                        }}
                        render={({ field }) => (
                          <InputText
                            {...field}
                            placeholder="City"
                            className="w-full"
                          />
                        )}
                      />
                      {errors.address?.city && (
                        <p className="mt-1 text-red-500">
                          {errors.address.city.message}
                        </p>
                      )}
                    </span>
                  </div>

                  <div>
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      State<span className="ml-1 text-red-600">*</span>
                    </label>
                    <span className="col-span-1">
                      <Controller
                        name="address.state"
                        control={control}
                        rules={{ required: "Please select a state" }}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            value={field.value}
                            placeholder="State"
                            options={states.map((option) => ({
                              value: option.name,
                              label: option.name,
                            }))}
                            onChange={(e) => field.onChange(e.value)}
                            className="md:w-14rem w-full"
                          />
                        )}
                      />
                      {errors.address?.state && (
                        <p className="mt-1 text-red-500">
                          {errors.address.state.message}
                        </p>
                      )}
                    </span>
                  </div>

                  <div>
                    <label className="font-Poppins text-base capitalize leading-loose text-gray-500">
                      ZIP Code<span className="ml-1 text-red-600">*</span>
                    </label>
                    <span className="col-span-1">
                      <Controller
                        name="address.zipCode"
                        control={control}
                        rules={{
                          required: "ZIP code is required",
                          pattern: {
                            value: /^\d{5}$/,
                            message: "ZIP Code must be exactly 5 digits",
                          },
                        }}
                        render={({ field }) => (
                          <InputText
                            {...field}
                            maxLength={5}
                            keyfilter="num"
                            placeholder="ZIP Code"
                            className="w-full"
                          />
                        )}
                      />
                      {errors.address?.zipCode && (
                        <p className="mt-1 text-red-500">
                          {errors.address.zipCode.message}
                        </p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex flex-col gap-2 sm:flex sm:flex-row sm:justify-end">
              <Button
                type="button"
                onClick={handleCancel}
                className="w-full justify-center rounded-full bg-white px-5 py-2 font-inter font-medium capitalize text-light-purple sm:w-auto"
              >
                cancel
              </Button>
              {locationData ? (
                <Button
                  type="submit"
                  className="w-full rounded-full bg-light-purple p-2 px-3 font-medium text-white sm:w-auto"
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="w-full justify-center rounded-full bg-light-purple p-2 px-3 font-medium text-white sm:w-auto"
                >
                  Add Location
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ManageLocationForm;
