import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useFormContext } from "react-hook-form";

const DisplayText = ({ question }) => {
  const { control } = useFormContext();

  const forceMinLength = question?.constraints?.minLength?.force;
  const forceMinLengthValue = Number(question?.constraints?.minLength?.value);

  const forceMaxLength = question?.constraints?.maxLength?.force;
  const forceMaxLengthValue = Number(question?.constraints?.maxLength?.value);

  const isMultiline = question?.constraints?.multiline?.force;
  const numberOfLines = question?.constraints?.multiline?.value;

  return (
    <Controller
      name={`${question.id}.0.value`}
      control={control}
      rules={{
        required: question?.required && "Question is required",
        validate: (value) => {
          if (
            forceMinLength &&
            forceMaxLength &&
            forceMinLengthValue === forceMaxLengthValue
          ) {
            return (
              value?.length === forceMinLengthValue ||
              `Answer must be exactly ${forceMinLengthValue} characters.`
            );
          }
          if (forceMinLength && value?.length < forceMinLengthValue) {
            return `Answer cannot be less than ${forceMinLengthValue} characters.`;
          }
          if (forceMaxLength && value?.length > forceMaxLengthValue) {
            return `Answer cannot be more than ${forceMaxLengthValue} characters.`;
          }
          return true;
        },
      }}
      render={({ field, fieldState: { error } }) =>
        isMultiline ? (
          <>
            <InputTextarea
              placeholder="Enter your answer"
              className="w-full"
              rows={numberOfLines}
              {...field}
            />
            {error && <p className="text-sm text-red-500">{error.message}</p>}
          </>
        ) : (
          <>
            <InputText
              placeholder="Enter your answer"
              className="w-full"
              {...field}
            />
            {error && <p className="text-sm text-red-500">{error.message}</p>}
          </>
        )
      }
    />
  );
};

export default DisplayText;
