import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

function DisplayDocuments({ filteredDocs }) {
  return (
    <div>
      {" "}
      <DataTable
        className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
        value={filteredDocs}
        scrollHeight="360px"
        scrollable
        emptyMessage={
          <p className="text-center">
            Currently, there are no documents selected.
          </p>
        }
      >
        <Column
          field="documentTitle"
          header="Title"
          align="center"
          alignHeader="center"
          style={{
            width: "30%",
          }}
        />
        <Column
          field="internalDescription"
          header="Description"
          align="center"
          alignHeader="center"
          style={{
            width: "60%",
          }}
        />
      </DataTable>
    </div>
  );
}

export default DisplayDocuments;
