import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { LOGICAL_OPERATORS } from "../../utils/constants";
import { trimValidation } from "../../../../../../../utils/helpers";

const ManageRulesFormHeader = () => {
  const { control, watch } = useFormContext();
  return (
    <div className="flex flex-col gap-2 bg-slate-50 p-3 md:flex-row md:items-center">
      <Controller
        name="title"
        control={control}
        defaultValue={`Group-${watch(`id`).split("-")[0]}`}
        rules={{
          required: "Enter Group Name",
          validate: (value) => trimValidation(value, "Group Name"),
        }}
        render={({ field, fieldState: { error } }) => (
          <div className="flex items-center gap-2">
            <label htmlFor={field.name} className="font-medium">
              Group name:
            </label>
            <InputText id={field.name} {...field} />
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        )}
      />
      <Controller
        name="operator"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div className="flex items-center gap-2">
            <label htmlFor={field.name} className="font-medium">
              Group Operator:
            </label>
            <Dropdown
              inputId={field.name}
              {...field}
              options={LOGICAL_OPERATORS}
              className="capitalize"
              pt={{ item: { className: "capitalize" } }}
            />
            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        )}
      />
    </div>
  );
};

export default ManageRulesFormHeader;
