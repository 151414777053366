import { Editor } from "primereact/editor";

import { editorHeaderTemplate } from "../../../../../../../../utils/helpers";

const AbortOrder = ({ field, error }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="font-semibold">Add Message to show on aborting</div>

      <Editor
        headerTemplate={editorHeaderTemplate}
        value={field?.value?.message}
        onTextChange={(e) => {
          field.onChange({ message: e.htmlValue });
        }}
        className="w-full"
        style={{ minHeight: "164px" }}
      />
      {error && <p className="text-red-500">{error.message}</p>}
    </div>
  );
};

export default AbortOrder;
