import { Skeleton } from "primereact/skeleton";

export function TeamCardSkeleton() {
  return (
    <div className="col-span-12 grid grid-cols-12 rounded-xl border-slate-200 bg-white p-6 shadow-sm sm:col-span-6">
      <div className="col-span-6 flex flex-col gap-2">
        <Skeleton width="80%" height="1.5rem" className="mb-2" />
        <Skeleton width="50%" height="1rem" className="mb-2" />
        <Skeleton width="60%" height="1rem" />
      </div>
      <div className="col-span-6 flex items-center justify-center gap-3">
        <Skeleton shape="circle" size="2.5rem" />
        <Skeleton shape="circle" size="2.5rem" />
      </div>
    </div>
  );
}
