const CheckIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 7.33333L8.5 9.33333L13.8333 4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8332 8.00002V12C13.8332 12.3536 13.6927 12.6928 13.4426 12.9428C13.1926 13.1929 12.8535 13.3334 12.4998 13.3334H4.49984C4.14622 13.3334 3.80708 13.1929 3.55703 12.9428C3.30698 12.6928 3.1665 12.3536 3.1665 12V4.00002C3.1665 3.6464 3.30698 3.30726 3.55703 3.05721C3.80708 2.80716 4.14622 2.66669 4.49984 2.66669H10.4998"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
