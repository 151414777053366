import { useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import useAxios from "../../hooks/useAxios";
import { useLayoutToast } from "../../store/store";
import useTokenData from "../../hooks/useTokenData";
import { ProviderStatusTemplate } from "../templates/columnBodyTemplates";
import { ReusableDataList } from "../Table";
import VerifyProvider from "../../pages/ordering/settings/providerVerification/VerifyProvider";
import { showErrorToast } from "../../utils/apiUtils";

const StaffDashboardProviders = ({ refetch, setRefetch }) => {
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const { layoutToast } = useLayoutToast();

  const [status, setStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [providerFormDialog, setProviderFormDialog] = useState();
  const [selectedIdentifierId, setSelectedIdentifierId] = useState(null);

  const handleEditProvider = (userId, identifierId, status) => {
    setSelectedUserId(userId);
    setSelectedIdentifierId(identifierId);
    setStatus(status);
    setProviderFormDialog(true);
  };

  const editProviderTemplate = (rowData) => {
    if (rowData.status === "Null") {
      return (
        <div className="flex gap-2">
          <Button
            className="rounded-full py-2.5"
            icon="pi pi-verified pr-1"
            severity="info"
            onClick={() =>
              handleEditProvider(
                rowData.userId,
                rowData.identifierId,
                rowData.status,
              )
            }
          >
            Verify provider
          </Button>
        </div>
      );
    } else if (rowData.status === "InComplete") {
      return (
        <div className="flex gap-2">
          <Button
            className="rounded-full py-2.5"
            icon="pi pi-verified pr-2"
            severity="help"
            loading={isLoading === rowData.userId}
            onClick={() => handleSendEmailToVerify(rowData)}
          >
            send link to complete verify
          </Button>
        </div>
      );
    } else if (rowData.status === "Pending") {
      return rowData.isReDueDiligence === true ? null : (
        <div className="flex gap-2">
          <Button
            className="rounded-full py-2.5"
            icon="pi pi-verified pr-1"
            severity="warning"
            onClick={() =>
              handleEditProvider(
                rowData.userId,
                rowData.identifierId,
                rowData.status,
              )
            }
          >
            Edit verify provider
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };
  const TABLE_COLUMNS = [
    { field: "fullName", header: "Name", style: { width: "20%" } },
    { field: "email", header: "Email", style: { width: "25%" } },
    {
      template: ProviderStatusTemplate,
      header: "Status",
      style: { width: "20%" },
    },
    { template: editProviderTemplate, header: "Actions" },
  ];
  const handleSendEmailToVerify = async (rowData) => {
    setIsLoading(rowData.userId);
    try {
      const response = await http.post(
        `/Identifications/SendCompleteProviderIdentityLink`,
        {
          userId: rowData.userId,
          userEmail: rowData.email,
          identifierId: rowData.identificationId,
        },
      );

      layoutToast.current.show({
        severity: "success",
        summary: "Confirmed",
        detail: response.data.message,
        life: 3000,
      });
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const cardListOfProvidersTemplate = (row) => {
    return (
      <>
        <div className="border-b border-solid border-gray-400 pb-5">
          <div className="flex items-center justify-between gap-2">
            <div className="flex items-center gap-2">
              <div>
                <p className="font-semibold capitalize">{row.fullName}</p>
              </div>
            </div>
            <div className="flex">{editProviderTemplate(row)}</div>
          </div>
          <p className="font-semibold">{row.email}</p>
          <div className="mt-2 font-semibold">
            <span className="font-semibold capitalize text-slate-500">
              status:&nbsp;
            </span>
            {ProviderStatusTemplate(row)}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        visible={providerFormDialog}
        onHide={() => {
          setProviderFormDialog(false);
        }}
        header="Verify Provider"
        className="w-[80vw]"
      >
        <VerifyProvider
          userIdTable={selectedUserId}
          identifierIdTable={selectedIdentifierId}
          statusTable={status}
          setProviderFormDialog={setProviderFormDialog}
          setRefetchData={refetch}
          refetchData={setRefetch}
        />
      </Dialog>

      <ReusableDataList
        title={"All Providers"}
        fromFront={true}
        dataLink={`/Entities/GetInvitedUsersAsAProvider/${entityId}`}
        columns={TABLE_COLUMNS}
        searchTitle="search by name"
        mobileTemplate={cardListOfProvidersTemplate}
        refetch={refetch}
        setRefetch={setRefetch}
        cardFilters={["email", "fullName"]}
      />
    </>
  );
};

export default StaffDashboardProviders;
