const RadioIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 16C7.01664 16 5.56659 15.5601 4.33323 14.736C3.09986 13.9119 2.13856 12.7406 1.57091 11.3701C1.00325 9.99968 0.854725 8.49168 1.14411 7.03682C1.4335 5.58197 2.14781 4.2456 3.1967 3.1967C4.2456 2.14781 5.58197 1.4335 7.03682 1.14411C8.49168 0.854725 9.99968 1.00325 11.3701 1.57091C12.7406 2.13856 13.9119 3.09986 14.736 4.33323C15.5601 5.56659 16 7.01664 16 8.5C15.9978 10.4884 15.2069 12.3948 13.8009 13.8009C12.3948 15.2069 10.4884 15.9978 8.5 16ZM8.5 2.04651C7.22362 2.04651 5.97591 2.425 4.91464 3.13412C3.85337 3.84324 3.02621 4.85114 2.53776 6.03036C2.04931 7.20958 1.92151 8.50716 2.17052 9.75901C2.41953 11.0109 3.03416 12.1608 3.9367 13.0633C4.83923 13.9658 5.98913 14.5805 7.24099 14.8295C8.49284 15.0785 9.79042 14.9507 10.9696 14.4622C12.1489 13.9738 13.1568 13.1466 13.8659 12.0854C14.575 11.0241 14.9535 9.77638 14.9535 8.5C14.9516 6.789 14.2711 5.1486 13.0613 3.93874C11.8514 2.72887 10.211 2.04836 8.5 2.04651ZM12.686 8.5C12.686 7.67208 12.4405 6.86275 11.9806 6.17436C11.5206 5.48597 10.8668 4.94943 10.1019 4.6326C9.33703 4.31577 8.49536 4.23287 7.68334 4.39439C6.87133 4.55591 6.12545 4.95459 5.54002 5.54002C4.95459 6.12545 4.55591 6.87133 4.39439 7.68334C4.23287 8.49536 4.31577 9.33703 4.6326 10.1019C4.94943 10.8668 5.48597 11.5206 6.17436 11.9806C6.86275 12.4405 7.67208 12.686 8.5 12.686C9.61021 12.686 10.6749 12.245 11.46 11.46C12.245 10.6749 12.686 9.61021 12.686 8.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RadioIcon;
