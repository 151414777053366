const DropdownIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_656_22116)">
        <path
          d="M0.431372 3C-0.281352 3 -0.638286 3.86171 -0.134315 4.36569L7.93431 12.4343C8.24673 12.7467 8.75327 12.7467 9.06569 12.4343L17.1343 4.36568C17.6383 3.86171 17.2814 3 16.5686 3H0.431372Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_656_22116">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DropdownIcon;
