import { Navigate, Outlet } from "react-router-dom";
import { useRefreshTokenStore } from "../store/store";

function AuthGuard() {
  const { refreshToken } = useRefreshTokenStore();

  if (!refreshToken) {
    return <Navigate to={"/login"} />;
  }

  return <Outlet />;
}
export default AuthGuard;
