import { ReusableDataList } from "../../../../components/Table";
import { formatDate } from "../../../../utils/helpers";

const TopOrderingProviders = ({
  entityId,
  selectedDay,
  refetchTopOrdering,
}) => {
  const cardBestOrderingProviderTemplate = (row) => {
    return (
      <>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Practice Name:&nbsp;
          </span>
          {row.orderPracticeName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Ordering Provider Name:&nbsp;
          </span>
          {row.orderProviderName}
        </p>
        <p className="font-semibold">
          <i className="pi pi-user" />
          <span className="font-semibold text-slate-500">
            &nbsp;Orders Number:&nbsp;
          </span>
          {row.ordersCount}
        </p>
        <p className="font-semibold">
          <i className="pi pi-calendar-times" />
          <span className="font-semibold text-slate-500">
            &nbsp;Last Order Date:&nbsp;
          </span>
          {row.lastOrderDate && (
            <span>{formatDate(row.lastOrderDate, true)}&nbsp;</span>
          )}
        </p>
      </>
    );
  };
  return (
    <div className="col-span-8 xl:col-span-9">
      <ReusableDataList
        title={"Top Ordering Providers"}
        fromFront={true}
        dataLink={`/Entities/GetTopOrderingProviders/${entityId}?days=${Number(
          selectedDay,
        )}`}
        columns={[
          { header: "Practice Name", field: "orderPracticeName" },
          { header: "Ordering Provider Name", field: "orderProviderName" },
          {
            header: "Last Order Date",
            field: "lastOrderDate",
            template: (rowData) => formatDate(rowData.lastOrderDate, true),
          },
          { header: "Orders Count", field: "ordersCount" },
        ]}
        refetch={refetchTopOrdering}
        searchTitle="search by practice name"
        mobileTemplate={cardBestOrderingProviderTemplate}
        cardFilters={["orderProviderName"]}
      />
    </div>
  );
};

export default TopOrderingProviders;
