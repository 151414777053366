import { createContext, useContext } from "react";

const ServiceDetailsContext = createContext();

export const useServiceDetailsContext = () => useContext(ServiceDetailsContext);

export const ServiceDetailsProvider = ({
  children,
  defaultBillableItems,
  defaultDocs,
}) => {
  return (
    <ServiceDetailsContext.Provider
      value={{ defaultBillableItems, defaultDocs }}
    >
      {children}
    </ServiceDetailsContext.Provider>
  );
};
