import { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { DataTable } from "primereact/datatable";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../../hooks/useAxios";
import useDocument from "../../../../hooks/useDocument";
import { downloadFileFromResponse } from "../../../../utils/helpers";
import PaymentSuccess from "../../../../components/cards/PaymentSuccess";
import { showErrorToast } from "../../../../utils/apiUtils";

export default function OrderSummary({
  orderId,
  orderDocs,
  paymentLink,
  paymentLinkId,
  isPaymentComplete,
  showCheckInPass,
}) {
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [documentsData, setDocumentsData] = useState([]);
  const [isSendingDocs, setIsSendingDocs] = useState(false);
  const [isDownloadingDocs, setIsDownloadingDocs] = useState(false);
  const [isDownloadingSummary, setIsDownloadingSummary] = useState(false);
  const [isDownloadingCheckInSlip, setIsDownloadingCheckInSlip] =
    useState(false);

  const [receiptData, setReceiptData] = useState(true);
  const toast = useRef(null);
  const { http } = useAxios();
  const { handleDownloadDocument } = useDocument();

  useEffect(() => {
    !isPaymentComplete && getPaymentLink(orderId);
    fetchData();
  }, [paymentLinkId]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchData = async () => {
    try {
      await Promise.all([
        isPaymentComplete
          ? delay(7000).then(() => fetchReceipt(paymentLinkId))
          : null,
        getOrderDocs(),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const getOrderDocs = async (docsArr) => {
    try {
      const response = await http.get(
        `/Orders/GetOrderDocumentsDetails/${orderId}`,
      );
      setDocumentsData(response.data);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  const sendDocsToPatient = async () => {
    setIsSendingDocs(true);

    try {
      const response = await http.post(
        `/Orders/SendOrderDocumentsToPatient/${orderId}`,
      );
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 3000,
      });
    } catch (error) {
      showErrorToast({ error });
    }
    setIsSendingDocs(false);
  };
  const downloadOrderSummary = async () => {
    setIsDownloadingSummary(true);
    try {
      const response = await http.get(
        `/Orders/DownloadOrderSummary/${orderId}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsDownloadingSummary(false);
  };
  const downloadCheckInSlip = async () => {
    setIsDownloadingCheckInSlip(true);
    try {
      const response = await http.get(
        `/Orders/DownloadOrderCheckInPass/${orderId}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsDownloadingCheckInSlip(false);
  };

  const downloadServiceDocs = async () => {
    setIsDownloadingDocs(true);
    try {
      const response = await http.get(
        `/Orders/DownloadOrderDocuments/${orderId}`,
        {
          responseType: "blob",
        },
      );
      downloadFileFromResponse(response);
    } catch (error) {
      showErrorToast({ error });
    }
    setIsDownloadingDocs(false);
  };

  const fetchReceipt = async (paymentLinkId) => {
    try {
      const receiptRes = await http.get(
        `/Payments/GetReceipt/${paymentLinkId}`,
      );
      setReceiptData(receiptRes.data);
    } catch (error) {
      showErrorToast({ error });
    } finally {
      setLoading(false);
    }
  };

  const getPaymentLink = async (orderId) => {
    try {
      const response = await http.get(`/Orders/GetOrderPaymentLink/${orderId}`);
      setLink(response.data.paymentLink);
    } catch (error) {
      showErrorToast({ error });
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <Toast ref={toast} />

      {isPaymentComplete && loading ? (
        <>
          <ProgressSpinner strokeWidth="5" />
          <p className="mt-2 text-center font-semibold text-gray-500">
            Please wait while we confirm your payment...
          </p>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            {isPaymentComplete ? (
              <>
                <div className="flex flex-col gap-3">
                  <h4 className="text-center font-philosopher text-title font-extrabold capitalize text-dark-purple">
                    Order Paid Successfully
                  </h4>
                  <p className="text-center text-gray-700">
                    We don&rsquo;t want to keep you waiting. Your receipt and
                    confirmation details are on their way to your email.{" "}
                  </p>
                  <p className="text-center font-bold capitalize text-gray-600">
                    Thank you for choosing MedX
                  </p>
                </div>
                <PaymentSuccess receipt={receiptData} />
              </>
            ) : link ? (
              <div className="m-4 flex flex-col gap-2">
                <div className="grid grid-cols-12 rounded-lg border">
                  <p className="col-span-8 w-full overflow-hidden text-ellipsis border-r p-4 text-gray-600">
                    {link}
                  </p>
                  <div
                    className="col-span-4 flex cursor-pointer justify-center bg-slate-50 p-4 hover:bg-slate-100"
                    onClick={async () => {
                      if (isCopied) return;
                      // ** This feature is available only in secure contexts (HTTPS)
                      try {
                        await navigator.clipboard.writeText(link);
                        setIsCopied(true);
                        setTimeout(() => {
                          setIsCopied(false);
                        }, 2500);
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  >
                    <div className="flex items-center gap-1 px-3">
                      <i
                        className={`pi pi-${isCopied ? "check" : "copy"} `}
                        style={{ fontSize: "1.125rem" }}
                      ></i>
                      <p className="m-0 font-semibold text-gray-800">
                        {isCopied ? "Copied!" : "Copy Payment Link"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-sm italic text-gray-500">
                  This link will expire in 24 hours
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex flex-col gap-2 px-4">
            <h4 className="text-xl font-semibold capitalize text-dark-purple">
              <i className={`pi pi-book text mr-2 text-xl`} />
              Order Summary
            </h4>
            <div className="grid grid-cols-12 gap-2 rounded-lg border border-slate-200 p-4">
              <p className="text-md col-span-12 text-justify text-gray-500 md:col-span-8">
                Please download the order summary for your records. This
                document includes all the information you provided during the
                ordering process, including patient details, responses, and any
                other relevant data.
              </p>
              <div className="col-span-12 flex items-center justify-center text-center md:col-span-4 md:justify-end">
                <Button
                  label="Download summary"
                  icon="pi pi-download"
                  outlined
                  loading={isDownloadingSummary}
                  severity="info"
                  onClick={downloadOrderSummary}
                />
              </div>
            </div>
          </div>
          {orderDocs?.length > 0 && (
            <div className="flex flex-col gap-2 px-4">
              <h4 className="text-xl font-semibold capitalize text-dark-purple">
                <i className={`pi pi-map text mr-2 text-xl`} />
                Service documents
              </h4>
              <div className="rounded-lg border border-slate-200 p-4">
                <div className="grid grid-cols-12 gap-2">
                  <p className="text-md col-span-12 text-justify text-gray-500 lg:col-span-7">
                    We kindly request you to download and carefully review the
                    service documents provided. These documents contain crucial
                    instructions and important details essential for your
                    preparation prior to engaging with the service.
                  </p>
                  <div className="col-span-12 flex items-center justify-center gap-2 text-center lg:col-span-5 lg:justify-end">
                    <Button
                      label="Send To Patient"
                      icon="pi pi-send"
                      text
                      loading={isSendingDocs}
                      severity="info"
                      onClick={sendDocsToPatient}
                    />
                    <Button
                      label="Download documents"
                      icon="pi pi-download"
                      outlined
                      loading={isDownloadingDocs}
                      severity="info"
                      onClick={downloadServiceDocs}
                    />
                  </div>
                </div>
                <div>
                  <Divider />
                  <div className="w-full">
                    <DataTable
                      showGridlines
                      scrollable
                      pt={{
                        loadingOverlay: {
                          style: {
                            backgroundColor: "#b9b5ff60",
                          },
                        },
                      }}
                      scrollHeight="400px"
                      className="overflow-hidden rounded-lg border border-gray-100 shadow-sm"
                      value={documentsData}
                      emptyMessage={
                        <p className="text-center">No results found</p>
                      }
                    >
                      <Column
                        field="documentTitle"
                        header="Title"
                        align="center"
                        alignHeader="center"
                        style={{ width: "30%" }}
                      />
                      <Column
                        field="documentDescription"
                        header="Description"
                        align="center"
                        alignHeader="center"
                        style={{ width: "60%" }}
                      />
                      <Column
                        header="Actions"
                        align="center"
                        alignHeader="center"
                        style={{ width: "10%" }}
                        body={(rowData, options) => (
                          <i
                            className="doc-action pi pi-download cursor-pointer text-gray-500"
                            data-pr-tooltip="Download Document"
                            data-pr-position="top"
                            onClick={() => {
                              handleDownloadDocument(rowData);
                            }}
                          ></i>
                        )}
                      />
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showCheckInPass && (
            <div className="flex flex-col gap-2 px-4">
              <h4 className="text-xl font-semibold capitalize text-dark-purple">
                <i className="pi pi-verified text mr-2 text-xl" />
                Appointment Check-In Slip
              </h4>
              <div className="grid grid-cols-12 gap-2 rounded-lg border border-slate-200 p-4">
                <p className="text-md col-span-12 text-justify text-gray-500 md:col-span-7">
                  Please download appointment check-in slip for your patient and
                  provide it to them, so that they can have the necessary
                  information about their appointment.
                </p>
                <div className="col-span-12 flex items-center justify-center text-center md:col-span-5 md:justify-end">
                  <Button
                    label="Download Check-In Slip"
                    icon="pi pi-download"
                    outlined
                    loading={isDownloadingCheckInSlip}
                    severity="info"
                    onClick={downloadCheckInSlip}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
