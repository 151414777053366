import { useState } from "react";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";

import {
  QUESTION_TYPES_ENUM,
  WEIGHT_OPTIONS_ENUM,
} from "../../utils/constants";

const WeightInput = ({
  field,
  error,
  unit,
  questionType,
  disabled = false,
  handleChange,
  handleUserSelection,
  defaultUserSelectable = WEIGHT_OPTIONS_ENUM.LBS,
}) => {
  const isInt = questionType === QUESTION_TYPES_ENUM.NUMBER.value;
  const minFractionDigits = isInt ? undefined : 2;
  const maxFractionDigits = isInt ? undefined : 2;

  const [selectedWeight, setSelectedWeight] = useState(defaultUserSelectable);

  const kilogramToPound = (value) => value * 2.20462262;
  const poundToKilogram = (value) => value / 2.20462262;

  const convertWeight = (value) => {
    if (value === null) return value;

    if (
      unit === WEIGHT_OPTIONS_ENUM.KGS ||
      (unit === WEIGHT_OPTIONS_ENUM.SELECTABLE &&
        selectedWeight === WEIGHT_OPTIONS_ENUM.KGS)
    ) {
      return kilogramToPound(value);
    } else {
      return value;
    }
  };

  const reconvert = (value) => {
    if (
      unit === WEIGHT_OPTIONS_ENUM.KGS ||
      (unit === WEIGHT_OPTIONS_ENUM.SELECTABLE &&
        selectedWeight === WEIGHT_OPTIONS_ENUM.KGS)
    ) {
      return poundToKilogram(value)?.toFixed(2);
    } else {
      return value;
    }
  };
  return (
    <div className="flex items-center gap-2">
      <InputNumber
        {...field}
        inputId={field.name}
        value={reconvert(field.value)}
        onChange={(e) => {
          field.onChange(convertWeight(e.value));
          handleChange && handleChange(field.value, e.value);
        }}
        useGrouping={false}
        placeholder={isInt ? "0" : "0.00"}
        min={0}
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        className={`flex-1 ${error ? "p-invalid" : ""}`}
        disabled={disabled}
      />
      {[WEIGHT_OPTIONS_ENUM.LBS, WEIGHT_OPTIONS_ENUM.KGS].includes(unit) ? (
        <label htmlFor={field.name} className="font-semibold text-gray-500">
          {unit}
        </label>
      ) : (
        <Dropdown
          value={selectedWeight}
          onChange={(e) => {
            handleUserSelection && handleUserSelection(e.value);
            field.onChange(null);
            setSelectedWeight(e.value);
          }}
          options={[
            { value: WEIGHT_OPTIONS_ENUM.KGS, label: "kgs" },
            { value: WEIGHT_OPTIONS_ENUM.LBS, label: "lbs" },
          ]}
          optionLabel="label"
          className="w-36"
        />
      )}
    </div>
  );
};

export default WeightInput;
