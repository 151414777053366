import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { InputText } from "primereact/inputtext";
import { trimValidation } from "../../../../../../../utils/helpers";

const TextInput = ({ inputNamePath, question }) => {
  const { control } = useFormContext();
  const singleAnswerIndex = 0;

  return (
    <Controller
      name={`${inputNamePath}.answers.${singleAnswerIndex}.value`}
      control={control}
      rules={{
        required: "Please enter a valid value",
        validate: (value) => trimValidation(value, `Answer`),
      }}
      render={({ field, fieldState: { error } }) => (
        <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
          <label htmlFor={field.name} className="col-span-3 font-semibold">
            Value
          </label>
          <div className="flex flex-col gap-2 md:col-span-9">
            <InputText
              {...field}
              id={field.name}
              keyfilter={question?.constraints?.keyfilter}
              placeholder={question?.constraints?.placeholder || "Enter Value"}
              className={`w-full ${error ? "p-invalid" : ""} `}
            />
            {question?.constraints?.placeholder && (
              <p className="text-xs italic text-gray-400">
                Ensure the name follows the format:{" "}
                {question?.constraints?.placeholder}
              </p>
            )}

            {error && <p className="text-red-500">{error.message}</p>}
          </div>
        </div>
      )}
    />
  );
};

export default TextInput;
