import React from "react";
import {
  DEFAULT_SELF_EVALUATING_QUESTIONS_IDS,
  NUMBER_TYPE_ENUM,
  OPERATORS,
  QUESTION_TYPES_ENUM,
} from "../../utils/constants";
import {
  convertStandardToAmerican,
  convertStandardToWeight,
} from "../../utils/numericConversions";
import DisplayBillableItems from "./renderActions/DisplayBillableItems";

const ConditionCardRender = ({
  condition,
  questionsInRule,
  conditions,
  ruleLogicalOperator,
  conditionIndex,
  dataOfAllBillableItems,
}) => {
  const question = questionsInRule.find(
    (item) => item.id === condition.questionId,
  );

  const isCheckExistingSelfEval =
    question.id === DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS;
  const isCompareUnitsSelfEval =
    question.id === DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE;
  const singleAnswerIndex = 0;

  const getOperatorLabelByValue = (value) => {
    for (const key in OPERATORS) {
      if (OPERATORS[key].value === value) {
        return OPERATORS[key].label;
      }
    }
    return null;
  };
  return (
    <div>
      <span className="text-base font-semibold capitalize">if&nbsp;</span>
      <span>
        {questionsInRule.find((item) => item.id === condition.questionId).label}
        &nbsp;
      </span>
      <span className="text-base font-semibold">
        {condition.equalityOperator === OPERATORS.EQUAL.value
          ? "="
          : condition.equalityOperator === OPERATORS.NOT_EQUAL.value
            ? "≠"
            : OPERATORS.NOT_ANSWERED.label}
        &nbsp;
      </span>

      {condition.equalityOperator !== "notAnswered" && (
        <>
          <span className="text-base font-semibold">
            {getOperatorLabelByValue(condition.comparisonOperator)}
          </span>
          <ol className="list-inside list-disc">
            {condition.answers.map((answer, idx) => {
              const isAmerican =
                question.constraints?.type === NUMBER_TYPE_ENUM.AMERICAN.value;
              const isWeight =
                question.constraints?.type === NUMBER_TYPE_ENUM.WEIGHT.value;

              const isDropdown =
                question.type === QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value ||
                question.type === QUESTION_TYPES_ENUM.DROPDOWN.value;

              const isBoolean =
                question.type === QUESTION_TYPES_ENUM.BOOLEAN.value;

              if (isCheckExistingSelfEval) {
                const billableItem = dataOfAllBillableItems.filter(
                  (bi) => bi.id === answer.value,
                );
                return (
                  <DisplayBillableItems dataOfAllBillableItems={billableItem} />
                );
              } else if (isCompareUnitsSelfEval) {
                const billableItem = dataOfAllBillableItems.filter(
                  (bi) => bi.id === answer.value.id,
                );

                const isBetween =
                  condition.comparisonOperator === OPERATORS.BETWEEN.value;
                return (
                  <div key={idx}>
                    {isBetween ? (
                      <>
                        <li>{answer.value.min}</li>
                        <li>{answer.value.max}</li>
                      </>
                    ) : (
                      <li>{answer.value.units}</li>
                    )}
                    <DisplayBillableItems
                      dataOfAllBillableItems={billableItem}
                    />
                  </div>
                );
              } else if (isBoolean) {
                return (
                  <li key={idx}>{answer.value === true ? "Yes" : "No"}</li>
                );
              } else if (isDropdown) {
                return (
                  <li key={idx}>
                    {
                      question.answers.find((opt) => opt.id === answer.value)
                        .value
                    }
                  </li>
                );
              } else if (isAmerican) {
                const { feet, inches } = convertStandardToAmerican(
                  answer.value,
                );
                return (
                  <li key={idx}>
                    {feet} ft. {inches} in.
                  </li>
                );
              } else if (isWeight) {
                return (
                  <li key={idx}>
                    <span>
                      {convertStandardToWeight(
                        answer.value,
                        question?.constraints?.storeAs,
                      )}
                    </span>{" "}
                    <strong>{question?.constraints?.storeAs}</strong>
                  </li>
                );
              } else {
                return <li key={idx}>{answer.value}</li>;
              }
            })}
          </ol>
        </>
      )}
      {conditionIndex < conditions.length - 1 && (
        <div className="my-3">
          <p className="text-base font-semibold capitalize">
            {ruleLogicalOperator}
          </p>
        </div>
      )}
    </div>
  );
};

export default ConditionCardRender;
