import { useServiceStore } from "../../utils/store";
import ConditionCardRender from "./ConditionCardRender";
import { filteredQuestionsByIds } from "../../utils/utils";
import {
  DEFAULT_QUESTIONS,
  DEFAULT_SELF_EVALUATING_QUESTIONS,
  RULE_TYPES,
} from "../../utils/constants";
import TypeCard from "./TypeCard";
import SortButtons from "./SortButtons";

const SingleRuleCard = ({
  rulesGroup,
  handleMoveToTop,
  handleMoveToBottom,
  dataOfAllBillableItems,
}) => {
  const singleRuleIndex = 0;

  const rule = rulesGroup.conditionalGroups[singleRuleIndex];
  const { questions } = useServiceStore();
  const isAction = rulesGroup?.type === RULE_TYPES.ACTION.value;

  const questionsInRule =
    !isAction &&
    filteredQuestionsByIds(rule, [
      ...questions,
      ...DEFAULT_QUESTIONS,
      ...DEFAULT_SELF_EVALUATING_QUESTIONS,
    ]);

  return (
    <>
      <div>
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <span className="text-xl font-semibold capitalize text-[#3D3877]">
              {rule.title}
            </span>
            <TypeCard
              type={RULE_TYPES[rulesGroup.type]?.label || rulesGroup.type}
            />
          </div>
          <SortButtons
            handleMoveToTop={handleMoveToTop}
            handleMoveToBottom={handleMoveToBottom}
          />
        </div>

        <div>
          <div className="flex flex-col gap-3 overflow-hidden rounded-md border border-gray-100 px-4 py-3 shadow-sm">
            {rule.conditions.map((condition, index) => (
              <ConditionCardRender
                key={index}
                condition={condition}
                conditionIndex={index}
                questionsInRule={questionsInRule}
                conditions={rule.conditions}
                ruleLogicalOperator={rule.operator}
                dataOfAllBillableItems={dataOfAllBillableItems}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRuleCard;
