import React from "react";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import { handleBeforeUnload } from "./utils/helpers";
import { useBlockNavigation, useSwitchOrdering } from "./store/store";
import { CopyToClipboard } from "./components/buttons/CopyToClipboard";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      isOpen: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
      isOpen: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
      isOpen: true,
    });
  }

  handleReload = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
      isOpen: false,
    });
    window.location.reload();
  };

  handleRedirect = () => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
      isOpen: false,
    });
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          {this.props.children}
          <Dialog
            visible={this.state.isOpen}
            style={{ width: "80vw" }}
            className="max-w-lg"
            header="Something went wrong"
            modal
            closable={false}
          >
            <ErrorDialogContent
              error={this.state.error}
              errorInfo={this.state.errorInfo}
              showStack={this.props.showStack}
              onReload={this.handleReload}
              onRedirect={this.handleRedirect}
            />
          </Dialog>
        </>
      );
    }

    return this.props.children;
  }
}

const ErrorDialogContent = ({
  error,
  errorInfo,
  showStack,
  onReload,
  onRedirect,
}) => {
  const navigate = useNavigate();
  const { isOrderingMode } = useSwitchOrdering();
  const { setBlockNavigation } = useBlockNavigation();

  const errorDetails = error?.toString() + errorInfo.componentStack;

  useEffect(() => {
    setBlockNavigation(false);
    window.removeEventListener("beforeunload", handleBeforeUnload);
    return () => setBlockNavigation(true);
  }, []);

  const handleGotoDashboard = () => {
    onRedirect();
    isOrderingMode
      ? navigate("/ordering/FindServices")
      : navigate("/dashboard");
  };
  return (
    <>
      <div className="flex flex-col gap-2 py-2">
        <p>An error has occurred in the application:</p>
        <div className="rounded-md bg-gray-100 p-4">
          <p className="font-mono text-sm">{error?.toString()}</p>
        </div>
        {showStack && errorInfo?.componentStack && (
          <details className="mt-4">
            <summary className="cursor-pointer">Stack trace</summary>
            <pre className="mt-2 max-h-40 overflow-auto rounded bg-gray-100 p-2 text-xs">
              {errorInfo.componentStack}
            </pre>
          </details>
        )}
        <CopyToClipboard text={errorDetails} label="Copy Error" />
      </div>

      <div className="flex justify-end p-5">
        <div className="flex gap-2">
          <Button
            severity="info"
            onClick={onReload}
            type="button"
            label="Reload Application"
            icon="pi pi-refresh"
          ></Button>
          <Button
            severity="info"
            onClick={handleGotoDashboard}
            type="button"
            outlined
            label={isOrderingMode ? "Find Services" : "Go to Dashboard"}
            icon="pi pi-th-large"
          ></Button>
        </div>
      </div>
    </>
  );
};

export default ErrorDialogContent;
