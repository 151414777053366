import React from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Tooltip } from "primereact/tooltip";
import { Dropdown } from "primereact/dropdown";

import ConditionCard from "./ConditionCard";
import { DEFAULT_CONDITION, LOGICAL_OPERATORS } from "../../utils/constants";

const ManageRule = ({ ruleIndex }) => {
  const { control, unregister } = useFormContext();

  const {
    fields: conditions,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: `conditionalGroups.${ruleIndex}.conditions`,
  });

  return (
    <div>
      <h3 className="mb-2 text-lg font-semibold">Rule Operator</h3>
      <div className="mb-2 mt-4">
        <Tooltip target=".custom-target-icon" />

        <div className="flex w-full justify-center">
          <Controller
            name={`conditionalGroups.${ruleIndex}.operator`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <label
                htmlFor={field.name}
                className="flex w-full items-center justify-center gap-2 rounded-xl bg-light-purple p-2 text-[.7rem] font-semibold text-white sm:text-base md:w-[80%]"
              >
                <span>If</span>
                <div className="flex flex-col gap-2 md:flex-row md:items-center">
                  <Dropdown
                    inputId={field.name}
                    {...field}
                    options={LOGICAL_OPERATORS}
                    className="flex h-8 w-32 items-center capitalize md:w-36"
                    pt={{ item: { className: "capitalize" } }}
                  />
                </div>
                <span>Of The Following “IF” Conditions Are Matched</span>
                {error && <p className="text-red-500">{error.message}</p>}
              </label>
            )}
          />
        </div>
      </div>

      <div className="flex flex-col gap-3 rounded-xl border border-slate-200 bg-slate-50 p-2 md:m-5 md:p-8">
        {conditions.map((condition, conditionIndex) => (
          <ConditionCard
            key={condition.id}
            conditions={conditions}
            append={() => append(DEFAULT_CONDITION)}
            remove={() => {
              setTimeout(() => remove(conditionIndex), 0);
              unregister(
                `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}`,
              );
            }}
            ruleIndex={ruleIndex}
            conditionIndex={conditionIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default ManageRule;
