import { useEffect, useState } from "react";

import { addYears } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";

import useAxios from "../../../hooks/useAxios";
import {
  GENDERS,
  GENDERS_ENUM,
  USER_ROLES_ENUM_Number,
} from "../../../constants/constants";
import {
  emailRegex,
  passwordRegex,
  phoneRegex,
  textRegex,
} from "../../../utils/regex";

import {
  useLayoutToast,
  useRefreshTokenStore,
  useRegisterFormStore,
  useTokenStore,
} from "../../../store/store";
import { Calendar } from "primereact/calendar";
import { showErrorToast } from "../../../utils/apiUtils";
import { trimValidation } from "../../../utils/helpers";

const SignUpForm = ({ searchParams }) => {
  const { http } = useAxios();
  const navigate = useNavigate();
  const setFormData = useRegisterFormStore((state) => state.setFormData);
  const { layoutToast } = useLayoutToast();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();

  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isEmailDisabled = searchParams.email ? true : false;

  const defaultValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    email: searchParams.email || "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: null,
    password: "",
    confirmPassword: "",
    checked: false,
    receiveSmsMessages: false,
    registrationCode: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    reset();
  }, []);

  const isProduction = process.env.REACT_APP_ENVIRONMENT === "prod";

  const onSubmit = async (formData) => {
    setIsLoading(true);

    const modifyData = {
      dateOfBirth: formData.dateOfBirth,
      gender:
        formData.gender === GENDERS_ENUM.MALE.label
          ? GENDERS_ENUM.MALE.value
          : GENDERS_ENUM.FEMALE.value,
    };
    const combinedFormData = { ...formData, ...modifyData };

    const registrationUrl = !searchParams.EId
      ? `/Authentication/RegisterAsync`
      : `/Authentication/RegisterAsync?EntityId=${searchParams.EId}&InvitationDate=${searchParams.date}&staffRole=${searchParams.role}&IsProvider=${searchParams.isProvider || ""}`;

    try {
      const response = await http.post(registrationUrl, combinedFormData);

      reset(defaultValues);
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
        life: 4000,
      });

      setFormData(response.data);
      setToken(response.data.token);
      setRefreshToken(response.data.refreshToken);

      if (isEmailDisabled) {
        if (response.data.isInvitedAsAProvider) {
          navigate("/welcome-provider");
        } else if (
          response.data.entityUserRole ===
          USER_ROLES_ENUM_Number.OrderingOnlyStaff
        ) {
          navigate("/ordering/FindServices", {
            replace: "true",
          });
        } else {
          navigate("/Dashboard");
        }
      } else {
        navigate("/verifyEmail", {
          state: {
            email: response.data.email,
            phoneNumber: formData.phoneNumber,
          },
          replace: true,
        });
      }
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  return (
    <div className="form-container mb-3">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="form-container mb-3">
          <label htmlFor="firstName">
            First Name <span className="text-red-600">*</span>
          </label>
          <span className="p-input-icon-left w-full">
            <i className="pi pi-user" />
            <InputText
              {...register("firstName", {
                required: "First name is required.",
                pattern: {
                  value: textRegex,
                  message: "First name should contain letters only.",
                },
                validate: {
                  trim: (v) =>
                    v.length === 0 ||
                    v.trim().length !== 0 ||
                    "Input shouldn't contain trailing spaces.",
                },
              })}
              id="firstName"
              placeholder="First Name"
              className="w-full pb-2 pt-2"
            />
          </span>
          {errors.firstName && (
            <p className="mt-1 text-red-500">{errors.firstName.message}</p>
          )}

          <div className="mb-4 mt-3 grid gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-5">
            <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
              <label htmlFor="middleName">Middle Name</label>
              <span>
                <InputText
                  {...register("middleName", {
                    pattern: {
                      value: textRegex,
                      message: "Middle name should contain letters only.",
                    },
                  })}
                  id="middleName"
                  placeholder="Middle Name"
                  className="w-full pb-2 pt-2"
                />
                {errors.middleName && (
                  <p className="mt-1 text-red-500">
                    {errors.middleName.message}
                  </p>
                )}
              </span>
            </div>
            <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-2">
              <label htmlFor="lastName">
                Last Name <span className="text-red-600">*</span>
              </label>
              <span>
                <InputText
                  {...register("lastName", {
                    required: "Last name is required.",
                    pattern: {
                      value: textRegex,
                      message: "Last name should contain letters only.",
                    },
                    validate: {
                      trim: (v) =>
                        v.length === 0 ||
                        v.trim().length !== 0 ||
                        "Input shouldn't contain trailing spaces.",
                    },
                  })}
                  id="lastName"
                  placeholder="Last Name"
                  className="w-full pb-2 pt-2"
                />
                {errors.lastName && (
                  <p className="mt-1 text-red-500">{errors.lastName.message}</p>
                )}
              </span>
            </div>
            <div className="lg:col-span-1 xl:col-span-1 2xl:col-span-1">
              <label htmlFor="suffix">Suffix</label>
              <span>
                <InputText
                  {...register("suffix", {
                    pattern: {
                      value: textRegex,
                      message: "Suffix should contain letters only.",
                    },
                    validate: {
                      trim: (v) =>
                        v.length === 0 ||
                        v.trim().length !== 0 ||
                        "Input shouldn't contain trailing spaces.",
                    },
                  })}
                  id="suffix"
                  placeholder="Suffix"
                  className="w-full pb-2 pt-2"
                />
                {errors.suffix && (
                  <p className="mt-1 text-red-500">{errors.suffix.message}</p>
                )}
              </span>
            </div>
          </div>
          <label htmlFor="dateOfBirth">
            Date of Birth <span className="text-red-600">*</span>
          </label>
          <span className="mt-1 w-full">
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: "Date of birth is required",
                validate: {
                  validDate: (value) => {
                    const currentDate = new Date();
                    const maxDate = addYears(currentDate, -18);
                    return (
                      new Date(value) <= maxDate ||
                      "You must be at least 18 years old."
                    );
                  },
                },
              }}
              render={({ field }) => (
                <Calendar
                  {...field}
                  showButtonBar
                  showIcon
                  showOnFocus={false}
                  pt={{
                    dropdownButton: {
                      root: {
                        style: {
                          backgroundColor: "#4F46E5",
                        },
                      },
                    },
                  }}
                  className="w-full"
                  mask="99/99/9999"
                  dateFormat="mm/dd/yy"
                  viewDate={addYears(new Date(), -18)}
                  placeholder="MM/DD/YYYY"
                  maxDate={addYears(new Date(), -18)}
                  minDate={new Date("1900-01-01")}
                  value={field.value ? new Date(field.value + "T00:00:00") : ""}
                  onChange={(e) => {
                    e.value &&
                      field.onChange(
                        new Date(e.value)
                          .toLocaleDateString("en-CA")
                          .split("T")[0],
                      );
                  }}
                />
              )}
            />
          </span>

          {errors.dateOfBirth && (
            <p className="mt-1 text-red-500">{errors.dateOfBirth.message}</p>
          )}

          <div className="pt-5">
            <label htmlFor="gender">
              Gender <span className="text-red-600">*</span>
            </label>
            <Controller
              control={control}
              name="gender"
              rules={{ required: "Please select your gender" }}
              render={({ field }) => (
                <>
                  <Dropdown
                    {...field}
                    options={GENDERS}
                    inputId="gender"
                    placeholder="Gender"
                    className="md:w-14rem w-full"
                  />
                  {errors.gender && (
                    <p className="mt-1 text-red-500">{errors.gender.message}</p>
                  )}
                </>
              )}
            />
          </div>

          <div className="pt-4">
            <label htmlFor="phoneNumber">
              Mobile Phone Number <span className="text-red-600">*</span>
            </label>
            <div>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <InputMask
                    {...field}
                    id="phoneNumber"
                    mask="999-999-9999"
                    placeholder="Mobile Phone Number"
                    className="w-full"
                    onClick={(e) => {
                      if (e.target.value === "___-___-____") {
                        e.target.setSelectionRange(0, 0);
                      }
                    }}
                  />
                )}
                rules={{
                  required: "Mobile phone number required",
                  pattern: {
                    value: phoneRegex,
                    message: "The phone number entered is incorrect",
                  },
                }}
              />
              {errors.phoneNumber && (
                <p className="mt-1 text-red-500">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
          </div>

          <div className="pt-4">
            <label htmlFor="email">
              Email <span className="text-red-600">*</span>
            </label>
            <span className="p-input-icon-left w-full">
              <i className="pi pi-envelope" />
              <InputText
                {...register("email", {
                  required: "Please enter your email",
                  pattern: {
                    value: emailRegex,
                    message: "Invalid email address",
                  },
                })}
                id="email"
                disabled={isEmailDisabled}
                placeholder="Email Address"
                className="w-full pb-2 pt-2"
              />
            </span>
            {errors.email && (
              <p className="mt-1 text-red-500">{errors.email.message}</p>
            )}
          </div>
          <InputText
            type="password"
            autoComplete="new-password"
            className="hidden"
          />
          <div className="pt-4">
            <label htmlFor="password">
              Password <span className="text-red-600">*</span>
            </label>
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
                pattern: {
                  value: passwordRegex,
                  message:
                    "Password must contain at least one uppercase letter, one digit, one special character.",
                },
                deps: ["confirmPassword"],
              }}
              render={({ field }) => (
                <span className="p-input-icon-left p-input-icon-right w-full">
                  <i className="pi pi-lock" />
                  <InputText
                    {...field}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      trigger("confirmPassword");
                    }}
                    // type={!passwordEye ? "password" : "text"}
                    type="text"
                    id="password"
                    autoComplete="off"
                    placeholder="Password (8 or more characters)"
                    className="w-full pb-2 pt-2"
                    style={{
                      WebkitTextSecurity: passwordEye ? null : "disc", // Webkit browsers
                      textSecurity: passwordEye ? null : "disc", // Firefox
                    }}
                  />
                  <i
                    className={`pi ${passwordEye ? "pi-eye" : "pi-eye-slash"}`}
                    onClick={() => setPasswordEye(!passwordEye)}
                  />
                </span>
              )}
            />
            {errors.password && (
              <p className="mt-1 text-red-500">{errors.password.message}</p>
            )}
          </div>

          <div className="pt-4">
            <label htmlFor="confirmPassword">
              Confirm Password <span className="text-red-600">*</span>
            </label>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "Confirm password is required",
                validate: (value, formValues) =>
                  value === formValues.password || "Passwords do not match",
              }}
              render={({ field }) => (
                <span className="p-input-icon-left p-input-icon-right w-full">
                  <i className="pi pi-lock" />
                  <InputText
                    {...field}
                    id="confirmPassword"
                    // type={!confirmPasswordEye ? "password" : "text"}
                    type="text"
                    placeholder="Confirm Password"
                    className="w-full pb-2 pt-2"
                    style={{
                      WebkitTextSecurity: confirmPasswordEye ? null : "disc", // Webkit browsers
                      textSecurity: confirmPasswordEye ? null : "disc", // Firefox
                    }}
                  />
                  <i
                    className={`pi ${confirmPasswordEye ? "pi-eye" : "pi-eye-slash"}`}
                    onClick={() => setConfirmPasswordEye(!confirmPasswordEye)}
                  />
                </span>
              )}
            />
            {errors.confirmPassword && (
              <p className="mt-1 text-red-500">
                {errors.confirmPassword.message}
              </p>
            )}
          </div>
          {!isProduction && (
            <div className="pt-4">
              <p className="text-left font-Poppins text-p leading-loose text-normal-text">
                Admin Registration Verification Code
              </p>
              <Controller
                name={`registrationCode`}
                control={control}
                rules={{
                  required: "Please enter a valid text",
                  validate: (value) => trimValidation(value, `Code`),
                }}
                render={({ field, fieldState: { error } }) => (
                  <div className="items-center gap-2">
                    <InputText
                      {...field}
                      id={field.name}
                      className={`w-full ${error ? "p-invalid" : ""} `}
                    />
                  </div>
                )}
              />
              {errors.code && (
                <p className="mt-1 text-red-500">{errors.code.message}</p>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="mt-4 flex">
            <Controller
              name="checked"
              control={control}
              rules={{
                required: "You must agree to MedXServices terms to proceed",
              }}
              render={({ field }) => (
                <>
                  <Checkbox
                    {...field}
                    inputId={field.name}
                    checked={field.value}
                    className="pt-1"
                  />
                  <label htmlFor={field.name} className="pl-2 text-gray-800">
                    I have <span className="font-bold">reviewed</span> and{" "}
                    <span className="font-bold">agree</span> to the{" "}
                    <span className="text-light-purple">
                      <Link to={"/legal/tos"} target="_blank">
                        MedXServices Terms of Service,{" "}
                      </Link>
                    </span>
                    and{" "}
                    <span className="text-light-purple">
                      {" "}
                      <Link to={"/legal/privacy"} target="_blank">
                        Privacy Policy
                      </Link>
                    </span>
                    .
                  </label>
                </>
              )}
            />
          </div>
          <div>
            {errors.checked && (
              <p className="mb-3 mt-1 text-red-500">{errors.checked.message}</p>
            )}
          </div>
          <div className="flex">
            <Controller
              name="receiveSmsMessages"
              control={control}
              render={({ field }) => (
                <div className="flex gap-2">
                  <Checkbox
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    onChange={(e) => field.onChange(e.checked)}
                    className="pt-1"
                  />
                  <div className="flex flex-col gap-1">
                    <label
                      htmlFor="receiveSmsMessages"
                      className="text-gray-800"
                    >
                      I
                      <span className="font-medium uppercase">
                        &nbsp;agree&nbsp;
                      </span>
                      to receive critical text&nbsp;
                      <span className="text-light-purple">(SMS)</span>
                      &nbsp;messages specific to activity on my account from AHS
                      <span className="text-light-purple">
                        &nbsp;MedXServices&nbsp;
                      </span>
                      at the mobile phone number provided above.
                    </label>
                    <p className="text-xs text-gray-500">
                      Such communication will always be related to my account,
                      including but not limited to: two factor authentication
                      codes (2FA), one time passwords (OTP), order updates,
                      order authorization requests, account updates, and
                      confirmation requests.
                      <span className="font-medium">
                        &nbsp;Message and data rates may apply.&nbsp;
                      </span>
                      <span className="font-medium">
                        Message frequency varies.&nbsp;
                      </span>
                      Please
                      <span className="font-medium text-light-purple">
                        &nbsp;reply STOP&nbsp;
                      </span>
                      to opt out at any time OR you can update your preferences
                      in your profile at any time.
                    </p>
                  </div>
                </div>
              )}
            />
          </div>
        </div>

        {isLoading ? <LoadingSpinner /> : <SubmitButton />}
      </form>
    </div>
  );
};

export default SignUpForm;

const LoadingSpinner = () => (
  <div className="text-center">
    <ProgressSpinner
      strokeWidth={3}
      style={{ width: "40px", height: "40px" }}
    />
  </div>
);

const SubmitButton = () => (
  <Button
    type="submit"
    className="mt-9 w-full justify-center rounded-full bg-light-purple py-2.5 font-inter font-normal capitalize text-light-text"
  >
    create my account
  </Button>
);
