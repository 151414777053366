import React from "react";
import { InputNumber } from "primereact/inputnumber";
import {
  convertAmericanToStandard,
  convertStandardToAmerican,
} from "../../utils/numericConversions";

const AmericanNumericInput = ({
  id,
  value,
  error,
  onChange,
  handleChange,
  disabled = false,
}) => {
  const americanMetrics = convertStandardToAmerican(value);
  const handleFeetChange = (e) => {
    const standardValue = convertAmericanToStandard(
      e.value,
      americanMetrics.inches,
    );
    const oldStandardValue = convertAmericanToStandard(
      americanMetrics.feet,
      americanMetrics.inches,
    );
    onChange(standardValue);
    handleChange && handleChange(oldStandardValue, standardValue);
  };

  const handleInchesChange = (e) => {
    const standardValue = convertAmericanToStandard(
      americanMetrics.feet,
      e.value,
    );
    const oldStandardValue = convertAmericanToStandard(
      americanMetrics.feet,
      americanMetrics.inches,
    );
    onChange(standardValue);
    handleChange && handleChange(oldStandardValue, standardValue);
  };

  return (
    <div className="flex w-full gap-4">
      <div className="flex flex-1 items-center gap-1">
        <InputNumber
          inputId={`${id}.ft`}
          value={americanMetrics.feet}
          onChange={handleFeetChange}
          disabled={disabled}
          useGrouping={false}
          onKeyDown={(e) => {
            // to prevent type number bigger than maximum value {9}
            // keydown indicates to press any key of keyboard
            const newValue = Number(e.target.value + e.key);
            if (newValue > 9) e.preventDefault();
          }}
          max={9}
          min={0}
          placeholder="ft."
          className={`!w-auto ${error ? "p-invalid" : ""}`}
          inputClassName="!w-full"
        />
        <label htmlFor={`${id}.ft`} className="font-semibold text-gray-500">
          &nbsp;ft.
        </label>
      </div>

      <div className="flex flex-1 items-center gap-1">
        <InputNumber
          inputId={`${id}.in`}
          value={americanMetrics.inches}
          onChange={handleInchesChange}
          disabled={disabled}
          useGrouping={false}
          max={11}
          min={0}
          onKeyDown={(e) => {
            const newValue = Number(e.target.value + e.key);
            if (newValue > 11) e.preventDefault();
          }}
          placeholder="in."
          className={`!w-auto ${error ? "p-invalid" : ""}`}
          inputClassName="!w-full"
        />
        <label htmlFor={`${id}.in`} className="font-semibold text-gray-500">
          &nbsp;in.
        </label>
      </div>
    </div>
  );
};

export default AmericanNumericInput;
