import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import { MSAuthButton } from "../formElements";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import {
  useExternalLogin,
  useLayoutToast,
  useRefreshTokenStore,
  useStoreMslMicrosoft,
  useTokenStore,
} from "../../store/store";
import axios from "axios";
import { EXTERNAL_AUTH_ATTR } from "./utils/constants";
import { showErrorToast } from "../../utils/apiUtils";

const ProviderAuthentication = ({ authenticationUrl, authTypeKey, invId }) => {
  const navigate = useNavigate();
  const { layoutToast } = useLayoutToast();
  const { setToken } = useTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { setIsCompletedRegister } = useExternalLogin();
  const { onMsalInstanceChange, setIsMicrosoftLoggedIn } =
    useStoreMslMicrosoft();

  function getAccessTokenFromSessionStorage() {
    const staticAccessKey = "login.windows.net-accesstoken";
    let accessToken = null;

    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.includes(staticAccessKey)) {
        const accessTokenData = JSON.parse(sessionStorage.getItem(key));
        accessToken = accessTokenData?.secret;
        break;
      }
    }

    return accessToken;
  }

  function getIdTokenFromSessionStorage() {
    const staticIdKey = "login.windows.net-idtoken";
    let idToken = null;
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key.includes(staticIdKey)) {
        const IdTokenData = JSON.parse(sessionStorage.getItem(key));
        idToken = IdTokenData?.secret;
        break;
      }
    }
    return idToken;
  }

  const onProviderAuthenticationSuccess = (response) => {
    layoutToast.current.show({
      severity: "success",
      summary: "Success",
      detail: response.data.message,
      life: 4000,
    });

    setToken(response.data.token);
    setRefreshToken(response.data.refreshToken);
    setIsCompletedRegister(response.data.isCompletedRegister);

    if (
      response.data.isNew === true ||
      response.data.isCompletedRegister === false
    ) {
      if (invId) {
        const currentUrl = response.data.registerationReturnLink;
        const domainNameTrailingSlashIndex = currentUrl.indexOf("/");

        const trimmedUrl =
          domainNameTrailingSlashIndex !== -1
            ? currentUrl.slice(domainNameTrailingSlashIndex)
            : currentUrl;

        navigate(trimmedUrl, { replace: "true" });
      } else {
        navigate("/completeRegistration", { replace: true });
      }
    } else {
      navigate("/chooseEntity", { replace: true });
    }
  };

  const authHandler = (err, data, msal) => {
    if (!err && data) {
      onMsalInstanceChange(msal);
      setIsMicrosoftLoggedIn(true);
    }

    const accessToken = getAccessTokenFromSessionStorage();
    const idToken = getIdTokenFromSessionStorage();

    const microsoftData = {
      provider: "MICROSOFT",
      idToken: idToken,
      accessToken: accessToken,
    };

    axios
      .post(authenticationUrl, microsoftData)
      .then(onProviderAuthenticationSuccess)
      .catch((error) => {
        showErrorToast({ error });
      });
  };

  const googleAuthLoginSuccess = (credentialResponse) => {
    const googleData = {
      provider: "GOOGLE",
      idToken: credentialResponse.credential,
    };

    axios
      .post(authenticationUrl, googleData)
      .then(onProviderAuthenticationSuccess)
      .catch((error) => {
        showErrorToast({ error });
      });
  };

  return (
    <div className="flex flex-col gap-2 p-4">
      <div className="flex justify-center">
        <MicrosoftLogin
          clientId={process.env.REACT_APP_MICROSOFT_CLIENT_ID}
          authCallback={authHandler}
        >
          <MSAuthButton
            label={EXTERNAL_AUTH_ATTR[authTypeKey].microsoft.label}
          />
        </MicrosoftLogin>
      </div>
      <div className="flex justify-center">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <GoogleLogin
            context={EXTERNAL_AUTH_ATTR[authTypeKey].google.context}
            text={EXTERNAL_AUTH_ATTR[authTypeKey].google.text}
            onSuccess={googleAuthLoginSuccess}
            onError={(error) => {
              console.error(error);
            }}
          />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
};

export default ProviderAuthentication;
