import { Tooltip } from "primereact/tooltip";
import { v4 as uuidv4 } from "uuid";

const SortButtons = ({ handleMoveToTop, handleMoveToBottom }) => {
  const id = uuidv4();
  return (
    <div className="flex flex-col p-2">
      <Tooltip target={`.move-tooltip-${id}`} />
      <i
        onClick={handleMoveToTop}
        className={`move-tooltip-${id} pi pi-arrow-circle-up cursor-pointer text-xl text-light-purple`}
        data-pr-tooltip="Move to Top"
      />
      <i
        onClick={handleMoveToBottom}
        className={`move-tooltip-${id} pi pi-arrow-circle-down cursor-pointer text-xl text-light-purple`}
        data-pr-tooltip="Move to Bottom"
      />
    </div>
  );
};

export default SortButtons;
