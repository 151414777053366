import { useEffect, useMemo } from "react";

import { useFormContext } from "react-hook-form";

import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";

import { ModTemplate } from "../../../templates/ModTemplate";
import useFetchBillablesByIds from "../useFetchBillablesByIds";
import { getAllBillableItemsInRules, getBillableItemsIds } from "./helpers";
import DescriptionWithTooltipTemplate from "../DescriptionWithTooltipTemplate";
import { PriceBillableTemplate } from "../../../templates/PriceBillableTemplate";
import { useServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import { BillableItemsAttributesTemplate } from "../../../templates/BillableItemsAttributesTemplate";

const RemoveBillableItem = ({ actionHeader, field, error }) => {
  const { getValues } = useFormContext();
  const { defaultBillableItems } = useServiceDetailsContext();

  const { billableItems, isLoading, getBillableItems } =
    useFetchBillablesByIds();

  const allBillableItemsInRules = useMemo(
    () =>
      getBillableItemsIds([
        ...getAllBillableItemsInRules(getValues("id")),
        ...defaultBillableItems,
      ]),
    [],
  );

  useEffect(() => {
    getBillableItems(allBillableItemsInRules);
  }, []);

  const VIEW_COLUMNS = [
    { header: "HCPCS", field: "hcpcs" },
    {
      header: "Description",
      body: DescriptionWithTooltipTemplate,
    },
    {
      body: ModTemplate,
      header: "Mod",
    },
    {
      header: "Attributes",
      body: BillableItemsAttributesTemplate,
    },
    {
      body: PriceBillableTemplate,
      header: "Price per unit",
    },
  ];
  const REMOVE_COLUMNS = [
    {
      header: actionHeader,
      body: (rowData) => DeleteBillableTemplate(rowData, field),
    },
    ...VIEW_COLUMNS,
  ];

  return (
    <div className="flex flex-col gap-4">
      <div>
        <DataTable
          value={billableItems}
          loading={isLoading}
          className="rounded-lg border-2 border-gray-200"
          emptyMessage={
            <p className="text-center">
              Currently, there are no billable items selected.
            </p>
          }
        >
          {REMOVE_COLUMNS.map((column) => (
            <Column
              key={column.header}
              align="center"
              alignHeader="center"
              {...column}
            />
          ))}
        </DataTable>
      </div>
      {error && <p className="text-red-500">{error.message}</p>}
    </div>
  );
};

export default RemoveBillableItem;

const DeleteBillableTemplate = (rowData, field) => {
  return (
    <Checkbox
      checked={field.value.some((v) => v.id === rowData.id)}
      onChange={(e) => {
        const isChecked = e.checked;

        if (isChecked) {
          field.onChange([...field.value, { id: rowData.id }]);
        } else {
          field.onChange(field.value.filter((v) => v.id !== rowData.id));
        }
      }}
    />
  );
};
