import { useState } from "react";

import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import useAxios from "../../../../hooks/useAxios";
import { useLayoutToast } from "../../../../store/store";
import { showErrorToast } from "../../../../utils/apiUtils";

const InviteAdminForm = ({ setRefetch }) => {
  const { http } = useAxios();
  const { layoutToast } = useLayoutToast();

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adminEmail: "",
    },
  });

  const onSubmit = async (formData) => {
    try {
      setIsLoading(true);
      await http.post(
        `/Authentication/InviteSystemAdmin?email=${formData.adminEmail}`,
      );
      layoutToast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Invitation sent successfully",
        life: 4000,
      });
      setValue("adminEmail", "");
      setRefetch((prev) => !prev);
    } catch (error) {
      setIsLoading(false);
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="btn-invite flex flex-row flex-nowrap items-center py-4">
        <div className="flex-column flex-grow flex-nowrap items-center justify-end">
          <div className="flex w-full flex-col gap-2 sm:flex sm:flex-row">
            <Controller
              name="adminEmail"
              control={control}
              rules={{
                required: "Please enter a valid email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid Email Address",
                },
              }}
              render={({ field }) => (
                <InputText
                  placeholder="Admin Email"
                  className="mr-4 w-full basis-4/12 py-3"
                  {...field}
                />
              )}
            />
            <Button
              className="w-full rounded-md bg-light-purple text-center font-inter sm:w-auto"
              loading={isLoading}
              type="submit"
              label="Send Invitation"
            ></Button>
          </div>
          {errors && (
            <div className="mt-1 block w-full text-red-500">
              {errors?.adminEmail?.message}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default InviteAdminForm;
