import { Controller, useFormContext } from "react-hook-form";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { useLocationsContext } from "../context/LocationsContext";
import { useEffect } from "react";
import { Skeleton } from "primereact/skeleton";

const LocationMultiSelect = () => {
  const { defaultLocations, setLocationVisible, isFetchingLocations } =
    useLocationsContext();
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (defaultLocations.length === 1) {
      setValue("locations", [defaultLocations[0].id]);
    }
  }, [defaultLocations]);

  return (
    <div className="flex flex-col">
      <label
        htmlFor="location"
        className="font-medium capitalize leading-loose text-gray-500"
      >
        Rendered at
        <span className="ml-1 font-bold text-red-500">*</span>
      </label>
      <div className="flex flex-col gap-3 rounded-lg border border-slate-200 p-3">
        <div className="flex flex-col gap-1">
          <p className="text-sm text-gray-500">
            Select one or more locations where this service can be performed.
          </p>
          <p className="text-xs text-gray-400">
            * Please note that locations flagged as disabled will not be visible
            to order providers.
          </p>
        </div>

        {!isFetchingLocations ? (
          <div id="serviceLocation">
            <Controller
              name="locations"
              control={control}
              rules={{ required: "Please select your location" }}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  display="chip"
                  filter
                  filterTemplate={() => (
                    <span className="ml-2 font-inter text-sm">Select All</span>
                  )}
                  panelHeaderTemplate={(options) => (
                    <div
                      className={`{${options.className} flex-start flex w-full items-center gap-4 bg-[#f8f9fa] pl-5`}
                    >
                      <div>
                        {options.checkboxElement}
                        {options.filterElement}
                      </div>
                      <Button
                        label="Create new location"
                        icon="pi pi-map-marker"
                        type="button"
                        link
                        onClick={() => setLocationVisible(true)}
                      />
                    </div>
                  )}
                  className="md:w-14rem w-full"
                  placeholder="Choose Locations"
                  options={defaultLocations}
                  optionLabel="name"
                  optionValue="id"
                  itemTemplate={(option) => (
                    <div>
                      <h4
                        className={`font-medium capitalize ${
                          option.isEnabled ? "text-gray-600" : "text-gray-400"
                        }`}
                      >
                        {option.name}{" "}
                      </h4>
                      <p
                        className={`font-normal capitalize ${
                          option.isEnabled ? "text-gray-600" : "text-gray-400"
                        }`}
                      >
                        {option.address.lineOne},{" "}
                        {option.address.lineTwo
                          ? option.address.lineTwo + ","
                          : ""}{" "}
                        {option.address.city}, {option.address.state}{" "}
                        {option.address.zipCode}
                      </p>
                    </div>
                  )}
                />
              )}
            />
          </div>
        ) : (
          <Skeleton height="48px" />
        )}
      </div>
      {errors?.locations?.message && (
        <div className="text-red-500">{errors.locations.message}</div>
      )}
    </div>
  );
};

export default LocationMultiSelect;
