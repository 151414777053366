import { useEffect, useRef, useState } from "react";

import { Messages } from "primereact/messages";

import useTokenData from "../../hooks/useTokenData";
import useTwilioStatus from "../../hooks/useTwilioStatus";
import { Dialog } from "primereact/dialog";
import ConfirmPhoneNumberDialog from "../../pages/settings/components/ConfirmPhoneNumberDialog";
import useImpersonating from "../../hooks/useImpersonating";

function PhoneVerificationBanner() {
  const msg = useRef(null);
  const [sentData, setSentData] = useState();
  const { twilioStatus } = useTwilioStatus({ keepAlive: true });
  const { IsPhoneVerified, phoneNumber } = useTokenData();
  const { isSystemAdminImpersonating } = useImpersonating();
  const [dialogVisible, setDialogVisible] = useState(false);

  useEffect(() => {
    msg?.current?.clear();
    if (msg.current) {
      msg.current.show({
        id: "1",
        sticky: true,
        severity: "warn",
        summary: "Phone Number Not Verified.",
        detail: (
          <span>
            <span
              onClick={() => {
                setDialogVisible(true);
              }}
              className="cursor-pointer font-semibold underline"
            >
              Click Here
            </span>{" "}
            Verify your phone number to receive critical alerts via SMS and
            complete setting up your account.
          </span>
        ),
        closable: false,
      });
    }
  }, [twilioStatus, IsPhoneVerified]);

  return IsPhoneVerified === "False" &&
    twilioStatus &&
    !isSystemAdminImpersonating ? (
    <>
      <Messages ref={msg} />
      <Dialog
        visible={dialogVisible}
        className="max-w-md"
        headerClassName="!p-0"
        pt={{ closeButton: { style: { right: "12px", top: "10px" } } }}
        onHide={() => setDialogVisible(false)}
      >
        <ConfirmPhoneNumberDialog
          sentData={sentData}
          setSentData={setSentData}
          setDialogVisible={setDialogVisible}
          dialogVisible={dialogVisible}
          VerifyPhoneNumber={phoneNumber}
        />
      </Dialog>
    </>
  ) : null;
}

export default PhoneVerificationBanner;
