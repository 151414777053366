export const EXTERNAL_AUTH_ATTR = {
  SIGN_UP: {
    google: {
      text: "signup_with",
      context: "signup",
    },
    microsoft: {
      label: "Register with Microsoft",
    },
  },
  SIGN_IN: {
    google: {
      text: "signin_with",
      context: "signin",
    },
    microsoft: {
      label: "Sign in with Microsoft",
    },
  },
};
