import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";

import AnswerInput from "../formElements/AnswerInput";
import {
  CONDITION_TYPE_ENUM,
  DEFAULT_SELF_EVALUATING_QUESTIONS_IDS,
  NUMBER_COMPARISON_OPERATORS,
  OPERATORS,
  QUESTION_TYPES_ENUM,
  TEXT_COMPARISON_OPERATORS,
} from "../../utils/constants";
import TextOptions from "../TextOptions";
import DisplayConstraints from "../formElements/DisplayConstraints";

const ComparisonCondition = ({ ruleIndex, conditionIndex, question }) => {
  const { control, setValue, watch, unregister } = useFormContext();
  const conditionType = watch(
    `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.type`,
  );

  let comparisonOperators = [OPERATORS.EQUAL];
  const isSelfEvaluatingCondition =
    conditionType === CONDITION_TYPE_ENUM.SELF_EVAL.value;

  if (isSelfEvaluatingCondition) {
    switch (question.id) {
      case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS:
        comparisonOperators = [OPERATORS.EQUAL];
        break;
      case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE:
        comparisonOperators = [OPERATORS.EQUAL, ...NUMBER_COMPARISON_OPERATORS];
        break;
      default:
        comparisonOperators = [OPERATORS.EQUAL];
        break;
    }
  } else {
    switch (question.type) {
      case QUESTION_TYPES_ENUM.BOOLEAN.value:
        comparisonOperators = [OPERATORS.EQUAL];
        break;
      case QUESTION_TYPES_ENUM.DROPDOWN.value:
      case QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value:
        comparisonOperators = [OPERATORS.EQUAL, OPERATORS.IN];
        break;
      case QUESTION_TYPES_ENUM.TEXT.value:
        comparisonOperators = [
          OPERATORS.EQUAL,
          OPERATORS.IN,
          ...TEXT_COMPARISON_OPERATORS,
        ];
        break;
      case QUESTION_TYPES_ENUM.NUMBER.value:
      case QUESTION_TYPES_ENUM.DECIMAL.value:
        comparisonOperators = [
          OPERATORS.EQUAL,
          OPERATORS.IN,
          ...NUMBER_COMPARISON_OPERATORS,
        ];
        break;
      default:
        comparisonOperators = [OPERATORS.EQUAL];
        break;
    }
  }
  return (
    <>
      <Controller
        name={`conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.comparisonOperator`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div className="grid grid-cols-1 items-center gap-2 md:grid-cols-12">
            <label htmlFor={field.name} className="col-span-3 font-semibold">
              Comparison
            </label>
            <div className="flex flex-col gap-2 md:col-span-9">
              <Dropdown
                {...field}
                inputId={field.name}
                onChange={(e) => {
                  field.onChange(e);
                  unregister(
                    `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                  );

                  const isMultiAnswerQuestion =
                    question.type ===
                      QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value ||
                    question.type === QUESTION_TYPES_ENUM.DROPDOWN.value;

                  const isMultiOptionalComparison =
                    e.value === OPERATORS.IN.value ||
                    e.value === OPERATORS.BETWEEN.value;
                  if (conditionType === CONDITION_TYPE_ENUM.QUESTION.value) {
                    if (isMultiOptionalComparison) {
                      if (isMultiAnswerQuestion) {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [],
                        );
                      } else {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [{ value: "" }, { value: "" }],
                        );
                      }
                    } else {
                      if (question.type === QUESTION_TYPES_ENUM.BOOLEAN.value) {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [{ value: false }],
                        );
                      } else {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [{ value: "" }],
                        );
                      }
                    }
                  } else {
                    if (isMultiOptionalComparison) {
                      if (isMultiAnswerQuestion) {
                        /**
                         * condition for future conditions
                         * if we would when adding an IN comparison operator with a multiselect UI
                         *  */
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [],
                        );
                      } else {
                        setValue(
                          `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                          [question.initialAnswerField],
                        );
                      }
                    } else {
                      setValue(
                        `conditionalGroups.${ruleIndex}.conditions.${conditionIndex}.answers`,
                        [question.initialAnswerField],
                      );
                    }
                  }
                }}
                options={comparisonOperators}
                pt={{ item: { className: "capitalize" } }}
                className="w-full capitalize"
              />
              {error && <p className="text-red-500">{error.message}</p>}
            </div>
          </div>
        )}
      />
      <AnswerInput
        inputNamePath={`conditionalGroups.${ruleIndex}.conditions.${conditionIndex}`}
        question={question}
      />
      {question?.type === QUESTION_TYPES_ENUM.TEXT.value && (
        <div className="py-3">
          <TextOptions
            path={`conditionalGroups.${ruleIndex}.conditions.${conditionIndex}`}
          />
        </div>
      )}
      <DisplayConstraints question={question} />
    </>
  );
};

export default ComparisonCondition;
