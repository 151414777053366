import React from "react";
import PersonShield from "../../../iconComponents/PersonShield";
import { Tooltip } from "primereact/tooltip";
import useHandleResize from "../../../hooks/useHandleResize";

export const OrderNumberTemplate = (rowData) => {
  const { isMobile } = useHandleResize();
  return (
    <div className="flex items-center gap-2">
      <Tooltip target=".confidential" className="max-w-xs" />
      {rowData?.orderTrackingNumber}
      {rowData.hasConfidentialInternalNote && (
        <span
          className={`${isMobile ? "h-4 w-4" : "h-6 w-6"} confidential cursor-pointer text-sm text-gray-500`}
          data-pr-position="top"
          data-pr-tooltip="Confidential Note present in order. Please view the Order Info section to view the confidential note."
        >
          <PersonShield />
        </span>
      )}
    </div>
  );
};
