import { useEffect } from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { Tooltip } from "primereact/tooltip";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";

import ConditionForm from "./ConditionForm";
import QuestionConstraints from "./QuestionConstraints";
import { DEFAULT_CONDITION, LOGICAL_OPERATORS } from "../utils/constants";

const QuestionConditionsForm = ({ questionIndex = 0, questionData }) => {
  const { control, watch, setValue, unregister } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: "conditions",
    shouldUnregister: true,
  });

  const isConditional = watch(`isConditional`);

  useEffect(() => {
    if (!watch("conditions").length) {
      setValue("conditions", [DEFAULT_CONDITION]);
    }
  }, []);

  return (
    <div>
      <Tooltip target=".custom-question-tooltip" />
      <div className="p-field">
        <div className="flex items-center gap-2">
          <span className="text-gray-600">Conditional Settings</span>
          <span className="text-gray-500">
            <i
              className="custom-question-tooltip pi pi-info-circle text-xl"
              data-pr-tooltip="This question will be displayed based on specific answer from previous question that you select"
              data-pr-position="right"
              style={{ cursor: "pointer" }}
            ></i>
          </span>
        </div>
        <div className="my-4 rounded-lg border border-slate-200 px-6 py-4">
          <div className="flex flex-col gap-2 py-2 lg:flex-row lg:items-center lg:gap-0">
            <div>
              <Controller
                name="isConditional"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    inputId={field.name}
                    name={field.name}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.checked);

                      if (!e.checked)
                        setValue("conditions", [DEFAULT_CONDITION]);
                    }}
                  />
                )}
              />
              <label htmlFor="isConditional" className="cursor-pointer pl-2">
                Only show this question if &nbsp;
              </label>
            </div>
            <Controller
              name="conditionalOperator"
              control={control}
              render={({ field }) => (
                <Dropdown
                  className="w-full md:w-28"
                  options={LOGICAL_OPERATORS}
                  {...field}
                  disabled={!watch("isConditional")}
                />
              )}
            />
            &nbsp; of these questions matched
          </div>

          <div className="p-0 md:p-2">
            <div className="flex flex-col gap-3">
              {fields.map((condition, index) => (
                <div
                  key={condition.id}
                  className="flex flex-col gap-3 md:grid md:grid-cols-12 md:items-center"
                >
                  <ConditionForm
                    condition={condition}
                    conditionIndex={index}
                    questionData={questionData}
                    questionIndex={questionIndex}
                  />
                  <div className="col-span-2 flex items-center gap-4 md:px-6">
                    {watch("conditions").length > 1 && (
                      <i
                        className="pi pi-trash cursor-pointer text-red-500"
                        onClick={() => {
                          setTimeout(() => remove(index), 0);
                          unregister(`conditions.${index}`);
                        }}
                      />
                    )}
                    {index === watch("conditions").length - 1 && (
                      <i
                        className="pi pi-plus cursor-pointer text-green-500"
                        hidden={!isConditional}
                        onClick={() => append(DEFAULT_CONDITION)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <QuestionConstraints />
    </div>
  );
};

export default QuestionConditionsForm;
