import { Editor } from "primereact/editor";

function DisplayAbortMessage({ rulesGroup }) {
  const on = rulesGroup.action.on;
  return (
    <div className="mt-4">
      <div>
        <Editor
          value={on.message}
          headerTemplate="Abort Message"
          readOnly
          id="abort-message"
          style={{ minHeight: "120px" }}
        />
      </div>
    </div>
  );
}

export default DisplayAbortMessage;
