import { useEffect } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import useFetchBillablesByIds from "../manage-service/components/rules/useFetchBillablesByIds";
import { BillableItemsAttributesTemplate } from "../manage-service/templates/BillableItemsAttributesTemplate";
import { ModTemplate } from "../manage-service/templates/ModTemplate";
import DescriptionWithTooltipTemplate from "../manage-service/components/rules/DescriptionWithTooltipTemplate";
import { PriceBillableTemplate } from "../manage-service/templates/PriceBillableTemplate";

const SwappedBillableTable = ({ selectedBillable, hideUnits = false }) => {
  const { billableItems, isLoading, getBillableItems } = useFetchBillablesByIds(
    [selectedBillable?.id],
  );

  useEffect(() => {
    selectedBillable?.id && getBillableItems([selectedBillable.id]);
  }, [selectedBillable?.id]);

  const COLUMNS = [
    { header: "HCPCS", field: "hcpcs" },
    {
      header: "Description",
      body: DescriptionWithTooltipTemplate,
    },
    { header: "Mods", body: ModTemplate },
    {
      header: "Attributes",
      body: BillableItemsAttributesTemplate,
    },
    {
      header: "Price per unit",
      body: PriceBillableTemplate,
    },
  ];

  if (!hideUnits) {
    COLUMNS.push({
      header: "Units",
      body: () => selectedBillable?.units,
    });
  }

  return (
    <DataTable
      loading={isLoading}
      value={billableItems}
      className="rounded-lg border-2 border-gray-200"
      emptyMessage={
        <p className="text-center">
          Currently, there are no billable items selected.
        </p>
      }
    >
      {COLUMNS.map((column) => (
        <Column
          key={column.header}
          align="center"
          alignHeader="center"
          {...column}
        />
      ))}
    </DataTable>
  );
};

export default SwappedBillableTable;
