import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Dropdown } from "primereact/dropdown";

import Actions from "./Actions";
import { ACTIONS, ACTIONS_ENUM } from "../../utils/constants";

const ManageActions = () => {
  const { control, watch, setValue, clearErrors } = useFormContext();

  return (
    <div>
      <div className="my-5 flex w-full justify-center">
        <Controller
          name={`action.key`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div className="rounded-xl bg-light-purple p-2 text-center font-semibold text-white md:flex md:w-[80%] md:items-center md:justify-center">
              <label htmlFor={field.name}>Apply This Action</label>
              <Dropdown
                {...field}
                inputId={field.name}
                onChange={(e) => {
                  field.onChange(e);
                  const isSwapCPT = e.value === ACTIONS_ENUM.SWAP_CPT.value;

                  if (isSwapCPT) {
                    setValue("action.on", { old: [{}], new: [{}] });
                  } else {
                    setValue("action.on", []);
                  }
                  clearErrors("action.on");
                }}
                options={ACTIONS}
                optionGroupLabel="label"
                optionGroupChildren="actions"
                placeholder="Select an Action"
                className="mx-2 w-96 text-start"
              />
            </div>
          )}
        />
      </div>

      <div className="flex flex-col gap-3 rounded-xl border-slate-200 bg-white md:border md:p-8">
        {/* ACTION ERRORS */}
        <div className="mb-4 items-center">
          {/* ACTION DYNAMIC UI */}
          <Actions action={watch("action.key")} />
        </div>
      </div>
    </div>
  );
};

export default ManageActions;
