import { useMemo } from "react";

import { v4 as uuidv4 } from "uuid";
import { Draggable } from "react-beautiful-dnd";
import { Controller, useFormContext } from "react-hook-form";

import { InputText } from "primereact/inputtext";

import { useServiceStore } from "../../utils/store";

const AnswerOptionInput = ({ answer, index, fields, append, remove, move }) => {
  const { control, watch, trigger } = useFormContext();

  const { questions, rulesGroups } = useServiceStore();

  const checkAnswerInCondition = (answerId) => {
    if (!answerId) return false;
    const questionsToCheck = questions.filter(
      (question) => question.id !== watch("id"),
    );

    return JSON.stringify([questionsToCheck, rulesGroups]).includes(answerId);
  };
  const answerId = watch(`answers.${index}.id`);

  const isAnswerInCondition = useMemo(
    () => checkAnswerInCondition(answerId),
    [answerId],
  );
  const isFirstItem = index === 0;
  const isLastItem = index === fields.length - 1;

  const answers = watch("answers");

  const handleChange = async (value, newValue) => {
    if (value) {
      const duplicateIndexes = answers
        .map((answer, idx) =>
          answer.value === value || answer.value === newValue ? idx : -1,
        )
        .filter((idx) => idx !== -1);

      if (duplicateIndexes.length >= 1) {
        await trigger(
          duplicateIndexes.map((idx) => `answers.${idx}.value`),
          { shouldFocus: false },
        );
      }
    }
  };

  return (
    <Draggable draggableId={answer.id} index={index}>
      {(provided) => (
        <Controller
          control={control}
          rules={{
            required: "Answer option is required",
            validate: (value, formValues) => {
              const normalizedValue = value?.trim();
              if (normalizedValue === "")
                return `Answer Option can not be empty `;
              const occurrences = formValues.answers.filter(
                (answer) => answer.value === value,
              ).length;
              const lastOccurrenceIndex = formValues.answers.findLastIndex(
                (answer) => answer.value === value,
              );
              const isLastOccurrence = lastOccurrenceIndex === index;
              const isDuplicate = occurrences > 1;

              return isLastOccurrence && isDuplicate
                ? `The value "${value}" is already entered. Please use a unique value.`
                : true;
            },
          }}
          name={`answers.${index}.value`}
          render={({ field, fieldState: { error } }) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="flex items-center gap-2"
            >
              <i
                {...provided.dragHandleProps}
                className="pi pi-bars cursor-grab"
              />
              <i
                className={`pi pi-angle-down cursor-pointer ${isLastItem ? "pointer-events-none opacity-50" : ""} hidden md:flex`}
                onClick={() => move(index, index + 1)}
              />
              <i
                className={`pi pi-angle-up cursor-pointer ${isFirstItem ? "pointer-events-none opacity-50" : ""} hidden md:flex`}
                onClick={() => move(index, index - 1)}
              />
              <div className="grid w-full grid-cols-12 gap-1">
                <InputText
                  {...field}
                  id={field.name}
                  onChange={(e) => {
                    field.onChange(e);
                    handleChange(field.value, e.target.value);
                  }}
                  className={`col-span-10 md:col-span-7 ${error ? "p-invalid" : ""} `}
                />
                <div className="col-span-2 flex items-center gap-4 md:px-6">
                  {isAnswerInCondition && (
                    <i
                      className="pi pi-info-circle question-info mr-1 text-sm text-gray-500"
                      data-pr-position="top"
                      data-pr-tooltip="Answer is used in a condition"
                    ></i>
                  )}
                  {watch("answers").length > 2 && !isAnswerInCondition ? (
                    <i
                      className="pi pi-trash cursor-pointer text-red-500"
                      onClick={() => {
                        remove(index);
                      }}
                    />
                  ) : null}
                  {index === watch("answers").length - 1 && (
                    <i
                      className="pi pi-plus cursor-pointer text-green-500"
                      onClick={() => append({ id: uuidv4(), value: "" })}
                    />
                  )}
                </div>
                <div className="col-span-12">
                  {error && <p className="text-red-500">{error.message}</p>}
                </div>
              </div>
            </div>
          )}
        />
      )}
    </Draggable>
  );
};

export default AnswerOptionInput;
