import { Checkbox } from "primereact/checkbox";
import { Controller, useFormContext } from "react-hook-form";

const TextOptions = ({ path }) => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-col gap-4 md:flex-row">
      <Controller
        name={`${path}.trim`}
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-2">
            <Checkbox
              {...field}
              inputId={field.name}
              name={field.name}
              checked={field.value}
            />
            <label htmlFor={field.name} className="cursor-pointer">
              Ignore Whitespace
            </label>
          </div>
        )}
      />
      <Controller
        name={`${path}.caseSensitive`}
        control={control}
        render={({ field }) => (
          <div className="flex items-center gap-2">
            <Checkbox
              {...field}
              inputId={field.name}
              name={field.name}
              checked={field.value}
            />
            <label htmlFor={field.name} className="cursor-pointer">
              Use Case Sensitive Comparison
            </label>
          </div>
        )}
      />
    </div>
  );
};

export default TextOptions;
