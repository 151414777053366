import { matchPattern } from "../../../my-services/manage-service/components/rules/actions/helpers";
import {
  OPERATORS,
  QUESTION_TYPES_ENUM,
  TEXT_COMPARISON_OPERATORS,
  NUMBER_COMPARISON_OPERATORS,
  DEFAULT_ANSWERS,
  DEFAULT_CONSTRAINTS,
  ACTIONS_ENUM,
  DEFAULT_QUESTIONS,
  DEFAULT_QUESTIONS_IDS,
  SERVICE_JSON_VERSION,
  CONDITION_TYPE_ENUM,
  DEFAULT_SELF_EVALUATING_QUESTIONS_IDS,
  RESOURCES_ENUM,
} from "./constants";
import { useServiceStore } from "./store";

export const getComparisonOperators = (questionType) => {
  switch (questionType) {
    case QUESTION_TYPES_ENUM.BOOLEAN.value:
      return [OPERATORS.EQUAL];

    case QUESTION_TYPES_ENUM.DROPDOWN.value:
    case QUESTION_TYPES_ENUM.MULTIPLE_CHOICE.value:
      return [OPERATORS.EQUAL, OPERATORS.IN];

    case QUESTION_TYPES_ENUM.TEXT.value:
      return [OPERATORS.EQUAL, OPERATORS.IN, ...TEXT_COMPARISON_OPERATORS];

    case QUESTION_TYPES_ENUM.NUMBER.value:
    case QUESTION_TYPES_ENUM.DECIMAL.value:
      return [OPERATORS.EQUAL, OPERATORS.IN, ...NUMBER_COMPARISON_OPERATORS];

    default:
      return [OPERATORS.EQUAL];
  }
};

export const preventEnterDefault = (e) => {
  if (e.key === "Enter") e.preventDefault();
};

export const getSwapCPTAction = (rulesGroup) => {
  if (!rulesGroup || !rulesGroup.action || !rulesGroup.action.on) {
    return { old: [], new: [] };
  }
  const { key, on } = rulesGroup.action;
  if (key === ACTIONS_ENUM.SWAP_CPT.value) {
    const { old = [], new: newItems = [] } = on;

    return { old, new: newItems };
  }
  return { old: [], new: [] };
};

export const getMatchedDataWithUnits = (data, criteria) => {
  if (!Array.isArray(data) || !Array.isArray(criteria)) {
    return [];
  }

  const criteriaMap = criteria.reduce(
    (acc, { id, units, questionId }) => ({
      ...acc,
      [id]: { units, questionId },
    }),
    {},
  );

  return data
    .filter((item) => criteriaMap[item.id])
    .map((item) => ({
      ...item,
      units: criteriaMap[item.id]?.units,
      questionId: criteriaMap[item.id]?.questionId,
    }));
};

export const filterDataBasedOnKey = (rulesGroup, dataOfAllBillableItems) => {
  if (!rulesGroup || !rulesGroup.action || !rulesGroup.action.on) {
    return [];
  }

  const { key, on } = rulesGroup.action;

  if (
    key === ACTIONS_ENUM.ADD_CPT.value ||
    key === ACTIONS_ENUM.REMOVE_UNITS_FROM_EXISTING.value ||
    key === ACTIONS_ENUM.ADD_UNITS_TO_EXISTING.value ||
    key === ACTIONS_ENUM.SET_UNITS.value ||
    key === ACTIONS_ENUM.REMOVE_ALL_EXCEPT.value ||
    key === ACTIONS_ENUM.REMOVE_CPT.value
  ) {
    return getMatchedDataWithUnits(dataOfAllBillableItems, on);
  }

  if (key === ACTIONS_ENUM.REMOVE_MATCHING_PATTERN.value) {
    return dataOfAllBillableItems.filter((item) =>
      matchPattern(item.hcpcs, on.pattern),
    );
  }

  return [];
};

export const getMatchedDataFromRulesGroup = (rulesGroup, allData) => {
  if (
    !rulesGroup?.action?.on ||
    !Array.isArray(rulesGroup.action.on) ||
    !Array.isArray(allData)
  ) {
    return [];
  }
  const dataIds = rulesGroup.action.on.map((data) => data.id);
  return allData.filter((item) => dataIds.includes(item.id));
};

export const filteredQuestionsByIds = (rule, allData) => {
  const questionIds = rule.conditions.map((condition) => condition.questionId);
  return allData.filter((item) => questionIds.includes(item.id));
};

export const filteredGroupQuestionsByIds = (rulesGroup, allData) => {
  const questionIds = rulesGroup.conditionalGroups.flatMap((group) =>
    group.conditions.map((condition) => condition.questionId),
  );

  return allData.filter((item) => questionIds.includes(item.id));
};

export const getDefaultAnswers = (questionType) => {
  return DEFAULT_ANSWERS[questionType] || DEFAULT_ANSWERS.DEFAULT;
};
export const getDefaultConstraints = (questionType) => {
  return DEFAULT_CONSTRAINTS[questionType] || DEFAULT_CONSTRAINTS.DEFAULT;
};

export const checkRuleLogicalOperator = (
  logicalOperator,
  conditionalGroups,
  userAnswers,
  appliedResources,
) => {
  const shouldValidateAllRules = logicalOperator === OPERATORS.ALL.value;

  const checkAllOrAnyConditions = (group) =>
    checkConditionLogicalOperator(
      group.operator,
      group.conditions,
      userAnswers,
      appliedResources,
    );

  return shouldValidateAllRules
    ? conditionalGroups.every(checkAllOrAnyConditions)
    : conditionalGroups.some(checkAllOrAnyConditions);
};

export const checkConditionLogicalOperator = (
  logicalOperator,
  conditions,
  userAnswers,
  appliedResources,
) => {
  const shouldValidateAllConditions = logicalOperator === OPERATORS.ALL.value;

  let comparedValues;
  const checkConditionEquality = (condition) => {
    const isSelfEvaluatingType =
      condition.type === CONDITION_TYPE_ENUM.SELF_EVAL.value;

    if (isSelfEvaluatingType) {
      const { locations, documents, billableItems } = appliedResources;
      switch (condition.questionId) {
        case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS:
          const isBillableItem =
            condition.on === RESOURCES_ENUM.BILLABLE_ITEMS.value;
          const isLocation = condition.on === RESOURCES_ENUM.LOCATIONS.value;
          const isDocument = condition.on === RESOURCES_ENUM.DOCUMENTS.value;

          if (isBillableItem) {
            comparedValues = billableItems.map((billableItemToCompareTo) => ({
              value: billableItemToCompareTo?.id,
            }));
          } else if (isLocation) {
            comparedValues = locations.map((loc) => ({ value: loc }));
          } else if (isDocument) {
            comparedValues = documents.map((doc) => ({ value: doc }));
          }
          break;
        case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE:
          const singleAnswerIndex = 0;

          comparedValues = [
            {
              value:
                billableItems?.find(
                  (billableItemToCompareTo) =>
                    condition.answers[singleAnswerIndex].value.id ===
                    billableItemToCompareTo?.id?.toString(),
                )?.units || 0,
            },
          ];
          break;
        default:
          break;
      }
    } else {
      comparedValues = userAnswers[condition.questionId];
    }

    return checkEquality(
      condition,
      comparedValues,
      { trim: condition.trim, caseSensitive: condition.caseSensitive },
      appliedResources,
    );
  };

  return shouldValidateAllConditions
    ? conditions.every(checkConditionEquality)
    : conditions.some(checkConditionEquality);
};

const checkEquality = (
  condition,
  comparedValues,
  constraints,
  appliedResources,
) => {
  const { type, questionId, equalityOperator, comparisonOperator, answers } =
    condition;
  const shouldNotBeAnswered = equalityOperator === OPERATORS.NOT_ANSWERED.value;
  const shouldBeEqualTo = equalityOperator === OPERATORS.EQUAL.value;
  const shouldNotBeEqualTo = equalityOperator === OPERATORS.NOT_EQUAL.value;

  const isSelfEvaluatingType = type === CONDITION_TYPE_ENUM.SELF_EVAL.value;

  if (isSelfEvaluatingType) {
    const { billableItems } = appliedResources;
    let achieved = false;
    switch (questionId) {
      case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.EXISTS:
        achieved = checkComparison(
          comparisonOperator,
          answers,
          comparedValues,
          constraints,
        );

        if (shouldBeEqualTo) {
          return achieved;
        } else if (shouldNotBeEqualTo) {
          return !achieved;
        }
        break;
      case DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE:
        const singleAnswerIndex = 0;

        const billableItemToEvaluate = billableItems?.find(
          (billableItemToCompareTo) =>
            answers[singleAnswerIndex].value.id ===
            billableItemToCompareTo?.id?.toString(),
        );

        if (!billableItemToEvaluate && !condition.parseSettings.treatAsZero)
          return false;

        achieved = checkComparison(
          comparisonOperator,
          answers,
          comparedValues,
          constraints,
          questionId,
        );

        if (shouldBeEqualTo) {
          return achieved;
        } else if (shouldNotBeEqualTo) {
          return !achieved;
        }
        break;
      default:
        break;
    }
  } else {
    const answered = comparedValues?.some((ans) =>
      ans?.value?.toString().trim(),
    );

    if (shouldNotBeAnswered) return !answered;
    if (!answered) return false;

    const comparisonResult = checkComparison(
      comparisonOperator,
      answers,
      comparedValues,
      constraints,
    );

    if (shouldBeEqualTo) {
      return comparisonResult;
    } else if (shouldNotBeEqualTo) {
      return !comparisonResult;
    }
  }
};

const checkComparison = (
  comparisonOperator,
  options,
  userAnswers,
  constraints,
  questionId,
) => {
  const normalizedOptions = getNormalizedText(options, constraints);
  const normalizedUserAnswers = getNormalizedText(userAnswers, constraints);

  const isCompareBIllableItemUnits =
    questionId === DEFAULT_SELF_EVALUATING_QUESTIONS_IDS.UNIT_COMPARE;

  const getOptionValue = (option) =>
    isCompareBIllableItemUnits ? option?.value?.units : option?.value;

  switch (comparisonOperator) {
    case OPERATORS.IN.value:
    case OPERATORS.EQUAL.value:
      return options?.some((option) =>
        normalizedUserAnswers?.some(
          (answer) =>
            answer?.value?.toString() === getOptionValue(option).toString(),
        ),
      );
    case OPERATORS.GREATER_THAN.value:
      return options?.some((option) =>
        normalizedUserAnswers?.some(
          (answer) => +answer?.value > +getOptionValue(option),
        ),
      );
    case OPERATORS.GREATER_OR_EQUAL.value:
      return options?.some((option) =>
        normalizedUserAnswers?.some(
          (answer) => +answer?.value >= +getOptionValue(option),
        ),
      );
    case OPERATORS.LESS_THAN.value:
      return options?.some((option) =>
        normalizedUserAnswers?.some(
          (answer) => +answer?.value < +getOptionValue(option),
        ),
      );
    case OPERATORS.LESS_OR_EQUAL.value:
      return options?.some((option) =>
        normalizedUserAnswers?.some(
          (answer) => +answer?.value <= +getOptionValue(option),
        ),
      );
    case OPERATORS.BETWEEN.value:
      if (isCompareBIllableItemUnits) {
        return options?.some((option) =>
          normalizedUserAnswers?.some(
            (answer) =>
              +answer?.value >= +option.value.min &&
              +answer?.value <= +option.value.max,
          ),
        );
      } else {
        return normalizedUserAnswers?.some(
          (answer) =>
            +answer?.value >= +normalizedOptions[0].value &&
            +answer?.value <= +normalizedOptions[1].value,
        );
      }

    case OPERATORS.STARTS_WITH.value:
      return normalizedOptions?.some((option) =>
        normalizedUserAnswers?.some((answer) =>
          answer?.value?.toString().startsWith(option?.value?.toString()),
        ),
      );

    case OPERATORS.ENDS_WITH.value:
      return normalizedOptions?.some((option) =>
        normalizedUserAnswers?.some((answer) =>
          answer?.value?.toString().endsWith(option?.value?.toString()),
        ),
      );

    case OPERATORS.CONTAINS.value:
      return normalizedOptions?.some((option) =>
        normalizedUserAnswers?.some((answer) =>
          answer?.value?.toString().includes(option?.value?.toString()),
        ),
      );

    default:
      return false;
  }
};

const getNormalizedText = (answers, constraints) => {
  let normalizedAnswers = answers;

  if (!constraints.caseSensitive) {
    normalizedAnswers = normalizedAnswers?.map((ans) => ({
      value: ans?.value?.toString().toLowerCase(),
    }));
  }

  if (constraints.trim) {
    normalizedAnswers = normalizedAnswers?.map((ans) => ({
      value: ans?.value?.toString().trim(),
    }));
  }

  return normalizedAnswers;
};

export const addStatesToDefaultQuestions = (states) => {
  const patientAddressIndex = DEFAULT_QUESTIONS.findIndex(
    (q) => q.id === DEFAULT_QUESTIONS_IDS.ADDRESS,
  );

  DEFAULT_QUESTIONS[patientAddressIndex] = {
    ...DEFAULT_QUESTIONS[patientAddressIndex],
    answers: states.map((state) => ({
      id: state.id,
      value: state.name,
    })),
  };
};

export const generateServiceBody = ({ formData }) => {
  const { rulesGroups, questions } = useServiceStore.getState();

  const data = {
    version: SERVICE_JSON_VERSION,
    ...formData,
    questions,
    rulesGroups,
  };
  const { billableItems, checkInPass, ...rest } = formData;
  const defaultBillablesIds = billableItems.map(
    (billableItem) => billableItem.id,
  );

  const rulesLocationsIds = [];
  const rulesBillablesIds = [];
  rulesGroups.forEach((rulesGroup) => {
    const isAddLocation =
      rulesGroup.action.key === ACTIONS_ENUM.ADD_LOCATIONS.value;
    const isAddCPT = rulesGroup.action.key === ACTIONS_ENUM.ADD_CPT.value;
    const isSwapCpt = rulesGroup.action.key === ACTIONS_ENUM.SWAP_CPT.value;

    if (isAddLocation)
      rulesLocationsIds.push(...rulesGroup.action.on.map((loc) => loc.id));
    if (isAddCPT)
      rulesGroup.action.on.forEach((billableItem) =>
        rulesBillablesIds.push(billableItem.id),
      );
    if (isSwapCpt)
      rulesGroup.action.on.new.forEach((billableItem) =>
        rulesBillablesIds.push(billableItem.id),
      );
  });

  return {
    ...rest,
    sendCheckInPass: checkInPass,
    billables: defaultBillablesIds,
    rulesBillables: rulesBillablesIds,
    rulesLocations: rulesLocationsIds,
    serviceJson: JSON.stringify(data),
    isTemplate: false,
  };
};
