import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Contactus,
  DevLinkProvider,
  Explor,
  Features,
  Hero,
} from "../../devlink";
import "../../devlink/global.css";
import useAxios from "../../hooks/useAxios";
import useHandleResize from "../../hooks/useHandleResize";
import useTokenData from "../../hooks/useTokenData";
import LogoIcon from "../../iconComponents/LogoIcon";
import { useTokenStore } from "../../store/store";
import { blockSpecialCharsPattern } from "../../utils/helpers";
import { ServicesCardSkeleton } from "../../components/skeletons/ServicesCardSkeleton";
import GlobalStyle from "./styles/LandingPageStyle";

function ServicesCard({
  serviceName,
  serviceDescriprtion,
  entityName,
  serviceType,
  minCost,
  maxCost,
  savedService,
  serviceEntityId,
  serviceId,
  fetchDataFunction,
  imageSrc,
  isEntityPage = false,
  showOrderButton = false,
  hideAddToFavorite = false,
  unclickableCard = false,
  orderButtonClick,
  onCardClick,
  disableOrderButton,
}) {
  const navigate = useNavigate();
  const { http } = useAxios();
  const { entityId } = useTokenData();
  const serviceTypeTemplate = (
    <>
      {serviceType === 1 ? (
        <p className="flex items-center justify-center rounded-lg bg-[#E0E7FF] p-2 text-xs text-[#6366F1]">
          Scheduled Appointment Service
        </p>
      ) : (
        <p
          className="flex items-center justify-center rounded-lg p-2 text-xs"
          style={{ backgroundColor: "#A0D9D9", color: "#2F4F4F" }}
        >
          Walk-In Service
        </p>
      )}
    </>
  );

  const saveService = async () => {
    await http.post("/Services/SaveService", {
      entityId: entityId,
      serviceId: serviceId,
    });
    fetchDataFunction();
  };
  const unSaveService = async () => {
    await http.delete(`/Services/UnSaveService/${entityId}/${serviceId}`);
    fetchDataFunction();
  };
  return (
    <div
      onClick={unclickableCard ? undefined : onCardClick}
      className={`mb-3 rounded-md bg-white p-5 font-sans text-black drop-shadow-sm ${
        unclickableCard ? "" : "cursor-pointer"
      }`}
    >
      <div className="mb-2 flex w-full justify-between font-semibold">
        <div className="flex gap-3 text-xl">
          {serviceName} {serviceTypeTemplate}
        </div>
        {hideAddToFavorite ? null : (
          <div>
            {savedService ? (
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  unSaveService();
                }}
                className="pi pi-bookmark-fill cursor-pointer rounded-full border-2 p-2 text-purple-700"
              />
            ) : (
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  saveService();
                }}
                className="pi pi-bookmark cursor-pointer rounded-full border-2 p-2 text-purple-700"
              />
            )}
          </div>
        )}
      </div>
      <div className="mb-2 text-sm">{serviceDescriprtion}</div>
      <div className="item-start flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
        <span className="flex flex-col content-center gap-2 sm:flex-row sm:items-center">
          <span
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
              if (!isEntityPage && !unclickableCard) {
                navigate(`/ordering/practices/${serviceEntityId}`);
              }
            }}
          >
            {" "}
            {imageSrc ? (
              <img
                className="h-[30px] w-[30px] rounded-full"
                alt=""
                src={imageSrc}
              />
            ) : (
              <Avatar
                label={entityName[0]}
                shape="circle"
                className="bg-blue-500 text-white"
              />
            )}
            <span className="text-sm">{entityName}</span>
          </span>
          {/* <span className="flex items-center">
            <i className="pi pi-dollar text-green-700" />
            <span className="text-sm">Starts from ~ ${minCost}</span>
          </span> */}
        </span>
        {showOrderButton ? (
          <Button
            type="button"
            onClick={orderButtonClick}
            severity="success"
            label="Order now"
          />
        ) : null}
      </div>
    </div>
  );
}

export const LandingPage = () => {
  const { http } = useAxios();
  const navigate = useNavigate();
  const [services, setServices] = useState();
  const [servicesCount, setServicesCount] = useState(null);
  const [searchService, setSearchService] = useState("");
  const [selectedState, setSelectedState] = useState(null);
  const [cities, setCities] = useState("");
  const [status, setStatus] = useState("idle");

  // Handle Paginator
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);

  const { token } = useTokenStore();
  const { isMobile } = useHandleResize();

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
    if (first !== event.first) {
      getServices(event.page + 1, event.rows);
    }
  };

  const getCities = async () => {
    try {
      const response = await http.get(`/States/GetStates`);
      setCities(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getServices = async (pageCount, pageSize) => {
    try {
      setStatus("loading");
      const response = await http.get(
        `/Services/LandingPageServices?Filters=(name|Entity.Name)@=*${
          searchService || ""
        }&Page=${pageCount || 1}&PageSize=${pageSize || 5}&searchState=${
          selectedState || ""
        }`,
      );
      setServices(response.data.services);
      setServicesCount(response.data.count);
      if (response.data && response.data.services.length !== 0) {
        setStatus("success");
      } else {
        setStatus("no-data");
      }
    } catch (error) {
      setStatus("no-data");
      console.error(error);
    }
  };

  useEffect(() => {
    getServices(1, rows);
    getCities();
  }, []);

  return (
    <>
      <GlobalStyle />
      <div className="body">
        {/* Navbar */}
        <div className="custom-nav flex items-center justify-between p-5 sm:px-10 md:px-20">
          <div className="">
            <LogoIcon />
          </div>
          {!token ? (
            <div className="z-20 flex items-center gap-2">
              <Button
                onClick={() => navigate("/login")}
                text
                className="font-semibold text-purple-700"
              >
                Log in
              </Button>
              <Button
                onClick={() => navigate("/registration")}
                className="font-semibold"
              >
                Sign Up
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => navigate("/chooseEntity")}
              className="font-semibold"
            >
              Dashboard
            </Button>
          )}
        </div>

        <DevLinkProvider>
          <Hero />
          <Explor />
          <div className="m-[6%] mb-8 p-5">
            <div className="grid grid-cols-9 justify-between gap-2">
              <div className="p-input-icon-left col-span-9 md:col-span-4">
                <i className="pi pi-search" />
                <InputText
                  keyfilter={blockSpecialCharsPattern}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setFirst(0);
                      getServices();
                    }
                  }}
                  placeholder="Search by service, provider etc."
                  value={searchService}
                  onChange={(e) => {
                    setSearchService(e.target.value);
                  }}
                  className="w-full"
                />
              </div>
              <div className="col-span-5 md:col-span-3">
                <Dropdown
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                  options={cities}
                  showClear
                  placeholder="Select State"
                  className="w-full"
                />
              </div>
              <div className="col-span-4 md:col-span-2">
                <Button
                  onClick={() => {
                    setFirst(0);
                    getServices();
                  }}
                  label="Search"
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="m-[6%] mt-0 rounded-md border border-gray-200 p-5">
            {status === "success"
              ? services?.map((service) => {
                  let currentServiceData;
                  if (service.serviceData) {
                    currentServiceData = service.serviceData;
                  } else {
                    currentServiceData = service;
                  }
                  return (
                    <div key={currentServiceData.id}>
                      <ServicesCard
                        serviceName={currentServiceData?.name}
                        serviceDescriprtion={currentServiceData?.description}
                        serviceType={currentServiceData?.serviceType}
                        entityName={currentServiceData?.entityName}
                        minCost={currentServiceData?.minFees}
                        maxCost={currentServiceData?.maxFees}
                        savedService={currentServiceData?.isSavedService}
                        serviceId={currentServiceData?.id}
                        serviceEntityId={currentServiceData?.entityId}
                        imageSrc={currentServiceData.entityImage}
                        showOrderButton={true}
                        hideAddToFavorite={true}
                        unclickableCard={true}
                        onCardClick={() => navigate("/registration")}
                        orderButtonClick={() => navigate("/registration")}
                      />
                    </div>
                  );
                })
              : status === "loading"
                ? Array(5)
                    .fill(0)
                    .map((n, i) => <ServicesCardSkeleton key={i} />)
                : status === "no-data" && (
                    <p className="mb-3 rounded bg-white p-5 text-gray-400">
                      No results found
                    </p>
                  )}
            {servicesCount > 0 && (
              <Paginator
                className="rounded-t-none"
                first={first}
                rows={rows}
                totalRecords={servicesCount}
                onPageChange={onPageChange}
                template={
                  isMobile
                    ? { layout: "PrevPageLink CurrentPageReport NextPageLink" }
                    : {
                        layout:
                          "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
                      }
                }
              />
            )}
          </div>
          <Features />
          <Contactus />
        </DevLinkProvider>
      </div>
    </>
  );
};
