import React from "react";

import { Button } from "primereact/button";

import { formatDate } from "../../utils/helpers";

export default function TeamCards({
  teams,
  handleManageTeam,
  handleDeleteTeam,
}) {
  if (!teams || teams.length === 0) {
    return (
      <div className="col-span-12 flex flex-col items-center justify-center gap-4 py-10 text-gray-500">
        <i className="pi pi-users text-5xl"></i>
        <p className="text-lg font-semibold">No teams available</p>
        <p className="text center text-sm text-gray-400">
          Start by creating a new team to manage your members.
        </p>
      </div>
    );
  }

  return teams.map((team) => (
    <div
      key={team.id}
      className="col-span-12 grid grid-cols-12 rounded-xl border-slate-200 bg-white p-6 shadow-sm hover:bg-slate-50 sm:col-span-6"
    >
      <div className="col-span-6 flex flex-col gap-2">
        <label className="text-lg font-semibold">{team.name}</label>

        <div className="flex items-center gap-1">
          <i className="pi pi-users text-gray-600" />
          <span className="text-sm font-semibold text-gray-700">
            {team.entityUsersCount}
          </span>
        </div>
        <div className="text-xs text-gray-500">
          <span className="font-semibold">Created at:</span>{" "}
          {formatDate(team.createdAt)}
        </div>
      </div>
      <div className="col-span-6 flex items-center justify-center gap-3">
        <Button
          icon="pi pi-cog"
          text
          type="button"
          className="bg-slate-200"
          onClick={() => handleManageTeam(team)}
          pt={{
            icon: {
              className: "text-xl ",
            },
          }}
        />

        <Button
          className="bg-slate-200"
          text
          severity="danger"
          icon="pi pi-trash"
          type="button"
          onClick={() => handleDeleteTeam(team.id)}
        ></Button>
      </div>
    </div>
  ));
}
