import { useState } from "react";

import { useFormContext } from "react-hook-form";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import { useServiceStore } from "../utils/store";
import useTokenData from "../../../../../../hooks/useTokenData";
import { CreateBillableItem } from "../../../components/CreateBillableItem";
import useDelayedInputChange from "../../../../../../hooks/useDelayedInputChange";
import SelectSingleBillableTable from "../../components/SelectSingleBillableTable";
import {
  getAllBillableItemsInRules,
  getBillableItemsIds,
  replace,
} from "../../../my-services/manage-service/components/rules/actions/helpers";

const ChooseBillableToReplaceDialog = ({
  billableToReplaceDialogVisible,
  setBillableToReplaceDialogVisible,
  showConfirmReplace,
  dataToReplace,
  getBillableItems,
}) => {
  const { entityId } = useTokenData();
  const { watch, setValue } = useFormContext();
  const { rulesGroups, setRulesGroups } = useServiceStore();

  const [refetch, setRefetch] = useState(false);
  const [searchValue, handleInputChange, setSearchValue] =
    useDelayedInputChange(refetch, setRefetch);

  const [collapse, setCollapse] = useState(true);

  const selectedItems = watch("billableItems");
  const [createBillableTableVisible, setCreateBillableTableVisible] =
    useState(false);

  const handleCreateBillableItem = (newBillableItem) => {
    const oldId = dataToReplace.id;
    const newId = newBillableItem.id;

    const newBillableItems = replace(selectedItems, oldId, newId);
    const newRules = replace(rulesGroups, oldId, newId);

    setValue("billableItems", newBillableItems);
    setRulesGroups(newRules);

    getBillableItems(getBillableItemsIds(newBillableItems));
  };
  const handleBeforeSubmit = (submit, formData) => {
    showConfirmReplace(formData, () => submit(formData));
  };

  return (
    <>
      <Dialog
        blockScroll
        draggable={false}
        className="w-11/12 lg:w-[70vw]"
        header="create Billable Items"
        visible={createBillableTableVisible}
        onHide={() => {
          setCollapse(true);
          setCreateBillableTableVisible(false);
        }}
      >
        <CreateBillableItem
          setVisible={setCreateBillableTableVisible}
          handleCreateBillableItem={handleCreateBillableItem}
          handleBeforeSubmit={handleBeforeSubmit}
          collapse={collapse}
        />
      </Dialog>

      <Dialog
        blockScroll
        draggable={false}
        visible={billableToReplaceDialogVisible}
        onHide={() => {
          setSearchValue("");
          setBillableToReplaceDialogVisible(false);
          setCollapse(true);
        }}
        header="Choose a billable item to replace"
        className="w-11/12 lg:w-[80vw]"
      >
        <div className="flex flex-col">
          <SelectSingleBillableTable
            link={`/BillableItems/GetAllBillableItems/${entityId}?Filters=${searchValue}`}
            refetch={refetch}
            searchValue={searchValue}
            handleInputChange={handleInputChange}
            emptyMessage={
              searchValue ? (
                <p className="text-center">
                  No results found in your practice's configured billable items.
                  Alternatively, you can{" "}
                  <span
                    className="cursor-pointer font-semibold text-light-purple underline"
                    onClick={() => {
                      setCollapse(false);
                      setCreateBillableTableVisible(true);
                      setBillableToReplaceDialogVisible(false);
                    }}
                  >
                    Search in National HCPCS Codes{" "}
                  </span>
                  and import a new item into your practice's billable items.
                </p>
              ) : (
                <p className="text-center">
                  You haven't created any billable items yet. You can create a
                  new item by clicking on 'Create New Billable Item'.
                </p>
              )
            }
            payload={getBillableItemsIds([
              ...getAllBillableItemsInRules(),
              ...selectedItems,
            ])}
            selection={{}}
            onSelectionChange={(e) => {
              showConfirmReplace(e.value);
            }}
          />
          <Divider
            layout="horizontal"
            align="center"
            className="w-10/12 self-center"
          >
            <b>OR</b>
          </Divider>
          <div className="flex justify-center">
            <Button
              id="serviceBillables"
              label="Create new billable item"
              type="button"
              outlined
              onClick={() => {
                setSearchValue("");
                setCreateBillableTableVisible(true);
                setBillableToReplaceDialogVisible(false);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ChooseBillableToReplaceDialog;
