import { RULE_TYPES } from "../../utils/constants";
import SortButtons from "./SortButtons";
import TypeCard from "./TypeCard";

const ActionHeaderView = ({
  rulesGroup,
  handleMoveToTop,
  handleMoveToBottom,
}) => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-2">
        <span className="text-xl font-semibold capitalize text-[#3D3877]">
          {rulesGroup?.title}
        </span>
        <TypeCard
          type={RULE_TYPES[rulesGroup.type]?.label || rulesGroup.type}
        />
      </div>
      <SortButtons
        handleMoveToTop={handleMoveToTop}
        handleMoveToBottom={handleMoveToBottom}
      />
    </div>
  );
};

export default ActionHeaderView;
