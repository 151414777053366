import { useMemo } from "react";

import { useFormContext } from "react-hook-form";

import SelectDocuments from "./SelectDocuments";
import { useServiceStore } from "../../../utils/store";
import { ACTIONS_ENUM } from "../../../utils/constants";
import { useServiceDetailsContext } from "../../../context/ServiceDetailsContext";

const SelectDocsForm = ({ field, error }) => {
  const { watch } = useFormContext();
  const { rulesGroups } = useServiceStore();

  const { defaultDocs } = useServiceDetailsContext();

  const getServiceDocsInRules = (ruleId) => {
    const documents = rulesGroups.flatMap((item) => {
      if (item.id === ruleId) return [];

      if (item.action.key === ACTIONS_ENUM.ADD_DOCS.value) {
        return item.action.on;
      }
      return [];
    });

    return [...documents, ...defaultDocs];
  };

  const allServiceDocuments = useMemo(
    () => getServiceDocsInRules(watch("id")),
    [],
  );

  return (
    <>
      <SelectDocuments
        action={watch("action.key")}
        allServiceDocuments={allServiceDocuments}
        serviceDocumentsIds={field.value.map((v) => v.id)}
        selectedDocs={field.value.map((v) => v.id)}
        handleSaveSelection={(unsavedSelectedDocs) => {
          field.onChange(unsavedSelectedDocs.map((doc) => ({ id: doc.id })));
        }}
        handleAddNew={(id) => {
          field.onChange([...field.value, { id }]);
        }}
        handleRemove={(rowData) => {
          const filteredDocs = field.value.filter(
            (doc) => doc.id !== rowData.id,
          );
          field.onChange(filteredDocs);
        }}
      />
      {error && <p className="text-red-500">{error.message}</p>}
    </>
  );
};

export default SelectDocsForm;
