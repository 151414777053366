import { useEffect, useState } from "react";

import useAxios from "../../../../../../../hooks/useAxios";
import useTokenData from "../../../../../../../hooks/useTokenData";
import { showErrorToast } from "../../../../../../../utils/apiUtils";

const useFetchBillablesByIds = (ids) => {
  const { http } = useAxios();

  const [isLoading, setIsLoading] = useState(false);
  const [billableItems, setBillableItems] = useState([]);

  const { entityId } = useTokenData();

  const getBillableItems = async (ids) => {
    if (!ids?.length) return;
    setIsLoading(true);
    try {
      const response = await http.put(
        `/BillableItems/GetBillableItemsByIds/${entityId}`,
        ids,
      );

      setBillableItems(response.data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      showErrorToast({ error });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (ids?.length && ids.some((id) => id)) {
      getBillableItems(ids);
    }
  }, []);

  return { isLoading, billableItems, getBillableItems };
};

export default useFetchBillablesByIds;
