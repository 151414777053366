import { useRef } from "react";

import { useForm } from "react-hook-form";

import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import { fileTyper } from "../../../../utils/fileTypesUtils";
import UploadFileIcon from "../../../../iconComponents/UploadFileIcon";

const Documentation = ({
  submitRef,
  setActiveIndex,
  uploadedFiles,
  setUploadedFiles,
}) => {
  const toast = useRef();
  const { handleSubmit } = useForm({
    mode: "onChange",
  });
  const deleteFile = (deletedFile) => {
    const newFiles = uploadedFiles.filter((file) => file !== deletedFile);
    setUploadedFiles(newFiles);
  };

  const preventDefault = (event) => {
    event.preventDefault();
  };
  const handleFilesUpload = (files) => {
    Array.from(files).forEach((file) => {
      if (fileTyper(file.type) && isValidFileSize(file)) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            fileDetails: file,
            fileType: fileTyper(file.type),
            fileName: file.name,
            filePropertyName: 8,
          },
        ]);
      } else {
        if (!fileTyper(file.type)) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "The file format is not supported. Please upload a file with one of the following extensions: jpg, .jpeg, .pdf, .docx, .dcm, .tiff, .gif, .txt, .csv, .xlsx, .svg, .doc.",
            life: 3000,
          });
        } else if (!isValidFileSize(file)) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "The file you're trying to upload exceeds the maximum size limit of 5MB. Please compress or resize the file.",
            life: 3000,
          });
        }
      }
    });
  };
  const isValidFileSize = (file) => {
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    return file.size <= maxSizeInBytes;
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    Array.from(files).forEach((file) => {
      if (fileTyper(file.type) && isValidFileSize(file)) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            fileDetails: file,
            fileType: fileTyper(file.type),
            filePropertyName: file.name,
          },
        ]);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Invalid file type or size.",
          life: 3000,
        });
      }
    });
  };

  const onSubmit = async (formData) => {
    if (!uploadedFiles[0]) {
      confirmDialog({
        message:
          "Are you sure you want to continue without uploading any patient medical records?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          setActiveIndex((old) => old + 1);
        },
      });
      return;
    }
    setActiveIndex((old) => old + 1);
  };

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <form
        ref={submitRef}
        className="w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div>
            <h1 className="font-inter font-extrabold capitalize">
              attach patient medical records
            </h1>
            <p className="pt-1 text-p text-gray-600">
              Please upload all patient medical records, including medical
              history, laboratory reports, and radiology images.
            </p>
          </div>
          <div className="mt-4 rounded-lg bg-slate-100 p-4 shadow-md">
            <div
              onDrop={handleDrop}
              onDragOver={preventDefault}
              onDragEnter={preventDefault}
              className="flex flex-col justify-between sm:flex-row"
            >
              <div>
                <UploadFileIcon />
                <span className="font-inter font-extrabold capitalize">
                  patient medical records
                </span>
              </div>
              <div>
                <Button
                  style={{ backgroundColor: "white" }}
                  onClick={() => {
                    const inputElement = document.getElementById("fileInput");
                    if (inputElement) {
                      inputElement.click();
                    }
                  }}
                  label={<span className="text-gray-500">Upload</span>}
                  text
                  raised
                  icon="pi pi-upload text-gray-500"
                  iconPos="right"
                  type="button"
                />
                <input
                  id="fileInput"
                  type="file"
                  onChange={(event) => {
                    const files = event.target.files;
                    handleFilesUpload(files);
                  }}
                  className="hidden"
                />
              </div>
            </div>
            <div className="mt-4">
              {uploadedFiles.map((file, index) => (
                <div key={index} className="mb-2 mt-2 flex items-center">
                  <div className="flex basis-1/3 items-center">
                    <i className="pi pi-file-o pr-2 text-[30px]" />
                    <span className="truncate">{file.fileDetails.name}</span>
                  </div>
                  <div>
                    <Tooltip target=".remove" />
                    <i
                      onClick={() => deleteFile(file)}
                      className="pi pi-times-circle remove cursor-pointer text-xl"
                      style={{ color: "red" }}
                      data-pr-tooltip="Remove"
                      data-pr-position="bottom"
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Documentation;
