import { convertStandardToAmerican } from "../../utils/numericConversions";

const ForcedNumericValueNotification = ({ isAmerican, value }) => {
  const { feet, inches } = isAmerican
    ? convertStandardToAmerican(value)
    : { feet: 0, inches: 0 };
  return isAmerican ? (
    <>
      {feet} ft. {inches} in.
    </>
  ) : (
    <>{value}</>
  );
};

export default ForcedNumericValueNotification;
