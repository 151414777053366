import { useEffect, useMemo, useState } from "react";

import { useFormContext } from "react-hook-form";

import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import ReferenceTemplate from "./ReferenceTemplate";
import { useServiceStore } from "../../../utils/store";
import { ModTemplate } from "../../../templates/ModTemplate";
import useFetchBillablesByIds from "../useFetchBillablesByIds";
import { getAllBillableItemsInRules, getBillableItemsIds } from "./helpers";
import DescriptionWithTooltipTemplate from "../DescriptionWithTooltipTemplate";
import { PriceBillableTemplate } from "../../../templates/PriceBillableTemplate";
import { useServiceDetailsContext } from "../../../context/ServiceDetailsContext";
import {
  DEFAULT_QUESTIONS,
  QUESTION_TYPES_ENUM,
} from "../../../utils/constants";
import { BillableItemsAttributesTemplate } from "../../../templates/BillableItemsAttributesTemplate";

const AdjustBillableUnits = ({ unitsHeader, field, error }) => {
  const { getValues } = useFormContext();

  const { defaultBillableItems } = useServiceDetailsContext();
  const allBillableItemsInRules = useMemo(
    () => [
      ...getAllBillableItemsInRules(getValues("id")),
      ...defaultBillableItems,
    ],
    [],
  );

  const allBillableItemsIDsInRules = useMemo(
    () => getBillableItemsIds(allBillableItemsInRules),
    [],
  );

  const { isLoading, billableItems } = useFetchBillablesByIds(
    allBillableItemsIDsInRules,
  );

  const COLUMNS = [
    { header: "HCPCS", field: "hcpcs" },
    {
      header: "Description",
      body: DescriptionWithTooltipTemplate,
    },
    { header: "Mods", body: ModTemplate },
    {
      header: "Attributes",
      body: BillableItemsAttributesTemplate,
    },
    {
      header: "Price/Unit",
      body: PriceBillableTemplate,
    },
    { header: "Units", field: "units" },
    {
      header: "Units values",
      className: "w-[26rem]",
      body: (e) => <UnitsTemplate rowData={e} field={field} />,
    },
  ];

  const billableItemsWithUnits = useMemo(
    () =>
      billableItems.map((BI) => {
        const matchedBI = allBillableItemsInRules.find(
          (_BI) => BI.id === _BI.id,
        );

        return matchedBI ? { ...BI, units: matchedBI.units } : BI;
      }),
    [billableItems],
  );

  return (
    <>
      <DataTable loading={isLoading} value={billableItemsWithUnits}>
        {COLUMNS.map((column) => (
          <Column
            key={column.header}
            {...column}
            align="center"
            alignHeader="center"
          />
        ))}
      </DataTable>
      {error && <p className="text-red-500">{error.message}</p>}
    </>
  );
};

const UNITS_VALUE_TYPE_ENUM = {
  FIXED: "fixed",
  REFERENCE: "ref",
};

const UnitsTemplate = ({ rowData, field }) => {
  const { watch } = useFormContext();
  const selectedBillables = watch("action.on");
  const actionKey = watch("action.key");

  const current = selectedBillables.find((b) => b.id === rowData.id);

  const [valueType, setValueType] = useState(
    current
      ? current?.units !== undefined
        ? UNITS_VALUE_TYPE_ENUM.FIXED
        : UNITS_VALUE_TYPE_ENUM.REFERENCE
      : null,
  );
  const { questions } = useServiceStore();

  const allQuestions = [...DEFAULT_QUESTIONS, ...questions];

  const numericQuestions = allQuestions.filter((q) => {
    const isNumber = q.type === QUESTION_TYPES_ENUM.NUMBER.value;
    const isDecimal = q.type === QUESTION_TYPES_ENUM.DECIMAL.value;

    return isNumber || isDecimal;
  });

  const removeSelection = () =>
    field.onChange(field.value.filter((item) => item.id !== rowData.id));

  useEffect(() => {
    !current && setValueType(null);
  }, [actionKey]);

  useEffect(() => {
    // clear field value on dropdown clear
    if (!valueType && current) removeSelection();
  }, [valueType]);

  const valueTypeOptions = [
    { label: "By Reference", value: UNITS_VALUE_TYPE_ENUM.REFERENCE },
    { label: "Fixed Value", value: UNITS_VALUE_TYPE_ENUM.FIXED },
  ];

  return (
    <div
      className={`grid ${valueType ? "grid-cols-1 lg:grid-cols-2" : "grid-cols-1 place-items-center"} gap-2`}
    >
      <Dropdown
        showClear
        placeholder="Value type"
        options={valueTypeOptions}
        onChange={(e) => {
          const currentValues = field.value.filter(
            (item) => item.id !== rowData.id,
          );

          if (e.target.value === UNITS_VALUE_TYPE_ENUM.REFERENCE) {
            field.onChange([
              ...currentValues,
              { id: rowData.id, questionId: numericQuestions[0].id },
            ]);
          } else field.onChange(currentValues);

          setValueType(e.target.value);
        }}
        value={valueType}
      />
      {valueType === UNITS_VALUE_TYPE_ENUM.FIXED ? (
        <InputText
          value={current?.units || 0}
          className="w-full"
          onChange={(e) => {
            const value = parseInt(e.target.value, 10) || 0;

            if (!value) {
              if (!current) return;

              removeSelection();

              return;
            }

            if (value === current?.units) return;

            const newValues = current
              ? field.value.map((item) =>
                  item.id === current.id ? { ...item, units: value } : item,
                )
              : [...field.value, { id: rowData.id, units: value }];

            field.onChange(newValues);
          }}
        />
      ) : valueType === UNITS_VALUE_TYPE_ENUM.REFERENCE ? (
        <Dropdown
          options={numericQuestions}
          optionValue="id"
          value={current?.questionId || null}
          className="text-start"
          placeholder="question"
          valueTemplate={(option, props) => {
            if (option) return <ReferenceTemplate option={option} />;

            return <span>{props.placeholder}</span>;
          }}
          itemTemplate={(option) => <ReferenceTemplate option={option} />}
          onChange={(e) => {
            const newValues = field.value.map((item) =>
              item.id === current.id
                ? { ...item, questionId: e.target.value }
                : item,
            );

            field.onChange(newValues);
          }}
        />
      ) : null}
    </div>
  );
};

export default AdjustBillableUnits;
